<template>
    <div class="body" v-loading="loading" element-loading-text="拼命加载中" element-loading-background="rgb(213 213 213 / 57%)">
        <!-- 查询 -->
        <div>
            <el-form :inline="true" label-width="" :model="form" class="demo-form-inline">
                <el-form-item label="企业名称：">
                    <el-input v-model="form.address" placeholder="请输入企业名称"></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-button type="primary" v-if="$hasPermission(['Partner_query'])" @click="query()">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" v-if="$hasPermission(['Partner_add'])" @click="add()">新增</el-button>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['Partner_audit']) & AuditiSactive == false & modeType == 0 & SureForm.purchaseId !== 0">
                    <el-button type="primary" @click="Audit()">审核</el-button>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['Partner_audit']) & AuditiSactive == true & SureisConfirm == false & modeType == 0 & SureForm.purchaseId !== 0">
                    <el-button type="primary" @click="CancelAudit()">取消审核</el-button>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['Partner_audit']) & AuditiSactive == false & SureForm.purchaseId == 0">
                    <el-button type="primary" @click="Audit()">审核</el-button>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['Partner_audit']) & AuditiSactive == true & SureisConfirm == false & SureForm.purchaseId == 0">
                    <el-button type="primary" @click="CancelAudit()">取消审核</el-button>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['Partner_bind']) & SureisConfirm == false & AuditiSactive == true & modeType == 1 & SureForm.purchaseId != 0">
                    <el-button type="primary" @click="openSureDialog()">确认</el-button>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['Partner_bind']) & SureisConfirm == true & modeType == 1 & SureForm.purchaseId != 0">
                    <el-button type="primary" @click="CancelSure()">取消确认</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- 表格 -->
        <div>
            <el-table
                :header-cell-style="headClass"
                :cell-style="rowClass"
                :data="tableData"
                :height="tableHeight"
                highlight-current-row
                @cell-dblclick="dblclick"
                border
                style="width: 100%">
                <el-table-column
                fixed
                prop="date"
                label="选择"
                width="75">
                    <template slot-scope="scope">
                        <el-radio :label="scope.row.id" v-model="radio" @change.native="getCurrentRow(scope.row)">{{''}}</el-radio>
                    </template>
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                prop="enterpriseName"
                :formatter="name"
                label="企业名称"
                width="150">
                </el-table-column>
                <el-table-column
                :formatter="contacts"
                prop="contacts"
                :show-overflow-tooltip="true"
                label="联系人"
                width="">
                </el-table-column>
                <el-table-column
                prop="contactTel"
                :formatter="contactTel"
                label="联系电话"
                width="">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                prop="businessName"
                :formatter="businessName"
                label="业务员"
                width="">
                </el-table-column>
                <el-table-column
                :formatter="businessType"
                prop="businessType"
                label="业务类型"
                width="">
                </el-table-column>
                <el-table-column
                prop="isActive"
                :formatter="isActive"
                label="审核状态"
                width="">
                </el-table-column>
                <el-table-column
                prop="isConfirm"
                :formatter="isConfirm"
                label="确认状态"
                width="">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                prop="creationTime"
                label="创建时间"
                width="">
                </el-table-column>
                <el-table-column
                fixed="right"
                label="操作"
                width="150">
                <template slot-scope="scope">
                    <el-button v-if="$hasPermission(['Partner_details'])" @click="handleClick(scope.row)" type="text" size="small">详情</el-button>
                    <el-button v-if="$hasPermission(['Partner_edit']) & scope.row.isActive == false & scope.row.modeType == 0" @click="modify(scope.row)" type="text" size="small">编辑</el-button>
                    <el-button v-if="$hasPermission(['Partner_delete']) & scope.row.isActive == false & scope.row.modeType == 0" @click="Delete(scope.row)" type="text" size="small">删除</el-button>
                    <el-button v-if="$hasPermission(['Partner_AddressManagement']) & scope.row.isActive == true & scope.row.modeType == 0 & scope.row.purchaseId == 0" @click="AddressManagement(scope.row)" type="text" size="small">地址管理</el-button>
                    <el-button v-if="$hasPermission(['Partner_AddressManagement']) & scope.row.isActive == true & scope.row.modeType == 0 & scope.row.enterpriseId == 0" @click="AddressManagement(scope.row)" type="text" size="small">地址管理</el-button>
                </template>
                </el-table-column>
            </el-table>
            <!-- 表格分页 -->
            <div style="float:right" class="block">
                <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10, 15, 20]"
                :page-size="size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
                </el-pagination>
            </div>
        </div>
        <!-- 表单 -->
        <div>
            <el-dialog
                :title="title"
                :visible.sync="dialogVisible"
                :before-close="handleClose"
                :show-close="false"
                width="55%"
                style="margin-top:-2%">
                <el-form style="width:95%" ref="addform" :rules="addformRules" :model="addform" label-width="110px" class="demo-form-inline">
                    <el-row :gutter="24">
                        <el-col :span="24">
                            <el-form-item v-if="formButton !== 'details'" label="业务类型：" prop="businessType">
                                <el-radio-group v-model="addform.businessType">
                                    <el-radio v-for="item in business" :key="item.value" :label="item.value">{{item.name}}</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item v-else label="业务类型：" prop="">
                                <span v-for="item in business" :key="item.value" :label="item.value">
                                    <span v-if="addform.businessType == item.value">{{item.name}}</span>
                                </span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item v-if="formButton !== 'details'" label="业务员：" prop="businessUserId">
                                <el-select  @change="select()" style="width:100%" v-model="addform.businessUserId" placeholder="请选择">
                                    <el-option
                                    v-for="item in User"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item v-else label="业务员：" prop="">
                                <span>{{addform.businessName}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="联系电话：" prop="">
                                <span>{{addform.businessTel}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="16">
                            <el-form-item v-if="formButton !== 'details'" label="关键字：" prop="">
                                <el-input v-model="EnterpriseName" placeholder="请输入完整企业名称或完整企业税号">
                                    <el-button slot="append" @click="querySysEnterprise()" icon="el-icon-search"></el-button>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="来源：" prop="">
                                <el-checkbox :disabled="formButton == 'details'" v-model="addform.checked">收发货系统</el-checkbox>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item v-if="formButton !== 'details'" label="企业名称：" prop="purchaseName">
                                <el-input :disabled="addforminput == false" maxlength="20" v-model="addform.purchaseName" placeholder="请输入企业名称"></el-input>
                            </el-form-item>
                            <el-form-item v-else label="企业名称：" prop="">
                                <span>{{addform.purchaseName}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item v-if="formButton !== 'details'" label="税号：" prop="purchaseTaxNumber">
                                <el-input maxlength="30" v-model="addform.purchaseTaxNumber" placeholder="请输入税号"></el-input>
                            </el-form-item>
                            <el-form-item v-else label="税号：" prop="">
                                <span>{{addform.purchaseTaxNumber}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item v-if="formButton !== 'details'" label="电话：" prop="purchaseTelephone">
                                <el-input maxlength="18" v-model="addform.purchaseTelephone" placeholder="请输入电话"></el-input>
                            </el-form-item>
                            <el-form-item v-else label="电话：" prop="">
                                <span>{{addform.purchaseTelephone}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="24">
                            <el-form-item v-if="formButton !== 'details'" label="注册地址：" prop="purchaseDomicile">
                                <el-input maxlength="50" v-model="addform.purchaseDomicile" placeholder="请输入注册地址"></el-input>
                            </el-form-item>
                            <el-form-item v-else label="注册地址：" prop="">
                                <span>{{addform.purchaseDomicile}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item v-if="formButton !== 'details'" label="开户银行：" prop="purchaseBankName">
                                <el-input v-model="addform.purchaseBankName" placeholder="请输入开户银行"></el-input>
                            </el-form-item>
                            <el-form-item v-else label="开户银行：" prop="">
                                <span>{{addform.purchaseBankName}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item v-if="formButton !== 'details'" label="银行账户：" prop="purchaseBankNo">
                                <el-input maxlength="20" v-model="addform.purchaseBankNo" placeholder="请输入银行账户"></el-input>
                            </el-form-item>
                            <el-form-item v-else label="银行账户：" prop="">
                                <span >{{addform.purchaseBankNo}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item v-if="formButton !== 'details'" label="联系人：" prop="purchaseContacts">
                                <el-input maxlength="20" v-model="addform.purchaseContacts" placeholder="请输入联系人"></el-input>
                            </el-form-item>
                            <el-form-item v-else label="联系人：" prop="">
                                <span>{{addform.purchaseContacts}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item v-if="formButton !== 'details'" label="联系电话：" prop="purchaseContactTel">
                                <el-input maxlength="11" v-model.number="addform.purchaseContactTel" placeholder="请输入联系电话"></el-input>
                            </el-form-item>
                            <el-form-item v-else label="联系电话：" prop="">
                                <span>{{addform.purchaseContactTel}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="AddCancel('addform')">取 消</el-button>
                    <el-button v-if="formButton == 'add'" type="primary" @click="AddSure('addform')">确 定</el-button>
                    <el-button v-if="formButton == 'modify'" type="primary" @click="AddSure('addform')">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <!-- 确认打开对话框 -->
        <div>
            <el-dialog
                title="提示"
                :visible.sync="SureDialog"
                width="40%"
                style="margin-top: -3%"
                :before-close="handleClose"
                :show-close="false">
                <el-form label-width="120px" :model="SureForm" class="demo-form-inline">
                    <el-divider content-position="left">对方企业信息</el-divider>
                    <el-row :gutter="24">
                        <el-col :span="24">
                            <el-form-item label="企业名称：">
                                <span>{{SureForm.purchaseName}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="联系电话：">
                                <span>{{SureForm.purchaseTelephone}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="税号：">
                                <span>{{SureForm.purchaseTaxNumber}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="业务联系人：">
                                <span>{{SureForm.purchaseContacts}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="业务联系电话：">
                                <span>{{SureForm.purchaseContactTel}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="24">
                            <el-form-item label="注册地址：">
                                <span>{{SureForm.purchaseDomicile}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-divider content-position="left">己方企业信息</el-divider>
                    <el-row :gutter="24">
                        <el-col :span="24">
                            <el-form-item label="企业名称：">
                                <span>{{SureForm.enterpriseName}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="联系电话：">
                                <span>{{SureForm.telephone}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="税号：">
                                <span>{{SureForm.taxNumber}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="业务员：" prop="businessUserId">
                                <el-select @change="select()" style="width:100%" v-model="SureForm.businessUserId" placeholder="请选择">
                                    <el-option
                                    v-for="item in User"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="业务联系电话：">
                                <span>{{SureForm.businessTel}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="24">
                            <el-form-item label="注册地址：">
                                <span>{{SureForm.domicile}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="cancel()">取 消</el-button>
                    <el-button type="primary" @click="Sure()">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <!-- 添加地址打开表单 -->
        <div>
            <el-dialog
            title="地址管理"
            style="margin-top:-3%"
            :visible.sync="AddressDialog"
            :show-close="false"
            :before-close="handleClose"
            width="80%">
                <Address ref="AddressRef"></Address>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="AddressDialog = false,start()">关 闭</el-button>
                    <!-- <el-button type="primary" @click="AddressDialog = false">确 定</el-button> -->
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import Address from '@/view/Partner/Address.vue'
export default {
    components: { Address },
    computed: {
        // tableHeight: document.documentElement.clientHeight
    },
    created() {
        var height = document.documentElement.clientHeight
        this.tableHeight = (height*0.67)+'px';
    },
    data(){
        return{
            loading: false,
            tableHeight: 0,
            form: {
                address: ''
            },
            tableData: [],
            // 表格分页
            page: 1,
            size: 10,
            total: 0,
            // 表格单选绑定
            radio: '',
            // 表单
            dialogVisible: false,
            // 表单里面的行业类型
            business: [],
            // 表单里面的所有用户
            User: [],
            // 表单input企业输入信息
            EnterpriseName: '',
            // 控制表单input是否禁用
            addforminput: true,
            // 添加表单
            addform: {
                checked: false,
                id: '',
                enterpriseId: 0,
                enterpriseName: '',
                taxNumber: '',
                telephone: '',
                domicile: '',
                bankName: '',
                bankNo: '',
                contacts: '',
                contactTel: '',
                businessName: '',
                businessUserId: '',
                businessTel: '',
                purchaseId: 0,
                purchaseName: '',
                purchaseTaxNumber: '',
                purchaseTelephone: '',
                purchaseDomicile: '',
                purchaseBankName: '',
                purchaseBankNo: '',
                purchaseContacts: '',
                purchaseContactTel: '',
                purchaseBusinessName: '',
                purchaseBusinessUserId: '',
                purchaseBusinessTel: '',
                businessType: '0',
                isActive: false,
                isConfirm: false,
                modeType: 0,
                creationTime: '',
                CreatorId: '',
            },
            // 添加表单验证
            addformRules: {
                business: [
                    { required: true , message: '请选择业务类型' , trigger: 'blur' }
                ],
                businessUserId: [
                    { required: true , message: '请选择业务员' , trigger: 'blur' }
                ],
                purchaseName: [
                    { required: true , message: '请输入企业名称' , trigger: 'blur' }
                ],
                purchaseTaxNumber: [
                    { required: true , message: '请输入税号' , trigger: 'blur' }
                ],
                purchaseTelephone: [
                    { required: true , message: '请输入电话' , trigger: 'blur' }
                ],
                purchaseDomicile: [
                    { required: true , message: '请输入注册地址' , trigger: 'blur' }
                ],
                purchaseBankName: [
                    { required: true , message: '请输入开户银行' , trigger: 'blur' }
                ],
                purchaseBankNo: [
                    { required: true , message: '请输入银行账户' , trigger: 'blur' }
                ],
                purchaseContacts: [
                    { required: true , message: '请输入联系人' , trigger: 'blur' }
                ],
                purchaseContactTel: [
                    { required: true , message: '请输入联系电话' , trigger: 'blur' }
                ],
                businessType: [
                    { required: true , message: '请选择业务类型' , trigger: 'blur' }
                ]
            },
            // 修改
            formButton: 'add',
            // 对话框标题
            title: '新增',
            // 审核以及确认id 
            auditid: '',
            AuditiSactive: false,
            // 确认
            SureisConfirm: false,
            // 判断选中的该条数据是显示审核还是确认
            modeType: 0,
            // 确认对话框
            SureDialog: false,
            // 确认表单
            SureForm: {
                id: '',
                enterpriseId: 0,
                enterpriseName: '',
                taxNumber: '',
                telephone: '',
                domicile: '',
                bankName: '',
                bankNo: '',
                contacts: '',
                contactTel: '',
                businessName: '',
                businessUserId: '',
                businessTel: '',
                purchaseId: 0,
                purchaseName: '',
                purchaseTaxNumber: '',
                purchaseTelephone: '',
                purchaseDomicile: '',
                purchaseBankName: '',
                purchaseBankNo: '',
                purchaseContacts: '',
                purchaseContactTel: '',
                purchaseBusinessName: '',
                purchaseBusinessUserId: '',
                purchaseBusinessTel: '',
                businessType: '',
                isActive: false,
                isConfirm: true,
                modeType: 0,
                creationTime: '',
                creatorId: ''
            },
            timer: null,// 初始定时器变量名为null
            // 添加地址打开表单
            AddressDialog: false
        }
    },
    beforeDestroy() {
        // js提供的clearInterval方法用来清除定时器
        clearInterval(this.timer);
        this.end()
    },
    methods:{
        // 表头 列 居中
        headClass() {
            return "text-align:center;background-color: #c8e0f391;color:#E6A23C";
        },
        rowClass() {
            return "text-align:center";
        },
        // 分页功能
        handleSizeChange(val) {
            this.size = val
            this.query()
        },
        handleCurrentChange(val) {
            this.page = val
            this.query()
        },
        start() {
            // console.log("开始---");
            // 将定时器名字赋值到变量中
            this.timer = setInterval(() => {
                this.query()
            }, 20000);
        },
        end() {
            clearInterval(this.timer);
            this.timer = null // 这里最好清除一下，回归默认值
            // 众所周知，定时器返回一个随机整数，用于表示定时器的编号，后面通过名字可以取消这个定时器的执行。
            // console.log('结束');
        },
        // 表格里面判断
        businessType(row){
            var a = ''
            this.business.forEach(item => {
                if(Number(item.value) == row.businessType){
                    a = item.name
                }
            })
            return a;
        },
        isActive(row){
            if(row.isActive == true){
                return '已审核';
            }else{
                return '未审核'
            }
        },
        isConfirm(row){
            if(row.isConfirm == true){
                return '已确认';
            }else{
                return '未确认'
            }
        },
        name(row){
            if(row.businessType == 0){
                if(row.modeType == 0){
                    return row.purchaseName;
                }else{
                    return row.enterpriseName;
                }
            }else{
                if(row.modeType == 1){
                    return row.purchaseName;
                }else{
                    return row.enterpriseName;
                }
            }
        },
        contacts(row){
            if(row.businessType == 0){
                return row.purchaseContacts;
            }else{
                return row.contacts;
            }
        },
        contactTel(row){
            if(row.businessType == 0){
                return row.purchaseContactTel;
            }else{
                return row.contactTel;
            }
        },
        businessName(row){
            if(row.businessType == 0){
                return row.businessName;
            }else{
                return row.purchaseBusinessName;
            }
        },
        // 查询事件
        query(){
            this.loading = true
            this.radio = ''
            this.auditid = ''
            if(!this.$hasPermission(['Partner_query']))
            {
                return;
            }
            this.$http.post(this.$SysPartners_GetPageSysPartnerList,{
                currentPage: this.page,
                pageSize: this.size,
                filter: {
                    queryString: this.form.address
                }
            }).then(response => {
                this.tableData = response.data.data.list
                this.total = response.data.data.total
                this.radio = ''
                this.auditid = ''
                this.AuditiSactive = ''
                this.SureisConfirm = ''
                this.modeType = ''
                this.loading = false
            })
        },
        // 新增按钮事件
        add(){
            this.end()
            this.formButton = 'add'
            this.title = '新增'
            this.dialogVisible = true
            this.addform.purchaseBusinessTel = ''
            this.queryUser()
        },
        // 查询所有业务员信息
        queryUser(){
            this.User = []
            this.$http.get(this.$BuyersManager_GetBuyersUser).then(response => {
                response.data.data.list.forEach(element => {
                    this.User.push(element)
                });
            })
        },
        // 获取用户选择的业务员信息
        select(){
            this.User.forEach(item => {
                if(item.id == this.addform.businessUserId){
                    this.addform.businessTel = item.mobile
                    this.addform.businessName = item.name
                }
                if(this.modeType == 1){
                    if(item.id == this.SureForm.businessUserId){
                        this.SureForm.businessTel = item.mobile
                        this.SureForm.businessName = item.name
                    }
                }
            })
        },
        // 查询企业信息
        querySysEnterprise(){
            if(this.EnterpriseName == ''){
                return this.$message.warning('请输入完整的企业名称或者完整的企业税号进行查询')
            }
            if(this.addform.checked == true){
                this.$http.get(this.$SysOrder_GetEnterprise+'?EnterpriseName='+this.EnterpriseName+'&BusinessType='+this.addform.businessType).then(response => {
                    if(response.data.data !== null){
                        if(this.addform.businessType == 0){
                            this.addform.purchaseId = response.data.data.purchaseId
                            this.addform.purchaseName = response.data.data.purchaseName
                            this.addform.purchaseTaxNumber = response.data.data.purchaseTaxNumber
                            this.addform.purchaseTelephone = response.data.data.purchaseTelephone
                            this.addform.purchaseDomicile = response.data.data.purchaseDomicile
                            this.addform.purchaseBankName = response.data.data.purchaseBankName
                            this.addform.purchaseBankNo = response.data.data.purchaseBankNo
                            this.addform.purchaseContacts = response.data.data.purchaseContacts
                            this.addform.purchaseContactTel = response.data.data.purchaseContactTel
                            this.addforminput = false
                        }else{
                            this.addform.purchaseId = response.data.data.enterpriseId
                            this.addform.purchaseName = response.data.data.enterpriseName
                            this.addform.purchaseTaxNumber = response.data.data.taxNumber
                            this.addform.purchaseTelephone = response.data.data.telephone
                            this.addform.purchaseDomicile = response.data.data.domicile
                            this.addform.purchaseBankName = response.data.data.bankName
                            this.addform.purchaseBankNo = response.data.data.bankNo
                            this.addform.purchaseContacts = response.data.data.contacts
                            this.addform.purchaseContactTel = response.data.data.contactTel
                            this.addforminput = false
                        }
                    }else{
                        this.addform.purchaseId = ''
                        this.addform.purchaseName = ''
                        this.addform.purchaseTaxNumber = ''
                        this.addform.purchaseTelephone = ''
                        this.addform.purchaseDomicile = ''
                        this.addform.purchaseBankName = ''
                        this.addform.purchaseBankNo = ''
                        this.addform.purchaseContacts = ''
                        this.addform.purchaseContactTel = ''
                        this.addforminput = false
                        this.addforminput = true
                    }
                })
            }else{
                this.$http.post(this.$SysEnterprise_GetPageList,{
                    currentPage: 1,
                    pageSize: 10,
                    filter: {
                        queryString: this.EnterpriseName
                    }
                }).then(response => {
                    if(response.data.data !== null){
                        if(response.data.data.list.length !== 0){
                            this.addform.purchaseId = response.data.data.list[0].id
                            this.addform.purchaseName = response.data.data.list[0].name
                            this.addform.purchaseTaxNumber = response.data.data.list[0].taxNumber
                            this.addform.purchaseTelephone = response.data.data.list[0].telephone
                            this.addform.purchaseDomicile = response.data.data.list[0].domicile
                            this.addform.purchaseBankName = response.data.data.list[0].bankName
                            this.addform.purchaseBankNo = response.data.data.list[0].bankNo
                            this.addform.purchaseContacts = response.data.data.list[0].contacts
                            this.addform.purchaseContactTel = response.data.data.list[0].contactTel
                            this.addforminput = false
                        }else{
                            this.addform.purchaseId = ''
                            this.addform.purchaseName = ''
                            this.addform.purchaseTaxNumber = ''
                            this.addform.purchaseTelephone = ''
                            this.addform.purchaseDomicile = ''
                            this.addform.purchaseBankName = ''
                            this.addform.purchaseBankNo = ''
                            this.addform.purchaseContacts = ''
                            this.addform.purchaseContactTel = ''
                            this.addforminput = false
                            this.addforminput = true
                        }
                    }else{
                        this.addform.purchaseId = ''
                        this.addform.purchaseName = ''
                        this.addform.purchaseTaxNumber = ''
                        this.addform.purchaseTelephone = ''
                        this.addform.purchaseDomicile = ''
                        this.addform.purchaseBankName = ''
                        this.addform.purchaseBankNo = ''
                        this.addform.purchaseContacts = ''
                        this.addform.purchaseContactTel = ''
                        this.addforminput = false
                        this.addforminput = true
                    }
                })
            }
        },
        // 添加点击确定事件
        AddSure(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if(this.formButton == 'add'){
                        if(this.addform.businessType == 1){
                            this.$http.post(this.$SysPartners_CreatePartner,{
                                    id : this.addform.id,
                                    enterpriseId : Number(this.addform.purchaseId),
                                    enterpriseName : this.addform.purchaseName,
                                    taxNumber : this.addform.purchaseTaxNumber,
                                    telephone : this.addform.purchaseTelephone,
                                    domicile : this.addform.purchaseDomicile,
                                    bankName : this.addform.purchaseBankName,
                                    bankNo : this.addform.purchaseBankNo,
                                    contacts : this.addform.purchaseContacts,
                                    contactTel : JSON.stringify(this.addform.purchaseContactTel),
                                    businessUserId : 0,
                                    businessName : this.addform.purchaseBusinessName,
                                    businessTel : this.addform.purchaseBusinessTel,
                                    purchaseId : Number(this.addform.enterpriseId),
                                    purchaseName : this.addform.enterpriseName,
                                    purchaseTaxNumber : this.addform.taxNumber,
                                    purchaseTelephone : this.addform.telephone,
                                    purchaseDomicile : this.addform.domicile,
                                    purchaseBankName : this.addform.bankName,
                                    purchaseBankNo : this.addform.bankNo,
                                    purchaseContacts : this.addform.contacts,
                                    purchaseContactTel : this.addform.contactTel,
                                    purchaseBusinessName : this.addform.businessName,
                                    purchaseBusinessUserId : this.addform.businessUserId,
                                    purchaseBusinessTel : this.addform.businessTel,
                                    businessType : Number(this.addform.businessType),
                                    isActive : this.addform.isActive,
                                    isConfirm : this.addform.isConfirm,
                                    modeType : Number(this.addform.modeType)
                            }).then( response => {
                                if(response.data.isSuccess == true){
                                    this.query()
                                    this.$message.success('添加成功')
                                    this.EnterpriseName = ''
                                    this.addforminput = true
                                    this.dialogVisible = false
                                    this.start()
                                }else{
                                    this.$message.warning(response.data.message)
                                }
                            }) 
                        }else{
                            this.$http.post(this.$SysPartners_CreatePartner,{
                                id: this.addform.id,
                                enterpriseId: Number(this.addform.enterpriseId),
                                enterpriseName: this.addform.enterpriseName,
                                taxNumber: this.addform.taxNumber,
                                telephone: this.addform.telephone,
                                domicile: this.addform.domicile,
                                bankName: this.addform.bankName,
                                bankNo: this.addform.bankNo,
                                contacts: this.addform.contacts,
                                contactTel: this.addform.contactTel,
                                businessName: this.addform.businessName,
                                businessUserId: this.addform.businessUserId,
                                businessTel: this.addform.businessTel,
                                purchaseId: Number(this.addform.purchaseId),
                                purchaseName: this.addform.purchaseName,
                                purchaseTaxNumber: this.addform.purchaseTaxNumber,
                                purchaseTelephone: this.addform.purchaseTelephone,
                                purchaseDomicile: this.addform.purchaseDomicile,
                                purchaseBankName: this.addform.purchaseBankName,
                                purchaseBankNo: this.addform.purchaseBankNo,
                                purchaseContacts: this.addform.purchaseContacts,
                                purchaseContactTel: this.addform.purchaseContactTel,
                                purchaseBusinessName: this.addform.purchaseBusinessName,
                                purchaseBusinessUserId: Number(this.addform.purchaseBusinessUserId),
                                purchaseBusinessTel: this.addform.purchaseBusinessTel,
                                businessType: this.addform.businessType,
                                isActive: this.addform.isActive,
                                isConfirm: this.addform.isConfirm,
                                modeType: Number(this.addform.modeType),
                            }).then( response => {
                                if(response.data.isSuccess == true){
                                    this.query()
                                    this.$message.success('添加成功')
                                    this.EnterpriseName = ''
                                    this.addforminput = true
                                    this.dialogVisible = false
                                }else{
                                    this.$message.warning(response.data.message)
                                }
                            }) 
                        }
                    }else if(this.formButton == 'modify'){
                        if(this.addform.businessType == 1){
                            this.$http.put(this.$SysPartners_UpdatePartner,{
                                id : this.addform.id,
                                enterpriseId : Number(this.addform.purchaseId),
                                enterpriseName : this.addform.purchaseName,
                                taxNumber : this.addform.purchaseTaxNumber,
                                telephone : this.addform.purchaseTelephone,
                                domicile : this.addform.purchaseDomicile,
                                bankName : this.addform.purchaseBankName,
                                bankNo : this.addform.purchaseBankNo,
                                contacts : this.addform.purchaseContacts,
                                contactTel : this.addform.purchaseContactTel,
                                businessUserId : 0,
                                businessName : this.addform.purchaseBusinessName,
                                businessTel : this.addform.purchaseBusinessTel,
                                purchaseId : Number(this.addform.enterpriseId),
                                purchaseName : this.addform.enterpriseName,
                                purchaseTaxNumber : this.addform.taxNumber,
                                purchaseTelephone : this.addform.telephone,
                                purchaseDomicile : this.addform.domicile,
                                purchaseBankName : this.addform.bankName,
                                purchaseBankNo : this.addform.bankNo,
                                purchaseContacts : this.addform.contacts,
                                purchaseContactTel : this.addform.contactTel,
                                purchaseBusinessName : this.addform.businessName,
                                purchaseBusinessUserId : this.addform.businessUserId,
                                purchaseBusinessTel : this.addform.businessTel,
                                businessType : Number(this.addform.businessType),
                                isActive : this.addform.isActive,
                                isConfirm : this.addform.isConfirm,
                                modeType : Number(this.addform.modeType),
                                CreatorId: Number(this.addform.CreatorId),
                                creationTime: this.addform.creationTime
                            }).then( response => {
                                if(response.data.isSuccess == true){
                                    this.query()
                                    this.$message.success('修改成功')
                                    this.EnterpriseName = ''
                                    this.addforminput = true
                                    this.dialogVisible = false
                                    this.start()
                                }else{
                                    this.$message.warning(response.data.message)
                                }
                            })
                        }else{
                            this.$http.put(this.$SysPartners_UpdatePartner,{
                                id: this.addform.id,
                                enterpriseId: Number(this.addform.enterpriseId),
                                enterpriseName: this.addform.enterpriseName,
                                taxNumber: this.addform.taxNumber,
                                telephone: this.addform.telephone,
                                domicile: this.addform.domicile,
                                bankName: this.addform.bankName,
                                bankNo: this.addform.bankNo,
                                contacts: this.addform.contacts,
                                contactTel: this.addform.contactTel,
                                businessName: this.addform.businessName,
                                businessUserId: this.addform.businessUserId,
                                businessTel: this.addform.businessTel,
                                purchaseId: Number(this.addform.purchaseId),
                                purchaseName: this.addform.purchaseName,
                                purchaseTaxNumber: this.addform.purchaseTaxNumber,
                                purchaseTelephone: this.addform.purchaseTelephone,
                                purchaseDomicile: this.addform.purchaseDomicile,
                                purchaseBankName: this.addform.purchaseBankName,
                                purchaseBankNo: this.addform.purchaseBankNo,
                                purchaseContacts: this.addform.purchaseContacts,
                                purchaseContactTel: this.addform.purchaseContactTel,
                                purchaseBusinessName: this.addform.purchaseBusinessName,
                                purchaseBusinessUserId: Number(this.addform.purchaseBusinessUserId),
                                purchaseBusinessTel: this.addform.purchaseBusinessTel,
                                businessType: this.addform.businessType,
                                isActive: this.addform.isActive,
                                isConfirm: this.addform.isConfirm,
                                modeType: Number(this.addform.modeType),
                                CreatorId: Number(this.addform.CreatorId),
                                creationTime: this.addform.creationTime
                            }).then( response => {
                                if(response.data.isSuccess == true){
                                    this.query()
                                    this.$message.success('修改成功')
                                    this.EnterpriseName = ''
                                    this.addforminput = true
                                    this.dialogVisible = false
                                    this.start()
                                }else{
                                    this.$message.warning(response.data.message)
                                }
                            })
                        }
                        
                    }
                } else {
                return false;
                }
            });
        },
        // 添加点击取消事件
        AddCancel(formName){
            this.$refs[formName].resetFields();
            this.dialogVisible = false
            this.EnterpriseName = ''
            this.addform = {
                checked: false,
                id: '',
                enterpriseId: 0,
                enterpriseName: '',
                taxNumber: '',
                telephone: '',
                domicile: '',
                bankName: '',
                bankNo: '',
                contacts: '',
                contactTel: '',
                businessName: '',
                businessUserId: '',
                businessTel: '',
                purchaseId: 0,
                purchaseName: '',
                purchaseTaxNumber: '',
                purchaseTelephone: '',
                purchaseDomicile: '',
                purchaseBankName: '',
                purchaseBankNo: '',
                purchaseContacts: '',
                purchaseContactTel: '',
                purchaseBusinessName: '',
                purchaseBusinessUserId: '',
                purchaseBusinessTel: '',
                businessType: '0',
                isActive: false,
                isConfirm: false,
                modeType: 0,
                creationTime: '',
                CreatorId: '',
            }
            this.start()
        },
        handleClose(done) {
            // this.$confirm('确认关闭？')
            // .then(_ => {
            //     done();
            // })
            // .catch(_ => {});
        },
        handleClick(row) {
            this.end()
            this.title = '详情'
            this.dialogVisible = true
            this.formButton = 'details'
            this.$http.get(this.$SysPartners_Get+'?id='+row.id).then(response => {
                this.addform.id = response.data.data.id
                if(response.data.data.modeType == 1){
                    if(response.data.data.businessType == 1){
                        this.addform.enterpriseId = response.data.data.enterpriseId
                        this.addform.enterpriseName = response.data.data.enterpriseName
                        this.addform.taxNumber = response.data.data.taxNumber
                        this.addform.telephone = response.data.data.telephone
                        this.addform.domicile = response.data.data.domicile
                        this.addform.bankName = response.data.data.bankName
                        this.addform.bankNo = response.data.data.bankNo
                        this.addform.contacts = response.data.data.contacts
                        this.addform.contactTel = response.data.data.contactTel
                        this.addform.businessName = response.data.data.businessName
                        this.addform.businessUserId = response.data.data.businessUserId
                        this.addform.businessTel = response.data.data.businessTel
                        this.addform.purchaseId = response.data.data.purchaseId
                        this.addform.purchaseName = response.data.data.purchaseName
                        this.addform.purchaseTaxNumber = response.data.data.purchaseTaxNumber
                        this.addform.purchaseTelephone = response.data.data.purchaseTelephone
                        this.addform.purchaseDomicile = response.data.data.purchaseDomicile
                        this.addform.purchaseBankName = response.data.data.purchaseBankName
                        this.addform.purchaseBankNo = response.data.data.purchaseBankNo
                        this.addform.purchaseContacts = response.data.data.purchaseContacts
                        this.addform.purchaseContactTel = response.data.data.purchaseContactTel
                        this.addform.purchaseBusinessName = response.data.data.purchaseBusinessName
                        this.addform.purchaseBusinessUserId = response.data.data.purchaseBusinessUserId
                        this.addform.purchaseBusinessTel = response.data.data.purchaseBusinessTel
                    }else{
                        this.addform.enterpriseId = response.data.data.purchaseId
                        this.addform.enterpriseName = response.data.data.purchaseName
                        this.addform.taxNumber = response.data.data.purchaseTaxNumber
                        this.addform.telephone = response.data.data.purchaseTelephone
                        this.addform.domicile = response.data.data.purchaseDomicile
                        this.addform.bankName = response.data.data.purchaseBankName
                        this.addform.bankNo = response.data.data.purchaseBankNo
                        this.addform.contacts = response.data.data.purchaseContacts
                        this.addform.contactTel = response.data.data.purchaseContactTel
                        this.addform.businessName = response.data.data.purchaseBusinessName
                        this.addform.businessUserId = response.data.data.purchaseBusinessUserId
                        this.addform.businessTel = response.data.data.purchaseBusinessTel
                        this.addform.purchaseId = response.data.data.enterpriseId
                        this.addform.purchaseName = response.data.data.enterpriseName
                        this.addform.purchaseTaxNumber = response.data.data.taxNumber
                        this.addform.purchaseTelephone = response.data.data.telephone
                        this.addform.purchaseDomicile = response.data.data.domicile
                        this.addform.purchaseBankName = response.data.data.bankName
                        this.addform.purchaseBankNo = response.data.data.bankNo
                        this.addform.purchaseContacts = response.data.data.contacts
                        this.addform.purchaseContactTel = response.data.data.contactTel
                        this.addform.purchaseBusinessName = response.data.data.businessName
                        this.addform.purchaseBusinessUserId = response.data.data.businessUserId
                        this.addform.purchaseBusinessTel = response.data.data.businessTel
                    }
                }else{
                    if(response.data.data.businessType == 1){
                        this.addform.enterpriseId = response.data.data.purchaseId
                        this.addform.enterpriseName = response.data.data.purchaseName
                        this.addform.taxNumber = response.data.data.purchaseTaxNumber
                        this.addform.telephone = response.data.data.purchaseTelephone
                        this.addform.domicile = response.data.data.purchaseDomicile
                        this.addform.bankName = response.data.data.purchaseBankName
                        this.addform.bankNo = response.data.data.purchaseBankNo
                        this.addform.contacts = response.data.data.purchaseContacts
                        this.addform.contactTel = response.data.data.purchaseContactTel
                        this.addform.businessName = response.data.data.purchaseBusinessName
                        this.addform.businessUserId = response.data.data.purchaseBusinessUserId
                        this.addform.businessTel = response.data.data.purchaseBusinessTel
                        this.addform.purchaseId = response.data.data.enterpriseId
                        this.addform.purchaseName = response.data.data.enterpriseName
                        this.addform.purchaseTaxNumber = response.data.data.taxNumber
                        this.addform.purchaseTelephone = response.data.data.telephone
                        this.addform.purchaseDomicile = response.data.data.domicile
                        this.addform.purchaseBankName = response.data.data.bankName
                        this.addform.purchaseBankNo = response.data.data.bankNo
                        this.addform.purchaseContacts = response.data.data.contacts
                        this.addform.purchaseContactTel = response.data.data.contactTel
                        this.addform.purchaseBusinessName = response.data.data.businessName
                        this.addform.purchaseBusinessUserId = response.data.data.businessUserId
                        this.addform.purchaseBusinessTel = response.data.data.businessTel
                    }else{
                        this.addform.enterpriseId = response.data.data.enterpriseId
                        this.addform.enterpriseName = response.data.data.enterpriseName
                        this.addform.taxNumber = response.data.data.taxNumber
                        this.addform.telephone = response.data.data.telephone
                        this.addform.domicile = response.data.data.domicile
                        this.addform.bankName = response.data.data.bankName
                        this.addform.bankNo = response.data.data.bankNo
                        this.addform.contacts = response.data.data.contacts
                        this.addform.contactTel = response.data.data.contactTel
                        this.addform.businessName = response.data.data.businessName
                        this.addform.businessUserId = response.data.data.businessUserId
                        this.addform.businessTel = response.data.data.businessTel
                        this.addform.purchaseId = response.data.data.purchaseId
                        this.addform.purchaseName = response.data.data.purchaseName
                        this.addform.purchaseTaxNumber = response.data.data.purchaseTaxNumber
                        this.addform.purchaseTelephone = response.data.data.purchaseTelephone
                        this.addform.purchaseDomicile = response.data.data.purchaseDomicile
                        this.addform.purchaseBankName = response.data.data.purchaseBankName
                        this.addform.purchaseBankNo = response.data.data.purchaseBankNo
                        this.addform.purchaseContacts = response.data.data.purchaseContacts
                        this.addform.purchaseContactTel = response.data.data.purchaseContactTel
                        this.addform.purchaseBusinessName = response.data.data.purchaseBusinessName
                        this.addform.purchaseBusinessUserId = response.data.data.purchaseBusinessUserId
                        this.addform.purchaseBusinessTel = response.data.data.purchaseBusinessTel
                    }
                }
                this.addform.businessType = JSON.stringify(response.data.data.businessType)
                this.addform.isActive = response.data.data.isActive
                this.addform.isConfirm = response.data.data.isConfirm
                this.addform.modeType = response.data.data.modeType
                this.loading = false
            })
        },
        dblclick(row){
            this.radio = row.id
            this.getCurrentRow(row)
        },
        getCurrentRow(row){
            this.auditid = row.id
            this.AuditiSactive = row.isActive
            this.SureisConfirm = row.isConfirm
            this.modeType = Number(row.modeType)
            if(row.isActive == true & row.modeType == 1 & row.purchaseId != 0){
                if(row.businessType == 1){
                    this.SureForm.id = row.id
                    this.SureForm.enterpriseId = row.enterpriseId
                    this.SureForm.enterpriseName = row.enterpriseName
                    this.SureForm.taxNumber = row.taxNumber
                    this.SureForm.telephone = row.telephone
                    this.SureForm.domicile = row.domicile
                    this.SureForm.bankName = row.bankName
                    this.SureForm.bankNo = row.bankNo
                    this.SureForm.contacts = row.contacts
                    this.SureForm.contactTel = row.contactTel
                    //  row.businessName
                    this.SureForm.businessName = ''
                    // row.businessUserId
                    this.SureForm.businessUserId = ''
                    this.SureForm.businessTel = row.businessTel
                    this.SureForm.purchaseId = row.purchaseId
                    this.SureForm.purchaseName = row.purchaseName
                    this.SureForm.purchaseTaxNumber = row.purchaseTaxNumber
                    this.SureForm.purchaseTelephone = row.purchaseTelephone
                    this.SureForm.purchaseDomicile = row.purchaseDomicile
                    this.SureForm.purchaseBankName = row.purchaseBankName
                    this.SureForm.purchaseBankNo = row.purchaseBankNo
                    this.SureForm.purchaseContacts = row.purchaseContacts
                    this.SureForm.purchaseContactTel = row.purchaseContactTel
                    this.SureForm.purchaseBusinessName = row.purchaseBusinessName
                    this.SureForm.purchaseBusinessUserId = row.purchaseBusinessUserId
                    this.SureForm.purchaseBusinessTel = row.purchaseBusinessTel
                    this.SureForm.isActive = row.isActive
                    this.SureForm.modeType = row.modeType
                    this.SureForm.creationTime = row.creationTime
                    this.SureForm.creatorId = row.creatorId
                    this.SureForm.businessType = JSON.stringify(row.businessType)
                }else{
                    this.SureForm.id = row.id
                    this.SureForm.enterpriseId = Number(row.purchaseId)
                    this.SureForm.enterpriseName = row.purchaseName
                    this.SureForm.taxNumber = row.purchaseTaxNumber
                    this.SureForm.telephone = row.purchaseTelephone
                    this.SureForm.domicile = row.purchaseDomicile
                    this.SureForm.bankName = row.purchaseBankName
                    this.SureForm.bankNo = row.purchaseBankNo
                    this.SureForm.contacts = row.purchaseContacts
                    this.SureForm.contactTel = row.purchaseContactTel
                    // row.purchaseBusinessName
                    this.SureForm.businessName = ''
                    // row.purchaseBusinessUserId
                    this.SureForm.businessUserId = ''
                    this.SureForm.businessTel = row.purchaseBusinessTel
                    this.SureForm.purchaseId = Number(row.enterpriseId)
                    this.SureForm.purchaseName = row.enterpriseName
                    this.SureForm.purchaseTaxNumber = row.taxNumber
                    this.SureForm.purchaseTelephone = row.telephone
                    this.SureForm.purchaseDomicile = row.domicile
                    this.SureForm.purchaseBankName = row.bankName
                    this.SureForm.purchaseBankNo = row.bankNo
                    this.SureForm.purchaseContacts = row.contacts
                    this.SureForm.purchaseContactTel = row.contactTel
                    this.SureForm.purchaseBusinessName = row.businessName
                    this.SureForm.purchaseBusinessUserId = row.businessUserId
                    this.SureForm.purchaseBusinessTel = row.businessTel
                    this.SureForm.isActive = row.isActive
                    this.SureForm.modeType = row.modeType
                    this.SureForm.creationTime = row.creationTime
                    this.SureForm.creatorId = row.creatorId
                    this.SureForm.businessType = JSON.stringify(row.businessType)
                }
            }
            // alert(name)
        },
        // 修改打开表单
        modify(row){
            this.end()
            this.queryUser()
            this.formButton = 'modify'
            this.title = '修改'
            this.dialogVisible = true
            this.loading = true
            this.$http.get(this.$SysPartners_Get+'?id='+row.id).then(response => {
                this.addform.id = response.data.data.id
                if(response.data.data.businessType == 0){
                    this.addform.enterpriseId = response.data.data.enterpriseId
                    this.addform.enterpriseName = response.data.data.enterpriseName
                    this.addform.taxNumber = response.data.data.taxNumber
                    this.addform.telephone = response.data.data.telephone
                    this.addform.domicile = response.data.data.domicile
                    this.addform.bankName = response.data.data.bankName
                    this.addform.bankNo = response.data.data.bankNo
                    this.addform.contacts = response.data.data.contacts
                    this.addform.contactTel = response.data.data.contactTel
                    this.addform.businessName = response.data.data.businessName
                    this.addform.businessUserId = response.data.data.businessUserId
                    this.addform.businessTel = response.data.data.businessTel
                    this.addform.purchaseId = response.data.data.purchaseId
                    this.addform.purchaseName = response.data.data.purchaseName
                    this.addform.purchaseTaxNumber = response.data.data.purchaseTaxNumber
                    this.addform.purchaseTelephone = response.data.data.purchaseTelephone
                    this.addform.purchaseDomicile = response.data.data.purchaseDomicile
                    this.addform.purchaseBankName = response.data.data.purchaseBankName
                    this.addform.purchaseBankNo = response.data.data.purchaseBankNo
                    this.addform.purchaseContacts = response.data.data.purchaseContacts
                    this.addform.purchaseContactTel = response.data.data.purchaseContactTel
                    this.addform.purchaseBusinessName = response.data.data.purchaseBusinessName
                    this.addform.purchaseBusinessUserId = response.data.data.purchaseBusinessUserId
                    this.addform.purchaseBusinessTel = response.data.data.purchaseBusinessTel
                }else{
                    this.addform.enterpriseId = response.data.data.purchaseId
                    this.addform.enterpriseName = response.data.data.purchaseName
                    this.addform.taxNumber = response.data.data.purchaseTaxNumber
                    this.addform.telephone = response.data.data.purchaseTelephone
                    this.addform.domicile = response.data.data.purchaseDomicile
                    this.addform.bankName = response.data.data.purchaseBankName
                    this.addform.bankNo = response.data.data.purchaseBankNo
                    this.addform.contacts = response.data.data.purchaseContacts
                    this.addform.contactTel = response.data.data.purchaseContactTel
                    this.addform.businessName = response.data.data.purchaseBusinessName
                    this.addform.businessUserId = response.data.data.purchaseBusinessUserId
                    this.addform.businessTel = response.data.data.purchaseBusinessTel
                    this.addform.purchaseId = response.data.data.enterpriseId
                    this.addform.purchaseName = response.data.data.enterpriseName
                    this.addform.purchaseTaxNumber = response.data.data.taxNumber
                    this.addform.purchaseTelephone = response.data.data.telephone
                    this.addform.purchaseDomicile = response.data.data.domicile
                    this.addform.purchaseBankName = response.data.data.bankName
                    this.addform.purchaseBankNo = response.data.data.bankNo
                    this.addform.purchaseContacts = response.data.data.contacts
                    this.addform.purchaseContactTel = response.data.data.contactTel
                    this.addform.purchaseBusinessName = response.data.data.businessName
                    this.addform.purchaseBusinessUserId = response.data.data.businessUserId
                    this.addform.purchaseBusinessTel = response.data.data.businessTel
                }
                if(response.data.data.businessType == 0){
                    if(response.data.data.purchaseId != 0){
                        this.addforminput = false
                    }else{
                        this.addforminput = true
                    }
                }else{
                    if(response.data.data.enterpriseId != 0){
                        this.addforminput = false
                    }else{
                        this.addforminput = true
                    }
                }
                this.addform.businessType = JSON.stringify(response.data.data.businessType)
                this.addform.isActive = response.data.data.isActive
                this.addform.isConfirm = response.data.data.isConfirm
                this.addform.modeType = response.data.data.modeType
                this.addform.creationTime = response.data.data.creationTime
                this.addform.CreatorId = response.data.data.creatorId
                this.loading = false
            })
        },
        // 审核
        Audit(){
            if(this.auditid == ''){
                return this.$message.info('请选择数据')
            }
            this.$confirm('此操作将审核该合作伙伴, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysPartners_AduitPartner,{
                    id : this.auditid,
                    isActive : true
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('审核成功')
                        this.query()
                        this.radio = ''
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        // 取消审核
        CancelAudit(){
            if(this.auditid == ''){
                return this.$message.info('请选择数据')
            }
            this.$confirm('此操作将取消审核该合作伙伴, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysPartners_AduitPartner,{
                    id : this.auditid,
                    isActive : false
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('取消审核成功')
                        this.query()
                        this.radio = ''
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        // 删除
        Delete(row){
            this.$confirm('此操作将删除该合作伙伴, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var arr = []
                arr.push(row.id)
                this.$http.post(this.$SysPartners_DeleteSysPartnerById,arr).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('删除成功')
                        this.query()
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        // 确认打开对话框
        openSureDialog(){
            this.end()
            this.SureDialog = true
            this.queryUser()
        },
        // 点击确认
        Sure(){
            if(this.SureForm.businessUserId == ''){
                return this.$message.info('请选择业务员')
            }
            this.$confirm('此操作将确认该合作伙伴, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                if(this.SureForm.businessType == 1){
                    this.$http.post(this.$SysPartners_ConfirmPartner,{
                        id: this.SureForm.id,
                        enterpriseId: this.SureForm.purchaseId,
                        enterpriseName: this.SureForm.purchaseName,
                        taxNumber: this.SureForm.purchaseTaxNumber,
                        telephone: this.SureForm.purchaseTelephone,
                        domicile: this.SureForm.purchaseDomicile,
                        bankName: this.SureForm.purchaseBankName,
                        bankNo: this.SureForm.purchaseBankNo,
                        contacts: this.SureForm.purchaseContacts,
                        contactTel: this.SureForm.purchaseContactTel,
                        businessName: this.SureForm.purchaseBusinessName,
                        businessUserId: this.SureForm.purchaseBusinessUserId,
                        businessTel: this.SureForm.purchaseBusinessTel,
                        purchaseId: this.SureForm.enterpriseId,
                        purchaseName: this.SureForm.enterpriseName,
                        purchaseTaxNumber: this.SureForm.taxNumber,
                        purchaseTelephone: this.SureForm.telephone,
                        purchaseDomicile: this.SureForm.domicile,
                        purchaseBankName: this.SureForm.bankName,
                        purchaseBankNo: this.SureForm.bankNo,
                        purchaseContacts: this.SureForm.contacts,
                        purchaseContactTel: this.SureForm.contactTel,
                        purchaseBusinessName: this.SureForm.businessName,
                        purchaseBusinessUserId: this.SureForm.businessUserId,
                        purchaseBusinessTel: this.SureForm.businessTel,
                        businessType: this.SureForm.businessType,
                        isActive: this.SureForm.isActive,
                        isConfirm: true,
                        modeType: this.SureForm.modeType,
                        creationTime: this.SureForm.creationTime,
                        CreatorId: Number(this.SureForm.creatorId),
                    }).then(response => {
                        if(response.data.isSuccess == true){
                            this.$message.success('确认成功')
                            this.SureDialog = false
                            this.query()
                            this.radio = ''
                            this.start()
                        }else{
                            this.$message.warning(response.data.message)
                        }
                    })
                }else{
                    this.$http.post(this.$SysPartners_ConfirmPartner,{
                        id: this.SureForm.id,
                        enterpriseId: this.SureForm.enterpriseId,
                        enterpriseName: this.SureForm.enterpriseName,
                        taxNumber: this.SureForm.taxNumber,
                        telephone: this.SureForm.telephone,
                        domicile: this.SureForm.domicile,
                        bankName: this.SureForm.bankName,
                        bankNo: this.SureForm.bankNo,
                        contacts: this.SureForm.contacts,
                        contactTel: this.SureForm.contactTel,
                        businessName: this.SureForm.businessName,
                        businessUserId: this.SureForm.businessUserId,
                        businessTel: this.SureForm.businessTel,
                        purchaseId: this.SureForm.purchaseId,
                        purchaseName: this.SureForm.purchaseName,
                        purchaseTaxNumber: this.SureForm.purchaseTaxNumber,
                        purchaseTelephone: this.SureForm.purchaseTelephone,
                        purchaseDomicile: this.SureForm.purchaseDomicile,
                        purchaseBankName: this.SureForm.purchaseBankName,
                        purchaseBankNo: this.SureForm.purchaseBankNo,
                        purchaseContacts: this.SureForm.purchaseContacts,
                        purchaseContactTel: this.SureForm.purchaseContactTel,
                        purchaseBusinessName: this.SureForm.purchaseBusinessName,
                        purchaseBusinessUserId: this.SureForm.purchaseBusinessUserId,
                        purchaseBusinessTel: this.SureForm.purchaseBusinessTel,
                        businessType: this.SureForm.businessType,
                        isActive: this.SureForm.isActive,
                        isConfirm: true,
                        modeType: this.SureForm.modeType,
                        creationTime: this.SureForm.creationTime,
                        CreatorId: Number(this.SureForm.creatorId),
                    }).then(response => {
                        if(response.data.isSuccess == true){
                            this.$message.success('确认成功')
                            this.SureDialog = false
                            this.query()
                            this.radio = ''
                            this.start()
                        }else{
                            this.$message.warning(response.data.message)
                        }
                    })
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        // 取消确认
        CancelSure(){
            this.$confirm('此操作将取消确认该合作伙伴, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysPartners_ConfirmPartner,{
                    id : this.auditid,
                    isConfirm : false
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('取消确认成功')
                        this.query()
                        this.radio = ''
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        cancel(){
            this.SureDialog = false
        },
        AddressManagement(row){
            this.end()
            this.AddressDialog = true
            this.$nextTick(() => {
                // console.log(this.$refs['AddressRef'])
                this.$refs['AddressRef'].getMsg(row.id);
            })
        }
    },
    mounted(){
        this.query()
        this.start()
        // 存储当前企业的业务类型
        this.business = JSON.parse(window.sessionStorage.getItem('business'))
    }
}
</script>
<style scoped>
.body{
    padding: 15px;
    height: 96%;
    width: 98%;
    background-color:  #fdfdfd;
    box-shadow: 2px 1px 1px  #d8d8d8;
    border: 1px solid #ddd;
    border-radius: 5px;
}
/deep/.el-dialog__header {
    padding: 13px 20px 13px;
    background-color: skyblue;
}
.xiangqing{
    color:#67C23A
}
.bianji{
    color: #E6A23C;
}
.shanchu{
    color: #F56C6C ;
}
</style>