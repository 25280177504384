<template>
    <div class="body" v-loading="loading" element-loading-text="拼命加载中" element-loading-background="rgb(213 213 213 / 57%)">
        <!-- 查询 -->
        <div>
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item label="合同编号">
                    <el-input v-model="form.contractNo" placeholder="请输入合同编号"></el-input>
                </el-form-item>
                <el-form-item label="企业名称：">
                    <el-input v-model="form.name" placeholder="请输入企业名称"></el-input>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['ContractManagement_query'])" label="">
                    <el-button type="primary" @click="query()">查询</el-button>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['ContractManagement_add'])">
                    <el-button type="primary" @click="add()">新增</el-button>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['ContractManagement_audit']) & AuditiSactive == false & AuditmodeType == 0">
                    <el-button type="primary" @click="Audit()">审核</el-button>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['ContractManagement_audit']) & AuditiSactive == true & Auditstate == 0 & AuditmodeType == 0">
                    <el-button type="primary" @click="CancelAudit()">取消审核</el-button>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['ContractManagement_Sure']) & AuditiSactive == true & Auditstate == 0 & AuditmodeType == 1">
                    <el-button type="primary" @click="Sure()">确认</el-button>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['ContractManagement_Sure']) & AuditiSactive == true & Auditstate == 1 & AuditmodeType == 1">
                    <el-button type="primary" @click="CancelSure()">取消确认</el-button>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['ContractManagement_AdjustConfirm']) & AuditiSactive == true & Auditstate == 7 & AuditmodeType == 1">
                    <el-button type="primary" @click="AdjustmentSure()">调整确认</el-button>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['ContractManagement_AdjustConfirm']) & AuditiSactive == true & Auditstate == 7 & AuditmodeType == 1">
                    <el-button type="primary" @click="CancelAdjustmentSure()">取消确认</el-button>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['ContractManagement_AdjustConfirm']) & AuditiSactive == true & Auditstate == 7 & AuditmodeType == 0 & AuditUserid == 0">
                    <el-button type="primary" @click="AdjustmentSure()">调整确认</el-button>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['ContractManagement_AdjustConfirm']) & AuditiSactive == true & Auditstate == 7 & AuditmodeType == 0 & AuditUserid == 0">
                    <el-button type="primary" @click="CancelAdjustmentSure()">取消确认</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- 表格 -->
        <div>
            <el-table
                :header-cell-style="headClass"
                :cell-style="rowClass"
                :data="tableData"
                :height="tableHeight"
                highlight-current-row
                @cell-dblclick="dblclick"
                border
                style="width: 100%">
                <el-table-column
                fixed
                prop="date"
                label="选择"
                width="75">
                    <template slot-scope="scope">
                        <el-radio :label="scope.row.id" v-model="radio" @change.native="getCurrentRow(scope.row)">{{''}}</el-radio>
                    </template>
                </el-table-column>
                <el-table-column
                prop="code"
                :show-overflow-tooltip="true"
                label="合同编号"
                width="180">
                </el-table-column>
                <el-table-column
                prop="name"
                :show-overflow-tooltip="true"
                label="合同名称"
                width="">
                </el-table-column>
                <el-table-column
                prop="enterpriseName"
                :formatter="aa"
                :show-overflow-tooltip="true"
                label="企业名称"
                width="">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                :formatter="payType"
                prop="payType"
                label="支付方式"
                width="">
                </el-table-column>
                <el-table-column
                prop="carryModel"
                :formatter="carryModel"
                :show-overflow-tooltip="true"
                label="承运方式"
                width="">
                </el-table-column>
                <el-table-column
                prop="businessType"
                :formatter="industryType"
                :show-overflow-tooltip="true"
                label="业务类型"
                width="">
                </el-table-column>
                <el-table-column
                prop="receivableAmount"
                :show-overflow-tooltip="true"
                label="应收款项"
                width="">
                </el-table-column>
                <el-table-column
                prop="receiptsAmount"
                :show-overflow-tooltip="true"
                label="实时款项"
                width="">
                </el-table-column>
                <el-table-column
                prop="arrears"
                :formatter="arrears"
                :show-overflow-tooltip="true"
                label="欠款"
                width="">
                </el-table-column>
                <el-table-column
                prop="isActive"
                :formatter="isActive"
                :show-overflow-tooltip="true"
                label="审核状态"
                width="">
                </el-table-column>
                <el-table-column
                prop="state"
                :formatter="state"
                :show-overflow-tooltip="true"
                label="确认状态"
                width="">
                </el-table-column>
                <el-table-column
                width="150"
                prop="creationTime"
                :show-overflow-tooltip="true"
                label="创建时间">
                </el-table-column>
                <el-table-column
                fixed="right"
                label="操作"
                width="200">
                <template slot-scope="scope">
                    <el-button v-if="$hasPermission(['ContractManagement_details'])" @click="handleClick(scope.row)" type="text" size="small">详情</el-button>
                    <el-button v-if="$hasPermission(['ContractManagement_edit']) & scope.row.isActive == false" @click="modify(scope.row)" type="text" size="small">编辑</el-button>
                    <el-button v-if="$hasPermission(['ContractManagement_delete']) & scope.row.isActive == false" @click="Delete(scope.row)" type="text" size="small">删除</el-button>
                    <el-button v-if="$hasPermission(['ActuallyReceived']) & scope.row.modeType == 0 & scope.row.isActive == true & scope.row.state > 0 & scope.row.payType == 0" @click="receipts(scope.row)" type="text" size="small">实收款项</el-button>
                    <el-button v-if="$hasPermission(['ActuallyReceived']) & scope.row.modeType == 0 & scope.row.isActive == true & scope.row.state > 0 & scope.row.payType == 1" @click="receipts(scope.row)" type="text" size="small">实收款项</el-button>
                    <el-button v-if="$hasPermission(['ContractManagement_Adjustment']) & scope.row.isActive == true & scope.row.modeType == 0 &  3 > scope.row.state > 0" @click="Adjustment(scope.row)" type="text" size="small">调整</el-button>
                    <el-button v-if="$hasPermission(['ContractManagement_Adjustment']) & scope.row.modeType == 0 & scope.row.state > 6" @click="AdjustmentEdit(scope.row)" type="text" size="small">调整</el-button>
                </template>
                </el-table-column>
            </el-table>
            <!-- 表格分页 -->
            <div style="float:right" class="block">
                <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10, 15, 20]"
                :page-size="size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
                </el-pagination>
            </div>
        </div>
        <!-- 表单 -->
        <div>
            <el-dialog
                :title="title"
                :visible.sync="dialogVisible"
                :before-close="handleClose"
                style=""
                :fullscreen="true">
                <el-form style="width:99%;min-width:1300px" size="small" ref="addform" :rules="addformRules" :model="addform" label-width="110px" class="demo-form-inline">
                    <!-- 基本信息 -->
                    <el-row :gutter="24">
                        <el-col :span="6">
                            <el-form-item v-if="formButton == 'details' || formButton == 'Adjustment' || formButton == 'AdjustmentEdit' || formButton == 'AdjustmentSure'" label="合同名称：" prop="name" :rules="[{ required: true, message: '请输入合同名称', trigger: 'blur' }]">
                                <span >{{addform.name}}</span>
                            </el-form-item>
                            <el-form-item v-else label="合同名称：" prop="name" :rules="[{ required: true, message: '请输入合同名称', trigger: 'blur' }]">
                                <el-input v-model="addform.name" placeholder="请输入合同名称"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="合同编码：" prop="code">
                                <span v-if="formButton == 'details' || formButton == 'Adjustment' || formButton == 'AdjustmentEdit' || formButton == 'AdjustmentSure'">{{addform.code}}</span>
                                <el-input v-else v-model="addform.code" placeholder="请输入合同编码"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item v-if="formButton == 'details' || formButton == 'Adjustment' || formButton == 'AdjustmentEdit' || formButton == 'AdjustmentSure'" label="业务类型：">
                                <span v-for="item in business" :key="item.value" :label="item.value">
                                    <span v-if="addform.businessType == item.value">{{item.name}}</span>
                                </span>
                            </el-form-item>
                            <el-form-item v-else label="业务类型：" prop="businessType" :rules="[{ required: true, message: '请选择业务类型', trigger: 'blur' }]">
                                <el-radio-group @change="querySysEnterprise()" v-model="addform.businessType">
                                    <el-radio v-for="item in business" :key="item.value" :label="item.value">{{item.name}}</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- 企业信息 -->
                    <el-form-item style="margin-top:-10px" label-width="0px">
                        <el-divider content-position="left">企业信息</el-divider>
                    </el-form-item>
                    <el-row v-if="formButton == 'add' || formButton == 'modify'" style="margin-top:-10px" :gutter="24">
                        <el-col :span="6">
                            <el-form-item label="选择企业:" prop="enterpriseName">
                                <el-select v-if="addform.businessType == 0" @change="SelectFormOptions()" v-model="enterpriseName" clearable placeholder="请选择">
                                    <el-option
                                    v-for="item in FormOptions"
                                    :key="item.id"
                                    :label="item.purchaseName"
                                    :value="item.id">
                                    </el-option>
                                </el-select>
                                <el-select v-if="addform.businessType == 1" @change="SelectFormOptions()" v-model="enterpriseName" clearable placeholder="请选择">
                                    <el-option
                                    v-for="item in FormOptions"
                                    :key="item.id"
                                    :label="item.enterpriseName"
                                    :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row v-else style="margin-top:-10px" :gutter="24">
                        <el-col :span="6">
                            <el-form-item label="企业名称:" prop="">
                                <span v-show="addform.businessType == 0">{{enterpriseData.purchaseName}}</span>
                                <span v-show="addform.businessType == 1">{{enterpriseData.enterpriseName}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row style="" :gutter="24">
                        <el-col :span="3">
                            <el-form-item label-width="" label="联系人:">
                                <span v-show="addform.businessType == 0">{{enterpriseData.purchaseContacts}}</span>
                                <span v-show="addform.businessType == 1">{{enterpriseData.contacts}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item label-width="" label="联系电话:">
                                <span v-show="addform.businessType == 0">{{enterpriseData.purchaseContactTel}}</span>
                                <span v-show="addform.businessType == 1">{{enterpriseData.contactTel}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label-width="" label="税号:">
                                <span v-show="addform.businessType == 0">{{enterpriseData.purchaseTaxNumber}}</span>
                                <span v-show="addform.businessType == 1">{{enterpriseData.taxNumber}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label-width="" label="注册地址:">
                                <span v-show="addform.businessType == 0">{{enterpriseData.purchaseDomicile}}</span>
                                <span v-show="addform.businessType == 1">{{enterpriseData.domicile}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label-width="" label="开户银行:">
                                <span v-show="addform.businessType == 0">{{enterpriseData.purchaseBankName}}</span>
                                <span v-show="addform.businessType == 1">{{enterpriseData.bankName}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label-width="" label="银行账户:">
                                <span v-show="addform.businessType == 0">{{enterpriseData.purchaseBankNo}}</span>
                                <span v-show="addform.businessType == 1">{{enterpriseData.bankNo}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- 商品信息 -->
                    <el-form-item style="margin-top:-10px" label-width="0px">
                        <el-divider content-position="left">商品信息</el-divider>
                    </el-form-item>
                    <el-form-item v-if="formButton == 'add' || formButton == 'modify'" style="margin-top:-10px" label-width="26px">
                        <el-button type="primary" @click="addCarryDialog()">添加商品</el-button>
                    </el-form-item>
                    <el-row v-if="addform.contractSands.length !== 0" style="margin-top: -20px" :gutter="24">
                        <el-form-item label-width="38px">
                            <el-table
                                :header-cell-style="headClass"
                                :cell-style="rowClass"
                                :data="addform.contractSands"
                                height="250px"
                                border
                                style="width: 100%">
                                <el-table-column
                                prop="name"
                                :show-overflow-tooltip="true"
                                label="商品名称"
                                width="150">
                                </el-table-column>
                                <el-table-column
                                prop="standards"
                                :show-overflow-tooltip="true"
                                label="规格"
                                width="">
                                </el-table-column>
                                <el-table-column
                                prop="unit"
                                :show-overflow-tooltip="true"
                                label="单位"
                                width="">
                                </el-table-column>
                                <el-table-column
                                prop="planNumber"
                                :key="1"
                                label="计划数量"
                                width="">
                                    <template slot-scope="scope">
                                        <el-input v-if="formButton !== 'details' & formButton !== 'AdjustmentSure'" maxlength="7" v-model="scope.row.planNumber" @blur="function1(scope.row)" v-input-limit="2" placeholder="请输入计划数量"></el-input>
                                        <span v-else>{{scope.row.planNumber}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                prop="discountPrice"
                                :key="2"
                                :show-overflow-tooltip="true"
                                label="结算单价"
                                width="">
                                <template slot-scope="scope">
                                    <el-input v-if="formButton !== 'details' & formButton !== 'AdjustmentSure'" v-model="scope.row.discountPrice" @blur="function2(scope.row)" v-input-limit="2" placeholder="请输入结算单价"></el-input>
                                    <span v-else>{{scope.row.discountPrice}}</span>
                                </template>
                                </el-table-column>
                                <el-table-column
                                prop="freightPrice"
                                :key="3"
                                :show-overflow-tooltip="true"
                                label="运费单价"
                                width="">
                                <template slot-scope="scope">
                                    <el-input v-if="formButton !== 'details' & formButton !== 'AdjustmentSure'" v-model="scope.row.freightPrice" @blur="function3(scope.row)" v-input-limit="2" placeholder="请输入运费单价"></el-input>
                                    <span v-else>{{scope.row.freightPrice}}</span>
                                </template>
                                </el-table-column>
                                <el-table-column
                                :key="4"
                                label="生产/发货地址"
                                width="">
                                <template slot-scope="scope">
                                    <!-- <el-butto @click="addCarryDialog(scope.row)" type="text" size="small">编辑</el-button> -->
                                    <el-button @click="detailsAddress(scope.row)" type="text" size="small">详情</el-button>
                                </template>
                                </el-table-column>
                                <el-table-column
                                :key="5"
                                label="操作"
                                v-if="formButton == 'add' || formButton == 'modify'"
                                width="">
                                <template slot-scope="scope">
                                    <!-- <el-butto @click="addCarryDialog(scope.row)" type="text" size="small">编辑</el-button> -->
                                    <el-button @click="DeleteKSShop(scope.row,scope.$index)" type="text" size="small">删除</el-button>
                                </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </el-row>
                    <!-- 混凝土版本商品 -->
                    <!-- <el-form-item v-if="formButton !== 'details'" style="margin-top:-10px" label="" label-width="0px">
                        <el-checkbox :disabled="formButton == 'Adjustment' || formButton == 'AdjustmentEdit'  || formButton == 'AdjustmentSure'" @change="queryShop2()" v-model="ch1">混凝土商品</el-checkbox>
                    </el-form-item> -->
                    <el-row v-if="addform.contractConcretes.length !== 0" style="margin-top: -20px" :gutter="24">
                        <el-form-item label-width="38px">
                            <el-table
                                :header-cell-style="headClass"
                                :cell-style="rowClass"
                                :data="addform.contractConcretes"
                                height="250px"
                                border
                                style="width: 100%">
                                <el-table-column
                                prop="name"
                                :show-overflow-tooltip="true"
                                label="配方名称"
                                width="150">
                                </el-table-column>
                                <el-table-column
                                prop="standards"
                                :show-overflow-tooltip="true"
                                label="强度等级"
                                width="">
                                </el-table-column>
                                <el-table-column
                                prop="impermeability"
                                :show-overflow-tooltip="true"
                                label="抗渗等级"
                                width="">
                                </el-table-column>
                                <el-table-column
                                prop="slump"
                                label="塌落度"
                                width="">
                                </el-table-column>
                                <el-table-column
                                prop="aggregateSize"
                                :key="5"
                                :show-overflow-tooltip="true"
                                label="骨料粒径"
                                width="">
                                    <template slot-scope="scope">
                                        <el-input v-if="formButton !== 'details' & formButton !== 'AdjustmentSure'" v-model="scope.row.aggregateSize" placeholder="请输入骨料粒径"></el-input>
                                        <span v-else>{{scope.row.aggregateSize}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                prop="pouringMethod"
                                :key="6"
                                :show-overflow-tooltip="true"
                                label="浇筑方式"
                                width="">
                                    <template slot-scope="scope">
                                        <el-input v-if="formButton !== 'details' & formButton !== 'AdjustmentSure'" v-model="scope.row.pouringMethod" placeholder="请输入浇筑方式"></el-input>
                                        <span v-else>{{scope.row.pouringMethod}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                prop="constructionPosition"
                                :key="7"
                                :show-overflow-tooltip="true"
                                label="施工部位"
                                width="">
                                    <template slot-scope="scope">
                                        <el-input v-if="formButton !== 'details' & formButton !== 'AdjustmentSure'" v-model="scope.row.constructionPosition" placeholder="请输入施工部位"></el-input>
                                        <span v-else>{{scope.row.constructionPosition}}</span>
                                        
                                    </template>
                                </el-table-column>
                                <el-table-column
                                prop="planNumber"
                                :key="8"
                                :show-overflow-tooltip="true"
                                label="计划数量(m³)"
                                width="">
                                    <template slot-scope="scope">
                                        <el-input v-if="formButton !== 'details' & formButton !== 'AdjustmentSure'" maxlength="7" v-model="scope.row.planNumber" @blur="functiona1(scope.row)" v-input-limit="2" placeholder="请输入计划数量"></el-input>
                                        <span v-else>{{scope.row.planNumber}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                prop="discountPrice"
                                :key="9"
                                :show-overflow-tooltip="true"
                                label="结算单价"
                                width="">
                                <template slot-scope="scope">
                                    <el-input v-if="formButton !== 'details'  & formButton !== 'AdjustmentSure'" v-model="scope.row.discountPrice" @blur="functiona2(scope.row)" v-input-limit="2" placeholder="请输入结算单价"></el-input>
                                    <span v-else>{{scope.row.discountPrice}}</span>
                                </template>
                                </el-table-column>
                                <el-table-column
                                prop="freightPrice"
                                :key="11"
                                :show-overflow-tooltip="true"
                                label="运费单价"
                                width="">
                                <template slot-scope="scope">
                                    <el-input v-if="formButton !== 'details'  & formButton !== 'AdjustmentSure'" v-model="scope.row.freightPrice" @blur="functiona3(scope.row)" v-input-limit="2" placeholder="请输入运费单价"></el-input>
                                    <span v-else>{{scope.row.freightPrice}}</span>
                                </template>
                                </el-table-column>
                                <el-table-column
                                prop="pumpingPrice"
                                :key="12"
                                :show-overflow-tooltip="true"
                                label="泵送单价"
                                width="">
                                    <template slot-scope="scope">
                                        <el-input v-if="formButton !== 'details' & formButton !== 'AdjustmentSure'" v-model="scope.row.pumpingPrice" @blur="functiona4(scope.row)" v-input-limit="2" placeholder="请输入泵送单价"></el-input>
                                        <span v-else>{{scope.row.pumpingPrice}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                :key="13"
                                label="生产/发货地址"
                                width="">
                                    <template slot-scope="scope">
                                        <el-button @click="detailsAddress(scope.row)" type="text" size="small">详情</el-button>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                :key="14"
                                label="操作"
                                v-if="formButton == 'add' || formButton == 'modify'"
                                width="">
                                <template slot-scope="scope">
                                    <!-- <el-butto @click="addCarryDialog(scope.row)" type="text" size="small">编辑</el-button> -->
                                    <el-button @click="DeleteHNTShop(scope.row,scope.$index)" type="text" size="small">删除</el-button>
                                </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </el-row>
                    <el-form-item style="margin-top:-10px" label-width="0px">
                        <el-divider content-position="left">支付信息</el-divider>
                    </el-form-item>
                    <!-- || formButton == 'Adjustment' || formButton == 'AdjustmentEdit' -->
                    <!-- 支付信息 -->
                    <div v-if="payType1 == false & payType2 == false & payType3 == false" style="display: flex">
                        <el-form-item label="" label-width="30px">
                            <span v-if="formButton == 'details' || formButton == 'AdjustmentSure'">支付方式：充值支付</span>
                            <el-checkbox v-else v-model="payType0" @change="payTypeChecked" :disabled="payType0checked">充值</el-checkbox>
                        </el-form-item>
                        <el-form-item v-if="payType0 == true" label="充值金额：">
                            <div style="display:flex">
                                <el-input v-if="formButton !== 'details'  & formButton !== 'AdjustmentSure'" v-model="addform.payAmount" @blur="functionp1()" v-input-limit="2" placeholder="请输入充值金额"></el-input><span v-if="formButton !== 'details'  & formButton !== 'AdjustmentSure'">元</span>
                                <span v-else style="width:100px">{{addform.payAmount}}元</span>
                            </div>
                        </el-form-item>
                        <el-form-item v-if="payType0 == true" label="金额提示：" label-width="130px">
                            <div style="display:flex">
                                <el-input v-if="formButton !== 'details'  & formButton !== 'AdjustmentSure'" v-model="addform.creditTipsAmount" v-input-limit="2" placeholder="请输入金额提示"></el-input><span v-if="formButton !== 'details'  & formButton !== 'AdjustmentSure'">元</span>
                                <span v-else style="width: 100px">{{addform.creditTipsAmount}}元</span>
                                <!-- <span style="margin-left:3%">元</span> -->
                            </div>
                        </el-form-item>
                    </div>
                    <div v-if="payType0 == false & payType3 == false" style="display: flex">
                        <el-form-item label="" label-width="30px">
                            <span v-if="formButton == 'details' || formButton == 'AdjustmentSure'">
                                <span v-if="formButton == 'details' & payType1 == true || formButton == 'AdjustmentSure' & payType1 == true">支付方式：授信支付</span>
                            </span>
                            <el-checkbox v-else v-model="payType1" :disabled="payType1checked" @change="payTypeChecked">授信</el-checkbox>
                        </el-form-item>
                        <el-form-item v-if="payType1 == true" label="授信金额：">
                            <div style="display:flex">
                                <el-input v-if="formButton !== 'details' & formButton !== 'AdjustmentSure'" v-model="addform.payAmount" @blur="functionp1()" v-input-limit="2" placeholder="请输入授信金额"></el-input><span v-if="formButton !== 'details'  & formButton !== 'AdjustmentSure'">元</span>
                                <span v-else style="width: 100px">{{addform.payAmount}}元</span>
                                <!-- <span style="margin-left:3%"></span> -->
                            </div>
                        </el-form-item>
                        <el-form-item v-if="payType1 == true" label="" label-width="15px">
                            <div class="block" style="display:flex">
                                <el-checkbox :disabled="formButton == 'details' || formButton == 'AdjustmentSure'" v-model="addform.isDate">应收货款日期</el-checkbox>
                                <el-date-picker
                                v-if="addform.isDate !== false & formButton !== 'details'  & formButton !== 'AdjustmentSure'"
                                style="width:207px;margin-left:2%"
                                value-format="yyyy-MM-dd"
                                v-model="addform.repaymentDate"
                                type="date"
                                :picker-options="pickerOptions"
                                placeholder="选择日期">
                                </el-date-picker>
                                <span v-else><span v-if="addform.isDate != false">{{addform.repaymentDate}}</span></span>
                            </div>
                        </el-form-item>
                        <el-form-item v-if="payType1 == true" label="应收货款提示：" label-width="130px">
                            <div style="display:flex">
                                <el-input v-if="formButton !== 'details'  & formButton !== 'AdjustmentSure'" v-model="addform.creditTipsAmount" v-input-limit="2" placeholder="请输入金额提示"></el-input><span v-if="formButton !== 'details'  & formButton !== 'AdjustmentSure'">元</span>
                                <span v-else style="width: 100px">{{addform.creditTipsAmount}}元</span>
                            </div>
                        </el-form-item>
                        <el-form-item v-if="payType1 == true" label="" label-width="5px">
                            <span style="width:100%">上笔应收贷款: {{addform.receivableAmount}}元</span>
                        </el-form-item>
                    </div>
                    <div v-if="payType0 == false & payType3 == false" style="display: flex">
                        <el-form-item label="" label-width="30px">
                            <span v-if="formButton == 'details' || formButton == 'AdjustmentSure'">
                                <span v-if="formButton == 'details' & payType2 == true || formButton == 'AdjustmentSure' & payType2 == true">支付方式：账期支付</span>
                            </span>
                            <el-checkbox v-else v-model="payType2" @change="payTypeChecked" :disabled="payType2checked">账期</el-checkbox>
                        </el-form-item>
                        <el-form-item v-if="payType2 == true" label="还款月份：">
                            <el-input v-if="formButton !== 'details'  & formButton !== 'AdjustmentSure'" v-model.number="addform.repaymentTime1" placeholder="请输入几个月"></el-input>
                            <span v-else>{{addform.repaymentTime1}}月</span>
                        </el-form-item>
                        <el-form-item v-if="payType2 == true" style="margin-left:7px" label="-" label-width="14px">
                            <el-input v-if="formButton !== 'details'  & formButton !== 'AdjustmentSure'" v-model.number="addform.repaymentTime2" placeholder="请输入几个月后的多少天"></el-input>
                            <span v-else>{{addform.repaymentTime2}}天</span>
                        </el-form-item>
                        <el-form-item v-if="payType2 == true" style="margin-left:2px" label="" label-width="0px">
                            <span style="color:red">注：为几个月之后的多少天</span>
                        </el-form-item>
                        <el-form-item v-if="payType2 == true" label="账期提示天数：" label-width="130px">
                            <el-input v-if="formButton !== 'details'  & formButton !== 'AdjustmentSure'" v-model.number="addform.tipsDay" placeholder="请输入天数"></el-input>
                            <span v-else>{{addform.tipsDay}}</span>
                        </el-form-item>
                        <el-form-item v-if="payType2 == true" label="" label-width="5px">
                            <span style="width:100%">上笔应收贷款: {{addform.receivableAmount}}元</span>
                        </el-form-item>
                    </div>
                    <div v-if="payType0 == false & payType1 == false & payType2 == false" style="display: flex">
                        <el-form-item label="" label-width="30px">
                            <span v-if="formButton == 'details' || formButton == 'AdjustmentSure'">支付方式：现金支付</span>
                            <el-checkbox v-else v-model="payType3" @change="payTypeChecked" :disabled="payType3checked">现结</el-checkbox>
                        </el-form-item>
                    </div>
                    <!-- 分配金额 -->
                    <div v-if="payType0 == true || payType1 == true">
                        <el-form-item v-if="addform.enterpriseId != 0" label="分配金额：" label-width="110px">
                            <el-form-item v-for="(item,index) in addressGroupsYuan" :key="item.addressId" :label="'厂区'+(index+1)+'： '+item.addressName" :value="item.addressId" label-width="250px">
                                <el-input v-if="formButton !== 'details' & formButton !== 'AdjustmentSure'" style="width:220px" placeholder="请输入金额" @blur="functiong1(item)" v-model="item.price"></el-input><span v-if="formButton !== 'details'  & formButton !== 'AdjustmentSure'">元</span>
                                <span v-else>{{item.price}}元</span> <span v-if="item.isStop == true">当前可调整金额为{{item.receiptsPrice}}元</span>
                            </el-form-item>
                        </el-form-item>
                    </div>
                    <!-- 承运信息 -->
                    <el-form-item style="margin-top:-10px" label-width="0px">
                        <el-divider content-position="left">承运信息</el-divider>
                    </el-form-item>
                    <el-row :gutter="24">
                        <el-col :span="6">
                            <el-form-item v-if="formButton == 'details' || formButton == 'Adjustment' || formButton == 'AdjustmentEdit' || formButton == 'AdjustmentSure'" label="承运方式：" prop="">
                                <span v-if="addform.carryModel == 0">买家自提</span>
                                <span v-if="addform.carryModel == 1">卖方配送</span>
                            </el-form-item>
                            <el-form-item v-else label="承运方式：" prop="carryModel" :rules="[{ required: true, message: '请选择承运方式', trigger: 'blur' }]">
                                <el-radio-group v-model="addform.carryModel">
                                    <el-radio :label="0">买家自提</el-radio>
                                    <el-radio :label="1">卖方配送</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item v-if="formButton == 'details' || formButton == 'Adjustment' || formButton == 'AdjustmentEdit' || formButton == 'AdjustmentSure'" label="运费计算：" prop="">
                                <span v-if="addform.freightCalculation == 0">按吨数</span>
                                <span v-if="addform.freightCalculation == 1">按公里</span>
                            </el-form-item>
                            <el-form-item v-else label="运费计算：" prop="freightCalculation" :rules="[{ required: true, message: '请选择运费计算', trigger: 'blur' }]">
                                <el-radio-group v-model="addform.freightCalculation">
                                    <el-radio :label="0">按吨数</el-radio>
                                    <el-radio :label="1">按公里</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- 其他信息 -->
                    <el-form-item style="margin-top:-10px" label-width="0px">
                        <el-divider content-position="left">其他信息</el-divider>
                    </el-form-item>
                    <!-- 混凝土版本其他信息 -->
                    <el-row :gutter="24">
                        <el-col v-show="addform.industryType == 1 || addform.industryType == '0,1'" :span="6">
                            <el-form-item v-if="formButton == 'details' || formButton == 'AdjustmentSure'" label="施工单位：" prop="unitName">
                                <span>{{addform.unitName}}</span>
                            </el-form-item>
                            <el-form-item v-else label="施工单位：" prop="unitName" :rules="[{ required: true, message: '请输入施工单位', trigger: 'blur' }]">
                                <el-input placeholder="请输入施工单位" v-model="addform.unitName"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col v-show="addform.industryType == 1 || addform.industryType == '0,1'" :span="6">
                            <el-form-item label="工程名称：" prop="projectName" v-if="formButton == 'details' || formButton == 'AdjustmentSure'">
                                <span>{{addform.projectName}}</span>
                            </el-form-item>
                            <el-form-item v-else label="工程名称：" prop="projectName" :rules="[{ required: true, message: '请输入工程名称', trigger: 'blur' }]">
                                <el-input placeholder="请输入工程名称" v-model="addform.projectName"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item v-if="formButton == 'details' || formButton == 'AdjustmentSure'" label="销售人员：" prop="salesman">
                                <span>{{addform.salesman}}</span>
                            </el-form-item>
                            <el-form-item v-else label="销售人员：" prop="salesman" :rules="[{ required: true, message: '请输入销售人员', trigger: 'blur' }]">
                                <el-input :disabled="disabled" placeholder="请输入销售人员" v-model="addform.salesman"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item v-if="formButton == 'details' || formButton == 'AdjustmentSure'" label="销售电话：" prop="salesTel">
                                <span >{{addform.salesTel}}</span>
                            </el-form-item>
                            <el-form-item v-else label="销售电话：" prop="salesTel" :rules="[{ required: true, message: '请输入销售电话', trigger: 'blur' }]">
                                <el-input :disabled="disabled" v-model.number="addform.salesTel" placeholder="请输入销售电话"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="备注：" prop="remarks">
                                 <span v-if="formButton == 'details' || formButton == 'Adjustment' || formButton == 'AdjustmentEdit' || formButton == 'AdjustmentSure'" >{{addform.remarks}}</span>
                                <el-input v-else type="textarea" :autosize="{ minRows: 2, maxRows: 4}" placeholder="请输入内容" v-model="addform.remarks"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="AddCancel('addform')">取 消</el-button>
                    <el-button v-if="formButton == 'add'" type="primary" @click="AddSure('addform')">确 定</el-button>
                    <el-button v-if="formButton == 'modify'" type="primary" @click="AddSure('addform')">确 定</el-button>
                    <el-button v-if="formButton == 'Adjustment' || this.formButton == 'AdjustmentEdit'" type="primary" @click="AddSure('addform')">确 定</el-button>
                    <el-button v-if="formButton == 'AdjustmentSure'" type="primary" @click="AdjustConfirm()">确 定</el-button>
                </span>
            </el-dialog>
            <!-- 添加地址表单 -->
            <el-dialog
                title="添加地址"
                :visible.sync="CarryDialog"
                :before-close="handleClose"
                :close-on-click-modal="false"
                style="margin-top:-2%;"
                width="45%">
                <el-row :gutter="24">
                    <el-col :span="10">
                        <el-card style="padding: 10px;height: 259px;overflow-y:scroll">
                            <div style="display:flex" v-if="formButton !== 'detail'">
                                <el-checkbox :disabled="formButton == 'Adjustment' || formButton == 'AdjustmentEdit'  || formButton == 'AdjustmentSure'" @change="queryShop()" v-model="ch0">矿石商品</el-checkbox>
                            </div>
                            <el-tree
                                :data="KSShopData"
                                show-checkbox
                                node-key="id"
                                ref="KStree"
                                highlight-current
                                :props="defaultProps"
                                :expand-on-click-node="false"
                                @check-change="CheckedShop">
                            </el-tree>
                        </el-card>
                        <el-card style="padding: 10px;height: 258px;overflow-y:scroll">
                            <div style="display:flex" v-if="formButton !== 'detail'">
                                <el-checkbox :disabled="formButton == 'Adjustment' || formButton == 'AdjustmentEdit'  || formButton == 'AdjustmentSure'" @change="queryShop2()" v-model="ch1">混凝土商品</el-checkbox>
                            </div>
                            <el-tree
                                :data="HNTShopData"
                                show-checkbox
                                node-key="id"
                                ref="HNTtree"
                                highlight-current
                                :props="defaultProps"
                                :expand-on-click-node="false"
                                @check-change="CheckedShop">
                            </el-tree>
                        </el-card>
                    </el-col>
                    <el-col :span="14">
                        <el-card style="padding: 10px;height: 540px">
                            <el-form v-loading="dialogloading" style="width:95%" label-width="90px" ref="CarryForm" :rules="CarryFormRules" :model="CarryForm" class="demo-form-inline">
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label-width="0px">
                                            <el-divider content-position="left">生产地址</el-divider>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="生产地址" prop="addressId">
                                            <el-select @change="selectAddress()" style="width:100%" v-model="CarryForm.addressId" placeholder="请选择生产地址">
                                                <el-option
                                                    v-for="item in PaddressList"
                                                    :key="item.id"
                                                    :label="item.name"
                                                    :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label-width="0px">
                                            <el-divider content-position="left">收货地址</el-divider>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="收货地址" prop="receivingAddressId">
                                            <el-select @change="SselectAddress()" style="width:100%" v-model="CarryForm.receivingAddressId" placeholder="请选择收货地址">
                                                <el-option
                                                    v-for="item in SaddressList"
                                                    :key="item.id"
                                                    :label="item.name"
                                                    :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="计算里程" prop="calculatedMileage">
                                            <el-input :disabled="true" v-model="CarryForm.calculatedMileage" placeholder="请输入计算里程"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="确认里程" prop="confirmMileage">
                                            <el-input v-model="CarryForm.confirmMileage" v-input-limit="2" placeholder="请输入确认里程"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="到货日期" prop="arrivalDate">
                                            <div class="block">
                                                <el-date-picker
                                                style="width:100%"
                                                value-format="yyyy-MM-dd"
                                                v-model="CarryForm.arrivalDate"
                                                type="date"
                                                placeholder="选择日期时间"
                                                default-time="12:00:00"
                                                :picker-options="expireTimeOPtion">
                                                </el-date-picker>
                                            </div>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-form>
                        </el-card>
                    </el-col>
                </el-row>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelCarryDialog('CarryForm')">关 闭</el-button>
                    <el-button type="primary" @click="SureAddress('CarryForm')">确 定</el-button>
                </span>
            </el-dialog>
            <!-- 承运信息地址详情表单 -->
            <el-dialog
                title="详情"
                :visible.sync="CarryDetailsDialog"
                :before-close="handleClose"
                :show-close="false"
                style="margin-top:-3%"
                width="60%">
                    <el-table
                        :header-cell-style="headClass"
                        :cell-style="rowClass"
                        :data="AddressData"
                        height="631px"
                        border
                        style="width: 100%">
                        <el-table-column
                        prop="address"
                        :show-overflow-tooltip="true"
                        label="生产地址"
                        width="150">
                        </el-table-column>
                        <el-table-column
                        prop="receivingAddress"
                        :show-overflow-tooltip="true"
                        label="收货地址"
                        width="">
                        </el-table-column>
                        <el-table-column
                        prop="calculatedMileage"
                        label="计算里程"
                        width="">
                        </el-table-column>
                        <el-table-column
                        prop="confirmMileage"
                        :show-overflow-tooltip="true"
                        label="确认里程"
                        width="">
                        </el-table-column>
                        <el-table-column
                        prop="arrivalDate"
                        :show-overflow-tooltip="true"
                        label="到货日期"
                        width="">
                        </el-table-column>
                        <el-table-column
                            v-if="formButton == 'add' || formButton == 'modify'"
                            label="操作"
                            width="">
                            <template slot-scope="scope">
                                <el-button @click="AddressDelete(scope.row,scope.$index)" type="text" size="small">删除</el-button>
                            </template>
                            </el-table-column>
                    </el-table>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="CarryDetailsDialog = false">关 闭</el-button>
                </span>
            </el-dialog>
        </div>
        <!-- 实收款项 -->
        <div>
            <el-dialog
                title="实收款项"
                :visible.sync="receiptsAmountDialog"
                :before-close="handleClose"
                :show-close="false"
                width="400px">
                <div style="display:flex">
                    <el-input style="width:320px" placeholder="请输入实收金额" v-model="receiptsAmount" clearable></el-input>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="receiptsAmountDialog = false,start()">取 消</el-button>
                    <el-button type="primary" @click="Surereceipts()">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
// 引入地图
import { TMap } from '@/utils/TMap'
// 地图参数
var searchService,geocoder,map,markersArray = [];
// 地图经纬度
var lat = '';
var lng = '';
// 初始生产地址经纬度
var jingdu = ''
var weidu = ''
// 总距离
var distance = ''
// 地址半径
var radius = 3000;
export default {
    computed: {
        // tableHeight: document.documentElement.clientHeight
    },
    created() {
        var height = document.documentElement.clientHeight
        this.tableHeight = (height*0.67)+'px';
    },
    data(){
        return{
            expireTimeOPtion: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;  //如果没有后面的-8.64e7就是不可以选择今天的 
                }
            },
            loading: false,
            tableHeight: 0,
            form: {
                name: '',
                contractNo: ''
            },
            FormOptions: [],
            // 日期
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                },
            },
            tableData: [],
            // 表格分页
            page: 1,
            size: 10,
            total: 0,
            // 表格单选绑定
            radio: '',
            // 表单
            dialogVisible: false,
            // 表单里面的行业类型
            business: [],
            // 添加对话框 企业搜索输入框
            enterpriseName: '',
            // 添加的企业表格数据
            enterpriseData: {},
            EnterpriseLoading: false,
            // 添加企业表格选中事件
            enterpriseRadio: -1,
            // 分配厂区金额
            addressGroupsYuan: [],
            // 添加表单
            addform: {
                id: '',
                enterpriseId: 0,
                enterpriseName: "",
                name: "",
                code: "",
                purchaseId: 0,
                purchaseName: "",
                taxNumber: "",
                telephone: "",
                domicile: "",
                bankName: "",
                bankNo: "",
                contacts: "",
                contactTel: "",
                purchaseTaxNumber: '',
                purchaseTelephone: '',
                purchaseDomicile: '',
                purchaseBankName: '',
                purchaseBankNo: '',
                purchaseContacts: '',
                purchaseContactTel: '',
                purchaseBusinessName: '',
                purchaseBusinessUserId: '',
                purchaseBusinessTel: '',
                payType: '',
                payAmount: '',
                isDate: false,
                repaymentDate: "",
                creditTipsAmount: '',
                creditTipsDay: '',
                repaymentTime: "",
                repaymentTime1: '',
                repaymentTime2: '',
                tipsDay: '',
                carryModel: 0,
                freightCalculation: 0,
                unitName: "",
                projectName: "",
                salesman: "",
                salesTel: "",
                remarks: "",
                receivableAmount: 0,
                receiptsAmount: '',
                type: 0,
                businessType: '0',
                industryType: 0,
                contractSands: [],
                contractConcretes: [],
                contractAddresses: [],
                creatorId: 0,
                addressGroups: [],
                partnersId: ''
            },
            // 支付信息 支付方式
            payType0: false,
            payType1: false,
            payType2: false,
            payType3: false,
            // 添加表单验证
            addformRules: {
                
            },
            // 修改
            formButton: 'add',
            // 对话框标题
            title: '新增',
            // 审核id 
            auditid: '',
            AuditiSactive: false,
            AuditmodeType: 0,
            Auditstate: 0,
            AuditUserid: 0,
            // 添加承运信息打开对话框
            CarryDialog: false,
            // 地址列表
            PaddressList: [],
            SaddressList: [],
            dialogloading: false,
            // 地址仓号
            dynamicTags: [],
            SdynamicTags: [],
            // 添加承运信息表单
            CarryForm: {
                productId: '',
                productName: '',
                addressId: '',
                address: '',
                longitude: '',
                latitude: '',
                addressName: '',
                warehouseNumber: '0',
                receivingAddress: '',
                receivingLongitude: '',
                receivingLatitude: '',
                receivingWarehouseNumber: '0',
                calculatedMileage: '',
                confirmMileage: '',
                arrivalDate: '',
                receivingAddressId: ''
            },
            CarryFormRules: {
                // warehouseNumber: [
                //     { required: true , message: '请输入生产卸料点' , trigger: 'blur' },
                // ],
                receivingAddress: [
                    { required: true , message: '请输入收货地址' , trigger: 'blur' },
                ],
                receivingAddressId: [
                    { required: true , message: '请选择收货地址' , trigger: 'blur' },
                ],
                calculatedMileage: [
                    { required: true , message: '请搜索收货地址' , trigger: 'blur' },
                ],
                confirmMileage: [
                    { required: true , message: '请输入确认里程' , trigger: 'blur' },
                ],
                // receivingWarehouseNumber: [
                //     { required: true , message: '请输入收货卸料点' , trigger: 'blur' },
                // ],
                arrivalDate: [
                    { required: true , message: '请选择到货日期' , trigger: 'blur' },
                ],
            },
            // 添加地址对话框
            CarryDetailsDialog: false,
            AddressData: [],
            // 判断是保存矿石商品 还是混凝土商品
            ch0: false,
            ch1: false,
            // 实收款项对话框
            receiptsAmountDialog: false,
            receiptsAmount0: '',
            receiptsAmount: '',
            receiptsno: '',
            // 调整返回条件判断对象
            tiaozhengduixiang: {},
            disabled: true,
            // 调整的时候判断当前的支付方式
            payType0checked:false,
            payType1checked: false,
            payType2checked: false,
            payType3checked: false,
            timer: null,// 初始定时器变量名为null
            KSShopData: [],
            HNTShopData: [],
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            getCheckedNodesKSdata: [],
            getCheckedNodesHNTdata: [],
            ShopDetailDialog: false,
        }
    },
    beforeDestroy() {
        // js提供的clearInterval方法用来清除定时器
        clearInterval(this.timer);
        this.end()
    },
    methods:{
        start() {
            // 将定时器名字赋值到变量中
            this.timer = setInterval(() => {
                // console.log('开始-----')
                this.query()
            }, 20000);
        },
        end() {
            clearInterval(this.timer);
            this.timer = null // 这里最好清除一下，回归默认值
            // 众所周知，定时器返回一个随机整数，用于表示定时器的编号，后面通过名字可以取消这个定时器的执行。
            // console.log('结束-----')
        },
        // 表头 列 居中
        headClass() {
            return "text-align:center;background-color: #c8e0f391;color:#E6A23C";
        },
        rowClass() {
            return "text-align:center";
        },
        // 分页功能
        handleSizeChange(val) {
            this.size = val
            this.query()
        },
        handleCurrentChange(val) {
            this.page = val
            this.query()
        },
        // 地图初始化函数，本例取名为init，开发者可根据实际情况定义
        initMap(row) {
            TMap('TSCBZ-QE7KW-IFNRH-OYI2A-5L6Z3-ZLBH3').then(qq => {
                var center = new qq.maps.LatLng(28.201195, 113.13224);
                var map = new qq.maps.Map(document.getElementById("qiyeMap"), {
                    center: center,
                    zoom: 12
                });
                var latlngBounds = new qq.maps.LatLngBounds();
                // (map, "click", function(event)
                qq.maps.event.addListener(map, function(event) {
                    if (markersArray) {
                        for (let i in markersArray) {
                            markersArray[i].setMap(null);
                        }
                    }
                    var marker = new qq.maps.Marker({
                        map: map,
                        position: event.latLng
                    });
                    markersArray.push(marker);
                });
                var that = this
                geocoder = new qq.maps.Geocoder({
                    complete: function(result) {
                        if (markersArray) {
                            for (var i = 0; i < markersArray.length; i++) {
                                markersArray[i].setMap(null);
                            }
                            markersArray.length = 0;
                        }
                        map.setCenter(result.detail.location);
                        var marker = new qq.maps.Marker({
                            map: map,
                            position: result.detail.location
                        });
                        markersArray.push(marker);
                        lat = result.detail.location.lat
                        lng = result.detail.location.lng
                        // 计算距离
                        var radLat1 = jingdu*Math.PI / 180.0;
                        var radLat2 = lat*Math.PI / 180.0;
                        var a = radLat1 - radLat2;
                        var  b = weidu*Math.PI / 180.0 - lng*Math.PI / 180.0;
                        var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a/2),2) +
                            Math.cos(radLat1)*Math.cos(radLat2)*Math.pow(Math.sin(b/2),2)));
                        s = s *6378.137 ;// EARTH_RADIUS;
                        s = Math.round(s * 10000) / 10000;
                        distance = s
                        that.CarryForm.calculatedMileage = distance
                    },
                }); 
            });
        },
        SinitMap(row) {
            TMap('TSCBZ-QE7KW-IFNRH-OYI2A-5L6Z3-ZLBH3').then(qq => {
                var center = new qq.maps.LatLng(28.201195, 113.13224);
                var map = new qq.maps.Map(document.getElementById("SqiyeMap"), {
                    center: center,
                    zoom: 12
                });
                var latlngBounds = new qq.maps.LatLngBounds();
                // (map, "click", function(event)
                qq.maps.event.addListener(map, function(event) {
                    if (markersArray) {
                    for (let i in markersArray) {
                        markersArray[i].setMap(null);
                    }
                    }
                    var marker = new qq.maps.Marker({
                    map: map,
                    position: event.latLng
                    });
                    markersArray.push(marker);
                });
                var that = this
                geocoder = new qq.maps.Geocoder({
                    complete: function(result) {
                        if (markersArray) {
                            for (var i = 0; i < markersArray.length; i++) {
                                markersArray[i].setMap(null);
                            }
                            markersArray.length = 0;
                        }
                        map.setCenter(result.detail.location);
                        var marker = new qq.maps.Marker({
                            map: map,
                            position: result.detail.location
                        });
                        markersArray.push(marker);
                        lat = result.detail.location.lat
                        lng = result.detail.location.lng
                        // 计算距离
                        var radLat1 = that.CarryForm.receivingLatitude*Math.PI / 180.0;
                        var radLat2 = lat*Math.PI / 180.0;
                        var a = radLat1 - radLat2;
                        var  b = that.CarryForm.receivingLongitude*Math.PI / 180.0 - lng*Math.PI / 180.0;
                        var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a/2),2) +
                            Math.cos(radLat1)*Math.cos(radLat2)*Math.pow(Math.sin(b/2),2)));
                        s = s *6378.137 ;// EARTH_RADIUS;
                        s = Math.round(s * 10000) / 10000;
                        distance = s
                        that.CarryForm.calculatedMileage = distance
                    },
                }); 
            });
        },
        // 搜索地址
        async searchKeyword() {
            if(this.CarryForm.address == ''){
                return this.$message.info('请输入地址')
            }
            var keyword = this.CarryForm.receivingAddress;
            //this.clearOverlays(markersArray);
            //根据输入的城市设置搜索范围
            // searchService.setLocation("北京");
            //根据输入的关键字在搜索范围内检索
            var key = 'TSCBZ-QE7KW-IFNRH-OYI2A-5L6Z3-ZLBH3'
            if (keyword) {
               await geocoder.getLocation(keyword)
            } else {
                this.$message.info('请输入正确的收货地址')
            }
        },
        async SsearchKeyword() {
            var keyword = this.CarryForm.address;
            if(keyword == ''){
                this.$message.info('请输入正确的生产地址')
                return
            }
            if(this.CarryForm.receivingAddressId == ''){
                this.$message.info('请选择正确的收货地址')
                return
            }
            //this.clearOverlays(markersArray);
            //根据输入的城市设置搜索范围
            // searchService.setLocation("北京");
            //根据输入的关键字在搜索范围内检索
            var key = 'TSCBZ-QE7KW-IFNRH-OYI2A-5L6Z3-ZLBH3'
            if (keyword) {
               await geocoder.getLocation(keyword)
            } else {
                this.$message.info('请输入正确的收货地址')
            }
        },
        // 表格里面判断
        isActive(row){
            if(row.isActive == true){
                return '已审核';
            }else{
                return '未审核'
            }
        },
        // 添加对话框企业信息表格返回值
        name(row){
            if(row.modeType == 0){
                return row.purchaseName;
            }else{
                return row.enterpriseName;
            }
        },
        contacts(row){
            if(row.modeType == 0){
                return row.purchaseContacts;
            }else{
                return row.contacts;
            }
        },
        contactTel(row){
            if(row.modeType == 0){
                return row.purchaseContactTel;
            }else{
                return row.contactTel;
            }
        },
        taxNumber(row){
            if(row.modeType == 0){
                return row.purchaseTaxNumber;
            }else{
                return row.taxNumber;
            }
        },
        domicile(row){
            if(row.modeType == 0){
                return row.purchaseDomicile;
            }else{
                return row.domicile;
            }
        },
        bankName(row){
            if(row.modeType == 0){
                return row.purchaseBankName;
            }else{
                return row.bankName;
            }
        },
        bankNo(row){
            if(row.modeType == 0){
                return row.purchaseBankNo;
            }else{
                return row.bankNo;
            }
        },
        payType(row){
            if(row.payType == 0){
                return '充值支付';
            }else if(row.payType == 1){
                return '授信支付';
            }else if(row.payType == 2){
                return '账期支付';
            }else if(row.payType == 3){
                return '现金支付';
            }else if(row.payType == '1,2'){
                return '账期,授信支付';
            }
        },
        carryModel(row){
            if(row.carryModel == 0){
                return '买家自提';
            }else{
                return '卖方配送';
            }
        },
        industryType(row){
            if(row.businessType == '0'){
                return '销售';
            }else if(row.businessType == '1'){
                return '采购';
            }
        },
        state(row){
            if(row.state == 0){
                return '未确认';
            }else if(row.state == 1){
                return '已确认';
            }else if(row.state == 2){
                return '已下单';
            }else if(row.state == 3){
                return '已完成';
            }else if(row.state == 4){
                return '暂停';
            }else if(row.state == 5){
                return '取消';
            }else if(row.state == 6){
                return '终止';
            }else if(row.state == 7){
                return '调整待确认';
            }
        },
        // 表格里面欠款金额
        arrears(row){
            var arrears = row.receivableAmount-row.receiptsAmount
            return arrears
        },
        // 表格里面企业名称判断是己方还是对方
        aa(row){
            if(row.businessType == 0){
                if(row.modeType == 0){
                    return row.purchaseName;
                }else{
                    return row.enterpriseName;
                }
            }else{
                if(row.modeType == 1){
                    return row.purchaseName;
                }else{
                    return row.enterpriseName;
                }
            }
        },
        // 查询企业信息
        querySysEnterprise(){
            this.enterpriseName = ''
            this.FormOptions = []
            this.addform.salesman = ''
            this.addform.salesTel = ''
            this.enterpriseData = {}
            this.PaddressList = []
            this.SaddressList = []
            this.addform.contractSands = []
            this.addform.contractConcretes = []
            this.ch0 = false
            this.ch1 = false
            this.payType0 = false
            this.payType1 = false
            this.payType2 = false
            this.payType3 = false
            this.addressGroupsYuan = []
            if(this.addform.businessType == 0){
                this.$http.get(this.$syspartners_getsalelist)
                .then(response => {
                    this.FormOptions = response.data.data.list
                })
            }else{
                this.$http.get(this.$syspartners_getpurchaselist)
                .then(response => {
                    this.FormOptions = response.data.data.list
                })
            }
        },
        // 选择客户框数据
        SelectFormOptions(){
            this.enterpriseData = {}
            this.AddressData = []
            this.addform.contractAddresses = []
            this.FormOptions.forEach(item => {
                if(item.id == this.enterpriseName){
                    this.enterpriseData = item
                    this.enterpriseRadio = item.enterpriseId
                    this.addform.enterpriseId = item.enterpriseId
                    this.addform.enterpriseName = item.enterpriseName
                    this.addform.taxNumber = item.taxNumber
                    this.addform.telephone = item.telephone
                    this.addform.domicile = item.domicile
                    this.addform.bankName = item.bankName
                    this.addform.bankNo = item.bankNo
                    this.addform.contacts = item.contacts
                    this.addform.contactTel = item.contactTel
                    this.addform.purchaseId	 = item.purchaseId	
                    this.addform.purchaseName = item.purchaseName
                    this.addform.purchaseTaxNumber = item.purchaseTaxNumber
                    this.addform.purchaseTelephone = item.purchaseTelephone
                    this.addform.purchaseDomicile = item.purchaseDomicile
                    this.addform.purchaseBankName = item.purchaseBankName
                    this.addform.purchaseBankNo = item.purchaseBankNo
                    this.addform.purchaseContacts = item.purchaseContacts
                    this.addform.purchaseContactTel = item.purchaseContactTel
                    this.addform.purchaseBusinessName = item.purchaseBusinessName
                    this.addform.purchaseBusinessUserId = item.purchaseBusinessUserId
                    this.addform.purchaseBusinessTel = item.purchaseBusinessTel
                    this.addform.partnersId = item.id
                    if(this.addform.businessType == 0){
                        if(item.businessName == ''){
                            this.disabled = false
                            return
                        }
                        this.disabled = true
                        this.addform.salesman = item.businessName
                        this.addform.salesTel = item.businessTel
                    }else{
                        if(item.purchaseBusinessName == ''){
                            this.disabled = false
                            return
                        }
                        this.disabled = true
                        this.addform.salesman = item.purchaseBusinessName
                        this.addform.salesTel = item.purchaseBusinessTel
                    }
                    var aa = item
                    this.queryAddress(item)
                }
            })
        },
        // 查询矿石商品信息
        queryShop(){
            this.ShopLoading = true
            this.KSShopData = []
            // this.enterpriseRadio = -1
            if(this.ch0 == true){
                this.addform.industryType = 0
                this.$http.post(this.$SysProduct_GetActivePageList,{
                    currentPage: this.page,
                    pageSize: this.size,
                    filter: {
                        queryString: '',
                        businessType: Number(this.addform.businessType),
                        industryType: Number(this.addform.industryType)
                    }
                }).then(response => {
                    response.data.data.list.forEach(ShopItem => {
                        this.KSShopData.push({
                            label: ShopItem.name,
                            active: ShopItem.active,
                            businessType: ShopItem.businessType,
                            creationTime: ShopItem.creationTime,
                            discountPrice: ShopItem.discountPrice,
                            enterpriseId: ShopItem.enterpriseId,
                            freightPrice: ShopItem.freightPrice,
                            id: ShopItem.id,
                            impermeability: ShopItem.impermeability,
                            impermeabilityCode: ShopItem.impermeabilityCode,
                            industryType: ShopItem.industryType,
                            isActive: ShopItem.isActive,
                            name: ShopItem.name,
                            no: ShopItem.no,
                            remarks: ShopItem.remarks,
                            slump: ShopItem.slump,
                            standards: ShopItem.standards,
                            standardsCode: ShopItem.standardsCode,
                            unit: ShopItem.unit,
                            unitPrice: ShopItem.unitPrice,
                        })
                    })
                    this.ShopLoading = false
                })
            }else{
                this.KSShopData = []
            }
        },
        // 查询混凝土商品信息
        queryShop2(){
            this.ShopLoading = true
            this.HNTShopData = []
            if(this.ch1 == true){
                this.addform.industryType = 1
                this.$http.post(this.$SysProduct_GetActivePageList,{
                    currentPage: this.page,
                    pageSize: this.size,
                    filter: {
                        queryString: '',
                        businessType: Number(this.addform.businessType),
                        industryType: Number(this.addform.industryType)
                    }
                }).then(response => {
                    response.data.data.list.forEach(ShopItem => {
                        this.HNTShopData.push({
                            label: ShopItem.name,
                            active: ShopItem.active,
                            businessType: ShopItem.businessType,
                            creationTime: ShopItem.creationTime,
                            discountPrice: ShopItem.discountPrice,
                            enterpriseId: ShopItem.enterpriseId,
                            freightPrice: ShopItem.freightPrice,
                            id: ShopItem.id,
                            impermeability: ShopItem.impermeability,
                            impermeabilityCode: ShopItem.impermeabilityCode,
                            industryType: ShopItem.industryType,
                            isActive: ShopItem.isActive,
                            name: ShopItem.name,
                            no: ShopItem.no,
                            remarks: ShopItem.remarks,
                            slump: ShopItem.slump,
                            standards: ShopItem.standards,
                            standardsCode: ShopItem.standardsCode,
                            unit: ShopItem.unit,
                            unitPrice: ShopItem.unitPrice,
                        })
                    })
                    this.ShopLoading = false
                })
            }else if(this.ch0 == true){
                this.addform.industryType = 0
                this.HNTShopData = []
            }else{
                this.addform.industryType = ''
                this.HNTShopData = []
            }
        },
        CheckedShop(){

        },
        // 查询生产地址信息
        queryAddress(aa){
            this.dialogloading = true
            var a = ''
            var b = ''
            if(this.addform.businessType == 0){
                a = aa.enterpriseId
                b = aa.purchaseId
                if(Number(aa.purchaseId) == 0){
                    this.$http.post(this.$SysAddress_GetPageList,{
                        currentPage: this.page,
                        pageSize: this.size,
                        filter: {
                            queryString: '',
                            enterpriseId: a
                        }
                    }).then(response => {
                        this.PaddressList = response.data.data.list
                    })
                    this.$http.post(this.$SysAddress_GetPageList2,{
                        currentPage: this.page,
                        pageSize: this.size,
                        filter: {
                            queryString: '',
                            enterpriseId: Number(aa.id)
                        }
                    }).then(response => {
                        this.SaddressList = response.data.data.list
                    })
                    this.dialogloading = false
                }else{
                    this.$http.post(this.$SysAddress_GetPageList,{
                        currentPage: this.page,
                        pageSize: this.size,
                        filter: {
                            queryString: '',
                            enterpriseId: a
                        }
                    }).then(response => {
                        this.PaddressList = response.data.data.list
                    })
                    this.$http.post(this.$SysAddress_GetPageList,{
                        currentPage: this.page,
                        pageSize: this.size,
                        filter: {
                            queryString: '',
                            enterpriseId: Number(b)
                        }
                    }).then(response => {
                        this.SaddressList = response.data.data.list
                    })
                    this.dialogloading = false
                }
            }else{
                b = aa.enterpriseId
                a = aa.purchaseId
                if(Number(aa.enterpriseId) == 0){
                    this.$http.post(this.$SysAddress_GetPageList,{
                        currentPage: this.page,
                        pageSize: this.size,
                        filter: {
                            queryString: '',
                            enterpriseId: a
                        }
                    }).then(response => {
                        this.SaddressList = response.data.data.list
                    })
                    this.$http.post(this.$SysAddress_GetPageList2,{
                        currentPage: this.page,
                        pageSize: this.size,
                        filter: {
                            queryString: '',
                            enterpriseId: Number(aa.id)
                        }
                    }).then(response => {
                        this.PaddressList = response.data.data.list
                    })
                    this.dialogloading = false
                }else{
                    this.$http.post(this.$SysAddress_GetPageList,{
                        currentPage: this.page,
                        pageSize: this.size,
                        filter: {
                            queryString: '',
                            enterpriseId: a
                        }
                    }).then(response => {
                        this.SaddressList = response.data.data.list
                    })
                    this.$http.post(this.$SysAddress_GetPageList,{
                        currentPage: this.page,
                        pageSize: this.size,
                        filter: {
                            queryString: '',
                            enterpriseId: Number(b)
                        }
                    }).then(response => {
                        this.PaddressList = response.data.data.list
                    })
                    this.dialogloading = false
                }
            }
        },
        // 查询事件
        query(){
            if(!this.$hasPermission(['ContractManagement_query'])){
                return
            }
            this.radio = -1
            this.auditid = ''
            this.loading = true
            this.$http.post(this.$SysContract_GetPageList,{
                currentPage: this.page,
                pageSize: this.size,
                filter: {
                    queryString: this.form.name,
                    contractNo: this.form.contractNo,
                    startDate: this.form.startDate,
                    endDate: this.form.endDate,
                }
            }).then(response => {
                this.tableData = response.data.data.list
                this.total = response.data.data.total
                this.loading = false
            })
        },
        // 新增按钮事件
        add(){
            this.end()
            this.payType0checked = false
            this.payType1checked = false
            this.payType2checked = false
            this.payType3checked = false
            this.formButton = 'add'
            this.restaddfrom()
            this.querySysEnterprise()
            this.title = '新增'
            this.dialogVisible = true
            this.enterpriseData = {}
        },
        // 获取企业信息 
        getenterprise(row){
            this.addform.enterpriseId = row.enterpriseId
            this.addform.enterpriseName = row.enterpriseName
            this.addform.taxNumber = row.taxNumber
            this.addform.telephone = row.telephone
            this.addform.domicile = row.domicile
            this.addform.bankName = row.bankName
            this.addform.bankNo = row.bankNo
            this.addform.contacts = row.contacts
            this.addform.contactTel = row.contactTel
            this.addform.purchaseId	 = row.purchaseId	
            this.addform.purchaseName = row.purchaseName
            this.addform.purchaseTaxNumber = row.purchaseTaxNumber
            this.addform.purchaseTelephone = row.purchaseTelephone
            this.addform.purchaseDomicile = row.purchaseDomicile
            this.addform.purchaseBankName = row.purchaseBankName
            this.addform.purchaseBankNo = row.purchaseBankNo
            this.addform.purchaseContacts = row.purchaseContacts
            this.addform.purchaseContactTel = row.purchaseContactTel
            this.addform.purchaseBusinessName = row.purchaseBusinessName
            this.addform.purchaseBusinessUserId = row.purchaseBusinessUserId
            this.addform.purchaseBusinessTel = row.purchaseBusinessTel
            if(this.addform.businessType == 0){
                if(row.businessName == ''){
                    this.disabled = false
                    return
                }
                this.disabled = true
                this.addform.salesman = row.businessName
                this.addform.salesTel = row.businessTel
            }else{
                if(row.purchaseBusinessName == ''){
                    this.disabled = false
                    return
                }
                this.disabled = true
                this.addform.salesman = row.purchaseBusinessName
                this.addform.salesTel = row.purchaseBusinessTel
            }
            var aa = row
            this.queryAddress(aa)
        },
        // 添加点击确定事件
        AddSure(formName){
            if(this.ch1 == false){
                this.addform.unitName = '1'
                this.addform.projectName = '1'
            }
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if(this.ch1 == false){
                        this.addform.unitName = ''
                        this.addform.projectName = ''
                    }
                    // 添加之前的判断
                    if(this.addform.name == ''){
                        return this.$message.warning('请输入合同名称')
                    }
                    // if(this.addform.code == ''){
                    //     return this.$message.warning('请输入合同编码')
                    // }
                    if(this.enterpriseRadio == -1){
                        return this.$message.warning('请选择企业')
                    }
                    var shop = []
                    var shop2 = []
                    var arrId = []
                    this.addform.contractAddresses.forEach(aa => {
                        arrId.push(aa.productId)
                    })
                    // 判断商品信息是否正确
                    if(this.ch0 == true){
                        this.addform.industryType = 0
                        var a = 0
                        this.addform.contractSands.forEach(item => {
                            a++
                            if(Number(item.planNumber) == 0 || item.planNumber == undefined){
                                this.$message.warning('请给第'+a+'条矿石商品添加计划数量')
                                throw Error();
                            }else{
                                shop.push({
                                    productId: Number(item.id),
                                    productName: item.name,
                                    standards: item.standards,
                                    unit: item.unit,
                                    price: Number(item.discountPrice),
                                    freightPrice: Number(item.freightPrice),
                                    planNumber: Number(item.planNumber),
                                    standardsCode: item.standardsCode
                                })
                                // 判断有没有给商品信息添加生产和发货地址
                                if(arrId.indexOf(Number(item.id)) == -1){
                                    this.$message.warning('请给第'+a+'条矿石商品添加生产和发货地址')
                                    throw Error();
                                }
                            }
                        })
                    }
                    if(this.ch1 == true){
                        this.addform.industryType = 1
                        var a = 0
                        this.addform.contractConcretes.forEach(item => {
                            a++
                            if(Number(item.planNumber) == 0 || item.planNumber == undefined){
                                this.$message.warning('请给第'+a+'条混凝土商品添加计划数量')
                                throw Error();
                            }else{
                                if(item.aggregateSize == undefined){
                                    this.$message.warning('请给第'+a+'条混凝土商品添加骨料粒径')
                                    throw Error();
                                }else if(item.pouringMethod == undefined){
                                    this.$message.warning('请给第'+a+'条混凝土商品添加浇筑方式')
                                    throw Error();
                                }else if(item.constructionPosition == undefined){
                                    this.$message.warning('请给第'+a+'条混凝土商品添加施工部位')
                                    throw Error();
                                }else if(item.pumpingPrice == undefined){
                                    this.$message.warning('请给第'+a+'条混凝土商品添加泵送单价')
                                    throw Error();
                                }else{
                                    shop2.push({
                                        productId: Number(item.id),
                                        productName: item.name,
                                        standards: item.standards,
                                        unit: item.unit,
                                        price: Number(item.discountPrice),
                                        freightPrice: Number(item.freightPrice),
                                        planNumber: Number(item.planNumber),
                                        pumpingPrice: Number(item.pumpingPrice),
                                        slump: item.slump,
                                        impermeability: item.impermeability,
                                        aggregateSize: item.aggregateSize,
                                        pouringMethod: item.pouringMethod,
                                        constructionPosition: item.constructionPosition,
                                        standardsCode: item.standardsCode,
                                        impermeabilityCode: item.impermeabilityCode
                                    })
                                    // 判断有没有给商品信息添加生产和发货地址
                                    if(arrId.indexOf(Number(item.id)) == -1){
                                        this.$message.warning('请给第'+a+'条混凝土商品添加生产和发货地址')
                                        throw Error();
                                    }
                                }
                            }
                        })
                    }
                    if(this.ch0 == true & this.ch1 == true){
                        this.addform.industryType = '0,1'
                    }
                    // 判断有没有商品信息
                    if(shop.length == 0){
                        if(shop2.length == 0){
                            return this.$message.warning('请至少购买一种商品')
                        }
                    }
                    // 判断有没有支付信息
                    if(this.payType0 == true){
                        this.addform.isDate = false
                        if(Number(this.addform.payAmount) == 0){
                            return this.$message.warning('请输入充值金额')
                        }
                        if(Number(this.addform.creditTipsAmount) == 0){
                            return this.$message.warning('请输入金额提示值')
                        }else{
                            if(Number(this.addform.creditTipsAmount) >= Number(this.addform.payAmount)){
                                return this.$message.warning('提示金额不得超过充值金额')
                            }
                        }
                        this.addform.repaymentDate = '1999-02-12 12:00:00'
                        this.addform.repaymentTime = '1999-02-12 12:00:00'
                        this.addform.payType = '0'
                    }else if(this.payType1 == true & this.payType2 == true){
                        if(Number(this.addform.payAmount) == 0){
                            return this.$message.warning('请输入授信金额')
                        }
                        if(this.addform.isDate == true){
                            if(this.addform.repaymentDate == ''){
                                return this.$message.warning('请选择应收货款日期')
                            }
                        }else{
                            this.addform.repaymentDate = '1999-02-12 12:00:00'
                        }
                        if(Number(this.addform.creditTipsAmount) == 0){
                            return this.$message.warning('请输入金额提示值')
                        }
                        // 账期
                        if(this.addform.repaymentTime1 == ''){
                            return this.$message.warning('请输入账期还款日期月份')
                        }
                        if(this.addform.repaymentTime2 == ''){
                            return this.$message.warning('请输入账期还款天数')
                        }
                        if(this.addform.tipsDay == ''){
                            return this.$message.warning('请选择账期还款提示天数')
                        }
                        this.addform.payType = '1,2'
                    }else if(this.payType1 == true & this.payType2 == false){
                        if(Number(this.addform.payAmount) == 0){
                            return this.$message.warning('请输入授信金额')
                        }
                        if(this.addform.repaymentDate == null){
                            this.addform.repaymentDate = ''
                        }
                        if(this.addform.isDate == true){
                            if(this.addform.repaymentDate == ''){
                                return this.$message.warning('请选择应收货款日期')
                            }
                        }else{
                            this.addform.repaymentDate = '1999-02-12 12:00:00'
                        }
                        if(Number(this.addform.creditTipsAmount) == 0){
                            return this.$message.warning('请输入金额提示值')
                        }else{
                            if(Number(this.addform.creditTipsAmount) >= Number(this.addform.payAmount)){
                                return this.$message.warning('提示金额不得超过充值金额')
                            }
                        }
                        this.addform.repaymentTime = '1999-02-12 12:00:00'
                        this.addform.payType = '1'
                    }else if(this.payType2 == true & this.payType1 == false){
                        if(this.addform.repaymentTime1 == ''){
                            return this.$message.warning('请输入账期还款日期月份')
                        }
                        if(this.addform.repaymentTime2 == ''){
                            return this.$message.warning('请输入账期还款日期日')
                        }
                        if(this.addform.tipsDay == ''){
                            return this.$message.warning('请选择账期还款提示天数')
                        }
                        this.addform.payType = '2'
                        this.addform.repaymentDate = '1999-02-12 12:00:00'
                    }else if(this.payType3 == true){
                        this.addform.repaymentDate = '1999-02-12 12:00:00'
                        this.addform.repaymentTime = '1999-02-12 12:00:00'
                        this.addform.payType = '3'
                    }else{
                        return this.$message.warning('请选择支付方式')
                    }
                    // 混凝土其他信息判断
                    if(this.ch1 == true){
                        if(this.addform.unitName == ''){
                            return this.$message.warning('请输入施工单位')
                        }
                        if(this.addform.projectName == ''){
                            return this.$message.warning('请输入工程名称')
                        }
                        if(this.addform.salesman == ''){
                            return this.$message.warning('请输入销售人员')
                        }
                        if(this.addform.salesTel == ''){
                            return this.$message.warning('请输入销售电话')
                        }
                    }
                    var aa = 0
                    this.addform.addressGroups = []
                    this.addform.contractAddresses.forEach(aaa => {
                        this.addform.addressGroups.push({
                            addressId: aaa.addressId,
                            addressName: aaa.address,
                            price: 0
                        })
                    })
                    var priceArr = []
                    // 分配厂区金额
                    this.addressGroupsYuan.forEach(item => {
                        this.addform.addressGroups.forEach(items => {
                            if(item.addressId == items.addressId){
                                items.price = Number(item.price)
                            }
                        })
                        this.addform.contractAddresses.forEach(aaa => {
                            if(aaa.addressId == item.addressId){
                                aaa.isStop = item.isStop,
                                aaa.receiptsPrice = item.receiptsPrice
                            }
                        })
                        priceArr.push(Number(item.price))
                    })
                    aa = eval(priceArr.join("+"))
                    if(this.addform.enterpriseId == 0){

                    }else{
                        if(aa != this.addform.payAmount){
                            return this.$message.warning('分配总金额 必须和充值金额相等')
                        }
                    }
                    if(this.formButton == 'add'){
                        this.$http.post(this.$SysContract_CreateContract,{
                            enterpriseId: Number(this.addform.enterpriseId),
                            enterpriseName: this.addform.enterpriseName,
                            name: this.addform.name,
                            code: this.addform.code,
                            purchaseId: Number(this.addform.purchaseId),
                            purchaseName: this.addform.purchaseName,
                            taxNumber: this.addform.taxNumber,
                            telephone: this.addform.telephone,
                            domicile: this.addform.domicile,
                            bankName: this.addform.bankName,
                            bankNo: this.addform.bankNo,
                            contacts: this.addform.contacts,
                            contactTel: this.addform.contactTel,
                            purchaseTaxNumber: this.addform.purchaseTaxNumber,
                            purchaseTelephone: this.addform.purchaseTelephone,
                            purchaseDomicile: this.addform.purchaseDomicile,
                            purchaseBankName: this.addform.purchaseBankName,
                            purchaseBankNo: this.addform.purchaseBankNo,
                            purchaseContacts: this.addform.purchaseContacts,
                            purchaseContactTel: this.addform.purchaseContactTel,
                            purchaseBusinessName: this.addform.purchaseBusinessName,
                            purchaseBusinessUserId: this.addform.purchaseBusinessUserId,
                            purchaseBusinessTel: this.addform.purchaseBusinessTel,
                            payType: this.addform.payType,
                            payAmount: Number(this.addform.payAmount),
                            isDate: this.addform.isDate,
                            repaymentDate: this.addform.repaymentDate,
                            creditTipsAmount: Number(this.addform.creditTipsAmount),
                            creditTipsDay: Number(this.addform.creditTipsDay),
                            repaymentTime: this.addform.repaymentTime1+'-'+this.addform.repaymentTime2,
                            tipsDay: Number(this.addform.tipsDay),
                            carryModel: Number(this.addform.carryModel),
                            freightCalculation: Number(this.addform.freightCalculation),
                            unitName: this.addform.unitName,
                            projectName: this.addform.projectName,
                            salesman: this.addform.salesman,
                            salesTel: this.addform.salesTel,
                            remarks: this.addform.remarks,
                            receivableAmount: Number(this.addform.receivableAmount),
                            receiptsAmount: Number(this.addform.receiptsAmount),
                            type: Number(this.addform.type),
                            businessType: Number(this.addform.businessType),
                            industryType: this.addform.industryType,
                            contractSands: shop,
                            contractConcretes: shop2,
                            contractAddresses: this.addform.contractAddresses,
                            addressGroupDtos: this.addform.addressGroups,
                            partnersId: Number(this.addform.partnersId)
                        }).then( response => {
                            if(response.data.code !== 200){
                                this.$message.warning(response.data.message)
                                return
                            }
                            if(response.data.isSuccess == true){
                                this.query()
                                this.start()
                                this.$message.success('添加成功')
                                this.dialogVisible = false
                            }else{
                                this.$message.warning(response.data.message)
                            }
                        })
                    }else if(this.formButton == 'modify'){
                        this.$http.put(this.$SysContract_UpdateContract,{
                            id: this.addform.id,
                            enterpriseId: Number(this.addform.enterpriseId),
                            enterpriseName: this.addform.enterpriseName,
                            name: this.addform.name,
                            code: this.addform.code,
                            purchaseId: Number(this.addform.purchaseId),
                            purchaseName: this.addform.purchaseName,
                            taxNumber: this.addform.taxNumber,
                            telephone: this.addform.telephone,
                            domicile: this.addform.domicile,
                            bankName: this.addform.bankName,
                            bankNo: this.addform.bankNo,
                            contacts: this.addform.contacts,
                            contactTel: this.addform.contactTel,
                            purchaseTaxNumber: this.addform.purchaseTaxNumber,
                            purchaseTelephone: this.addform.purchaseTelephone,
                            purchaseDomicile: this.addform.purchaseDomicile,
                            purchaseBankName: this.addform.purchaseBankName,
                            purchaseBankNo: this.addform.purchaseBankNo,
                            purchaseContacts: this.addform.purchaseContacts,
                            purchaseContactTel: this.addform.purchaseContactTel,
                            purchaseBusinessName: this.addform.purchaseBusinessName,
                            purchaseBusinessUserId: this.addform.purchaseBusinessUserId,
                            purchaseBusinessTel: this.addform.purchaseBusinessTel,
                            payType: this.addform.payType,
                            payAmount: Number(this.addform.payAmount),
                            isDate: this.addform.isDate,
                            repaymentDate: this.addform.repaymentDate,
                            creditTipsAmount: Number(this.addform.creditTipsAmount),
                            creditTipsDay: Number(this.addform.creditTipsDay),
                            repaymentTime: this.addform.repaymentTime1+'-'+this.addform.repaymentTime2,
                            tipsDay: Number(this.addform.tipsDay),
                            carryModel: Number(this.addform.carryModel),
                            freightCalculation: Number(this.addform.freightCalculation),
                            unitName: this.addform.unitName,
                            projectName: this.addform.projectName,
                            salesman: this.addform.salesman,
                            salesTel: this.addform.salesTel,
                            remarks: this.addform.remarks,
                            receivableAmount: Number(this.addform.receivableAmount),
                            receiptsAmount: Number(this.addform.receiptsAmount),
                            type: Number(this.addform.type),
                            businessType: Number(this.addform.businessType),
                            industryType: this.addform.industryType,
                            contractSands: shop,
                            contractConcretes: shop2,
                            contractAddresses: this.addform.contractAddresses,
                            creatorId: this.addform.creatorId,
                            addressGroupDtos: this.addform.addressGroups,
                            partnersId: Number(this.addform.partnersId)
                        }).then( response => {
                            if(response.data.isSuccess == true){
                                this.query()
                                this.$message.success('修改成功')
                                this.start()
                                // this.addform = {
                                    
                                // }
                                this.dialogVisible = false
                            }else{
                                this.$message.warning(response.data.message)
                            }
                        })
                    }else if(this.formButton == 'Adjustment'){
                        this.$http.post(this.$SysContract_AddContract,{
                            id: this.addform.id,
                            enterpriseId: Number(this.addform.enterpriseId),
                            enterpriseName: this.addform.enterpriseName,
                            name: this.addform.name,
                            code: this.addform.code,
                            purchaseId: Number(this.addform.purchaseId),
                            purchaseName: this.addform.purchaseName,
                            taxNumber: this.addform.taxNumber,
                            telephone: this.addform.telephone,
                            domicile: this.addform.domicile,
                            bankName: this.addform.bankName,
                            bankNo: this.addform.bankNo,
                            contacts: this.addform.contacts,
                            contactTel: this.addform.contactTel,
                            purchaseTaxNumber: this.addform.purchaseTaxNumber,
                            purchaseTelephone: this.addform.purchaseTelephone,
                            purchaseDomicile: this.addform.purchaseDomicile,
                            purchaseBankName: this.addform.purchaseBankName,
                            purchaseBankNo: this.addform.purchaseBankNo,
                            purchaseContacts: this.addform.purchaseContacts,
                            purchaseContactTel: this.addform.purchaseContactTel,
                            purchaseBusinessName: this.addform.purchaseBusinessName,
                            purchaseBusinessUserId: this.addform.purchaseBusinessUserId,
                            purchaseBusinessTel: this.addform.purchaseBusinessTel,
                            payType: this.addform.payType,
                            payAmount: Number(this.addform.payAmount),
                            isDate: this.addform.isDate,
                            repaymentDate: this.addform.repaymentDate,
                            creditTipsAmount: Number(this.addform.creditTipsAmount),
                            creditTipsDay: Number(this.addform.creditTipsDay),
                            repaymentTime: this.addform.repaymentTime1+'-'+this.addform.repaymentTime2,
                            tipsDay: Number(this.addform.tipsDay),
                            carryModel: Number(this.addform.carryModel),
                            freightCalculation: Number(this.addform.freightCalculation),
                            unitName: this.addform.unitName,
                            projectName: this.addform.projectName,
                            salesman: this.addform.salesman,
                            salesTel: this.addform.salesTel,
                            remarks: this.addform.remarks,
                            receivableAmount: Number(this.addform.receivableAmount),
                            receiptsAmount: Number(this.addform.receiptsAmount),
                            type: Number(this.addform.type),
                            businessType: Number(this.addform.businessType),
                            industryType: this.addform.industryType,
                            contractSands: shop,
                            contractConcretes: shop2,
                            contractAddresses: this.addform.contractAddresses,
                            creatorId: this.addform.creatorId,
                            addressGroupDtos: this.addform.addressGroups,
                            partnersId: Number(this.addform.partnersId)
                        }).then( response => {
                            if(response.data.isSuccess == true){
                                this.query()
                                this.$message.success('调整成功')
                                this.dialogVisible = false
                                this.start()
                            }else{
                                this.$message.warning(response.data.message)
                            }
                        })
                    }else if(this.formButton == 'AdjustmentEdit'){
                        this.$http.post(this.$SysContract_EditContract,{
                            id: this.addform.id,
                            enterpriseId: Number(this.addform.enterpriseId),
                            enterpriseName: this.addform.enterpriseName,
                            name: this.addform.name,
                            code: this.addform.code,
                            purchaseId: Number(this.addform.purchaseId),
                            purchaseName: this.addform.purchaseName,
                            taxNumber: this.addform.taxNumber,
                            telephone: this.addform.telephone,
                            domicile: this.addform.domicile,
                            bankName: this.addform.bankName,
                            bankNo: this.addform.bankNo,
                            contacts: this.addform.contacts,
                            contactTel: this.addform.contactTel,
                            purchaseTaxNumber: this.addform.purchaseTaxNumber,
                            purchaseTelephone: this.addform.purchaseTelephone,
                            purchaseDomicile: this.addform.purchaseDomicile,
                            purchaseBankName: this.addform.purchaseBankName,
                            purchaseBankNo: this.addform.purchaseBankNo,
                            purchaseContacts: this.addform.purchaseContacts,
                            purchaseContactTel: this.addform.purchaseContactTel,
                            purchaseBusinessName: this.addform.purchaseBusinessName,
                            purchaseBusinessUserId: this.addform.purchaseBusinessUserId,
                            purchaseBusinessTel: this.addform.purchaseBusinessTel,
                            payType: this.addform.payType,
                            payAmount: Number(this.addform.payAmount),
                            isDate: this.addform.isDate,
                            repaymentDate: this.addform.repaymentDate,
                            creditTipsAmount: Number(this.addform.creditTipsAmount),
                            creditTipsDay: Number(this.addform.creditTipsDay),
                            repaymentTime: this.addform.repaymentTime1+'-'+this.addform.repaymentTime2,
                            tipsDay: Number(this.addform.tipsDay),
                            carryModel: Number(this.addform.carryModel),
                            freightCalculation: Number(this.addform.freightCalculation),
                            unitName: this.addform.unitName,
                            projectName: this.addform.projectName,
                            salesman: this.addform.salesman,
                            salesTel: this.addform.salesTel,
                            remarks: this.addform.remarks,
                            receivableAmount: Number(this.addform.receivableAmount),
                            receiptsAmount: Number(this.addform.receiptsAmount),
                            type: Number(this.addform.type),
                            businessType: Number(this.addform.businessType),
                            industryType: this.addform.industryType,
                            contractSands: shop,
                            contractConcretes: shop2,
                            contractAddresses: this.addform.contractAddresses,
                            creatorId: this.addform.creatorId,
                            addressGroupDtos: this.addform.addressGroups,
                            partnersId: Number(this.addform.partnersId)
                        }).then( response => {
                            if(response.data.isSuccess == true){
                                this.query()
                                this.$message.success('调整成功')
                                this.dialogVisible = false
                                this.start()
                            }else{
                                this.$message.warning(response.data.message)
                            }
                        })
                    }
                } else {
                    return false;
                }
            });
        },
        // 添加点击取消事件
        AddCancel(formName){
            this.start()
            this.dialogVisible = false
            this.enterpriseName = ''
            this.$refs[formName].resetFields();
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
            .then(_ => {
                done();
            })
            .catch(_ => {});
        },
        // 点击详情按钮打开表单
        handleClick(row) {
            this.end()
            this.enterpriseData = {}
            this.restaddfrom()
            this.title = '详情'
            this.formButton = 'details'
            this.loading = true
            this.$http.get(this.$SysContract_Get+'?id='+row.id).then(response => {
                this.addform.businessType = JSON.stringify(response.data.data.businessType)
                if(response.data.data.businessType == 0){
                    if(response.data.data.modeType == 0){
                        this.enterpriseName = response.data.data.purchaseName
                        this.enterpriseRadio = Number(response.data.data.enterpriseId)
                        // this.querySysEnterprise()
                        this.enterpriseData = {
                            purchaseName: response.data.data.purchaseName,
                            purchaseContacts: response.data.data.purchaseContacts,
                            purchaseContactTel: response.data.data.purchaseContactTel,
                            purchaseTaxNumber: response.data.data.purchaseTaxNumber,
                            purchaseDomicile: response.data.data.purchaseDomicile,
                            purchaseBankName: response.data.data.purchaseBankName,
                            purchaseBankNo: response.data.data.purchaseBankNo,
                        }
                    }else{
                        this.enterpriseName = response.data.data.enterpriseName
                        this.enterpriseRadio = Number(response.data.data.enterpriseId)
                        this.enterpriseData = {
                            purchaseName: response.data.data.enterpriseName,
                            purchaseContacts: response.data.data.contacts,
                            purchaseContactTel: response.data.data.contactTel,
                            purchaseTaxNumber: response.data.data.taxNumber,
                            purchaseDomicile: response.data.data.domicile,
                            purchaseBankName: response.data.data.bankName,
                            purchaseBankNo: response.data.data.bankNo,
                        }
                    }
                }else{
                    if(response.data.data.modeType == 1){
                        this.enterpriseName = response.data.data.purchaseName
                        this.enterpriseRadio = Number(response.data.data.enterpriseId)
                        // this.querySysEnterprise()
                        this.enterpriseData = {
                            enterpriseName: response.data.data.purchaseName,
                            contacts: response.data.data.purchaseContacts,
                            contactTel: response.data.data.purchaseContactTel,
                            taxNumber: response.data.data.purchaseTaxNumber,
                            domicile: response.data.data.purchaseDomicile,
                            bankName: response.data.data.purchaseBankName,
                            bankNo: response.data.data.purchaseBankNo,
                        }
                    }else{
                        this.enterpriseName = response.data.data.enterpriseName
                        this.enterpriseRadio = Number(response.data.data.enterpriseId)
                        this.enterpriseData = {
                            enterpriseName: response.data.data.enterpriseName,
                            contacts: response.data.data.contacts,
                            contactTel: response.data.data.contactTel,
                            taxNumber: response.data.data.taxNumber,
                            domicile: response.data.data.domicile,
                            bankName: response.data.data.bankName,
                            bankNo: response.data.data.bankNo,
                        }
                    }
                }
                this.addform.id =  response.data.data.id,
                this.addform.name = response.data.data.name,
                this.addform.code = response.data.data.code,
                this.addform.payType = response.data.data.payType
                if(this.addform.payType == '0'){
                    this.payType0 = true
                }else if(this.addform.payType == '1'){
                    this.payType1 = true
                }else if(this.addform.payType == '2'){
                    this.payType2 = true
                }else if(this.addform.payType == '3'){
                    this.payType3 = true
                }else if(this.addform.payType == '1,2'){
                    this.payType1 = true
                    this.payType2 = true
                }
                this.addform.payAmount = response.data.data.payAmount,
                this.addform.isDate = response.data.data.isDate,
                this.addform.repaymentDate = response.data.data.repaymentDate,
                this.addform.creditTipsAmount = response.data.data.creditTipsAmount,
                this.addform.creditTipsDay = response.data.data.creditTipsDay,
                this.addform.repaymentTime = response.data.data.repaymentTime
                var i1 = response.data.data.repaymentTime.indexOf("-");
                this.addform.repaymentTime1 = response.data.data.repaymentTime.slice(0,i1)
                this.addform.repaymentTime2 = response.data.data.repaymentTime.slice(i1+1)
                this.addform.tipsDay = response.data.data.tipsDay,
                this.addform.carryModel = Number(response.data.data.carryModel),
                this.addform.freightCalculation = Number(response.data.data.freightCalculation),
                this.addform.unitName = response.data.data.unitName,
                this.addform.projectName = response.data.data.projectName,
                this.addform.salesman = response.data.data.salesman,
                this.addform.salesTel = response.data.data.salesTel,
                this.addform.remarks = response.data.data.remarks,
                this.addform.receivableAmount = response.data.data.receivableAmount,
                this.addform.receiptsAmount = response.data.data.receiptsAmount,
                this.addform.type = Number(response.data.data.type),
                this.addform.industryType = response.data.data.industryType
                if(this.addform.industryType == '0'){
                    this.ch0 = true
                    this.ch1 = false
                    response.data.data.contractSands.forEach(item => {
                        this.addform.contractSands.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            unit: item.unit,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            planNumber: item.planNumber
                        })
                    })
                }else if(this.addform.industryType == '1'){
                    this.ch1 = true
                    this.ch0 = false
                    response.data.data.contractConcretes.forEach(item => {
                        this.addform.contractConcretes.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            impermeability: item.impermeability,
                            slump: item.slump,
                            aggregateSize: item.aggregateSize,
                            pouringMethod: item.pouringMethod,
                            constructionPosition: item.constructionPosition,
                            planNumber: item.planNumber,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            pumpingPrice: item.pumpingPrice,
                            unit: item.unit,
                        })
                    })
                }else{
                    this.ch0 = true
                    response.data.data.contractSands.forEach(item => {
                        this.addform.contractSands.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            unit: item.unit,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            planNumber: item.planNumber
                        })
                    })
                    this.ch1 = true
                    response.data.data.contractConcretes.forEach(item => {
                        this.addform.contractConcretes.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            impermeability: item.impermeability,
                            slump: item.slump,
                            aggregateSize: item.aggregateSize,
                            pouringMethod: item.pouringMethod,
                            constructionPosition: item.constructionPosition,
                            planNumber: item.planNumber,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            pumpingPrice: item.pumpingPrice,
                            unit: item.unit,
                        })
                    })
                }
                this.addform.contractAddresses = response.data.data.contractAddresses
                // 分配厂区金额
                this.addform.addressGroups = response.data.data.addressGroups
                var idarr = []
                this.addform.addressGroups.forEach(aa => {
                    if(idarr.indexOf(aa.addressId) === -1){
                        idarr.push(aa.addressId)
                        this.addressGroupsYuan.push({
                            addressId: aa.addressId,
                            addressName: aa.addressName,
                            price: Number(aa.price)
                        })
                    }
                })
                this.dialogVisible = true
            })
            this.loading = false
        },
        dblclick(row){
            this.radio = row.id
            this.getCurrentRow(row)
        },
        // 点击表格里面的单选框
        getCurrentRow(row){
            this.auditid = row.code
            this.AuditiSactive = row.isActive
            this.AuditmodeType = Number(row.modeType)
            this.Auditstate = Number(row.state)
            if(row.businessType == 0){
                this.AuditUserid = row.purchaseId
            }else if(row.businessType == 1){
                this.AuditUserid = row.enterpriseId
            }
            // alert(name)
        },
        // 修改打开表单
        modify(row){
            this.end()
            this.payType0checked = false
            this.payType1checked = false
            this.payType2checked = false
            this.payType3checked = false
            this.formButton = 'modify'
            this.title = '修改'
            this.restaddfrom()
            this.loading = true
            this.$http.get(this.$SysContract_Get+'?id='+row.id).then(response => {
                this.addform.businessType = JSON.stringify(response.data.data.businessType)
                if(response.data.data.businessType == 0){
                    this.$http.get(this.$syspartners_getsalelist)
                    .then(responseT => {
                        this.FormOptions = responseT.data.data.list
                        this.enterpriseRadio = Number(response.data.data.enterpriseId)
                        this.FormOptions.forEach(item => {
                            if(response.data.data.purchaseId == item.purchaseId & item.id == response.data.data.partnersId){
                                this.enterpriseName = item.id
                                this.enterpriseData = item
                            }
                        })
                    })
                    
                }else{ 
                    this.enterpriseRadio = Number(response.data.data.enterpriseId)
                    this.$http.get(this.$syspartners_getpurchaselist)
                    .then(responseT => {
                        this.FormOptions = responseT.data.data.list
                        this.FormOptions.forEach(item => {
                            if(response.data.data.enterpriseId == item.enterpriseId & item.id == response.data.data.partnersId){
                                this.enterpriseName = item.id
                                this.enterpriseData = item
                            }
                        })
                    })
                }
                var aa = {
                    enterpriseId: response.data.data.enterpriseId,
                    purchaseId: response.data.data.purchaseId,
                    id: response.data.data.partnersId
                }
                this.queryAddress(aa)
                this.addform.partnersId = response.data.data.partnersId
                this.addform.id =  response.data.data.id,
                this.addform.enterpriseId = Number(response.data.data.enterpriseId),
                this.addform.enterpriseName = response.data.data.enterpriseName,
                this.addform.name = response.data.data.name,
                this.addform.code = response.data.data.code,
                this.addform.purchaseId = Number(response.data.data.purchaseId),
                this.addform.purchaseName = response.data.data.purchaseName,
                this.addform.taxNumber = response.data.data.taxNumber,
                this.addform.telephone = response.data.data.telephone,
                this.addform.domicile = response.data.data.domicile,
                this.addform.bankName = response.data.data.bankName,
                this.addform.bankNo = response.data.data.bankNo,
                this.addform.contacts = response.data.data.contacts,
                this.addform.contactTel = response.data.data.contactTel,
                this.addform.purchaseTaxNumber = response.data.data.purchaseTaxNumber,
                this.addform.purchaseTelephone = response.data.data.purchaseTelephone,
                this.addform.purchaseDomicile = response.data.data.purchaseDomicile,
                this.addform.purchaseBankName = response.data.data.purchaseBankName,
                this.addform.purchaseBankNo = response.data.data.purchaseBankNo,
                this.addform.purchaseContacts = response.data.data.purchaseContacts,
                this.addform.purchaseContactTel = response.data.data.purchaseContactTel,
                this.addform.purchaseBusinessName = response.data.data.purchaseBusinessName,
                this.addform.purchaseBusinessUserId = response.data.data.purchaseBusinessUserId,
                this.addform.purchaseBusinessTel = response.data.data.purchaseBusinessTel,
                this.addform.payType = response.data.data.payType
                if(this.addform.payType == 0){
                    this.payType0 = true
                }else if(this.addform.payType == 1){
                    this.payType1 = true
                }else if(this.addform.payType == 2){
                    this.payType2 = true
                }else if(this.addform.payType == 3){
                    this.payType3 = true
                }else if(this.addform.payType == '1,2'){
                    this.payType1 = true
                    this.payType2 = true
                }
                this.addform.payAmount = response.data.data.payAmount,
                this.addform.isDate = response.data.data.isDate,
                this.addform.repaymentDate = response.data.data.repaymentDate,
                this.addform.creditTipsAmount = response.data.data.creditTipsAmount,
                this.addform.creditTipsDay = response.data.data.creditTipsDay,
                this.addform.repaymentTime = response.data.data.repaymentTime
                var i1 = response.data.data.repaymentTime.indexOf("-");
                this.addform.repaymentTime1 = response.data.data.repaymentTime.slice(0,i1)
                this.addform.repaymentTime2 = response.data.data.repaymentTime.slice(i1+1)
                this.addform.tipsDay = response.data.data.tipsDay,
                this.addform.carryModel = Number(response.data.data.carryModel),
                this.addform.freightCalculation = Number(response.data.data.freightCalculation),
                this.addform.unitName = response.data.data.unitName,
                this.addform.projectName = response.data.data.projectName,
                this.addform.salesman = response.data.data.salesman,
                this.addform.salesTel = response.data.data.salesTel,
                this.addform.remarks = response.data.data.remarks,
                this.addform.receivableAmount = response.data.data.receivableAmount,
                this.addform.receiptsAmount = response.data.data.receiptsAmount,
                this.addform.type = Number(response.data.data.type),
                this.addform.businessType = JSON.stringify(response.data.data.businessType),
                this.addform.industryType = response.data.data.industryType
                this.addform.creatorId = response.data.data.creatorId
                if(this.addform.industryType == '0'){
                    this.ch0 = true
                    this.ch1 = false
                    response.data.data.contractSands.forEach(item => {
                        this.addform.contractSands.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            unit: item.unit,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            planNumber: item.planNumber,
                            standardsCode: item.standardsCode
                        })
                    })
                }else if(this.addform.industryType == '1'){
                    this.ch1 = true
                    this.ch0 = false
                    response.data.data.contractConcretes.forEach(item => {
                        this.addform.contractConcretes.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            impermeability: item.impermeability,
                            slump: item.slump,
                            aggregateSize: item.aggregateSize,
                            pouringMethod: item.pouringMethod,
                            constructionPosition: item.constructionPosition,
                            planNumber: item.planNumber,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            pumpingPrice: item.pumpingPrice,
                            standardsCode: item.standardsCode,
                            impermeabilityCode: item.impermeabilityCode,
                            unit: item.unit,
                        })
                    })
                }else{
                    this.ch0 = true
                    response.data.data.contractSands.forEach(item => {
                        this.addform.contractSands.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            unit: item.unit,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            planNumber: item.planNumber,
                            standardsCode: item.standardsCode
                        })
                    })
                    this.ch1 = true
                    response.data.data.contractConcretes.forEach(item => {
                        this.addform.contractConcretes.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            impermeability: item.impermeability,
                            slump: item.slump,
                            aggregateSize: item.aggregateSize,
                            pouringMethod: item.pouringMethod,
                            constructionPosition: item.constructionPosition,
                            planNumber: item.planNumber,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            pumpingPrice: item.pumpingPrice,
                            standardsCode: item.standardsCode,
                            unit: item.unit,
                            impermeabilityCode: item.impermeabilityCode
                        })
                    })
                }
                this.addform.contractAddresses = response.data.data.contractAddresses
                this.addform.addressGroups = response.data.data.addressGroups
                // 分配厂区金额
                var idarr = []
                this.addform.addressGroups.forEach(aa => {
                    if(idarr.indexOf(aa.addressId) === -1){
                        idarr.push(aa.addressId)
                        this.addressGroupsYuan.push({
                            addressId: aa.addressId,
                            addressName: aa.addressName,
                            price: Number(aa.price)
                        })
                    }
                })
                this.dialogVisible = true
            })
            this.loading = false
        },
        // 调整打开表单
        Adjustment(row){
            this.end()
            this.payType0checked = false
            this.payType1checked = false
            this.payType2checked = false
            this.payType3checked = false
            this.formButton = 'Adjustment'
            this.title = '调整'
            this.restaddfrom()
            this.loading = true
            this.$http.get(this.$SysContract_Get+'?id='+row.id).then(response => {
                this.addform.businessType = JSON.stringify(response.data.data.businessType),
                this.tiaozhengduixiang = response.data.data
                if(response.data.data.businessType == 0){
                    this.enterpriseName = response.data.data.purchaseName
                    this.enterpriseRadio = Number(response.data.data.enterpriseId)
                    // this.querySysEnterprise()
                    this.enterpriseData = {
                        purchaseName: response.data.data.purchaseName,
                        purchaseContacts: response.data.data.purchaseContacts,
                        purchaseContactTel: response.data.data.purchaseContactTel,
                        purchaseTaxNumber: response.data.data.purchaseTaxNumber,
                        purchaseDomicile: response.data.data.purchaseDomicile,
                        purchaseBankName: response.data.data.purchaseBankName,
                        purchaseBankNo: response.data.data.purchaseBankNo,
                    }
                }else{ 
                    this.enterpriseName = response.data.data.enterpriseName
                    this.enterpriseRadio = Number(response.data.data.enterpriseId)
                    // this.querySysEnterprise()
                    this.enterpriseData = {
                        enterpriseName: response.data.data.enterpriseName,
                        contacts: response.data.data.contacts,
                        contactTel: response.data.data.contactTel,
                        taxNumber: response.data.data.taxNumber,
                        domicile: response.data.data.domicile,
                        bankName: response.data.data.bankName,
                        bankNo: response.data.data.bankNo,
                    }
                }
                var aa = {
                    enterpriseId: response.data.data.enterpriseId,
                    purchaseId: response.data.data.purchaseId,
                    id: response.data.data.partnersId
                }
                this.queryAddress(aa)
                this.addform.partnersId = response.data.data.partnersId
                this.addform.id =  response.data.data.id,
                this.addform.enterpriseId = Number(response.data.data.enterpriseId),
                this.addform.enterpriseName = response.data.data.enterpriseName,
                this.addform.name = response.data.data.name,
                this.addform.code = response.data.data.code,
                this.addform.purchaseId = Number(response.data.data.purchaseId),
                this.addform.purchaseName = response.data.data.purchaseName,
                this.addform.taxNumber = response.data.data.taxNumber,
                this.addform.telephone = response.data.data.telephone,
                this.addform.domicile = response.data.data.domicile,
                this.addform.bankName = response.data.data.bankName,
                this.addform.bankNo = response.data.data.bankNo,
                this.addform.contacts = response.data.data.contacts,
                this.addform.contactTel = response.data.data.contactTel,
                this.addform.purchaseTaxNumber = response.data.data.purchaseTaxNumber,
                this.addform.purchaseTelephone = response.data.data.purchaseTelephone,
                this.addform.purchaseDomicile = response.data.data.purchaseDomicile,
                this.addform.purchaseBankName = response.data.data.purchaseBankName,
                this.addform.purchaseBankNo = response.data.data.purchaseBankNo,
                this.addform.purchaseContacts = response.data.data.purchaseContacts,
                this.addform.purchaseContactTel = response.data.data.purchaseContactTel,
                this.addform.purchaseBusinessName = response.data.data.purchaseBusinessName,
                this.addform.purchaseBusinessUserId = response.data.data.purchaseBusinessUserId,
                this.addform.purchaseBusinessTel = response.data.data.purchaseBusinessTel,
                this.addform.payType = response.data.data.payType
                if(this.addform.payType == 0){
                    this.payType0 = true
                }else if(this.addform.payType == 1){
                    this.payType1 = true
                }else if(this.addform.payType == 2){
                    this.payType1checked = true
                    this.payType2 = true
                }else if(this.addform.payType == 3){
                    this.payType3 = true
                }else if(this.addform.payType == '1,2'){
                    this.payType1 = true
                    this.payType2 = true
                }
                this.addform.payAmount = response.data.data.payAmount,
                this.addform.isDate = response.data.data.isDate,
                this.addform.repaymentDate = response.data.data.repaymentDate,
                this.addform.creditTipsAmount = response.data.data.creditTipsAmount,
                this.addform.creditTipsDay = response.data.data.creditTipsDay,
                this.addform.repaymentTime = response.data.data.repaymentTime
                var i1 = response.data.data.repaymentTime.indexOf("-");
                this.addform.repaymentTime1 = response.data.data.repaymentTime.slice(0,i1)
                this.addform.repaymentTime2 = response.data.data.repaymentTime.slice(i1+1)
                this.addform.tipsDay = response.data.data.tipsDay,
                this.addform.carryModel = Number(response.data.data.carryModel),
                this.addform.freightCalculation = Number(response.data.data.freightCalculation),
                this.addform.unitName = response.data.data.unitName,
                this.addform.projectName = response.data.data.projectName,
                this.addform.salesman = response.data.data.salesman,
                this.addform.salesTel = response.data.data.salesTel,
                this.addform.remarks = response.data.data.remarks,
                this.addform.receivableAmount = response.data.data.receivableAmount,
                this.addform.receiptsAmount = response.data.data.receiptsAmount,
                this.addform.type = Number(response.data.data.type),
                this.addform.industryType = response.data.data.industryType
                this.addform.creatorId = response.data.data.creatorId
                if(this.addform.industryType == '0'){
                    this.ch0 = true
                    this.ch1 = false
                    response.data.data.contractSands.forEach(item => {
                        this.addform.contractSands.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            unit: item.unit,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            planNumber: item.planNumber,
                            standardsCode: item.standardsCode
                        })
                    })
                }else if(this.addform.industryType == '1'){
                    this.ch1 = true
                    this.ch0 = false
                    response.data.data.contractConcretes.forEach(item => {
                        this.addform.contractConcretes.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            impermeability: item.impermeability,
                            slump: item.slump,
                            aggregateSize: item.aggregateSize,
                            pouringMethod: item.pouringMethod,
                            constructionPosition: item.constructionPosition,
                            planNumber: item.planNumber,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            pumpingPrice: item.pumpingPrice,
                            unit: item.unit,
                            standardsCode: item.standardsCode,
                            impermeabilityCode: item.impermeabilityCode
                        })
                    })
                }else{
                    this.ch0 = true
                    response.data.data.contractSands.forEach(item => {
                        this.addform.contractSands.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            unit: item.unit,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            planNumber: item.planNumber,
                            standardsCode: item.standardsCode
                        })
                    })
                    this.ch1 = true
                    response.data.data.contractConcretes.forEach(item => {
                        this.addform.contractConcretes.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            impermeability: item.impermeability,
                            slump: item.slump,
                            aggregateSize: item.aggregateSize,
                            pouringMethod: item.pouringMethod,
                            constructionPosition: item.constructionPosition,
                            planNumber: item.planNumber,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            pumpingPrice: item.pumpingPrice,
                            unit: item.unit,
                            standardsCode: item.standardsCode,
                            impermeabilityCode: item.impermeabilityCode
                        })
                    })
                }
                this.addform.contractAddresses = response.data.data.contractAddresses
                this.addform.addressGroups = response.data.data.addressGroups
                // 分配厂区金额
                var idarr = []
                this.addform.addressGroups.forEach(aa => {
                    if(idarr.indexOf(aa.addressId) === -1){
                        idarr.push(aa.addressId)
                        this.addressGroupsYuan.push({
                            addressId: aa.addressId,
                            addressName: aa.addressName,
                            price: Number(aa.price),
                            isStop: aa.isStop,
                            receiptsPrice: aa.receiptsPrice,
                        })
                    }
                })
                this.dialogVisible = true
            })
            this.loading = false
        },
        // 调整编辑打开表单
        AdjustmentEdit(row){
            this.end()
            this.payType0checked = false
            this.payType1checked = false
            this.payType2checked = false
            this.payType3checked = false
            this.formButton = 'AdjustmentEdit'
            this.title = '调整'
            this.restaddfrom()
            this.loading = true
            this.$http.get(this.$SysContract_GetInfo+'?code='+row.code).then(response => {
                this.tiaozhengduixiang = response.data.data
                this.addform.businessType = JSON.stringify(response.data.data.businessType)
                if(response.data.data.businessType == 0){
                    this.enterpriseName = response.data.data.purchaseName
                    this.enterpriseRadio = Number(response.data.data.enterpriseId)
                    // this.querySysEnterprise()
                    this.enterpriseData = {
                        purchaseName: response.data.data.purchaseName,
                        purchaseContacts: response.data.data.purchaseContacts,
                        purchaseContactTel: response.data.data.purchaseContactTel,
                        purchaseTaxNumber: response.data.data.purchaseTaxNumber,
                        purchaseDomicile: response.data.data.purchaseDomicile,
                        purchaseBankName: response.data.data.purchaseBankName,
                        purchaseBankNo: response.data.data.purchaseBankNo,
                    }
                }else{ 
                    this.enterpriseName = response.data.data.enterpriseName
                    this.enterpriseRadio = Number(response.data.data.enterpriseId)
                    // this.querySysEnterprise()
                    this.enterpriseData = {
                        enterpriseName: response.data.data.enterpriseName,
                        contacts: response.data.data.contacts,
                        contactTel: response.data.data.contactTel,
                        taxNumber: response.data.data.taxNumber,
                        domicile: response.data.data.domicile,
                        bankName: response.data.data.bankName,
                        bankNo: response.data.data.bankNo,
                    }
                }
                var aa = {
                    enterpriseId: response.data.data.enterpriseId,
                    purchaseId: response.data.data.purchaseId,
                    id: response.data.data.partnersId
                }
                this.queryAddress(aa)
                this.addform.partnersId = response.data.data.partnersId
                this.addform.id =  response.data.data.id,
                this.addform.enterpriseId = Number(response.data.data.enterpriseId),
                this.addform.enterpriseName = response.data.data.enterpriseName,
                this.addform.name = response.data.data.name,
                this.addform.code = response.data.data.code,
                this.addform.purchaseId = Number(response.data.data.purchaseId),
                this.addform.purchaseName = response.data.data.purchaseName,
                this.addform.taxNumber = response.data.data.taxNumber,
                this.addform.telephone = response.data.data.telephone,
                this.addform.domicile = response.data.data.domicile,
                this.addform.bankName = response.data.data.bankName,
                this.addform.bankNo = response.data.data.bankNo,
                this.addform.contacts = response.data.data.contacts,
                this.addform.contactTel = response.data.data.contactTel,
                this.addform.purchaseTaxNumber = response.data.data.purchaseTaxNumber,
                this.addform.purchaseTelephone = response.data.data.purchaseTelephone,
                this.addform.purchaseDomicile = response.data.data.purchaseDomicile,
                this.addform.purchaseBankName = response.data.data.purchaseBankName,
                this.addform.purchaseBankNo = response.data.data.purchaseBankNo,
                this.addform.purchaseContacts = response.data.data.purchaseContacts,
                this.addform.purchaseContactTel = response.data.data.purchaseContactTel,
                this.addform.purchaseBusinessName = response.data.data.purchaseBusinessName,
                this.addform.purchaseBusinessUserId = response.data.data.purchaseBusinessUserId,
                this.addform.purchaseBusinessTel = response.data.data.purchaseBusinessTel,
                this.addform.payType = response.data.data.payType
                if(this.addform.payType == 0){
                    this.payType0 = true
                }else if(this.addform.payType == 1){
                    this.payType1 = true
                }else if(this.addform.payType == 2){
                    this.payType2 = true
                }else if(this.addform.payType == 3){
                    this.payType3 = true
                }else if(this.addform.payType == '1,2'){
                    this.payType1 = true
                    this.payType2 = true
                }
                this.addform.payAmount = response.data.data.payAmount,
                this.addform.isDate = response.data.data.isDate,
                this.addform.repaymentDate = response.data.data.repaymentDate,
                this.addform.creditTipsAmount = response.data.data.creditTipsAmount,
                this.addform.creditTipsDay = response.data.data.creditTipsDay,
                this.addform.repaymentTime = response.data.data.repaymentTime
                var i1 = response.data.data.repaymentTime.indexOf("-");
                this.addform.repaymentTime1 = response.data.data.repaymentTime.slice(0,i1)
                this.addform.repaymentTime2 = response.data.data.repaymentTime.slice(i1+1)
                this.addform.tipsDay = response.data.data.tipsDay,
                this.addform.carryModel = Number(response.data.data.carryModel),
                this.addform.freightCalculation = Number(response.data.data.freightCalculation),
                this.addform.unitName = response.data.data.unitName,
                this.addform.projectName = response.data.data.projectName,
                this.addform.salesman = response.data.data.salesman,
                this.addform.salesTel = response.data.data.salesTel,
                this.addform.remarks = response.data.data.remarks,
                this.addform.receivableAmount = response.data.data.receivableAmount,
                this.addform.receiptsAmount = response.data.data.receiptsAmount,
                this.addform.type = Number(response.data.data.type),
                this.addform.industryType = response.data.data.industryType
                this.addform.creatorId = response.data.data.creatorId
                if(this.addform.industryType == '0'){
                    this.ch0 = true
                    this.ch1 = false
                    response.data.data.contractSands.forEach(item => {
                        this.addform.contractSands.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            unit: item.unit,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            planNumber: item.planNumber,
                            standardsCode: item.standardsCode
                        })
                    })
                }else if(this.addform.industryType == '1'){
                    this.ch1 = true
                    this.ch0 = false
                    response.data.data.contractConcretes.forEach(item => {
                        this.addform.contractConcretes.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            impermeability: item.impermeability,
                            slump: item.slump,
                            aggregateSize: item.aggregateSize,
                            pouringMethod: item.pouringMethod,
                            constructionPosition: item.constructionPosition,
                            planNumber: item.planNumber,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            pumpingPrice: item.pumpingPrice,
                            unit: item.unit,
                            standardsCode: item.standardsCode,
                            impermeabilityCode: item.impermeabilityCode
                        })
                    })
                }else{
                    this.ch0 = true
                    response.data.data.contractSands.forEach(item => {
                        this.addform.contractSands.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            unit: item.unit,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            planNumber: item.planNumber,
                            standardsCode: item.standardsCode
                        })
                    })
                    this.ch1 = true
                    response.data.data.contractConcretes.forEach(item => {
                        this.addform.contractConcretes.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            impermeability: item.impermeability,
                            slump: item.slump,
                            aggregateSize: item.aggregateSize,
                            pouringMethod: item.pouringMethod,
                            constructionPosition: item.constructionPosition,
                            planNumber: item.planNumber,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            pumpingPrice: item.pumpingPrice,
                            unit: item.unit,
                            standardsCode: item.standardsCode,
                            impermeabilityCode: item.impermeabilityCode
                        })
                    })
                }
                this.addform.contractAddresses = response.data.data.contractAddresses
                this.addform.addressGroups = response.data.data.addressGroups
                // 分配厂区金额
                var idarr = []
                this.addform.addressGroups.forEach(aa => {
                    if(idarr.indexOf(aa.addressId) === -1){
                        idarr.push(aa.addressId)
                        this.addressGroupsYuan.push({
                            addressId: aa.addressId,
                            addressName: aa.addressName,
                            price: Number(aa.price),
                            isStop: aa.isStop,
                            receiptsPrice: aa.receiptsPrice,
                        })
                    }
                })
                this.dialogVisible = true
            })
            this.loading = false
        },
        // 添加调整矿石商品需要的判断
        function1(row) {
            if(this.formButton == 'Adjustment' || this.formButton == 'AdjustmentEdit'){
                this.tiaozhengduixiang.contractSands.forEach(item => {
                    if(item.productId == row.id){
                        if(row.planNumber < item.planNumber){
                            row.planNumber = item.planNumber
                            this.$message.warning('调整计划数量 不得少于上次添加数量')
                        }
                    }
                })
            }
        },
        function2(row) {
            if(this.formButton == 'Adjustment' || this.formButton == 'AdjustmentEdit'){
                this.tiaozhengduixiang.contractSands.forEach(item => {
                    if(item.productId == row.id){
                        if(row.discountPrice < item.price){
                            row.discountPrice = item.price
                            this.$message.warning('调整结算单价 不得少于上次添加结算单价')
                        }
                    }
                })
            }
        },
        function3(row) {
            if(this.formButton == 'Adjustment' || this.formButton == 'AdjustmentEdit'){
                this.tiaozhengduixiang.contractSands.forEach(item => {
                    if(item.productId == row.id){
                        if(row.freightPrice < item.freightPrice){
                            row.freightPrice = item.freightPrice
                            this.$message.warning('调整运费单价 不得少于上次添加运费单价')
                        }
                    }
                })
            }
        },
        // 添加调整判断混凝土商品
        functiona1(row) {
            if(this.formButton == 'Adjustment' || this.formButton == 'AdjustmentEdit'){
                this.tiaozhengduixiang.contractConcretes.forEach(item => {
                    if(item.productId == row.id){
                        if(row.planNumber < item.planNumber){
                            row.planNumber = item.planNumber
                            this.$message.warning('调整计划数量 不得少于上次添加数量')
                        }
                    }
                })
            }
        },
        functiona2(row) {
            if(this.formButton == 'Adjustment' || this.formButton == 'AdjustmentEdit'){
                this.tiaozhengduixiang.contractConcretes.forEach(item => {
                    if(item.productId == row.id){
                        if(row.discountPrice < item.price){
                            row.discountPrice = item.price
                            this.$message.warning('调整结算单价 不得少于上次添加结算单价')
                        }
                    }
                })
            }
        },
        functiona3(row) {
            if(this.formButton == 'Adjustment' || this.formButton == 'AdjustmentEdit'){
                this.tiaozhengduixiang.contractConcretes.forEach(item => {
                    if(item.productId == row.id){
                        if(row.freightPrice < item.freightPrice){
                            row.freightPrice = item.freightPrice
                            this.$message.warning('调整运费单价 不得少于上次添加运费单价')
                        }
                    }
                })
            }
        },
        functiona4(row) {
            if(this.formButton == 'Adjustment' || this.formButton == 'AdjustmentEdit'){
                this.tiaozhengduixiang.contractConcretes.forEach(item => {
                    if(item.productId == row.id){
                        if(row.pumpingPrice < item.pumpingPrice){
                            this.$message.warning('调整泵送单价 不得少于上次添泵送单价')
                        }
                    }
                })
            }
        },
        //  添加调整判断支付金额
        functionp1() {
            if(this.addressGroupsYuan.length == 1 & this.payType0 == true || this.payType1 == true){
                this.addressGroupsYuan[0].price = this.addform.payAmount
            }
            if(this.formButton == 'Adjustment' || this.formButton == 'AdjustmentEdit'){
                if(this.addform.payAmount < this.tiaozhengduixiang.payAmount){
                    this.addform.payAmount = this.tiaozhengduixiang.payAmount
                    this.$message.warning('调整金额 不得少于上次添加金额')
                }
            }
        },
        // 分配金额
        functiong1(item){
            if(this.formButton == 'Adjustment' || this.formButton == 'AdjustmentEdit'){
                this.tiaozhengduixiang.addressGroups.forEach(aa => {
                    if(this.tiaozhengduixiang.addressGroups.length == 1){
                        if(aa.addressId == item.addressId){
                            if(aa.price > item.price){
                                item.price = aa.price
                                return this.$message.warning('调整厂区金额 不得少于上次分配厂区金额')
                            }
                        }
                    }else{
                        if(aa.addressId == item.addressId){
                            if(aa.isStop == false){
                                if(aa.price > item.price){
                                    item.price = aa.price
                                    return this.$message.warning('调整厂区金额 不得少于上次分配厂区金额')
                                }
                            }else{
                                if((aa.price - item.price) > aa.receiptsPrice){
                                    item.price = aa.price
                                    return this.$message.warning('当前剩余调整金额为'+aa.receiptsPrice+'元')
                                }
                                if(aa.price < item.price){
                                    item.price = aa.price
                                    return this.$message.warning('调整厂区金额 不得大于上次分配厂区金额')
                                }
                            }
                        }
                    }
                })
            }
        },
        // 审核
        Audit(){
            if(this.auditid == ''){
                return this.$message.info('请选择数据')
            }
            this.$confirm('此操作将审核该合同, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysContract_AuditContract,{
                    contractNo : this.auditid,
                    aduitRemarks : ''
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('审核成功')
                        this.query()
                        this.radio = -1
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        // 取消审核
        CancelAudit(){
            if(this.auditid == ''){
                return this.$message.info('请选择数据')
            }
            this.$confirm('此操作将取消审核该合同, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysContract_CancelAuditContract,{
                    contractNo : this.auditid,
                    aduitRemarks : ''
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('取消审核成功')
                        this.query()
                        this.radio = -1
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        // 确认
        Sure(){
            if(this.auditid == ''){
                return this.$message.info('请选择数据')
            }
            this.$confirm('此操作将确认该合同, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysContract_ConfirmContract,{
                    contractNo : this.auditid,
                    aduitRemarks : ''
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('确认成功')
                        this.query()
                        this.radio = -1
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        // 取消确认
        CancelSure(){
            if(this.auditid == ''){
                return this.$message.info('请选择数据')
            }
            this.$confirm('此操作将取消确认该合同, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysContract_CancelContract,{
                    contractNo : this.auditid,
                    aduitRemarks : ''
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('取消确认成功')
                        this.query()
                        this.radio = -1
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        // 删除
        Delete(row){
            this.$confirm('此操作将删除该合同, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var ids = []
                ids.push(row.id)
                this.$http.post(this.$SysContract_DeleteSysContractById,ids).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('删除成功')
                        this.query()
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        // 添加生产发货地址打开表单
        addCarryDialog(row){
            if(this.enterpriseRadio == -1){
                this.$message.info('请先选择企业！')
                return
            }
            this.dynamicTags = []
            this.SdynamicTags = []
            this.AddressData = this.addform.contractAddresses
            this.CarryDialog = true
            this.queryShop()
            this.queryShop2()
        },
        // 选择生产地址
        selectAddress(){
            this.PaddressList.forEach(item => {
                if(this.CarryForm.addressId == item.id){
                    this.CarryForm.address = item.address
                    this.CarryForm.latitude = item.latitude
                    this.CarryForm.longitude = item.longitude
                    this.CarryForm.addressName = item.name
                    jingdu = item.latitude
                    weidu = item.longitude
                }
                // 
            })
            // 计算两个地址距离
            if(this.SaddressList.length > 0 & this.PaddressList.length > 0){
                var radLat1 = this.CarryForm.receivingLatitude*Math.PI / 180.0;
                var radLat2 = this.CarryForm.latitude*Math.PI / 180.0;
                var a = radLat1 - radLat2;
                var  b = this.CarryForm.receivingLongitude*Math.PI / 180.0 - this.CarryForm.longitude*Math.PI / 180.0;
                var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a/2),2) +
                    Math.cos(radLat1)*Math.cos(radLat2)*Math.pow(Math.sin(b/2),2)));
                s = s *6378.137 ;// EARTH_RADIUS;
                s = Math.round(s * 10000) / 10000;
                distance = s
                this.CarryForm.calculatedMileage = distance
                lat = this.CarryForm.receivingLatitude
                lng = this.CarryForm.receivingLongitude
            }
        },
        SselectAddress(){
            this.SaddressList.forEach(item => {
                if(this.CarryForm.receivingAddressId == item.id){
                    this.CarryForm.receivingAddress = item.address
                    this.CarryForm.receivingLatitude = item.latitude
                    this.CarryForm.receivingLongitude = item.longitude
                }
            })
            // 计算两个地址距离
            if(this.SaddressList.length > 0 & this.PaddressList.length > 0){
                var radLat1 = this.CarryForm.receivingLatitude*Math.PI / 180.0;
                var radLat2 = this.CarryForm.latitude*Math.PI / 180.0;
                var a = radLat1 - radLat2;
                var  b = this.CarryForm.receivingLongitude*Math.PI / 180.0 - this.CarryForm.longitude*Math.PI / 180.0;
                var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a/2),2) +
                    Math.cos(radLat1)*Math.cos(radLat2)*Math.pow(Math.sin(b/2),2)));
                s = s *6378.137 ;// EARTH_RADIUS;
                s = Math.round(s * 10000) / 10000;
                distance = s
                this.CarryForm.calculatedMileage = distance
                lat = this.CarryForm.receivingLatitude
                lng = this.CarryForm.receivingLongitude
            }
        },
        // 选择卸料点
        selectWarehouseNumber(){

        },
        selectReceivingWarehouseNumber(){

        },
        // 添加地址点击确定
        SureAddress(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if(lat == '' & lng == ''){
                        return this.$message.warning('请定位地址')
                    }
                    this.getCheckedNodesKSdata = this.$refs['KStree'].getCheckedNodes()
                    this.getCheckedNodesHNTdata = this.$refs['HNTtree'].getCheckedNodes()
                    if(this.getCheckedNodesKSdata.length + this.getCheckedNodesHNTdata.length == 0){
                        this.$message.info('请至少选择一种商品!')
                        return
                    }
                    this.CarryForm.receivingLatitude = lat
                    this.CarryForm.receivingLongitude = lng
                    this.getCheckedNodesKSdata.forEach(Ksitem => {
                        if(this.addform.contractSands.length !== 0){
                            let aa = this.addform.contractSands.find(a => Number(a.id) === Number(Ksitem.id))
                            if(aa == undefined){
                                this.addform.contractSands.push(Ksitem)
                                this.addform.contractAddresses.push({
                                    address: this.CarryForm.address,
                                    addressId: Number(this.CarryForm.addressId),
                                    addressName: this.CarryForm.addressName,
                                    arrivalDate: this.CarryForm.arrivalDate,
                                    calculatedMileage:Number(this.CarryForm.calculatedMileage),
                                    confirmMileage:Number(this.CarryForm.confirmMileage),
                                    latitude: this.CarryForm.latitude,
                                    longitude: this.CarryForm.longitude,
                                    productId: Number(Ksitem.id),
                                    productName: Ksitem.name,
                                    receivingAddress: this.CarryForm.receivingAddress,
                                    receivingLatitude: JSON.stringify(this.CarryForm.receivingLatitude),
                                    receivingLongitude: JSON.stringify(this.CarryForm.receivingLongitude),
                                    receivingWarehouseNumber: '0',
                                    warehouseNumber: '0',
                                    receivingAddressId: Number(this.CarryForm.receivingAddressId),
                                })
                            }else{
                                let bb 
                                // if (this.addform.businessType == 0) {
                                    bb = this.addform.contractAddresses.find(b => Number(b.productId) === Number(Ksitem.id) & Number(b.addressId) === Number(this.CarryForm.addressId) & Number(b.receivingAddressId) === Number(this.CarryForm.receivingAddressId))
                                // }else{
                                //     bb = this.addform.contractAddresses.find(b => Number(b.productId) === Number(Ksitem.id) & Number(b.receivingAddressId) === Number(this.CarryForm.receivingAddressId))
                                // }
                                if(bb == undefined){
                                    this.addform.contractAddresses.push({
                                        address: this.CarryForm.address,
                                        addressId: Number(this.CarryForm.addressId),
                                        addressName: this.CarryForm.addressName,
                                        arrivalDate: this.CarryForm.arrivalDate,
                                        calculatedMileage:Number(this.CarryForm.calculatedMileage),
                                        confirmMileage:Number(this.CarryForm.confirmMileage),
                                        latitude: this.CarryForm.latitude,
                                        longitude: this.CarryForm.longitude,
                                        productId: Number(Ksitem.id),
                                        productName: Ksitem.name,
                                        receivingAddress: this.CarryForm.receivingAddress,
                                        receivingLatitude: JSON.stringify(this.CarryForm.receivingLatitude),
                                        receivingLongitude: JSON.stringify(this.CarryForm.receivingLongitude),
                                        receivingWarehouseNumber: '0',
                                        warehouseNumber: '0',
                                        receivingAddressId: Number(this.CarryForm.receivingAddressId),
                                    })
                                }
                            }
                        }else{
                            this.addform.contractSands.push(Ksitem)
                            this.addform.contractAddresses.push({
                                address: this.CarryForm.address,
                                addressId: Number(this.CarryForm.addressId),
                                addressName: this.CarryForm.addressName,
                                arrivalDate: this.CarryForm.arrivalDate,
                                calculatedMileage:Number(this.CarryForm.calculatedMileage),
                                confirmMileage:Number(this.CarryForm.confirmMileage),
                                latitude: this.CarryForm.latitude,
                                longitude: this.CarryForm.longitude,
                                productId: Number(Ksitem.id),
                                productName: Ksitem.name,
                                receivingAddress: this.CarryForm.receivingAddress,
                                receivingLatitude: JSON.stringify(this.CarryForm.receivingLatitude),
                                receivingLongitude: JSON.stringify(this.CarryForm.receivingLongitude),
                                receivingWarehouseNumber: '0',
                                warehouseNumber: '0',
                                receivingAddressId: Number(this.CarryForm.receivingAddressId),
                            })
                        }
                    })
                    this.getCheckedNodesHNTdata.forEach(Hntitem => {
                        if(this.addform.contractConcretes.length !== 0){
                            let aa = this.addform.contractConcretes.find(a => Number(a.id) === Number(Hntitem.id))
                            if(aa == undefined){
                                this.addform.contractConcretes.push(Hntitem)
                                this.addform.contractAddresses.push({
                                    address: this.CarryForm.address,
                                    addressId: Number(this.CarryForm.addressId),
                                    addressName: this.CarryForm.addressName,
                                    arrivalDate: this.CarryForm.arrivalDate,
                                    calculatedMileage:Number(this.CarryForm.calculatedMileage),
                                    confirmMileage:Number(this.CarryForm.confirmMileage),
                                    latitude: this.CarryForm.latitude,
                                    longitude: this.CarryForm.longitude,
                                    productId: Number(Hntitem.id),
                                    productName: Hntitem.name,
                                    receivingAddress: this.CarryForm.receivingAddress,
                                    receivingLatitude: JSON.stringify(this.CarryForm.receivingLatitude),
                                    receivingLongitude: JSON.stringify(this.CarryForm.receivingLongitude),
                                    receivingWarehouseNumber: '0',
                                    warehouseNumber: '0',
                                    receivingAddressId: Number(this.CarryForm.receivingAddressId),
                                })
                            }else{
                                let bb 
                                if (this.addform.businessType == 0) {
                                    bb = this.addform.contractAddresses.find(b => Number(b.productId) === Number(Hntitem.id) & Number(b.addressId) === Number(this.CarryForm.addressId))
                                }else{
                                    bb = this.addform.contractAddresses.find(b => Number(b.productId) === Number(Hntitem.id) & Number(b.receivingAddressId) === Number(this.CarryForm.receivingAddressId))
                                }
                                if(bb == undefined){
                                    this.addform.contractAddresses.push({
                                        address: this.CarryForm.address,
                                        addressId: Number(this.CarryForm.addressId),
                                        addressName: this.CarryForm.addressName,
                                        arrivalDate: this.CarryForm.arrivalDate,
                                        calculatedMileage:Number(this.CarryForm.calculatedMileage),
                                        confirmMileage:Number(this.CarryForm.confirmMileage),
                                        latitude: this.CarryForm.latitude,
                                        longitude: this.CarryForm.longitude,
                                        productId: Number(Hntitem.id),
                                        productName: Hntitem.name,
                                        receivingAddress: this.CarryForm.receivingAddress,
                                        receivingLatitude: JSON.stringify(this.CarryForm.receivingLatitude),
                                        receivingLongitude: JSON.stringify(this.CarryForm.receivingLongitude),
                                        receivingWarehouseNumber: '0',
                                        warehouseNumber: '0',
                                        receivingAddressId: Number(this.CarryForm.receivingAddressId),
                                    })
                                }    
                            }
                        }else{
                            this.addform.contractConcretes.push(Hntitem)
                            this.addform.contractAddresses.push({
                                address: this.CarryForm.address,
                                addressId: Number(this.CarryForm.addressId),
                                addressName: this.CarryForm.addressName,
                                arrivalDate: this.CarryForm.arrivalDate,
                                calculatedMileage:Number(this.CarryForm.calculatedMileage),
                                confirmMileage:Number(this.CarryForm.confirmMileage),
                                latitude: this.CarryForm.latitude,
                                longitude: this.CarryForm.longitude,
                                productId: Number(Hntitem.id),
                                productName: Hntitem.name,
                                receivingAddress: this.CarryForm.receivingAddress,
                                receivingLatitude: JSON.stringify(this.CarryForm.receivingLatitude),
                                receivingLongitude: JSON.stringify(this.CarryForm.receivingLongitude),
                                receivingWarehouseNumber: '0',
                                warehouseNumber: '0',
                                receivingAddressId: Number(this.CarryForm.receivingAddressId),
                            })
                        }
                    })
                    // 分配厂区金额
                    var idarr = []
                    var productIdarr = []
                    this.addressGroupsYuan = []
                    this.addform.contractAddresses.forEach(aa => {
                        if(idarr.indexOf(aa.addressId) == -1){
                            idarr.push(aa.addressId)
                            this.addressGroupsYuan.push({
                                addressId: aa.addressId,
                                addressName: aa.address,
                                price: 0,
                                isStop: aa.isStop,
                                receiptsPrice: aa.receiptsPrice,
                            })
                        }
                    })
                    this.AddressData = this.addform.contractAddresses
                    // if(this.ch0 == true & this.getCheckedNodesKSdata.length == 0){
                    //     this.$message.info('请选择矿石商品')
                    //     return
                    // }
                    // if(this.ch1 == true & this.getCheckedNodesHNTdata.length == 0){
                    //     this.$message.info('请选择混凝土商品')
                    //     return
                    // }
                    this.CarryDialog = false
                    this.$refs['KStree'].setCheckedKeys([])
                    this.$refs['HNTtree'].setCheckedKeys([])
                    lat = ''
                    lng = ''
                    jingdu = ''
                    weidu = ''
                    this.CarryForm = {
                        productId: Number(this.CarryForm.productId),
                        productName: this.CarryForm.productName,
                        addressId: '',
                        address: this.CarryForm.address,
                        longitude: '',
                        latitude: '',
                        warehouseNumber: '0',
                        receivingAddress: this.CarryForm.receivingAddress,
                        receivingLongitude: '',
                        receivingLatitude: '',
                        receivingWarehouseNumber: '0',
                        calculatedMileage: '',
                        confirmMileage: '',
                        arrivalDate: '',
                        receivingAddressId: '',
                    }
                } else {
                    return false;
                }
            });
        },
        cancelCarryDialog(formName) {
            var aa = this.CarryForm.address
            var bb = this.CarryForm.receivingAddress
            this.CarryDialog = false
            this.$refs[formName].resetFields();
            this.CarryForm.address = aa
            this.CarryForm.receivingAddress = bb
            lat = ''
            lng = ''
            jingdu = ''
            weidu = ''
            this.$refs['KStree'].setCheckedKeys([])
            this.$refs['HNTtree'].setCheckedKeys([])
        },
        // 重置表单
        restaddfrom(){
            this.addform = {
                id: '',
                enterpriseId: 0,
                enterpriseName: "",
                name: "",
                code: "",
                purchaseId: 0,
                purchaseName: "",
                taxNumber: "",
                telephone: "",
                domicile: "",
                bankName: "",
                bankNo: "",
                contacts: "",
                contactTel: "",
                purchaseTaxNumber: '',
                purchaseTelephone: '',
                purchaseDomicile: '',
                purchaseBankName: '',
                purchaseBankNo: '',
                purchaseContacts: '',
                purchaseContactTel: '',
                purchaseBusinessName: '',
                purchaseBusinessUserId: '',
                purchaseBusinessTel: '',
                payType: '',
                payAmount: '',
                isDate: false,
                repaymentDate: "",
                creditTipsAmount: '',
                creditTipsDay: '',
                repaymentTime: "",
                repaymentTime1: '',
                repaymentTime2: '',
                tipsDay: '',
                carryModel: 0,
                freightCalculation: 0,
                unitName: "",
                projectName: "",
                salesman: "",
                salesTel: "",
                remarks: "",
                receivableAmount: 0,
                receiptsAmount: '',
                type: 0,
                businessType: '0',
                industryType: 0,
                contractSands: [],
                contractConcretes: [],
                contractAddresses: [],
                addressGroups: []
            }
            this.CarryForm = {
                productId: '',
                productName: '',
                addressId: '',
                address: '',
                longitude: '',
                latitude: '',
                warehouseNumber: '0',
                receivingAddress: '',
                receivingLongitude: '',
                receivingLatitude: '',
                receivingWarehouseNumber: '0',
                calculatedMileage: '',
                confirmMileage: '',
                arrivalDate: '',
                receivingAddressId: '',
            }
            this.ch0 = false
            this.ch1 = false
            this.enterpriseRadio = -1
            this.payType0 = false,
            this.payType1 = false,
            this.payType2 = false,
            this.payType3 = false,
            this.addressGroupsYuan = []
        },
        // 查看地址信息详情
        detailsAddress(row){
            this.AddressData = []
            this.addform.contractAddresses.forEach(item => {
                if(row.id == item.productId){
                    this.AddressData.push(item)
                }
            })
            this.CarryDetailsDialog = true
        },
        // 点击实收款项打开对话框
        receipts(row){
            this.end()
            this.receiptsAmount = ''
            this.receiptsAmountDialog = true
            this.receiptsAmount0 = row.receiptsAmount
            this.receiptsno = row.code
        },
        // 点击确认 实收款项 保存
        Surereceipts(){
            if(this.receiptsAmount == ''){
                return this.$message.warning('请输入实收金额')
            }
            if(Number(this.receiptsAmount) <= Number(this.receiptsAmount0)){
                return this.$message.warning('实收金额不得少于上次的实收金额')
            }
            this.$http.post(this.$SysContract_SetReceiptsAmountContract,{
                contractNo : this.receiptsno,
                receiptsAmount : Number(this.receiptsAmount)
            }).then(response => {
                if(response.data.isSuccess == true){
                    this.receiptsAmountDialog = false
                    this.$message.success('更改成功')
                    this.start()
                    this.query()
                }else{
                    this.$message.warning(response.data.message)
                }
            })
        },
        // 调整确认打开表单
        AdjustmentSure(){
            if(this.auditid == ''){
                return this.$message.info('请选择数据')
            }
            this.end()
            this.payType0checked = false
            this.payType1checked = false
            this.payType2checked = false
            this.payType3checked = false
            this.formButton = 'AdjustmentSure'
            this.enterpriseData = {}
            this.title = '调整确认'
            this.restaddfrom()
            this.loading = true
            this.$http.get(this.$SysContract_GetInfo+'?code='+this.auditid).then(response => {
                this.loading = false
                this.tiaozhengduixiang = response.data.data
                if(response.data.data.businessType == 0){
                    if(response.data.data.modeType == 0){
                        this.enterpriseName = response.data.data.purchaseName
                        this.enterpriseRadio = Number(response.data.data.enterpriseId)
                        // this.querySysEnterprise()
                        this.enterpriseData = {
                            purchaseName: response.data.data.purchaseName,
                            purchaseContacts: response.data.data.purchaseContacts,
                            purchaseContactTel: response.data.data.purchaseContactTel,
                            purchaseTaxNumber: response.data.data.purchaseTaxNumber,
                            purchaseDomicile: response.data.data.purchaseDomicile,
                            purchaseBankName: response.data.data.purchaseBankName,
                            purchaseBankNo: response.data.data.purchaseBankNo,
                        }
                    }else{
                        this.enterpriseName = response.data.data.enterpriseName
                        this.enterpriseRadio = Number(response.data.data.enterpriseId)
                        this.enterpriseData = {
                            purchaseName: response.data.data.enterpriseName,
                            purchaseContacts: response.data.data.contacts,
                            purchaseContactTel: response.data.data.contactTel,
                            purchaseTaxNumber: response.data.data.taxNumber,
                            purchaseDomicile: response.data.data.domicile,
                            purchaseBankName: response.data.data.bankName,
                            purchaseBankNo: response.data.data.bankNo,
                        }
                    }
                }else{
                    if(response.data.data.modeType == 1){
                        this.enterpriseName = response.data.data.purchaseName
                        this.enterpriseRadio = Number(response.data.data.enterpriseId)
                        // this.querySysEnterprise()
                        this.enterpriseData = {
                            enterpriseName: response.data.data.purchaseName,
                            contacts: response.data.data.purchaseContacts,
                            contactTel: response.data.data.purchaseContactTel,
                            taxNumber: response.data.data.purchaseTaxNumber,
                            domicile: response.data.data.purchaseDomicile,
                            bankName: response.data.data.purchaseBankName,
                            bankNo: response.data.data.purchaseBankNo,
                        }
                    }else{
                        this.enterpriseName = response.data.data.enterpriseName
                        this.enterpriseRadio = Number(response.data.data.enterpriseId)
                        this.enterpriseData = {
                            enterpriseName: response.data.data.enterpriseName,
                            contacts: response.data.data.contacts,
                            contactTel: response.data.data.contactTel,
                            taxNumber: response.data.data.taxNumber,
                            domicile: response.data.data.domicile,
                            bankName: response.data.data.bankName,
                            bankNo: response.data.data.bankNo,
                        }
                    }
                }
                this.addform.id =  response.data.data.id,
                this.addform.enterpriseId = Number(response.data.data.enterpriseId),
                this.addform.enterpriseName = response.data.data.enterpriseName,
                this.addform.name = response.data.data.name,
                this.addform.code = response.data.data.code,
                this.addform.purchaseId = Number(response.data.data.purchaseId),
                this.addform.purchaseName = response.data.data.purchaseName,
                this.addform.taxNumber = response.data.data.taxNumber,
                this.addform.telephone = response.data.data.telephone,
                this.addform.domicile = response.data.data.domicile,
                this.addform.bankName = response.data.data.bankName,
                this.addform.bankNo = response.data.data.bankNo,
                this.addform.contacts = response.data.data.contacts,
                this.addform.contactTel = response.data.data.contactTel,
                this.addform.purchaseTaxNumber = response.data.data.purchaseTaxNumber,
                this.addform.purchaseTelephone = response.data.data.purchaseTelephone,
                this.addform.purchaseDomicile = response.data.data.purchaseDomicile,
                this.addform.purchaseBankName = response.data.data.purchaseBankName,
                this.addform.purchaseBankNo = response.data.data.purchaseBankNo,
                this.addform.purchaseContacts = response.data.data.purchaseContacts,
                this.addform.purchaseContactTel = response.data.data.purchaseContactTel,
                this.addform.purchaseBusinessName = response.data.data.purchaseBusinessName,
                this.addform.purchaseBusinessUserId = response.data.data.purchaseBusinessUserId,
                this.addform.purchaseBusinessTel = response.data.data.purchaseBusinessTel,
                this.addform.payType = response.data.data.payType
                if(this.addform.payType == 0){
                    this.payType0 = true
                }else if(this.addform.payType == 1){
                    this.payType1 = true
                }else if(this.addform.payType == 2){
                    this.payType2 = true
                }else if(this.addform.payType == 3){
                    this.payType3 = true
                }else if(this.addform.payType == '1,2'){
                    this.payType1 = true
                    this.payType2 = true
                }
                this.addform.payAmount = response.data.data.payAmount,
                this.addform.isDate = response.data.data.isDate,
                this.addform.repaymentDate = response.data.data.repaymentDate,
                this.addform.creditTipsAmount = response.data.data.creditTipsAmount,
                this.addform.creditTipsDay = response.data.data.creditTipsDay,
                this.addform.repaymentTime = response.data.data.repaymentTime
                var i1 = response.data.data.repaymentTime.indexOf("-");
                this.addform.repaymentTime1 = response.data.data.repaymentTime.slice(0,i1)
                this.addform.repaymentTime2 = response.data.data.repaymentTime.slice(i1+1)
                this.addform.tipsDay = response.data.data.tipsDay,
                this.addform.carryModel = Number(response.data.data.carryModel),
                this.addform.freightCalculation = Number(response.data.data.freightCalculation),
                this.addform.unitName = response.data.data.unitName,
                this.addform.projectName = response.data.data.projectName,
                this.addform.salesman = response.data.data.salesman,
                this.addform.salesTel = response.data.data.salesTel,
                this.addform.remarks = response.data.data.remarks,
                this.addform.receivableAmount = response.data.data.receivableAmount,
                this.addform.receiptsAmount = response.data.data.receiptsAmount,
                this.addform.type = Number(response.data.data.type),
                this.addform.businessType = JSON.stringify(response.data.data.businessType),
                this.addform.industryType = response.data.data.industryType
                this.addform.creatorId = response.data.data.creatorId
                if(this.addform.industryType == '0'){
                    this.ch0 = true
                    this.ch1 = false
                    response.data.data.contractSands.forEach(item => {
                        this.addform.contractSands.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            unit: item.unit,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            planNumber: item.planNumber,
                            standardsCode: item.standardsCode
                        })
                    })
                }else if(this.addform.industryType == '1'){
                    this.ch1 = true
                    this.ch0 = false
                    response.data.data.contractConcretes.forEach(item => {
                        this.addform.contractConcretes.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            impermeability: item.impermeability,
                            slump: item.slump,
                            aggregateSize: item.aggregateSize,
                            pouringMethod: item.pouringMethod,
                            constructionPosition: item.constructionPosition,
                            planNumber: item.planNumber,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            pumpingPrice: item.pumpingPrice,
                            unit: item.unit,
                            standardsCode: item.standardsCode,
                            impermeabilityCode: item.impermeabilityCode
                        })
                    })
                }else{
                    this.ch0 = true
                    response.data.data.contractSands.forEach(item => {
                        this.addform.contractSands.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            unit: item.unit,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            planNumber: item.planNumber,
                            standardsCode: item.standardsCode
                        })
                    })
                    this.ch1 = true
                    response.data.data.contractConcretes.forEach(item => {
                        this.addform.contractConcretes.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            impermeability: item.impermeability,
                            slump: item.slump,
                            aggregateSize: item.aggregateSize,
                            pouringMethod: item.pouringMethod,
                            constructionPosition: item.constructionPosition,
                            planNumber: item.planNumber,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            pumpingPrice: item.pumpingPrice,
                            standardsCode: item.standardsCode,
                            impermeabilityCode: item.impermeabilityCode
                        })
                    })
                }
                this.addform.contractAddresses = response.data.data.contractAddresses
                this.addform.addressGroups = response.data.data.addressGroups
                // 分配厂区金额
                var idarr = []
                this.addform.addressGroups.forEach(aa => {
                    if(idarr.indexOf(aa.addressId) === -1){
                        idarr.push(aa.addressId)
                        this.addressGroupsYuan.push({
                            addressId: aa.addressId,
                            addressName: aa.addressName,
                            price: Number(aa.price),
                            isStop: aa.isStop,
                            receiptsPrice: aa.receiptsPrice,
                        })
                    }
                })
                this.dialogVisible = true
            })
        },
        // 调整确认
        AdjustConfirm(){
            this.$confirm('此操作将确认该合同, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysContract_AdjustConfirmContract,{
                    contractNo : this.auditid,
                    aduitRemarks : ''
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('确认成功')
                        this.query()
                        this.dialogVisible = false
                        this.radio = -1
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        CancelAdjustmentSure(){
            if(this.auditid == ''){
                return this.$message.info('请选择数据')
            }
            this.$confirm('此操作将取消确认该合同, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysContract_AdjustCancelConfirmContract,{
                    contractNo : this.auditid,
                    aduitRemarks : ''
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('取消确认成功')
                        this.query()
                        this.radio = -1
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        AddressDelete(row,index){
            this.AddressData.splice(index,1)
            for(var i = 0; i<this.addform.contractAddresses.length; i++){
                if(
                    row.address == this.addform.contractAddresses[i].address &
                    // row.warehouseNumber == this.addform.contractAddresses[i].warehouseNumber &
                    row.receivingAddress == this.addform.contractAddresses[i].receivingAddress &
                    // row.receivingWarehouseNumber == this.addform.contractAddresses[i].receivingWarehouseNumber &
                    row.arrivalDate == this.addform.contractAddresses[i].arrivalDate &
                    row.productId == this.addform.contractAddresses[i].productId
                ){
                    this.addform.contractAddresses.splice(i,1)
                    // 分配厂区金额
                    var idarr = []
                    var productIdarr = []
                    this.addressGroupsYuan = []
                    this.addform.contractAddresses.forEach(aa => {
                        if(idarr.indexOf(aa.addressId) == -1){
                            idarr.push(aa.addressId)
                            this.addressGroupsYuan.push({
                                addressId: aa.addressId,
                                addressName: aa.address,
                                price: 0,
                                isStop: aa.isStop,
                                receiptsPrice: aa.receiptsPrice,
                            })
                        }
                    })
                }
            }
        },
        // 调整的时候判断当前的支付方式
        payTypeChecked(){
            if(this.payType0 == false & this.payType1 == false){
                this.addform.payAmount = 0
                this.addform.creditTipsAmount = 0
                this.addform.repaymentDate = ''
                this.addform.receivableAmount = 0
            }
            if(this.payType1 == false){
                this.addform.repaymentDate = ''
                this.addform.receivableAmount = 0
            }
            if(this.payType2 == false){
                this.addform.repaymentTime1 = ''
                this.addform.repaymentTime2 = ''
                this.addform.tipsDay = ''
                this.addform.receivableAmount = 0
            }
            if(this.formButton == 'Adjustment' || this.formButton == 'AdjustmentEdit'){
                if(this.addform.payType == '1' || this.addform.payType == '1,2'){
                    this.payType1checked = false
                    this.payType2checked = false
                    this.payType0checked = true
                    this.payType3checked = true
                }
                if(this.addform.payType == '2'){
                    this.payType1checked = true
                    this.payType2checked = false
                    this.payType0checked = true
                    this.payType3checked = true
                }
                if(this.addform.payType == '0'){
                    this.payType0checked = false
                    this.payType1checked = true
                    this.payType2checked = true
                    this.payType3checked = true
                }
                if(this.addform.payType == '3'){
                    this.payType3checked = false
                    this.payType0checked = true
                    this.payType1checked = true
                    this.payType2checked = true
                }
            }
        },
        DeleteKSShop(row,index){
            this.addform.contractSands.splice(index,1)
            var i = -1
            this.addform.contractAddresses.forEach(item => {
                i++
                if(item.productId == row.id){
                    this.addform.contractAddresses.splice(i,1)
                }
            })
        },
        DeleteHNTShop(row,index){
            this.addform.contractConcretes.splice(index,1)
            var i = -1
            this.addform.contractAddresses.forEach(item => {
                i++
                if(item.productId == row.id){
                    this.addform.contractAddresses.splice(i,1)
                }
            })
        }
    },
    mounted(){
        this.query()
        this.start()
        // 存储当前企业的业务类型
        this.business = JSON.parse(window.sessionStorage.getItem('business'))
    }
}
</script>
<style scoped>
.body{
    padding: 15px;
    height: 96%;
    width: 98%;
    background-color:  #fdfdfd;
    box-shadow: 2px 1px 1px  #d8d8d8;
    border: 1px solid #ddd;
    border-radius: 5px;
}
/deep/.el-dialog__header {
    padding: 13px 20px 13px;
    background-color: skyblue;
}
/deep/.el-dialog__footer {
    /* min-width: 1300px; */
}
.xiangqing{
    color:#67C23A
}
.bianji{
    color: #E6A23C;
}
.shanchu{
    color: #F56C6C;
}
</style>