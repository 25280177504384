<template>
    <div class="body" v-loading="loading" element-loading-text="拼命加载中" element-loading-background="rgb(213 213 213 / 57%)">
        <!-- 查询 -->
        <div>
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item label="企业名称">
                    <el-input v-model="form.name" placeholder="请输入企业名称"></el-input>
                </el-form-item>
                <el-form-item label="订单编号">
                    <el-input v-model="form.orderNo" placeholder="请输入订单编号"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select @change="query()" v-model="form.state" clearable placeholder="请选择">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="查询方式">
                    <el-select @change="query()" v-model="querytype" placeholder="请选择">
                        <el-option
                        v-for="item in options1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="开始时间">
                    <el-date-picker
                        @change="query()"
                        v-model="form.startDate"
                        type="date"
                        placeholder="选择日期"
                        format="yyyy 年 MM 月 dd 日"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间">
                    <el-date-picker
                        @change="query()"
                        v-model="form.endDate"
                        type="date"
                        placeholder="选择日期"
                        format="yyyy 年 MM 月 dd 日"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="">
                    <el-button type="primary" v-if="$hasPermission(['PurchasingStatistics_query'])" @click="query()">查询</el-button>
                    <el-button v-if="$hasPermission(['PurchasingStatistics_export'])" @click="Export()" type="primary">导出</el-button>
                    <!-- <el-button v-if="$hasPermission(['PurchasingStatistics_export'])" @click="ExportPurchaser()" type="primary">统计导出</el-button> -->
                </el-form-item>
            </el-form>
        </div>
        <!-- 表格 -->
        <div>
            <el-table
                :header-cell-style="headClass"
                :cell-style="rowClass"
                :data="tableData"
                :height="tableHeight"
                border
                style="width: 100%">
                <el-table-column
                prop="contractCode"
                v-if="querytype == 0"
                :key="1"
                label="合同编号"
                :show-overflow-tooltip="true"
                width="200px">
                </el-table-column>
                <el-table-column
                prop="orderCode"
                v-if="querytype == 0"
                :key="2"
                label="订单编号"
                :show-overflow-tooltip="true"
                width="200px">
                </el-table-column>
                <el-table-column
                prop="enterpriseName"
                :key="3"
                :show-overflow-tooltip="true"
                label="企业名称"
                width="">
                </el-table-column>
                <el-table-column
                prop="payType"
                v-if="querytype == 0"
                :key="4"
                :formatter="payType"
                :show-overflow-tooltip="true"
                label="支付方式">
                </el-table-column>
                <el-table-column
                prop="productName"
                :key="5"
                :show-overflow-tooltip="true"
                label="商品名称/配方名称">
                </el-table-column>
                <el-table-column
                prop="standards"
                v-if="querytype == 0"
                :key="6"
                :show-overflow-tooltip="true"
                label="规格">
                </el-table-column>
                <el-table-column
                prop="price"
                :key="7"
                :show-overflow-tooltip="true"
                label="结算单价(元)">
                </el-table-column>
                <el-table-column
                prop="driver"
                v-if="querytype == 0"
                :key="8"
                :show-overflow-tooltip="true"
                label="司机名称">
                </el-table-column>
                <el-table-column
                prop="licensePlate"
                v-if="querytype == 0"
                :key="9"
                :show-overflow-tooltip="true"
                label="车牌号码">
                </el-table-column>
                <el-table-column
                prop="actualWeight"
                :key="10"
                :show-overflow-tooltip="true"
                label="实际重量">
                </el-table-column>
                <el-table-column
                prop="unit"
                v-if="querytype == 0"
                :key="11"
                width="80px"
                :show-overflow-tooltip="true"
                label="单位">
                </el-table-column>
                <el-table-column
                prop="creationTime"
                :formatter="creationTime"
                v-if="querytype == 0"
                :key="12"
                :show-overflow-tooltip="true"
                label="出厂时间"
                width="200px">
                </el-table-column>
                <el-table-column
                prop="receivableAmount"
                :key="13"
                v-if="querytype == 1"
                :show-overflow-tooltip="true"
                label="应收金额">
                </el-table-column>
            </el-table>
            <!-- 表格分页 -->
            <div style="float:right" class="block">
                <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10, 15, 20]"
                :page-size="size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    computed: {
        // tableHeight: document.documentElement.clientHeight
    },
    created() {
        var height = document.documentElement.clientHeight
        var width = document.documentElement.clientWidth
        if(width >= 1980){
            this.tableHeight = (height*0.67)+'px';
        }else{
            this.tableHeight = (height*0.58)+'px';
        }
    },
    data(){
        return{
            loading: false,
            tableHeight: 0,
            tableData: [],
            FormOptions: [],
            // 表格分页
            page: 1,
            size: 10,
            total: 0,
            options: [{
                value: 0,
                label: '未接单'
                }, {
                value: 1,
                label: '已接单'
                }, {
                value: 2,
                label: '装货中'
                }, {
                value: 3,
                label: '已完成'
            }, {
                value: 4,
                label: '已取消'
            }, {
                value: 5,
                label: '改价'
            }, {
                value: 6,
                label: '废弃'
            }, {
                value: 7,
                label: '终止'
            }, {
                value: 8,
                label: '暂停'
            }],
            options1: [{
                value: 0,
                label: '明细查询'
            },{
                value: 1,
                label: '统计查询'
            }],
            querytype: 0,
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
            },
            form: {
                name: '',
                orderNo: '',
                contractCode: '',
                state: null,
                payType: '',
                carryModel: null,
                startDate: '',
                endDate: '',
                datetime: this.getDateTime()
            },
        }
    },
    methods:{
        // 表头 列 居中
        headClass() {
            return "text-align:center;background-color: #c8e0f391;color:#E6A23C";
        },
        rowClass() {
            return "text-align:center";
        },
        // 分页功能
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.size = val
            this.query()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.page = val
            this.query()
        },
        creationTime(row){
            if(row.creationTime == '0001-01-01 00:00:00'){
                return ''
            }else{
                return row.creationTime
            }
        },
        querySysEnterprise(){
            // this.loading = true
            this.$http.get(this.$syspartners_getpurchaselist)
            .then(response => {
                this.FormOptions = response.data.data.list
                // this.EnterpriseLoading = false
            })
        },
        getDateTime() {
            const start = new Date(new Date().getTime() - 3600 * 1000 * 24 * 1)
                .toISOString()
                .replace('T', ' ')
                .split('.')[0] //默认开始时间1天前
            const end = new Date(new Date().getTime() - 3600 * 1000 * 0)
                .toISOString()
                .replace('T', ' ')
                .split('.')[0]//默认结束时间今天
            return [start.slice(0,11), end.slice(0,11)]
        },
        // 表格里面判断
        isActive(row){
            if(row.isActive == true){
                return '已审核';
            }else{
                return '未审核'
            }
        },
        // 查询事件
        query(){
            this.loading = true
            if(!this.$hasPermission(['PurchasingStatistics_query']))
            {
                return;
            }
            if(this.querytype == 0){
                this.$http.post(this.$PurchaserReport_GetPagePurchaseList,{
                    currentPage: this.page,
                    pageSize: this.size,
                    filter: {
                        name: this.form.name,
                        orderNo: this.form.orderNo,
                        contractCode: this.form.contractCode,
                        state: this.form.state,
                        payType: this.form.payType,
                        carryModel: this.form.carryModel,
                        startDate: this.form.startDate,
                        endDate: this.form.endDate
                    }
                }).then(response => {
                    this.tableData = response.data.data.list
                    this.total = response.data.data.total
                    this.loading = false
                })
            }else{
                this.$http.post(this.$PurchaserReport_GetPagePurchaseList2,{
                    currentPage: this.page,
                    pageSize: this.size,
                    filter: {
                        name: this.form.name,
                        orderNo: this.form.orderNo,
                        contractCode: this.form.contractCode,
                        state: this.form.state,
                        payType: this.form.payType,
                        carryModel: this.form.carryModel,
                        startDate: this.form.startDate,
                        endDate: this.form.endDate
                    }
                }).then(response => {
                    this.tableData = response.data.data.list
                    this.total = response.data.data.total
                    this.loading = false
                })
            }
        },
        payType(row){
            if(row.payType == '0'){
                return '充值支付';
            }else if(row.payType == '1'){
                return '授信支付';
            }else if(row.payType == '2'){
                return '账期支付';
            }else if(row.payType == '3'){
                return '现金支付';
            }else if(row.payType == '1,2'){
                return '授信,账期支付';
            }
        },
        Export(){
            if(this.querytype == 0){
                var params={
                    currentPage: this.page,
                    pageSize: this.size,
                    filter: {
                        keyWork: this.form.keyWork,
                        orderNo: this.form.orderNo,
                        contractCode: this.form.contractCode,
                        payType: this.form.payType,
                        startDate: this.form.startDate,
                        endDate: this.form.endDate
                    }
                }
                // 'http://192.168.2.187:5010/ysreprot/File/ExportSupplierCarryOrders'  this.$ReportManger_PostCarryListExport
                axios.post(this.$File_ExportPurchaserOrders, params, {
                    headers: { 'Authorization': 'Bearer ' + window.sessionStorage.getItem('token') },
                    'responseType': 'blob'  //设置响应的数据类型为一个包含二进制数据的 Blob 对象，必须设置！！！
                }).then(function (response) {
                  
                    const blob = new Blob([response.data]); 
                    const date = new Date()
                    const fileName = '采购订单数据.xls';
                    const linkNode = document.createElement('a');

                    linkNode.download = fileName; //a标签的download属性规定下载文件的名称
                    linkNode.style.display = 'none';
                    linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
                    document.body.appendChild(linkNode);
                    linkNode.click();  //模拟在按钮上的一次鼠标单击

                    URL.revokeObjectURL(linkNode.href); // 释放URL 对象
                    document.body.removeChild(linkNode);

                }).catch(function (error) {
                    console.log(error);
                });
            }else{
                var params={
                    currentPage: this.page,
                    pageSize: this.size,
                    filter: {
                        keyWork: this.form.keyWork,
                        orderNo: this.form.orderNo,
                        contractCode: this.form.contractCode,
                        payType: this.form.payType,
                        startDate: this.form.startDate,
                        endDate: this.form.endDate
                    }
                }
                // 'http://192.168.2.187:5010/ysreprot/File/ExportSupplierCarryOrders'  this.$ReportManger_PostCarryListExport
                axios.post(this.$File_ExportPurchaserProductOrders, params, {
                    headers: { 'Authorization': 'Bearer ' + window.sessionStorage.getItem('token') },
                    'responseType': 'blob'  //设置响应的数据类型为一个包含二进制数据的 Blob 对象，必须设置！！！
                }).then(function (response) {
                  
                    const blob = new Blob([response.data]); 
                    const date = new Date()
                    const fileName = '采购统计数据.xls';
                    const linkNode = document.createElement('a');

                    linkNode.download = fileName; //a标签的download属性规定下载文件的名称
                    linkNode.style.display = 'none';
                    linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
                    document.body.appendChild(linkNode);
                    linkNode.click();  //模拟在按钮上的一次鼠标单击

                    URL.revokeObjectURL(linkNode.href); // 释放URL 对象
                    document.body.removeChild(linkNode);

                }).catch(function (error) {
                    console.log(error);
                });
            }
        },
        ExportPurchaser(){
            
        }
    },
    mounted(){
        this.query()
        // this.querySysEnterprise()
    }
}
</script>
<style scoped>
.body{
    padding: 15px;
    height: 96%;
    width: 98%;
    background-color:  #fdfdfd;
    box-shadow: 2px 1px 1px  #d8d8d8;
    border: 1px solid #ddd;
    border-radius: 5px;
}
/deep/.el-dialog__header {
    padding: 13px 20px 13px;
    background-color: skyblue;
}
.xiangqing{
    color:#67C23A
}
.bianji{
    color: #E6A23C;
}
.shanchu{
    color: #F56C6C ;
}
</style>