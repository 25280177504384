<template>
<!-- 主页面板页面 -->
  <div class="main1" style="padding:0px;">
    <div style="display: flex;width:100%">
      <div id="myEchart" class="body1">
      </div>
      <div id="myEchart1" class="body1" style="margin-left: 2%">
      </div>
    </div>
    <div class="table">
      <div class="table1">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span class="clearfix_span" style="font-size: 21px">销售订单</span>
          </div>
          <el-table
            :header-cell-style="headClass"
            :cell-style="rowClass"
            :data="tableData1"
            border
            height="300"
            style="width:99%;margin-top:0.5%;margin-left:0.5%">
            <el-table-column
              prop="contractCode"
              label="合同编号"
              :show-overflow-tooltip="true"
              width="">
            </el-table-column>
            <el-table-column
              prop="orderCode"
              label="订单编号"
              :show-overflow-tooltip="true"
              width="">
            </el-table-column>
            <el-table-column
              prop="enterpriseName"
              label="企业名称"
              :show-overflow-tooltip="true"
              width="">
            </el-table-column>
            <el-table-column
              :formatter="industryType"
              prop="industryType"
              :show-overflow-tooltip="true"
              label="商品类型"
              width="">
            </el-table-column>
            <el-table-column
              prop="planNumber"
              :show-overflow-tooltip="true"
              label="计划数量">
            </el-table-column>
            <el-table-column
              prop="number"
              :show-overflow-tooltip="true"
              label="数量">
            </el-table-column>
            <el-table-column
              prop="unit"
              width="70"
              :show-overflow-tooltip="true"
              label="单位">
            </el-table-column>
            <el-table-column
              prop="productName"
              :show-overflow-tooltip="true"
              label="商品名称">
            </el-table-column>
            <el-table-column
              prop="standards"
              :show-overflow-tooltip="true"
              label="规格">
            </el-table-column>
          </el-table>
        </el-card>
      </div>
      <div class="table2">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
              <span class="clearfix_span" style="font-size: 21px">采购订单</span>
          </div>
          <el-table
          :header-cell-style="headClass"
          :cell-style="rowClass"
          :data="tableData2"
          border
          height="300"
          style="width:99%;margin-top:0.5%;margin-left:0.5%">
            <el-table-column
              prop="contractCode"
              label="合同编号"
              :show-overflow-tooltip="true"
              width="">
            </el-table-column>
            <el-table-column
              prop="orderCode"
              label="订单编号"
              :show-overflow-tooltip="true"
              width="">
            </el-table-column>
            <el-table-column
              prop="enterpriseName"
              label="企业名称"
              :show-overflow-tooltip="true"
              width="">
            </el-table-column>
            <el-table-column
              :formatter="industryType"
              prop="industryType"
              :show-overflow-tooltip="true"
              label="商品类型"
              width="">
            </el-table-column>
            <el-table-column
              prop="planNumber"
              :show-overflow-tooltip="true"
              label="计划数量">
            </el-table-column>
            <el-table-column
              prop="number"
              :show-overflow-tooltip="true"
              label="数量">
            </el-table-column>
            <el-table-column
              prop="unit"
              width="70"
              :show-overflow-tooltip="true"
              label="单位">
            </el-table-column>
            <el-table-column
              prop="productName"
              :show-overflow-tooltip="true"
              label="商品名称">
            </el-table-column>
            <el-table-column
              prop="standards"
              :show-overflow-tooltip="true"
              label="规格">
            </el-table-column>
          </el-table>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'hello',
  data () {
    return {
      msg: 'Welcome to Your Vue.js App',
      tableData1:[],
      tableData2:[],
      tableData3:[],
    }
  },
  mounted(){
    this.drawLine();
    window.onresize = () => {
      return (() => {
        this.drawLine();
      })();
    };
  },
  methods: {
    // 表头 列 居中
    headClass() {
        return "text-align:center;background-color:#F5F7FA;color:#E6A23C";
    },
    rowClass() {
        return "text-align:center";
    },
    statu(row , index){
      if(row.isActive == true){
        return '已阅读'
      }else{
        return '未阅读'
      }
    },
    SER(){
      // this.hAdsTimer = setInterval(this.query, 30000)
      // this.$once('hook:beforeDestroy',()=>{
      //   clearInterval(this.hAdsTimer)
      // })
    },
    query(){
      // this.OrderQueryList()
      // this.RechargeQueryList()
      // this.WorkOrderQueryList()
    },
    async drawLine(){
      // 基于准备好的dom，初始化echarts实例
      let myEchart = this.$echarts.init(document.getElementById('myEchart'))
      var salesdataname = []
      var salesdatanumber = []
      var purchasesdataname = []
      var purchasesdatanumber = []
      await this.$http.get(this.$SaleReport_GetHomeReport).then(response => {
        // 图表数据
        response.data.data.sales.forEach(item => {
          salesdataname.push(item.productName)
          salesdatanumber.push(item.number)
        })
        response.data.data.purchases.forEach(item => {
          purchasesdataname.push(item.productName)
          purchasesdatanumber.push(item.number)
        })
        // 列表数据
        this.tableData1 = response.data.data.saleList
        this.tableData2 = response.data.data.purchaseList
      })
      // 绘制图表
      const option = {
        // title: { text: '在Vue中使用echarts' },
        tooltip: {},
        xAxis: {
          data: salesdataname
        },
        yAxis: {},
        title: {
          text: '日销售量',
          textStyle:{
            //文字颜色
            // color:'#ccc',
            //字体风格,'normal','italic','oblique'
            fontStyle:'normal',
            //字体粗细 'normal','bold','bolder','lighter',100 | 200 | 300 | 400...
            fontWeight:'normal',
            //字体系列
            fontFamily:'sans-serif',
            //字体大小
            fontSize: 21
          },
        },
        series: [{
          name: '销量',
          type: 'bar',
          data: salesdatanumber,
          itemStyle: {
            normal: {
              color: function(params) {
                var colorList = ['#5470c6', '#91cc75','#fac858','#ee6666','#73c0de','#3ba272','#fc8452','#9a60b4','#ea7ccc',];
                var index=params.dataIndex%colorList.length;
                return colorList[index]
              }
            }
          }
        }]
      }
      myEchart.setOption(option)
      // 绘制采购图表
      let myEchart1 = this.$echarts.init(document.getElementById('myEchart1'))
      const option1 = {
        // title: { text: '在Vue中使用echarts' },
        tooltip: {},
        xAxis: {
            data: purchasesdataname
        },
        yAxis: {},
        title: {
          text: '日采购量',
          textStyle:{
            //文字颜色
            // color:'#ccc',
            //字体风格,'normal','italic','oblique'
            fontStyle:'normal',
            //字体粗细 'normal','bold','bolder','lighter',100 | 200 | 300 | 400...
            fontWeight:'normal',
            //字体系列
            fontFamily:'sans-serif',
            //字体大小
            fontSize: 21
          },
        },
        series: [{
          name: '采购量',
          type: 'bar',
          data: purchasesdatanumber,
            itemStyle: {
              normal: {
                color: function(params) {
                  var colorList = ['#5470c6', '#91cc75','#fac858','#ee6666','#73c0de','#3ba272','#fc8452','#9a60b4','#ea7ccc',];
                  var index=params.dataIndex%colorList.length;
                  return colorList[index]
                }
              }
            }
        }]
      }
      myEchart1.setOption(option1)
    },
    industryType(row){
      if(row.industryType == 0){
        return '矿石'
      }else{
        return '混凝土'
      }
    }
  }
}
</script>
<style scoped>
.main1{
    height: 90%;
    width: 100%;
    /* min-width: '1980px'; */
    /* background-color: red; */
}
/* .header{
    background-color: rgb(216, 231, 233);
    display: flex;
    width: 100%;
    height: 6vh;
    justify-content:center;
    align-items:center;
    margin: auto;
    box-shadow: 2px 2px 10px rgb(208, 234, 238);
}
.header_1{
    height: 5vh;
    background-color: rgb(189, 225, 230);
    font-size: 1.5rem;
    line-height: 5vh;
    width: 24%;
    box-shadow: 2px 2px 10px rgb(208, 234, 238);
} */
.body1{
    /* margin-top: 1%; */
    height: 400px;
    width: 50%;
    border: 1px solid rgb(231, 225, 225);
}
.table{
    margin-top: 1%;
    /* border: 1px solid rgb(231, 225, 225); */
    width: 100%;
    /* height: 40%; */
    display: flex;
}
.table1{
    height: 400px;
    width: 50%;
    /* background-color: red; */
}
.table2{
    height: 400px;
    width: 50%;
    margin-left: 2%;
    /* background-color: red; */
}
.text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }

  .box-card {
    height: 100%;
    width: 100%;
  }
  .clearfix_span{
      font-size: 1.5rem;
  }
  /deep/.el-card__body{
    padding: 0px;
  }
</style>
