<template>
    <div>
        <div>
            <el-form label-width="" style="width:97%" :model="carform" class="demo-form-inline">
                <el-form-item label-width="30px">
                    <el-button type="primary" @click="addCar()">添加矿石车辆</el-button>
                    <el-button type="primary" @click="addCar2()">添加混凝土车辆</el-button>
                </el-form-item>
                <el-row style="margin-top: -20px" :gutter="24">
                    <el-form-item label-width="38px">
                        <el-table
                            :header-cell-style="headClass"
                            :cell-style="rowClass"
                            :data="CarrysData"
                            height="500px"
                            border
                            style="width: 100%">
                            <el-table-column
                            prop="productName"
                            :show-overflow-tooltip="true"
                            label="商品名称/配方名称"
                            width="150">
                            </el-table-column>
                            <el-table-column
                            prop="carryName"
                            :show-overflow-tooltip="true"
                            label="承运单位"
                            width="">
                            </el-table-column>
                            <el-table-column
                            prop="driver"
                            :show-overflow-tooltip="true"
                            label="司机名称"
                            width="">
                            </el-table-column>
                            <el-table-column
                            prop="telephone"
                            :show-overflow-tooltip="true"
                            label="联系电话"
                            width="">
                            </el-table-column>
                            <el-table-column
                            prop="licensePlate"
                            label="车牌号"
                            width="">
                            </el-table-column>
                            <el-table-column
                            prop="maxLoad"
                            :show-overflow-tooltip="true"
                            label="最大载重(吨)"
                            width="">
                            </el-table-column>
                            <el-table-column
                            :formatter="CarryCartype"
                            prop="carType"
                            :show-overflow-tooltip="true"
                            label="车辆类型"
                            width="">
                            </el-table-column>
                            <el-table-column
                            prop="address"
                            :show-overflow-tooltip="true"
                            label="生产地址"
                            width="">
                            </el-table-column>
                            <el-table-column
                            label="操作"
                            width="150">
                                <template slot-scope="scope">
                                    <el-popover
                                        placement="right"
                                        width="400"
                                        :ref="'popover-'+scope.row.licensePlate">
                                        <el-row :gutter="24">
                                            <el-col :span="24">
                                                <el-table
                                                    ref="multipleTable"
                                                    :data="shopNameCarryData"
                                                    border
                                                    height="300px"
                                                    tooltip-effect="dark"
                                                    style="width: 100%"
                                                    >
                                                    <el-table-column
                                                    fixed
                                                    prop="date"
                                                    label="选择"
                                                    width="75">
                                                        <template slot-scope="scope">
                                                            <el-radio :label="scope.row.productId" v-model="ModifyProductid" @change.native="handleSelectionChangeModifyCar(scope.row)">{{''}}</el-radio>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column
                                                    label="商品名称/配方名称"
                                                    prop="productName"
                                                    width="">
                                                    </el-table-column>
                                                </el-table>
                                            </el-col>
                                        </el-row>
                                        <div style="text-align: right; margin: 10px">
                                            <el-button size="mini" type="primary" @click="CancelModifyCarry(scope.row)">取消</el-button>
                                            <el-button type="primary" size="mini" @click="SureModifyCarry(scope.row)">确定</el-button>
                                        </div>
                                        <el-button @click="ModifyCarry(scope.row,scope.$index)" style="font-size: 11px" type="text" slot="reference">更改商品 </el-button>
                                    </el-popover>
                                    <!-- <el-button    size="small">更改商品</el-button> -->
                                    <el-button style="margin-left: 5%"  @click="DeleteCarry(scope.row,scope.$index)" type="text" size="small"> 删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                </el-row>
            </el-form>
        </div>
        <div>
            <!-- :before-close="handleClose" -->
            <el-dialog
            title="添加车辆"
            :visible.sync="dialogVisible"
            width="50%"
            style="margin-top: -1.5%"
            :append-to-body="true"
            :show-close="false"
            >
                <el-row :gutter="24">
                    <el-form style="" ref="Carryform" label-width="100px" :rules="CarryformRules" :model="Carryform" class="demo-form-inline">
                        <el-col :span="12">
                            <el-card>
                                <el-form-item label="生产厂区：" label-width="88px" prop="addressId">
                                    <el-select @change="SelectFactoryAddress" v-model="Carryform.addressId" clearable placeholder="请选择">
                                        <el-option
                                        v-for="item in FactoryAddressData"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="收货厂区：" label-width="88px" prop="receivingAddressId">
                                    <el-select @change="SelectDeliveryAddress" v-model="Carryform.receivingAddressId" clearable placeholder="请选择">
                                        <el-option
                                        v-for="item in DeliveryAddressData"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-table
                                    ref="multipleTable"
                                    :data="shopNameCarryData"
                                    border
                                    height="413px"
                                    tooltip-effect="dark"
                                    style="width: 100%"
                                    >
                                    <el-table-column
                                    fixed
                                    prop="date"
                                    label="选择"
                                    width="75">
                                        <template slot-scope="scope">
                                            <el-radio :label="scope.row.productId" v-model="multipleSelection" @change.native="handleSelectionChange(scope.row)">{{''}}</el-radio>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                    label="商品名称/配方名称"
                                    prop="productName"
                                    width="">
                                    </el-table-column>
                                </el-table>
                            </el-card>
                        </el-col>
                        <el-col :span="12">
                            <el-card style="padding:10px">
                                <el-form-item label="企业名称：" prop="customerId">
                                    <el-select @change="SelectCustomer" v-model="Carryform.customerId" clearable placeholder="请选择">
                                        <el-option
                                        v-for="item in CustomerList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="车牌号码：">
                                    <el-input placeholder="请输入" style="width:76%" v-model="filterText"></el-input>
                                </el-form-item>
                                <div style="height:335px;overflow-y:scroll">
                                    <el-tree
                                        :data="CarryList"
                                        show-checkbox
                                        node-key="licensePlate"
                                        ref="Cartree"
                                        highlight-current
                                        :props="defaultProps"
                                        :filter-node-method="filterNode"
                                        :expand-on-click-node="false"
                                        @check-change="SelectCarry">
                                    </el-tree>
                                </div>
                            </el-card>
                        </el-col>
                    </el-form>
                </el-row>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="CancelCarry('Carryform')">取 消</el-button>
                    <el-button type="primary" @click="SureCarry('Carryform')">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
    watch: {
        filterText(val) {
            this.$refs.Cartree.filter(val);
        }
    },
    data(){
        return{
            // 树形结构过滤
            filterText: '',
            dialogVisible: false,
            CarrysData: [],
            carform:{

            },
            // 添加承运信息商品表格
            shopNameCarryData: [],
            Carryform: {
                // 选中的企业名称id
                customerId: '',
                // 车辆id
                id: '',
                productId: 0,
                productName: '',
                driver: '',
                carryName: '',
                telephone: '',
                licensePlate: '',
                maxLoad: 0,
                remarks: '',
                state: 0,
                carType: 0,
                baseId: 0,
                receivingAddressId: null,
                addressId: null
            },
            // 添加承运信息规则验证
            CarryformRules: {
                customerId: [
                    { required: true , message: '请选择企业名称' , trigger: 'blur' },
                ],
                id: [
                    { required: true , message: '请选择车牌' , trigger: 'blur' },
                ],
            },
            CarryindustryType: 0,
            orderSands: [],
            orderConcretes: [],
            orderAddresses: [],
            multipleSelection: '',
            // 承运信息企业名称
            CustomerList: [],
            CarryList: [],
            visible: false,
            ModifyProductid: '',
            index: 0,
            deleteCarArr: [],
            defaultProps: {
                children: 'children',
                label: 'licensePlate'
            },
            getCheckedNodesCardata: [],
            FactoryAddressData: [],
            DeliveryAddressData: []
        }
    },
    methods:{
        filterNode(value, data) {
            if (!value) return true;
            return data.licensePlate.indexOf(value) !== -1;
        },
        // 表头 列 居中
        headClass() {
            return "text-align:center;background-color: #c8e0f391;color:#E6A23C";
        },
        rowClass() {
            return "text-align:center";
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
            .then(_ => {
                done();
            })
            .catch(_ => {});
        },
        // 承运信息表格判断
        CarryCartype(row){
            if(row.carType == 0){
                return '低围板'
            }else if(row.carType == 1){
                return '集装箱'
            }else if(row.carType == 2){
                return '小三轮'
            }else if(row.carType == 3){
                return '其他'
            }
        },
        getquery(orderid){
            this.$http.get(this.$SysOrder_Get+'?id='+orderid).then(res => {
                this.orderSands = res.data.data.orderSands
                this.orderConcretes = res.data.data.orderConcretes
                this.orderAddresses = res.data.data.orderAddresses
                var arr = []
                arr = res.data.data.orderCarrys
                arr.forEach(item => {
                    let aa = this.orderAddresses.find(a => a.addressId === item.addressId)
                    if( aa !== undefined) {
                        let a = {
                            receivingAddress: aa.receivingAddress
                        }
                        Object.assign(item,a)
                    }
                })
                this.CarrysData = arr
            })
        },
        // 删除车辆
        DeleteCarry(row,index){
            row.state = 4
            this.deleteCarArr.push(row)
            this.CarrysData.splice(index,1)
            this.CarryList.forEach( Carry => {
                if(row.licensePlate == Carry.licensePlate){
                    Carry.disabled = false
                    this.$refs.Cartree.setChecked(Carry, false)
                }
            })
            for(var a=0; a<this.getCheckedNodesCardata.length; a++){
                if(row.licensePlate == this.getCheckedNodesCardata[a].licensePlate){
                    this.getCheckedNodesCardata.splice(a,1)
                }
            }
        },
        addCar(){
            this.Carryform.addressId = null
            this.Carryform.customerId = null
            this.shopNameCarryData = []
            this.FactoryAddressData = []
            this.DeliveryAddressData = []
            var AddressIDArr = []
            var shouhuoAddressIDArr = []
            this.CarryList = []
            this.orderAddresses.forEach(AddressItem => {
                let aa = AddressIDArr.find(a => a === AddressItem.addressId)
                if(aa == undefined){
                    AddressIDArr.push(AddressItem.addressId)
                    this.FactoryAddressData.push({
                        id: AddressItem.addressId,
                        name: AddressItem.address,
                        addressId: AddressItem.addressId,
                        address: AddressItem.address,
                        receivingAddressId: AddressItem.receivingAddressId,
                        receivingAddress: AddressItem.receivingAddress
                    })
                }
                let bb = shouhuoAddressIDArr.find(b => b === AddressItem.receivingAddressId)
                if(bb == undefined){
                    shouhuoAddressIDArr.push(AddressItem.receivingAddressId)
                    this.DeliveryAddressData.push({
                        id: AddressItem.receivingAddressId,
                        name: AddressItem.receivingAddress,
                        addressId: AddressItem.addressId,
                        address: AddressItem.address,
                        receivingAddressId: AddressItem.receivingAddressId,
                        receivingAddress: AddressItem.receivingAddress
                    })
                }
            })
            this.CarryindustryType = 0
            // 查询承运信息企业名称
            this.$http.get(this.$SysCarry_GetCustomerList+'?industryType=0')
            .then(response => {
                this.CustomerList = response.data.data
            })
            this.dialogVisible = true
        },
        addCar2(){
            this.Carryform.addressId = null
            this.Carryform.customerId = null
            this.shopNameCarryData = []
            this.FactoryAddressData = []
            this.DeliveryAddressData = []
            var AddressIDArr = []
            var shouhuoAddressIDArr = []
            this.CarryList = []
            this.orderAddresses.forEach(AddressItem => {
                let aa = AddressIDArr.find(a => a === AddressItem.addressId)
                if(aa == undefined){
                    AddressIDArr.push(AddressItem.addressId)
                    this.FactoryAddressData.push({
                        id: AddressItem.addressId,
                        name: AddressItem.address,
                        addressId: AddressItem.addressId,
                        address: AddressItem.address,
                        receivingAddressId: AddressItem.receivingAddressId,
                        receivingAddress: AddressItem.receivingAddress
                    })
                }
                let bb = shouhuoAddressIDArr.find(b => b === AddressItem.receivingAddressId)
                if(bb == undefined){
                    shouhuoAddressIDArr.push(AddressItem.receivingAddressId)
                    this.DeliveryAddressData.push({
                        id: AddressItem.receivingAddressId,
                        name: AddressItem.receivingAddress,
                        addressId: AddressItem.addressId,
                        address: AddressItem.address,
                        receivingAddressId: AddressItem.receivingAddressId,
                        receivingAddress: AddressItem.receivingAddress
                    })
                }
            })
            this.CarryindustryType = 1
            // 查询承运信息企业名称
            this.$http.get(this.$SysCarry_GetCustomerList+'?industryType=1')
            .then(response => {
                this.CustomerList = response.data.data
            })
            this.dialogVisible = true
        },
        // 选择的厂区
        SelectFactoryAddress(){
            this.Carryform.customerId = null
            this.multipleSelection = ''
            this.CarryList = []
            this.getCheckedNodesCardata = []
            var index = 1
            var shopNameIDArr = []
            this.shopNameCarryData = []
            this.orderAddresses.forEach(AddressItem => {
                let bb = shopNameIDArr.find(b => b === AddressItem.productId)
                if(bb == undefined & AddressItem.addressId == this.Carryform.addressId){
                    if(this.CarryindustryType == '0'){
                        let KSahop = this.orderSands.find(shop => Number(shop.productId) === Number(AddressItem.productId))
                        if(KSahop == undefined) {
                            
                        }else{
                            shopNameIDArr.push(AddressItem.productId)
                            this.shopNameCarryData.push({
                                productName: AddressItem.productName,
                                productId: AddressItem.productId,
                                index: index
                            })
                        }
                    }else{
                        let HNTahop = this.orderConcretes.find(shop => shop.productId === AddressItem.productId)
                        if(HNTahop == undefined) {
                            
                        }else{
                            shopNameIDArr.push(AddressItem.productId)
                            this.shopNameCarryData.push({
                                productName: AddressItem.productName,
                                productId: AddressItem.productId,
                                index: index
                            })
                        }
                    }
                }
            })
        },
        SelectDeliveryAddress(){

        },
        // 选择的公司
        SelectCustomer(){
            this.Carryform.driver = '',
            this.Carryform.id = '',
            this.Carryform.telephone = '',
            this.Carryform.licensePlate = '',
            this.Carryform.maxLoad = '',
            this.Carryform.remarks = '',
            this.Carryform.carType = '',
            this.GetCarryList()
            this.CustomerList.forEach(item => {
                if(this.Carryform.customerId == item.id){
                    this.Carryform.carryName = item.name
                }
            })
        },
        // 查询承运信息选择列表
        GetCarryList(){
            if(this.Carryform.addressId == null) {
                this.Carryform.customerId = null
                this.$message.info('请选择厂区地址')
                return
            }
            this.CarryList = []
            this.getCheckedNodesCardata = []
            this.$http.get(this.$SysCarry_GetCarryList+'?customerId='+Number(this.Carryform.customerId))
            .then(response => {
                response.data.data.forEach(element => {
                    let aa = this.CarrysData.find(a => a.licensePlate === element.licensePlate & Number(a.addressId) === Number(this.Carryform.addressId))
                    if(aa == undefined){
                        this.CarryList.push({
                            carType:  Number(element.carType),
                            creationTime: element.creationTime,
                            customerId: Number(element.customerId),
                            driver:  element.driver,
                            id: element.id,
                            isActive: element.isActive,
                            licensePlate: element.licensePlate,
                            maxLoad: element.maxLoad,
                            remarks: element.remarks,
                            telephone: element.telephone,
                            type: Number(element.type),
                            disabled: false,
                            addressId: this.Carryform.addressId
                        })
                    }else{
                        this.CarryList.push({
                            carType:  Number(element.carType),
                            creationTime: element.creationTime,
                            customerId: Number(element.customerId),
                            driver:  element.driver,
                            id: element.id,
                            isActive: element.isActive,
                            licensePlate: element.licensePlate,
                            maxLoad: element.maxLoad,
                            remarks: element.remarks,
                            telephone: element.telephone,
                            type: Number(element.type),
                            disabled: true,
                            addressId: this.Carryform.addressId
                        })
                    }
                });
                this.$nextTick(() => {
                    var idarr = []
                    this.getCheckedNodesCardata.forEach(item => {
                        idarr.push(Number(item.id))
                    })
                    this.CarrysData.forEach(id => {
                        if(id.licensePlate == undefined){
                            this.CarryList.forEach(aaitem => {
                                if(id.licensePlate == aaitem.licensePlate & Number(id.addressId) == Number(this.Carryform.addressId)){
                                    idarr.push(aaitem.licensePlate)
                                }
                            })
                        }else{
                            if(Number(id.addressId) == Number(this.Carryform.addressId)) {
                                idarr.push(id.licensePlate)
                            }
                        }
                    })
                    this.$refs.Cartree.setCheckedKeys(idarr)
                })
            })
        },
        // 选择车辆
        SelectCarry(data,node,obj){
            var index = -1
            if(this.getCheckedNodesCardata.length == 0) {
                if(node == true){
                    this.getCheckedNodesCardata.push(data)
                }else{

                }
            }else{
                this.getCheckedNodesCardata.forEach( i => {
                    index++
                    if(node == true) {
                        let a = this.getCheckedNodesCardata.find(a => a.licensePlate === data.licensePlate)
                        if (a == undefined) {
                            this.getCheckedNodesCardata.push(data)
                        }
                    }else{
                        if(i.licensePlate == data.licensePlate){
                            this.getCheckedNodesCardata.splice(index , 1)
                        }
                    }
                })
            }
        }, 
        // 选中承运信息商品表格事件 
        handleSelectionChange(row){
            this.multipleSelection = row.productId
            // this.multipleSelection = val
        },
        handleSelectionChangeModifyCar(row){
            this.ModifyProductid = row.productId
            // this.multipleSelection = val
        },
        // 确认添加承运信息
        SureCarry(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if(this.multipleSelection == ''){
                        return this.$message.warning('请选择一个商品')
                    }
                    if(this.Carryform.addressId == null){
                        return this.$message.warning('请选择生产地址!')
                    }
                    if(this.Carryform.receivingAddressId == null){
                        return this.$message.warning('请选择收货地址!')
                    }
                    var addressIdArr = []
                    this.getCheckedNodesCardata.forEach(CarItem => {
                        if(CarItem.disabled == false){
                            this.shopNameCarryData.forEach(item => {
                                if(this.multipleSelection == item.productId){
                                    let aa = this.orderAddresses.find(a => a.addressId == this.Carryform.addressId)
                                    let bb = this.orderAddresses.find(b => b.receivingAddressId == this.Carryform.receivingAddressId)
                                    CarItem.disabled = true
                                    this.CarrysData.push({
                                        productId: Number(item.productId),
                                        productName: item.productName,
                                        index: Number(item.index),
                                        driver: CarItem.driver,
                                        carryName: this.Carryform.carryName,
                                        telephone: CarItem.telephone,
                                        licensePlate: CarItem.licensePlate,
                                        maxLoad: CarItem.maxLoad,
                                        remarks: CarItem.remarks,
                                        carType: CarItem.carType,
                                        CARid: CarItem.id,
                                        // id: CarItem.id,
                                        address: aa.address,
                                        addressId: aa.addressId,
                                        receivingAddressId: bb.receivingAddressId,
                                        receivingAddress: bb.receivingAddress,
                                    })
                                }
                            })
                        }else{

                        }
                    })
                    this.getCheckedNodesCardata = []
                    this.multipleSelection = ''
                    this.dialogVisible = false
                } else {
                    return false;
                }
            });
        },
        ModifyCarry(row,index){
            this.shopNameCarryData = []
            this.orderSands.forEach(item => {
                this.shopNameCarryData.push({
                    productName: item.productName,
                    productId: item.productId,
                    index: 1
                })
            })
            this.multipleSelection = row.licensePlate
            this.ModifyProductid = row.productId
        },
        SureModifyCarry(row){
            this.$refs["popover-" + row.licensePlate].showPopper = false;
            this.shopNameCarryData.forEach(item => {
                if(this.ModifyProductid == item.productId){
                    this.CarrysData.forEach(arr => {
                        if(arr.licensePlate == this.multipleSelection){
                            arr.productId = item.productId,
                            arr.productName = item.productName,
                            arr.index = 1
                        }
                    })
                }
            })
        },
        CancelModifyCarry(row){
            const that = this
            that.$refs["popover-" + row.licensePlate].showPopper = false;
        },
        CancelCarry(formName){
            this.dialogVisible = false
            this.$refs[formName].resetFields();
            this.multipleSelection = '',
            this.Carryform = {
                // 选中的企业名称id
                customerId: '',
                // 车辆id
                id: '',
                productId: 0,
                productName: '',
                driver: '',
                carryName: '',
                telephone: '',
                licensePlate: '',
                maxLoad: 0,
                remarks: '',
                state: 0,
                carType: 0,
                baseId: 0,
            }
        }
    },
    mounted(){
        
    }
}
</script>
<style scoped>
.body{
    padding: 15px;
    height: 96%;
    width: 98%;
    background-color:  #fdfdfd;
    box-shadow: 2px 1px 1px  #d8d8d8;
    border: 1px solid #ddd;
    border-radius: 5px;
}
/deep/.el-dialog__header {
    padding: 13px 20px 13px;
    background-color: skyblue;
}
.xiangqing{
    color:#67C23A
}
.bianji{
    color: #E6A23C;
}
.shanchu{
    color: #F56C6C ;
}
</style>