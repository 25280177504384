import axios from 'axios'
import { Message } from 'element-ui'

// 创建一个新的create
// https://api.i-hg.cn/  http://192.168.2.128:9999/
const service = axios.create({
    baseURL: 'https://api.i-hg.cn/',
    timeout: 5000
})

// 请求前做的事情 请求头带上token
service.interceptors.request.use(
    config => {
        if(window.sessionStorage.getItem('token')){
            config.headers['Authorization'] = window.sessionStorage.getItem('token')
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
// 响应数据 请求后需要做的事情
service.interceptors.response.use(
    response => {
        const res = response.data
        if(res.code !== 200){
            Message({
                message: res.message || 'Error',
                type: 'error',
                duration: 5 * 1000
            })
            return Promise.reject(res.code)
        }else{
            return res
        }
    },
    error => {
        Message({
            message: '服务器错误，请联系管理员',
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)
export default service