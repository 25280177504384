<template>
    <div class="body" v-loading="loading" element-loading-text="拼命加载中" element-loading-background="rgb(213 213 213 / 57%)">
        <div style="width:25%;height:100%;min-width:430px;">
            <el-card style="width:100%;height:794px;overflow-y:scroll">
                <el-input placeholder="输入关键字进行过滤" v-model="filterText"></el-input>
                <el-tree
                    style="margin-top:2%"
                    class="filter-tree"
                    :data="TreeData"
                    :props="defaultProps"
                    :default-expand-all="true"
                    :filter-node-method="filterNode"
                    :expand-on-click-node="false"
                    @node-click="handleNodeClick"
                    @node-contextmenu="youjishijian"
                    ref="tree">
                    <span class="custom-tree-node" slot-scope="{ node, data }">
                        <span>{{ node.label }}</span>
                        <span>
                        <el-button
                            style="margin-left: 10px"
                            type="text"
                            v-if="node.label !== '承运信息' & data.ji !== '四级' & $hasPermission(['TransportationManagement_add'])"
                            icon="el-icon-circle-plus-outline"
                            @click="() => append(data)">
                        </el-button>
                        <el-button
                            style="margin-left: 10px"
                            type="text"
                            icon="el-icon-circle-close"
                            v-if="node.label !== '承运信息' & data.ji == '三级' & $hasPermission(['TransportationManagement_delete']) & Number(data.gonsilength) == 0"
                            @click="() => remove(node, data)">
                        </el-button>
                        <el-button
                            style="margin-left: 10px"
                            type="text"
                            icon="el-icon-circle-close"
                            v-if="node.label !== '承运信息' & data.ji == '四级' & $hasPermission(['TransportationManagement_delete']) & data.isActive == false"
                            @click="() => remove(node, data)">
                        </el-button>
                        </span>
                    </span>
                </el-tree>
            </el-card>
        </div>
        <el-dialog
            :title="customtitle"
            :visible.sync="Customerdialog"
            width="30%"
            :before-close="handleClose"
            :show-close="false">
            <el-form style="width:95%" label-width="90px" :model="form" class="demo-form-inline">
                <el-form-item label="公司名称">
                    <el-input v-model="form.name" maxlength="20" placeholder="请输入公司名称"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelCustomer()">取 消</el-button>
                <el-button type="primary" @click="SureCustomer()">确 定</el-button>
            </span>
        </el-dialog>
        <div style="width:75%;height:100%">
            <el-card v-if="CarryFromStatu == true" v-loading="Tree1loading" element-loading-text="拼命加载中" element-loading-background="rgb(213 213 213 / 57%)" style="width:100%;height:100%">
                <div style="height:40px;width:100%">
                    <div style="width:20%;margin-left:50px">
                        <span v-if="CrryButtonStatu == 'modify' & CarryFrom.isActive == false" style="">修改承运信息表单</span>
                        <span v-if="CrryButtonStatu == 'add' & CarryFrom.isActive == false" style="">添加承运信息表单</span>
                        <span v-if="CarryFrom.isActive == true" style="">承运信息表单详情</span>
                    </div>
                </div>
                <div>
                    <el-form style="width:70%;margin-top:-2.5%" ref="CarryFrom" :rules="CarryFromRule" label-width="120px" :model="CarryFrom" class="demo-form-inline">
                        <el-divider></el-divider>
                        <!-- 公用数据 -->
                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-form-item v-if="CarryFrom.isActive  == false" label="车牌号：" prop="licensePlate">
                                    <el-input v-model="CarryFrom.licensePlate" @input="inpuchepai" maxlength="8" placeholder="请输入车牌号"></el-input>
                                </el-form-item>
                                <el-form-item v-else label="车牌号：" prop="">
                                    <span>{{CarryFrom.licensePlate}}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item v-if="CarryFrom.isActive  == false" label="司机：" prop="driver">
                                    <el-input v-model="CarryFrom.driver" maxlength="20" placeholder="请输入司机"></el-input>
                                </el-form-item>
                                <el-form-item v-else label="司机：" prop="">
                                    <span>{{CarryFrom.driver}}</span>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-form-item v-if="CarryFrom.isActive  == false" label="联系电话：" prop="telephone">
                                    <el-input maxlength="11" v-model.number="CarryFrom.telephone" placeholder="请输入联系电话"></el-input>
                                </el-form-item>
                                <el-form-item v-else label="联系电话：" prop="">
                                    <span >{{CarryFrom.telephone}}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item v-if="CarryFrom.isActive  == false" label="最大载重(吨)：" prop="maxLoad">
                                    <el-input  v-model="CarryFrom.maxLoad" v-input-limit="2" placeholder="请输入最大载重"></el-input>
                                </el-form-item>
                                <el-form-item v-else label="最大载重(吨)：" prop="">
                                    <span>{{CarryFrom.maxLoad}}</span>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-form-item v-if="CarryFrom.isActive  == false" label="驾驶证号：">
                                    <el-input v-model="CarryFrom.driveCard" maxlength="40" placeholder="请输入驾驶证号"></el-input>
                                </el-form-item>
                                <el-form-item v-else label="驾驶证号：" prop="">
                                    <span>{{CarryFrom.driveCard}}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item v-if="CarryFrom.isActive  == false" label="运输证号：">
                                    <el-input maxlength="40" v-model="CarryFrom.roadCard" placeholder="请输入运输证号"></el-input>
                                </el-form-item>
                                <el-form-item v-else label="运输证号：" prop="">
                                    <span >{{CarryFrom.roadCard}}</span>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <!-- 矿石商品需要添加的数据 -->
                        <el-row :gutter="24">
                            <el-col v-if="industryType == 0" :span="12">
                                <el-form-item v-if="CarryFrom.isActive == false" label="车辆类型：" prop="carType">
                                    <el-select style="width:100%" v-model="CarryFrom.carType" placeholder="请选择">
                                        <el-option
                                        v-for="item in list"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item v-else label="车辆类型：" prop="">
                                    <div><span v-show="CarryFrom.carType == 0">低围板</span><span v-show="CarryFrom.carType == 1">集装箱</span><span v-show="CarryFrom.carType == 2">小四轮</span><span v-show="CarryFrom.carType == 3">其他</span></div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item v-if="CarryFrom.isActive == false" label="类型：" prop="type">
                                    <el-radio-group v-model="CarryFrom.type">
                                        <el-radio :label="0">内部</el-radio>
                                        <el-radio :label="1">外部</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item v-else label="类型：" prop="">
                                    <div><span v-show="CarryFrom.type == 0">内部</span> <span v-show="CarryFrom.type == 1">外部</span></div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <!-- 公用数据 -->
                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-form-item label="备注：" prop="remarks">
                                    <el-input v-if="CarryFrom.isActive  == false" v-model="CarryFrom.remarks" placeholder="请输入备注"></el-input>
                                    <span v-else>{{CarryFrom.remarks}}</span>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-form-item label="">
                            <el-button v-if="CrryButtonStatu == 'modify' & CarryFrom.isActive == false & $hasPermission(['TransportationManagement_audit'])" size="small" type="primary" @click="Audit()">审 核</el-button>
                            <el-button v-if="CrryButtonStatu == 'modify' & CarryFrom.isActive == true & $hasPermission(['TransportationManagement_audit'])" size="small" type="primary" @click="CancelAudit()">取消审核</el-button>
                            <el-button v-if="CrryButtonStatu == 'modify' & CarryFrom.isActive == false & $hasPermission(['TransportationManagement_edit'])" size="small" type="primary" @click="SaveUser('CarryFrom')">保 存</el-button>
                            <el-button v-if="CrryButtonStatu == 'add' & $hasPermission(['TransportationManagement_add'])" size="small" type="primary" @click="SaveUser('CarryFrom')">保 存</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-card>
            <el-card v-else style="width:100%;height:100%">
                
            </el-card>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
        
    },
    watch: {
        filterText(val) {
            this.$refs.tree.filter(val);
        }
    },
    data(){
        return{
            loading: false,
            Tree1loading: false,
            // 树形结构过滤
            filterText: '',
            // 商品树形结构数据
            TreeData: [{
                id: -1,
                name: '承运信息',
                children: []
            }],
            defaultProps: {
                children: 'children',
                label: 'name'
            },
            // 
            // 添加公司名称对话框
            Customerdialog: false,
            customtitle: '添加公司',
            // 公司名称表单
            form: {
                industryType: 0,
                name: '',
                id: ''
            },
            // 用来判断是否要执行节点事件
            tree1: 0,
            // 用来判断商品是修改还是添加
            CrryButtonStatu: '',
            // 用来判断是否打开表单
            CarryFromStatu: false,
            // 用来判断打开哪个行业的添加表单
            industryType: 0,
            // 表单数据
            CarryFrom: {
                id: '',
                licensePlate: '',
                telephone: '',
                driver: '',
                customerId: '',
                maxLoad: '48.8',
                type: '',
                remarks: '',
                carType: 0,
                isActive: false,
                creationTime: '',
                driveCard: '',
                roadCard: ''
            },
            // 表单验证
            CarryFromRule: {
                driver: [
                    { required: true , message: '请输入司机' , trigger: 'blur' },
                ],
                telephone: [
                    { required: true , message: '请输入联系电话' , trigger: 'blur' },
                    // { type: 'number', message: '联系电话必须为数字值'}
                ],
                licensePlate: [
                    { required: true , message: '请输入车牌号' , trigger: 'blur' },
                    { min: 7, max: 8, message: '长度在 7 ~ 8 个字符', trigger: 'blur' }
                ],
                maxLoad: [
                    { required: true , message: '请输入最大载重' , trigger: 'blur' },
                    // { type: 'number', message: '最大载重必须为数字值'}
                ],
                type: [
                    { required: true , message: '请选择类型' , trigger: 'blur' },
                ],
                carType: [
                    { required: true , message: '请选择车辆类型' , trigger: 'blur' },
                ],
            },
            list: [{
                value: 0,
                label: '低围板'
            },{
                value: 1,
                label: '集装箱'
            },{
                value: 2,
                label: '小四轮'
            },{
                value: 3,
                label: '其他'
            }],
        }
    },
    methods:{
        inpuchepai(val) {
            this.$refs.tree.filter(this.CarryFrom.licensePlate);
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        // 点击添加点击事件
        append(data){
            if(data.ji == '二级'){
                this.customtitle = '添加公司'
                this.form.industryType = data.value
                this.Customerdialog = true
                return
            }
            // 判断是矿石还是混凝土车辆
            this.industryType = Number(data.pid)
            // 清空数据
            this.CarryFrom = {
                id: '',
                licensePlate: '',
                telephone: '',
                driver: '',
                customerId: '',
                maxLoad: '48.8',
                remarks: '',
                carType: 0,
                isActive: false,
                creationTime: '',
                type: 0,
                driveCard: '',
                roadCard: ''
            }
            // 打开表单el-card
            this.CarryFromStatu = true
            // 如果点击添加事件 this.tree2 = 1 下面节点事件就不会进行处理
            this.tree1 = 1
            // 添加打开表单
            this.CrryButtonStatu = 'add'
            // 获取他的承运单位id
            this.CarryFrom.customerId = data.value
        },
        // 添加公司名称点击确定
        SureCustomer(){
            // 添加角色名称
            if(this.form.name == ''){
                return this.$message.info('请输入角色名称之后再点击确定哦')
            }else{
                if(this.customtitle == '修改公司'){
                    this.$http.post(this.$SysCarry_UpdateSysCustomer,{
                        name: this.form.name,
                        industryType: Number(this.form.industryType),
                        id: this.form.id
                    }).then(response => {
                        if(response.data.isSuccess == true){
                            this.queryData()
                            this.Customerdialog = false
                            this.$message.success('修改成功')
                        }else{
                            this.$message.warning(response.data.message)
                        }
                    })
                }else{
                    this.$http.post(this.$SysCarry_CreateSysCustomer,{
                        name: this.form.name,
                        industryType: Number(this.form.industryType)
                    }).then(response => {
                        if(response.data.isSuccess == true){
                            this.queryData()
                            this.Customerdialog = false
                            this.$message.success('添加成功')
                        }else{
                            this.$message.warning(response.data.message)
                        }
                    })
                }
            }
        },
        // 添加公司名称点击取消
        cancelCustomer(){
            this.form.name = ''
            this.Customerdialog = false
        },
        // 点击对话框之外的区域
        handleClose(done) {
            // this.$confirm('确认关闭？')
            // .then(_ => {
            //     done();
            //     this.form.name = ''
            //     this.form.industryType = ''
            // })
            // .catch(_ => {});
        },
        // 当节点被点击时候执行的事件
        handleNodeClick(data,node,obj){
            if(this.tree1 == 1){
                this.tree1 = 0
            }else{
                if(data.ji == '四级'){
                    // 打开加载动画
                    this.Tree1loading = true
                    // 判断是矿石还是混凝土
                    this.industryType = Number(data.pid)
                    // 设置表单状态
                    this.CrryButtonStatu = 'modify'
                    // 打开表单
                    this.CarryFromStatu = true
                    // 通过点击节点获取数据
                    this.CarryFrom.driver = data.driver
                    this.CarryFrom.id = data.id
                    this.CarryFrom.licensePlate = data.licensePlate
                    this.CarryFrom.telephone = data.telephone
                    this.CarryFrom.customerId = data.customerId
                    this.CarryFrom.maxLoad = data.maxLoad
                    this.CarryFrom.remarks = data.remarks
                    this.CarryFrom.carType = data.carType
                    this.CarryFrom.isActive = data.isActive
                    this.CarryFrom.creationTime = data.creationTime
                    this.CarryFrom.type = data.type
                    this.CarryFrom.driveCard = data.driveCard
                    this.CarryFrom.roadCard = data.roadCard
                    // 关闭加载动画
                    this.Tree1loading = false
                }
                if(data.ji == '三级'){
                    return
                }
            }
        },
        youjishijian(data,node){
            if(this.tree1 == 1){
                this.tree1 = 0
            }else{
                if(node.ji == '三级'){
                    this.customtitle = '修改公司'
                    this.form.name = node.name
                    this.form.industryType = node.pid
                    this.form.id = node.value
                    this.Customerdialog = true
                    return
                }
            }
        },
        // 点击删除按钮点击事件
        remove(node, data){
            // 删除创建的角色名称
            if(node.id !== -1){
                this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    if(data.ji == '四级'){
                        var idarr = []
                        idarr.push(node.data.id)
                        this.$http.post(this.$SysCarry_DeleteSysCarryById,
                            idarr
                        ).then(response => {
                            if(response.data.isSuccess == true){
                                this.$message.success('删除成功')
                                this.CarryFromStatu = false
                                this.queryData()
                            }else{
                                this.$message.warning(response.data.message)
                            }
                        })
                    }else if(data.ji == '三级'){
                        var idarr = []
                        idarr.push(data.value)
                        this.$http.post(this.$SysCarry_DeleteSysCustomerById,
                            idarr
                        ).then(response => {
                            if(response.data.isSuccess == true){
                                this.$message.success('删除成功')
                                this.CarryFromStatu = false
                                this.queryData()
                            }else{
                                this.$message.warning(response.data.message)
                            }
                        })
                    }
                    
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
                });
            }
        },
        // 设置前两级数据
        queryData(){
            if(!this.$hasPermission(['TransportationManagement_query'])){
                return
            }
            // 打开整个页面的加载动画
            this.loading = true
            this.TreeData = [{
                id: -1,
                name: '承运信息',
                children: []
            }]
            // 查询第三级商品数据
            this.$http.get(this.$SysCarry_GetTreeList).then(response => {
                var data = []
                // 第三级数据
                var sanjiData = []
                // 第四级数据
                var sijiData = []
                data = response.data.data
                data.forEach(item => {
                    // 循环后端返回的第三级数据 全部拿到
                    item.customerList.forEach(arr => {
                        // 设置第三级的数据的父级id
                        sanjiData.push({
                            carryList: arr.carryList,
                            gonsilength: arr.carryList.length,
                            children: [],
                            name: arr.name,
                            value: arr.id,
                            pid: item.value,
                            ji: '三级'
                        })
                    })
                    this.TreeData[0].children.push({
                        children: [],
                        name: item.name,
                        value: item.value,
                        ji: '二级'
                    })
                });
                // 循环第三级数据 赋值到第二季数据的children下面
                sanjiData.forEach(item => {
                    sijiData.push(item.carryList)
                    this.TreeData[0].children.forEach(arr => {
                        if(item.pid == arr.value){
                            arr.children.push(item)
                        }
                    })
                    var i = 1
                    item.carryList.forEach(index => {
                        if(item.value == index.customerId){
                            item.children.push({
                                pid: item.pid,
                                carType: index.carType,
                                creationTime: index.creationTime,
                                customerId: index.customerId,
                                driver: index.driver,
                                id: index.id,
                                isActive: index.isActive,
                                licensePlate: index.licensePlate,
                                maxLoad: index.maxLoad,
                                remarks: index.remarks,
                                telephone: index.telephone,
                                type: index.type,
                                driveCard: index.driveCard,
                                roadCard: index.roadCard,
                                name: i +'--'+ index.driver + '/' + index.licensePlate,
                                ji: '四级'
                            })
                        }
                        i++
                    })
                })
                // 关闭整个页面的加载动画
                this.loading = false
            })
            // this.gonsilength = sijiData.carryList.length
        },
        // 添加和修改点击保存触发事件
        SaveUser(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // 打开加载动画
                    this.Tree1loading = true
                    // 判断是添加还是修改
                    if(this.CrryButtonStatu == 'add'){
                        this.$http.post(this.$SysCarry_CreateSysCarry,{
                            licensePlate: this.CarryFrom.licensePlate,
                            telephone: this.CarryFrom.telephone,
                            driver: this.CarryFrom.driver,
                            customerId: this.CarryFrom.customerId,
                            maxLoad: Number(this.CarryFrom.maxLoad),
                            carType: Number(this.CarryFrom.carType),
                            remarks: this.CarryFrom.remarks,
                            type: Number(this.CarryFrom.type),
                            driveCard: this.CarryFrom.driveCard,
                            roadCard: this.CarryFrom.roadCard
                        }).then(response => {
                            if(response.data.isSuccess == true){
                                this.CarryFromStatu = false
                                this.queryData()
                                this.$message.success('添加成功')
                                this.CarryFrom = {
                                    id: '',
                                    licensePlate: '',
                                    telephone: '',
                                    driver: '',
                                    customerId: '',
                                    maxLoad: '',
                                    remarks: '',
                                    carType: 0,
                                    isActive: false,
                                    creationTime: '',
                                    type: 0,
                                }
                            }else{
                                this.$message.warning(response.data.message)
                            }
                            // 关闭加载动画
                            this.Tree1loading = false
                        })
                    }else if(this.CrryButtonStatu == 'modify'){
                        this.$http.put(this.$SysCarry_UpdateSysCarry,{
                            licensePlate: this.CarryFrom.licensePlate,
                            telephone: this.CarryFrom.telephone,
                            driver: this.CarryFrom.driver,
                            customerId: this.CarryFrom.customerId,
                            maxLoad: Number(this.CarryFrom.maxLoad),
                            carType: Number(this.CarryFrom.carType),
                            remarks: this.CarryFrom.remarks,
                            type: Number(this.CarryFrom.type),
                            isActive: this.CarryFrom.isActive,
                            creationTime: this.CarryFrom.creationTime,
                            id: this.CarryFrom.id,
                            driveCard: this.CarryFrom.driveCard,
                            roadCard: this.CarryFrom.roadCard
                        }).then(response => {
                            if(response.data.isSuccess == true){
                                this.queryData()
                                this.$message.success('修改成功')
                            }else{
                                this.$message.warning(response.data.message)
                            }
                        })
                        // 关闭加载动画
                        this.Tree1loading = false
                    }
                } else {
                    return false;
                }
            });
        },
        // 审核
        Audit(){
            this.$confirm('此操作将审核该车辆, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysCarry_AuditSysCarry,{
                    id : this.CarryFrom.id,
                    isActive : true
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('审核成功')
                        this.queryData()
                        // 关闭表单
                        this.CarryFromStatu = false
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        // 取消审核
        CancelAudit(){
            this.$confirm('此操作将取消审核该车辆, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysCarry_AuditSysCarry,{
                    id : this.CarryFrom.id,
                    isActive : false
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('取消审核成功')
                        this.queryData()
                        // 关闭表单
                        this.CarryFromStatu = false
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
    },
    mounted(){
        this.queryData()
    }
}
</script>
<style scoped>
.body{
    padding: 15px;
    height: 96%;
    width: 98%;
    background-color:  #fdfdfd;
    box-shadow: 2px 1px 1px  #d8d8d8;
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex;
}
/deep/.el-dialog__header {
    padding: 13px 20px 13px;
    background-color: skyblue;
}
.xiangqing{
    color:#67C23A
}
.bianji{
    color: #E6A23C;
}
.shanchu{
    color: #F56C6C ;
}
</style>