<template>
    <div class="body" v-loading="loading" element-loading-text="拼命加载中" element-loading-background="rgb(213 213 213 / 57%)">
        <div style="width:25%;height:100%">
            <el-card style="width:100%;height:100%">
                <el-input placeholder="输入关键字进行过滤" v-model="filterText"></el-input>
                <el-tree
                    style="margin-top:2%"
                    class="filter-tree"
                    :data="TreeData"
                    :props="defaultProps"
                    default-expand-all
                    :filter-node-method="filterNode"
                    :expand-on-click-node="false"
                    @node-click="handleNodeClick"
                    ref="tree">
                    <span class="custom-tree-node" slot-scope="{ node, data }">
                        <span>{{ node.label }}</span>
                        <span>
                        <el-button
                            style="margin-left: 10px"
                            type="text"
                            v-if="node.label !== '商品' & data.ji !== '二级' & data.ji !== '四级' & $hasPermission(['GoodsManagement_add'])"
                            icon="el-icon-circle-plus-outline"
                            @click="() => append(data)">
                        </el-button>
                        <el-button
                            style="margin-left: 10px"
                            type="text"
                            icon="el-icon-circle-close"
                            v-if="node.label !== '商品' & data.ji !== '二级' & data.ji !== '三级' & data.isActive !== true & $hasPermission(['GoodsManagement_delete'])"
                            @click="() => remove(node, data)">
                        </el-button>
                        </span>
                    </span>
                </el-tree>
            </el-card>
        </div>
        <div style="width:75%;height:100%">
            <el-card v-if="ShopFromStatu == true" v-loading="Tree1loading" element-loading-text="拼命加载中" element-loading-background="rgb(213 213 213 / 57%)" style="width:100%;height:100%">
                <div style="height:40px;width:100%;display:flex">
                    <div style="width:20%;margin-left:50px">
                        <span v-if="ShopButtonStatu == 'modify' & ShopFrom.isActive  == false" style="">修改商品表单</span>
                        <span v-if="ShopButtonStatu == 'add' & ShopFrom.isActive  == false" style="">添加商品表单</span>
                        <span v-if="ShopFrom.isActive  == true" style="">商品表单详情</span>
                    </div>
                </div>
                <div>
                    <el-form style="width:60%;margin-top:-2.5%" ref="ShopFrom" :rules="ShopFromRule" label-width="120px" :model="ShopFrom" class="demo-form-inline">
                        <el-divider></el-divider>
                        <!-- 商品名称 公用数据 -->
                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-form-item v-if="ShopFrom.isActive  == false" :label="this.industryType == 0 ? '商品名称：' : '配方名称'" prop="name">
                                    <el-input v-model="ShopFrom.name" maxlength="20" placeholder="请输入商品名称"></el-input>
                                </el-form-item>
                                <el-form-item v-else :label="this.industryType == 0 ? '商品名称：' : '配方名称'" prop="">
                                    <span>{{ShopFrom.name}}</span>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <!-- 矿石商品需要添加的数据 -->
                        <el-row v-if="industryType == 0" :gutter="24">
                            <el-col :span="12">
                                <el-form-item v-if="ShopFrom.isActive == false" label="规格：" prop="standards">
                                    <el-select v-if="ShopFrom.isActive == false" style="width:100%" v-model="ShopFrom.standards" placeholder="请选择">
                                        <el-option
                                        v-for="item in standards"
                                        :key="item.code"
                                        :label="item.name"
                                        :value="item.code">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item v-else label="规格：" prop="">
                                    <span>{{ShopFrom.standards}}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item v-if="ShopFrom.isActive  == false" label="单位：" prop="unit">
                                    <el-select style="width:100%" v-model="ShopFrom.unit" placeholder="请选择">
                                        <el-option
                                        v-for="item in unit"
                                        :key="item.code"
                                        :label="item.name"
                                        :value="item.code">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item v-else label="单位：" prop="">
                                    <span>{{ShopFrom.unit}}</span>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <!-- 混凝土商品需要添加的数据 -->
                        <el-row v-if="industryType == 1" :gutter="24">
                            <el-col :span="12">
                                <el-form-item v-if="ShopFrom.isActive  == false" label="强度等级：" prop="standards">
                                    <el-select style="width:100%" v-model="ShopFrom.standards" placeholder="请选择">
                                        <el-option
                                        v-for="item in strengthGrade"
                                        :key="item.code"
                                        :label="item.name"
                                        :value="item.code">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item v-else label="强度等级：" prop="">
                                    <span>{{ShopFrom.standards}}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item v-if="ShopFrom.isActive  == false" label="塌落度：" prop="slump">
                                    <el-select style="width:100%" v-model="ShopFrom.slump" placeholder="请选择">
                                        <el-option
                                        v-for="item in slump"
                                        :key="item.code"
                                        :label="item.name"
                                        :value="item.code">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item v-else label="塌落度：" prop="">
                                    <span>{{ShopFrom.slump}}</span>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row v-if="industryType == 1" :gutter="24">
                            <el-col :span="12">
                                <el-form-item v-if="ShopFrom.isActive  == false" label="抗渗等级：" prop="impermeability">
                                    <el-select style="width:100%" v-model="ShopFrom.impermeability" placeholder="请选择">
                                        <el-option
                                        v-for="item in impermeabilityGrade"
                                        :key="item.code"
                                        :label="item.name"
                                        :value="item.code">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item v-else label="抗渗等级：" prop="">
                                    <span>{{ShopFrom.impermeability}}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item v-if="ShopFrom.isActive  == false" label="单位：" prop="unit">
                                    <el-select style="width:100%" v-model="ShopFrom.unit" placeholder="请选择">
                                        <el-option
                                        v-for="item in unit1"
                                        :key="item.code"
                                        :label="item.name"
                                        :value="item.name">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item v-else label="单位：" prop="">
                                    <span>{{ShopFrom.unit}}</span>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <!-- 公用数据 -->
                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-form-item v-if="ShopFrom.isActive  == false" label="标准单价(元)：" prop="unitPrice">
                                    <el-input v-model="ShopFrom.unitPrice" v-input-limit="2" placeholder="请输入标准单价(元)"></el-input>
                                </el-form-item>
                                <el-form-item v-else label="标准单价(元)：" prop="">
                                    <span>{{ShopFrom.unitPrice}}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item v-if="ShopFrom.isActive  == false" label="结算单价(元)：" prop="discountPrice">
                                    <el-input v-model="ShopFrom.discountPrice" v-input-limit="2" placeholder="请输入结算单价(元)"></el-input>
                                </el-form-item>
                                <el-form-item v-else label="结算单价(元)：" prop="">
                                    <span>{{ShopFrom.discountPrice}}</span>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-form-item v-if="ShopFrom.isActive == false" label="运费单价(元)：" prop="freightPrice">
                                    <el-input v-model="ShopFrom.freightPrice" v-input-limit="2" placeholder="请输入运费单价(元)"></el-input>
                                </el-form-item>
                                <el-form-item v-else label="运费单价(元)：" prop="">
                                    <span>{{ShopFrom.freightPrice}}</span>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-form-item label="">
                            <el-button v-if="ShopButtonStatu == 'modify' & ShopFrom.isActive == false & $hasPermission(['GoodsManagement_audit'])" size="small" type="primary" @click="Audit()">审 核</el-button>
                            <el-button v-if="ShopButtonStatu == 'modify' & ShopFrom.isActive == true & $hasPermission(['GoodsManagement_audit'])" size="small" type="primary" @click="CancelAudit()">取消审核</el-button>
                            <el-button v-if="ShopButtonStatu == 'modify' & ShopFrom.isActive == false & $hasPermission(['GoodsManagement_edit'])" size="small" type="primary" @click="SaveUser('ShopFrom')">保 存</el-button>
                            <el-button v-if="ShopButtonStatu == 'add' & $hasPermission(['GoodsManagement_add'])" size="small" type="primary" @click="SaveUser('ShopFrom')">保 存</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-card>
            <el-card v-else style="width:100%;height:100%">
                
            </el-card>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
        
    },
    watch: {
        filterText(val) {
            this.$refs.tree.filter(val);
        }
    },
    data(){
        return{
            loading: false,
            Tree1loading: false,
            // 树形结构过滤
            filterText: '',
            // 当前企业的塌落度
            slump: [],
            // 当前企业的规格
            standards: [],
            // 当前企业的强度等级
            strengthGrade: [],
            // 当前企业的单位
            unit: [],
            // 当前企业的抗渗等级
            impermeabilityGrade: [],
            // 当前企业的业务类型
            business: [],
            // 当前企业的行业类型
            industry: [],
            // 商品树形结构数据
            TreeData: [{
                id: -1,
                name: '商品',
                children: []
            }],
            unit1: [{
                name: '立方',
                code: 'm³'
            }],
            defaultProps: {
                children: 'children',
                label: 'name'
            },
            // 用来判断是否要执行节点事件
            tree1: 0,
            // 用来判断商品是修改还是添加
            ShopButtonStatu: '',
            // 用来判断是否打开表单
            ShopFromStatu: false,
            // 用来判断是销售还是采购表单
            businessType: 0,
            // 用来判断打开哪个行业的添加表单
            industryType: 0,
            // 表单数据
            ShopFrom: {
                name: '',
                no: '',
                standards: '',
                unit: '',
                unitPrice: '',
                discountPrice: '',
                freightPrice: '',
                remarks: '',
                industryType: '',
                businessType: '',
                id: '',
                enterpriseId: '',
                isActive: false,
                slump: '',
                impermeability: '',
                StandardsCode: '',
                ImpermeabilityCode: ''
            },
            // 表单验证
            ShopFromRule: {
                name: [
                    { required: true , message: '请输入商品名称' , trigger: 'blur' },
                ],
                unitPrice: [
                    { required: true , message: '请输入标准单价' , trigger: 'blur' },
                ],
                discountPrice: [
                    { required: true , message: '请输入结算单价' , trigger: 'blur' },
                ],
                freightPrice: [
                    { required: true , message: '请输入运费单价' , trigger: 'blur' },
                ],
                standards: [
                    { required: true , message: '请选择规格或强度等级' , trigger: 'blur' },
                ],
                unit: [
                    { required: true , message: '请选择单位' , trigger: 'blur' },
                ],
                slump: [
                    { required: true , message: '请选择塌落度' , trigger: 'blur' },
                ],
            },
        }
    },
    methods:{
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        // 点击添加点击事件
        append(data){
            // 清空数据
            this.ShopFrom = {
                name: '',
                no: '',
                standards: '',
                unit: '',
                unitPrice: '',
                discountPrice: '',
                freightPrice: '',
                remarks: '',
                industryType: '',
                businessType: '',
                id: '',
                enterpriseId: '',
                isActive: '',
                slump: '',
                impermeability: '',
                StandardsCode: '',
                ImpermeabilityCode: ''
            }
            // 打开表单el-card
            this.ShopFromStatu = true
            // 如果点击添加事件 this.tree2 = 1 下面节点事件就不会进行处理
            this.tree1 = 1
            // 添加打开表单
            this.ShopButtonStatu = 'add'
            this.businessType = data.pid
            this.industryType = data.value
            this.ShopFrom.businessType = data.pid
            this.ShopFrom.industryType = data.value
        },
        // 当节点被点击时候执行的事件
        handleNodeClick(data,node,obj){
            if(this.tree1 == 1){
                this.tree1 = 0
            }else{
                if(data.ji == '四级'){
                    // 打开加载动画
                    this.Tree1loading = true
                    // 设置表单状态
                    this.ShopButtonStatu = 'modify'
                    // 设置显示行业类型表单
                    this.industryType = data.industryType
                    // 打开表单
                    this.ShopFromStatu = true
                    // 通过点击节点获取数据
                    this.ShopFrom.name = data.Isname
                    this.ShopFrom.no = data.no
                    this.ShopFrom.standards = data.standards
                    this.ShopFrom.unit = data.unit
                    this.ShopFrom.unitPrice = data.unitPrice
                    this.ShopFrom.discountPrice = data.discountPrice
                    this.ShopFrom.freightPrice = data.freightPrice
                    this.ShopFrom.remarks = data.remarks
                    this.ShopFrom.industryType = data.industryType
                    this.ShopFrom.businessType = data.businessType
                    this.ShopFrom.id = data.id
                    this.ShopFrom.enterpriseId = data.enterpriseId
                    this.ShopFrom.isActive = data.isActive
                    this.ShopFrom.slump = data.slump
                    this.ShopFrom.impermeability = data.impermeability
                    this.ShopFrom.StandardsCode = data.StandardsCode
                    this.ShopFrom.ImpermeabilityCode = data.ImpermeabilityCode
                    // 关闭加载动画
                    this.Tree1loading = false
                }
            }
        },
        // 点击删除按钮点击事件
        remove(node, data){
            // 删除创建的角色名称
            if(node.id !== -1){
                this.$confirm('此操作将永久删除该商品信息, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    var idarr = []
                    idarr.push(node.data.id)
                    this.$http.post(this.$SysProduct_DeleteSysProductById,
                        idarr
                    ).then(response => {
                        if(response.data.isSuccess == true){
                            this.ShopFromStatu = false
                            this.$message.success('删除成功')
                            this.queryData()
                        }else{
                            this.$message.warning(response.data.message)
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
                });
            }
        },
        // 设置前两级数据
        queryData(){
            if(!this.$hasPermission(['GoodsManagement_query'])){
                return
            }
            // 打开整个页面的加载动画
            this.loading = true
            this.TreeData = [{
                id: -1,
                name: '商品',
                children: []
            }]
            // 树形结构前两级处理
            this.business.forEach(item => {
                this.TreeData[0].children.push({
                    code: item.code,
                    name: item.name,
                    value: item.value,
                    id: item.value,
                    // 设置他是第几级
                    ji: '二级',
                    children: []
                })
            })
            this.industry.forEach(arr => {
                for(var i=0 ; i<this.TreeData[0].children.length; i++){
                    this.TreeData[0].children[i].children.push({
                        code: arr.code,
                        name: arr.name,
                        value: arr.value,
                        id: arr.value,
                        // 设置他是第几级
                        ji: '三级',
                        // 获取他的父级id
                        pid: this.TreeData[0].children[i].id,
                        children: []
                    })
                }
            })
            // 查询第三级商品数据
            this.$http.get(this.$SysProduct_GetTreeList).then(response => {
                var arr = []
                var data = []
                // 通过两个循环获取商品数据
                response.data.data.forEach(item => {
                    item.industry.forEach(items => {
                        arr.push(items)
                    })
                })
                arr.forEach(index => {
                    index.proList.forEach(indexs => {
                        data.push(indexs)
                    })
                })
                // 循坏商品数据,添加进树形结构的数组里面去
                data.forEach(shopItem => {
                    // 树形结构二级循环 判断data数据是销售还是采购数据
                    this.TreeData[0].children.forEach(erji => {
                        if(shopItem.businessType == erji.value){
                            // 树形结构三级循环 判断data数据是矿石还是混凝土数据
                            erji.children.forEach(sanji => {
                                if(shopItem.industryType == sanji.value){
                                    // 判断是混凝土还是矿山数据 因为矿山商品和混凝土显示商品名字不同
                                    if(shopItem.industryType == 1){
                                        sanji.children.push({
                                            active: shopItem.active,
                                            businessType: Number(shopItem.businessType),
                                            creationTime: shopItem.creationTime,
                                            discountPrice: Number(shopItem.discountPrice),
                                            enterpriseId: Number(shopItem.enterpriseId),
                                            freightPrice: Number(shopItem.freightPrice),
                                            id: shopItem.id,
                                            impermeability: shopItem.impermeability,
                                            industryType: Number(shopItem.industryType),
                                            isActive: shopItem.isActive,
                                            name: shopItem.name+shopItem.standards+shopItem.impermeability,
                                            Isname: shopItem.name,
                                            no: shopItem.no,
                                            remarks: shopItem.remarks,
                                            slump: shopItem.slump,
                                            standards: shopItem.standards,
                                            unit: shopItem.unit,
                                            unitPrice: Number(shopItem.unitPrice),
                                            StandardsCode: shopItem.StandardsCode,
                                            ImpermeabilityCode: shopItem.ImpermeabilityCode,
                                            ji: '四级'
                                        })
                                    }else{
                                        sanji.children.push({
                                            active: shopItem.active,
                                            businessType: Number(shopItem.businessType),
                                            creationTime: shopItem.creationTime,
                                            discountPrice: Number(shopItem.discountPrice),
                                            enterpriseId: Number(shopItem.enterpriseId),
                                            freightPrice: Number(shopItem.freightPrice),
                                            id: shopItem.id,
                                            impermeability: shopItem.impermeability,
                                            industryType: Number(shopItem.industryType),
                                            isActive: shopItem.isActive,
                                            name: shopItem.name,
                                            Isname: shopItem.name,
                                            no: shopItem.no,
                                            remarks: shopItem.remarks,
                                            slump: shopItem.slump,
                                            standards: shopItem.standards,
                                            unit: shopItem.unit,
                                            unitPrice: Number(shopItem.unitPrice),
                                            StandardsCode: shopItem.StandardsCode,
                                            ImpermeabilityCode: shopItem.ImpermeabilityCode,
                                            ji: '四级'
                                        })
                                    }
                                }
                            })
                        }
                    })
                })
                // 关闭整个页面的加载动画
                this.loading = false
            })
        },
        // 添加和修改点击保存触发事件
        SaveUser(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // 打开加载动画
                    this.Tree1loading = true
                    // 获取商品单位规格
                    if(this.industryType == 0){
                        this.unit.forEach(item => {
                            if(this.ShopFrom.unit == item.code){
                                this.ShopFrom.unit = item.name
                            }
                        })
                        this.standards.forEach(items => {
                            if(this.ShopFrom.standards == items.code){
                                this.ShopFrom.standards = items.name
                                this.ShopFrom.StandardsCode = items.code
                            }
                        })
                    }
                    this.impermeabilityGrade.forEach(item => {
                        if(this.ShopFrom.impermeability == item.impermeability){
                            this.ShopFrom.impermeability = item.name
                            this.ShopFrom.ImpermeabilityCode = item.code
                        }
                    })
                    // 混凝土获取商品规格单位
                    if(this.industryType == 1){
                        this.strengthGrade.forEach(itema => {
                            if(this.ShopFrom.standards == itema.code){
                                this.ShopFrom.StandardsCode = itema.code
                            }
                        })
                        this.impermeabilityGrade.forEach(itemb => {
                            if(this.ShopFrom.impermeability == itemb.code){
                                this.ShopFrom.impermeability = itemb.name
                                this.ShopFrom.ImpermeabilityCode = itemb.code
                            }
                        })
                    }
                    // 判断是添加还是修改
                    if(this.ShopButtonStatu == 'add'){
                        this.$http.post(this.$SysProduct_CreateSysProduct,{
                            name: this.ShopFrom.name,
                            no: this.ShopFrom.no,
                            standards: this.ShopFrom.standards,
                            unit: this.ShopFrom.unit,
                            unitPrice: Number(this.ShopFrom.unitPrice),
                            discountPrice: Number(this.ShopFrom.discountPrice),
                            freightPrice: Number(this.ShopFrom.freightPrice),
                            remarks: this.ShopFrom.remarks,
                            industryType: Number(this.ShopFrom.industryType),
                            businessType: Number(this.ShopFrom.businessType),
                            slump: this.ShopFrom.slump,
                            impermeability: this.ShopFrom.impermeability,
                            StandardsCode: this.ShopFrom.StandardsCode,
                            ImpermeabilityCode: this.ShopFrom.ImpermeabilityCode,
                        }).then(response => {
                            if(response.data.isSuccess == true){
                                this.queryData()
                                this.$message.success('添加成功')
                                this.ShopFrom = {
                                    name: '',
                                    no: '',
                                    standards: '',
                                    unit: '',
                                    unitPrice: '',
                                    discountPrice: '',
                                    freightPrice: '',
                                    remarks: '',
                                    industryType: '',
                                    businessType: '',
                                    id: '',
                                    enterpriseId: '',
                                    isActive: '',
                                    slump: '',
                                    impermeability: '',
                                    StandardsCode: '',
                                    ImpermeabilityCode: ''
                                }
                            }else{
                                this.$message.warning(response.data.message)
                            }
                            // 关闭加载动画
                            this.Tree1loading = false
                        })
                    }else if(this.ShopButtonStatu == 'modify'){
                        this.$http.put(this.$SysProduct_UpdateSysProduct,{
                            name: this.ShopFrom.name,
                            no: this.ShopFrom.no,
                            standards: this.ShopFrom.standards,
                            unit: this.ShopFrom.unit,
                            unitPrice: Number(this.ShopFrom.unitPrice),
                            discountPrice: Number(this.ShopFrom.discountPrice),
                            freightPrice: Number(this.ShopFrom.freightPrice),
                            remarks: this.ShopFrom.remarks,
                            industryType: Number(this.ShopFrom.industryType),
                            businessType: Number(this.ShopFrom.businessType),
                            slump: this.ShopFrom.slump,
                            impermeability: this.ShopFrom.impermeability,
                            isActive: this.ShopFrom.isActive,
                            id: this.ShopFrom.id,
                            enterpriseId: Number(this.ShopFrom.enterpriseId),
                            StandardsCode: this.ShopFrom.StandardsCode,
                            ImpermeabilityCode: this.ShopFrom.ImpermeabilityCode,
                        }).then(response => {
                            if(response.data.isSuccess == true){
                                this.queryData()
                                this.$message.success('修改成功')
                            }else{
                                this.$message.warning(response.data.message)
                            }
                        })
                        // 关闭加载动画
                        this.Tree1loading = false
                    }
                } else {
                    return false;
                }
            });
        },
        // 审核
        Audit(){
            this.$confirm('此操作将审核该商品, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysProduct_AduitSysProduct,{
                    id : this.ShopFrom.id,
                    isActive : true
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('审核成功')
                        this.queryData()
                        // 关闭表单
                        this.ShopFromStatu = false
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        // 取消审核
        CancelAudit(){
            this.$confirm('此操作将取消审核该商品, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysProduct_AduitSysProduct,{
                    id : this.ShopFrom.id,
                    isActive : false
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('取消审核成功')
                        this.queryData()
                        // 关闭表单
                        this.ShopFromStatu = false
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
    },
    mounted(){
        // 取出商品相关的树形结构的值
        // 存储当前企业的塌落度
        this.slump = JSON.parse(window.sessionStorage.getItem('slump'))
        // 存储当前企业的规格
        this.standards = JSON.parse(window.sessionStorage.getItem('standards'))
        // 存储当前企业的强度等级
        this.strengthGrade = JSON.parse(window.sessionStorage.getItem('strengthGrade'))
        // 存储当前企业的单位
        this.unit = JSON.parse(window.sessionStorage.getItem('unit'))
        // 存储当前企业的抗渗等级
        this.impermeabilityGrade = JSON.parse(window.sessionStorage.getItem('impermeabilityGrade'))
        // 存储当前企业的业务类型
        this.business = JSON.parse(window.sessionStorage.getItem('business'))
        // 存储当前企业的行业类型
        this.industry = JSON.parse(window.sessionStorage.getItem('industry'))
        this.queryData()
    }
}
</script>
<style scoped>
.body{
    padding: 15px;
    height: 96%;
    width: 98%;
    background-color:  #fdfdfd;
    box-shadow: 2px 1px 1px  #d8d8d8;
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex;
}
/deep/.el-dialog__header {
    padding: 13px 20px 13px;
    background-color: skyblue;
}
.xiangqing{
    color:#67C23A
}
.bianji{
    color: #E6A23C;
}
.shanchu{
    color: #F56C6C ;
}
</style>