<template>
    <div class="body" v-loading="loading" element-loading-text="拼命加载中" element-loading-background="rgb(213 213 213 / 57%)">
        <div class="header">
        <!-- <el-card style="height:100%"> -->
            <!-- 充值支付 -->
            <el-form style="width:100%" ref="formline" :inline="true" :model="formline">
                <el-form-item label="标题: ">
                    <el-input clearable v-model="formline.name" placeholder="请输入标题"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button v-if="$hasPermission(['OnlineService_query'])" @click="queryWorkOrder()" type="primary">查询</el-button>
                    <el-button v-if="$hasPermission(['OnlineService_add'])" @click="add()" type="primary">新增</el-button>
                </el-form-item>
                <div style="float:right">
                    <el-form-item>
                        <el-button v-if="$hasPermission(['OnlineService_add'])" icon="el-icon-download" type="primary"><el-link style="color:white" target="_blank" href="./word/智慧云商操作手册.docx"><span>帮助文档</span></el-link></el-button>
                    </el-form-item>
                </div>
            </el-form>
        <!-- </el-card> -->
        </div>
        <div class="table" style="height:88%;">
        <!-- <el-card style="height:100%"> -->
            <el-table
                :header-cell-style="headClass"
                :cell-style="rowClass"
                :height="tableHeight"
                :data="tableData"
                border
                style="width: 100%">
                <el-table-column
                prop="title"
                width=""
                :show-overflow-tooltip="true"
                label="标题">
                </el-table-column>
                <el-table-column
                prop="info"
                label="内容"
                :show-overflow-tooltip="true"
                width="">
                </el-table-column>
                <el-table-column
                width=""
                prop="info" :show-overflow-tooltip="true"
                label="反馈内容">
                </el-table-column>
                <el-table-column
                width=""
                :formatter="status"
                prop="isActive" :show-overflow-tooltip="true"
                label="状态">
                </el-table-column>
                <el-table-column
                prop="creationTime"
                width=""
                label="创建时间">
                </el-table-column>
                <el-table-column
                fixed="right"
                label="操作"
                width="180">
                    <template slot-scope="scope">
                    <el-button v-if="$hasPermission(['OnlineService_edit']) & scope.row.isActive == false" @click="editStorehouse(scope.row)" class="bianji" icon="el-icon-edit" type="text" size="small"><span>编辑</span></el-button>
                    <el-button v-if="$hasPermission(['OnlineService_delete']) & scope.row.isActive == false" @click="deletes(scope.row)" class="shanchu" icon="el-icon-delete" type="text" size="small"><span>删除</span></el-button>
                    <el-button v-if="$hasPermission(['OnlineService_details'])" @click="query(scope.row)" class="xiangqing" icon="el-icon-share" type="text" size="small"><span>详情</span></el-button>
                    </template>
                </el-table-column>  
            </el-table>
            <div style="margin:0%;float:right">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10, 20]"
                :page-size="size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
              </el-pagination>
            </div>
        <!-- </el-card> -->
        </div>
        <div class="dialog">
            <!-- 仓库管理表单 -->
            <el-dialog
              :title="title"
              :visible.sync="StorehouseDialog"
              :before-close="handleClose"
                :show-close="false"
              width="40%"
              v-loading="dialogLoading"
              >
                <el-form style="width:90%" ref="WorkOrderForm" :model="WorkOrderForm" class="demo-form-inline">
                    <el-form-item label-width="80px" prop="" label="标题: ">
                        <el-input clearable v-model="WorkOrderForm.title" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label-width="80px" prop="" label="内容: ">
                        <el-input type="textarea" autosize clearable v-model="WorkOrderForm.info" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label-width="80px" prop="" label="上传图片: ">
                        <div style="display:flex">
                            <div v-show="imgList.length != 0">
                                <el-image
                                    style="width: 148px; height: 148px;border-radius:5px;"
                                    v-for="url in imgList" 
                                    :key="url" 
                                    :src="url" 
                                    lazy>
                                </el-image>
                            </div>
                            <el-upload
                                ref="upload"
                                :action="this.$UserManager_UploadLargeFile"
                                multiple
                                :headers="headers"
                                list-type="picture-card"
                                :limit="3"
                                :on-success="handleAvatarSuccess"
                                :on-preview="handlePictureCardPreview"
                                :on-remove="handleRemove">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="dialogImageUrl " alt="">
                            </el-dialog>
                        </div>
                    </el-form-item>
                </el-form>
              <span slot="footer" class="dialog-footer">
                <el-button @click="resetStorehouse('WorkOrderForm')">取 消</el-button>
                <el-button type="primary" @click="SureStorehouse('WorkOrderForm')">确 定</el-button>
              </span>
            </el-dialog>
            <!-- 查看详情 -->
            <el-dialog
              title="查看详情"
              :visible.sync="queryDialog"
              width="40%"
              >
                <el-form style="width:90%" ref="WorkOrderForm" :model="WorkOrderForm" class="demo-form-inline">
                    <el-form-item label-width="80px" prop="name" label="标题: ">
                        <span>{{WorkOrderForm.title}}</span>
                    </el-form-item>
                    <el-form-item label-width="80px" prop="code" label="内容: ">
                        <span>{{WorkOrderForm.info}}</span>
                    </el-form-item>
                    <el-form-item label-width="80px" prop="address" label="上传文件: ">
                        <el-image
                            v-show="dialogImageUrl !== ''"
                            style="width: 148px; height: 148px;border-radius:5px;"
                            :src="dialogImageUrl"
                            :preview-src-list="imgList">
                        </el-image>
                    </el-form-item>
                    <el-form-item label-width="80px" prop="code" label="状态: ">
                        <span>{{WorkOrderForm.isActive}}</span>
                    </el-form-item>
                    <el-form-item label-width="80px" prop="code" label="反馈: ">
                        <span>{{WorkOrderForm.feedBack}}</span>
                    </el-form-item>
                </el-form>
              <span slot="footer" class="dialog-footer">
                <!-- <el-button @click="queryDialog = false">取 消</el-button> -->
                <el-button type="" @click="queryDialog = false">关 闭</el-button>
              </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
        headers() {
          return{
            "Authorization": 'Bearer ' + window.sessionStorage.getItem('token') // 直接从本地获取token就行
          }
        }
    },
    created() {
        var height = document.documentElement.clientHeight
        this.tableHeight = (height*0.67)+'px';
    },
    data(){
        return{
            loading: false,
            tableHeight: 0,
            size: 10,
            page: 1,
            total: 0,
            title: '',
            formline:{
                name: ''
            },
            // 表格数据
            tableData: [],
            // 新增表单
            WorkOrderForm: {
                fileList: '',
                ID:'',
                title: '',
                info: '',
                isActive: '',
                feedBack: ''
            },
            StorehouseDialog: false,
            // 上传
            imgList: [],
            dialogImageUrl: '',
            dialogVisible: false,
            // 查看详情
            queryDialog: false,
            dialogLoading: false,
        }
    },
    methods:{
        handleClose(){

        },
        // 上传图片
        handleRemove(file, dialogImageUrl ) {
            console.log(file, dialogImageUrl );
        },
        handleAvatarSuccess(response, file, fileList) {
            this.dialogLoading = true
            this.imgList = []
            this.WorkOrderForm.fileList = response.data
            this.imgList.push(response.data)
            this.dialogImageUrl = response.data
            this.$refs.upload.clearFiles();
            this.dialogLoading = false
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl  = file.url;
            this.dialogVisible = true;
        },
        // 分页
        // 分页功能
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.size = val
            this.queryWorkOrder()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.page = val
            this.queryWorkOrder()
        },
        // 表头 列 居中
        headClass() {
            return "text-align:center;background-color: #c8e0f391;color:#E6A23C";
        },
        rowClass() {
            return "text-align:center";
        },
        queryWorkOrder(){
            if(!this.$hasPermission(['OnlineService_query'])){
                return
            }
            this.loading = true
            this.$http.post(this.$WorkOrderManager_PostWorkOrderList, {
                currentPage: this.page,
                pageSize: this.size,
                filter: {
                    queryString: this.formline.name
                }
            }).then((response) => {
                this.tableData = response.data.data.list
                this.total = response.data.data.total
                this.loading = false
            })
        },
        status(row , index){
            if(row.isActive == true){
                return '已阅读'
            }else{
                return '未阅读'
            }
        },
        // 新增工单
        add(){
            this.title = '新增在线服务'
            this.WorkOrderForm = {
                fileList: '',
                ID:'',
                title: '',
                info: '',
                isActive: '',
                feedBack: ''
            },
            this.dialogImageUrl = ''
            this.imgList = []
            this.StorehouseDialog = true
        },
        // 工单管理取消重置表单
        resetStorehouse(formName){
            this.StorehouseDialog = false
            this.$refs[formName].resetFields()
            this.imgList = []
        },
        // 工单管理确定按钮
        SureStorehouse(formName){
            this.$refs[formName].validate((valid) => {
                if(valid){
                    if(this.title == '新增在线服务'){
                        this.$http.post(this.$WorkOrderManager_PostWorkOrder , {
                            title: this.WorkOrderForm.title,
                            info: this.WorkOrderForm.info,
                            fileUrl: this.WorkOrderForm.fileList
                        }).then(response => {
                            this.$message.success('添加成功')
                            this.StorehouseDialog = false
                            this.imgList = []
                            this.queryWorkOrder()
                        })
                    }else{
                        this.$http.put(this.$WorkOrderManager_PutWorkOrder , {
                            id: this.WorkOrderForm.ID,
                            title: this.WorkOrderForm.title,
                            info: this.WorkOrderForm.info,
                            fileUrl: this.WorkOrderForm.fileList
                        }).then(response => {
                            this.$message.success('修改成功')
                            this.StorehouseDialog = false
                            this.imgList = []
                            this.queryWorkOrder()
                        })
                    }
                }else{
                    this.$message('还有信息未填，请填写完整信息')
                }
            })
        },
        // 工单管理编辑按钮
        editStorehouse(row){
            this.imgList = []
            this.title = '在线服务'
            this.StorehouseDialog = true
            this.WorkOrderForm.ID = row.id
            this.WorkOrderForm.title = row.title
            this.WorkOrderForm.info = row.info
            this.WorkOrderForm.fileList = row.fileUrl
            this.dialogImageUrl = row.fileUrl,
            this.WorkOrderForm.feedBack = row.feedBack
            this.imgList.push(row.fileUrl)
        },
        // 工单管理查看详情事件
        query(row){
            this.imgList = []
            this.queryDialog = true
            this.$http.get(this.$WorkOrderManager_GetWorkOrder+ '?id=' + row.id)
            .then((response) => {
                
            })
            this.WorkOrderForm.ID = row.id
            this.WorkOrderForm.title = row.title
            this.WorkOrderForm.info = row.info
            this.dialogImageUrl = row.fileUrl
            this.imgList.push(row.fileUrl)
            this.WorkOrderForm.feedBack = row.feedBack
            if(row.isActive = true){
                this.WorkOrderForm.isActive = '已阅读'
                return '已阅读'
            }else{
                this.WorkOrderForm.isActive = '未阅读'
                return '未阅读'
            }
        },
        // 删除
        deletes(row){
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var idsList = []
                idsList.push(Number(row.id))
                this.$http.delete(this.$WorkOrderManager_DeleteWorkOrder+ '?id=' + row.id)
                .then((response) => {
                    this.$message({
                    type: 'success',
                    message: '删除成功'
                    })
                    this.queryWorkOrder()
                }).catch((error) => {
                    this.$message({
                    type: 'error',
                    message: '删除失败'
                    })
                })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    })
                })
        }
    },
    mounted(){
        this.queryWorkOrder()
    }
}
</script>
<style scoped>
.body{
    padding: 15px;
    height: 96%;
    width: 98%;
    background-color:  #fdfdfd;
    box-shadow: 2px 1px 1px  #d8d8d8;
    border: 1px solid #ddd;
    border-radius: 5px;
}
/* .xiangqing{
    color:#67C23A
}
.bianji{
    color: #E6A23C;
}
.shanchu{
    color: #F56C6C ;
} */
</style>