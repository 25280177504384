<template>
  <el-container class="home-container">
    <!-- header区域 -->
    <el-header>
      <div style="width:24%">
        <h3 style="display:flex;margin-left:22px">智慧云商平台
          <!-- -<h3 style="font-size:11px;line-height:10px">矿山版</h3> -->
          </h3>
        <div class="toggle-button" @click="toggleCollapse">
             <i :class="isCollapse? 'el-icon-s-unfold' : 'el-icon-s-fold'  "></i>
          </div>
      </div>
      <div style="float:right;">
        <div class="demo-basic--circle" style="margin-left:-2%">
          <div class="block"><el-avatar shape="square" :size="35" :src="squareUrl"></el-avatar></div>
        </div>
        <div style="margin-left:-1%">
          <h3 style="font-size:15px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
            {{enterpriseName}}
          </h3>
        </div>
        <el-dropdown style="margin-left:2%" @command="Myself">
          <span class="el-dropdown-link">
            <i class="el-icon-s-custom geren"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-if="restPass == true" command="RestPass">修改密码</el-dropdown-item>
            <el-dropdown-item command="loginOut">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-menu
          style="margin-left:1%;"
          mode="horizontal"
          background-color="#c8e0f3"
          text-color="#303133"
          active-text-color="#303133"
          router
        >
          <el-submenu index="2">
            <template slot="title">设置</template>
            <el-menu-item 
              :index="subItem.path"
              v-for="subItem in myWork"
              :key="subItem.id"
              @click="saveNavState(subItem.path)"
            >
              <template slot="title">
                <!-- 图标 -->
                <i :class="subItem.icon"></i>
                <!-- 文本 -->
                <span>{{ subItem.label }}</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
    </el-header>
    <!-- 下部区域 内部分为 左侧和中间区域 -->
    <el-container style="overflow-y:visible;">
      <div>
         <el-dialog
          title="修改密码"
          :visible.sync="restPassdialog"
          width="40%">
            <el-form style="width:88%" :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
              <!-- <div style="width:80%"> -->
                  <el-form-item label="密码" prop="pass">
                      <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item label="确认密码" prop="checkPass">
                      <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
                  </el-form-item>
              <!-- </div> -->
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="resetForm('ruleForm')">取 消</el-button>
            <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
          </span>
        </el-dialog>
      </div>
      <!-- 下部区域 左侧区域 64px -->
      <el-aside class="" :width="isCollapse ? '64px' : '200px'">
        <el-menu
          class="el-menu-vertical"
          :unique-opened="true"
          :collapse="isCollapse"
          :collapse-transition="false"
          :router="true"
          :default-active="this.$route.path"
        >
        <!-- activePath -->
          <el-menu-item
            :index="item.path + ''"
            v-for="item in this.menuListNoChild"
            :key="item.id"
          >
            <i :class="item.icon"></i>
            <span slot="title">{{ item.label }}</span>
          </el-menu-item>

          <!-- 一级菜单 -->
          <el-submenu
            :index="item.id + ''"
            v-for="item in this.menuListHaveChild"
            :key="item.id"
          >
            <!-- 一级菜单的模板区域 -->
            <template slot="title">
              <!-- 图标 -->
              <i :class="item.icon"></i>
              <!-- 文本 -->
              <span>{{ item.label }}</span>
            </template>

            <!-- 二级菜单 -->
            <el-menu-item
              :index="subItem.path"
              v-for="subItem in item.children"
              :key="subItem.id"
              @click="saveNavState(subItem.path)"
            >
              <template slot="title">
                <!-- 图标 -->
                <i :class="subItem.icon"></i>
                <!-- 文本 -->
                <span>{{ subItem.label }}</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <!-- 下部区域的中间区域 -->
      <el-main style="min-height: 725px;">
        <div class="tag" style="">
          <div style="margin-left: 1%;width:50%">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <!-- <el-breadcrumb-item style="" :to="{ path: '/home' }">首页</el-breadcrumb-item> -->
              <el-breadcrumb-item>{{Amenu}}</el-breadcrumb-item>
              <el-breadcrumb-item v-if="BmenuStatu == true">{{Bmenu}}</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div v-if="aalength == 0">

          </div>
          <div v-else style="width:45%;line-height:35px;display:flex;color: red;">
            <span>通知：</span>
            <div class="message">
                <span class="sapn_message">{{message}}</span>
              <!--  -->
            </div>
          </div>
        </div>
        <div style="height:93%;margin-top:0.3%;width:100%;">
          <router-view class="router_view"></router-view>
        </div>
      <div class="footer">
        <span style="font-size:10px">COPYRIGHT 长沙惠工智能科技股份有限公司All Rightreserved. 湘ICP备18002477号</span>
      </div>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import md5 from 'js-md5';
  export default {
    data() {
      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.ruleForm.checkPass !== '') {
            this.$refs.ruleForm.validateField('checkPass');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.pass) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        restPassdialog: false,//修改密码弹框
        isCollapse: false,
        menuList: [], //菜单数据
        menuListNoChild: [], //菜单数据，没有子节点
        menuListHaveChild: [], //菜单数据，有子节点
        myWork: [],
        // 被激活的链接地址
        activePath: '',
        // 是否折叠
        isCollapse: false,
        path: '',
        ruleForm: {
          pass: '',
          checkPass: '',
        //   age: ''
        },
        rules: {
          pass: [
            { validator: validatePass, trigger: 'blur' },
             {
            min: 6,
            max: 15,
            message: '长度在 6 到 15个字符',
            trigger: 'blur'
          }
          ],
          checkPass: [
            { validator: validatePass2, trigger: 'blur' }
          ],
        },
        // 面包屑切换
        Amenu: '',
        BmenuStatu: false,
        Bmenu: '',
        // 企业名称
        enterpriseName: '',
        // 修改密码
        restPass: false,
        // 头像logo
        squareUrl: '',
        // 公告消息
        message: '',
        aa: [],
        aalength: 0,
        // 设置一个定时器
        timer: null,
        index: -1
      }
    },
    created() {
      this.enterpriseName = window.sessionStorage.getItem('enterpriseName')
      this.menuList = this.$getMenu()
      var data = []
      data = _.cloneDeep(this.menuList).filter(x => x.children == undefined)
      // console.log(data)
      data.forEach(item => {
        if(item.hidden == false){
          this.menuListNoChild.push(item)
        }
      });
      var data1 = []
      data1 = _.cloneDeep(this.menuList).filter(x => x.children !== undefined)
      data1.forEach(item => {
        if(item.hidden == false){
          this.menuListHaveChild.push(item)
        }else{
          item.children.forEach(arr => {
            if(arr.hidden == false){
              if(arr.path == '/ChangePassword' & arr.hidden == false){
                this.restPass = true
              }else{
                this.myWork.push(arr)
              }
            }
          })
        }
      })
      this.activePath = window.sessionStorage.getItem('activePath')
    },
    mounted(){
      // this.Amenu = this.$route.name
      this.squareUrl = window.sessionStorage.getItem('logo')
      this.rest()
      // 获取当前公告的值
      this.aa = JSON.parse(window.sessionStorage.getItem('aa'))
      if(this.aa == null){
        this.aa = null
        this.aalength = 0
      }else{
        this.aalength = this.aa.length
        this.start()
      }
      // this.queryTip()
    },
    // 监听,当路由发生变化的时候执行
    watch:{
      $route(to,from){
        this.rest()
        // this.queryTip()
      }
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$http.post(this.$usermanager_putPassUrl,{
                password: md5(this.ruleForm.checkPass)
            }).then(response => {
                this.$message.success('修改成功')
                this.restPassdialog = false
            }).catch(error => {
                this.$message.error('修改失败')
            })
          } else {
            // console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.restPassdialog = false
        this.$refs[formName].resetFields();
      },
      // 个人
      Myself(command){
        if(command == 'loginOut'){
          this.loginOut()
        }else if(command == 'RestPass'){
          this.restPassdialog = true
        }
      },
      loginOut() {
        this.$confirm('确定退出登录吗, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '退出登录!'
          });
          window.sessionStorage.clear()
          this.$router.push('/login')
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
      },
      // 刷新面包屑
      rest(){
        this.path = this.$route.path
        var parentId
        this.menuList.forEach(item => {
          if (item.children == undefined) {
            if(item.path == this.path){
              this.BmenuStatu = false
              this.Amenu = item.label
            }
          }else{
            item.children.forEach(items => {
              if (items.path == this.path) {
                this.BmenuStatu = true
                var parentId = items.parentId
                if (item.id == parentId) {
                  this.Amenu = item.label
                }
                this.Bmenu = items.label
              }
            })
          }
        });
      },
      // 点击按钮，切换菜单的折叠与展开
      toggleCollapse() {
        this.isCollapse = !this.isCollapse
      },
      // 保存链接的激活状态
      saveNavState(activePath) {
        if (activePath == '/RestPass') {
          this.restPassdialog = true
        }else{
          window.sessionStorage.setItem('activePath', activePath)
          this.activePath = activePath
          // console.log("激活的菜单:"+activePath)
        }
      },
       //刷新token
      async refreshToken() {
        const tokenTime = new Date(window.sessionStorage.getItem('tokenTime')).getTime() //获取teken存储时间
        const nowDate = new Date().getTime() //当前时间
        var m = parseInt(Math.abs(nowDate - tokenTime) / 1000 / 60) //分钟
        if (m >=60) { //超过60分钟
          const token = window.sessionStorage.getItem('token')
          const {
            data: res
          } = await this.$http.post(this.$refreshTokenUrl+"?token="+token)
          if (res.code !== 200) {
            return this.$message.error('获取数据失败！' + res.message)
          }
          const refreshToken = res.data
          if (refreshToken !== null) { //成功获取

            window.sessionStorage.setItem('token', refreshToken)
            window.sessionStorage.setItem('tokenTime', new Date())

          }

        }
      },
      // 获取公告数据
      async queryTip(){
        if(!this.$hasPermission(['Welcome'])){
          return;
        }
        await this.$http.get(this.$SysOrder_GetTipsInfo).then(response => {
          // response.data.data.forEach(item => {
          //   this.message = item
          // })
          this.aa = response.data.data
        })
      },
      start() {
        // 将定时器名字赋值到变量中
        this.timer = setInterval(() => {
          if(this.index == this.aa.length){
            this.index = -1
          }
          this.index++
          // alert(this.index++)
          this.message = this.aa[this.index]
        }, 4800);
      },
      end() {
        clearInterval(this.timer);
        this.timer = null
        this.index = -1 // 这里最好清除一下，回归默认值
        // 众所周知，定时器返回一个随机整数，用于表示定时器的编号，后面通过名字可以取消这个定时器的执行。
      },
    },
    beforeDestroy() {
      // js提供的clearInterval方法用来清除定时器
      clearInterval(this.timer);
      this.index = -1
    },
  }

</script>

<style lang="less" scoped>
.el-menu-vertical:not(.el-menu--collapse) {
  width: 200px;
  background-color: #e8ecff;
  min-height: 400px;
}
.el-submenu.is-active {
  background-color: #dfe3ff;
  .el-submenu__title i{
    color: #409EFF;
  }
  .el-submenu__title * {
    vertical-align: middle;
    color: #409EFF;
  }
}
.home-container {
  height: 100%;
  width:100%;
  min-width: 1000px;
  min-height: 700px;
}
.item {
  margin-top: 0px;
  margin-right: 40px;
}
.tag{
  margin-top: -0.3%;
  width: 100%;
  height: 35px; 
  background-color: #d8e6f1;
  border-radius: 2px;
  box-shadow: 1px 2px rgba(216, 239, 255, 0.5);
}
.el-header {
  background-color: #c8e0f3;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  height: 20px;

  > div {
    display: flex;
    align-items: center;

    h3 {
      color: #303133;
      margin-left: 5px;
    }
  }
}

.el-aside {
  background-color: #e8ecff;
  height: 100%;
  overflow: hidden;
  .el-menu {
    border-right: none;
    background-color: #e8ecff;
  }
}
.footer{
  height: 3%;
  margin: 0 auto;
  text-align: center;
  bottom: 0px;
  width: 100%;
  // float: right;
  // background-color: red;
}
.el-main {
  background-color: #ffffff;
  padding: 10px;
}
.breadcrumb{
  width: 88%;
  margin: auto;
}
.xiaoxi{
  width: 12%;
}
.toggle-button {
  //background-color: #4a5064;
  font-size: 25px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
  margin-left: 15px;
}
</style>
<style scoped>
/deep/.el-submenu__title.is-active {
  color: #409EFF;
}
/deep/.el-breadcrumb{
  /* display:table-cell */
  line-height: 34px;
}
/* // 有二级菜单 鼠标放上去的颜色 */
/deep/.el-submenu__title:hover {
    background-color: #dae0e6;
}
/* // 二级菜单背景颜色 */
/deep/.el-submenu .el-menu-item{
  background-color: #eff1ff;
}
/deep/.el-menu:hover {
  background-color: #e8ecff;
}
/* // 没有二级菜单 鼠标放上去的背景颜色 */
/deep/.el-menu-item:hover{
  background-color: #d5d7e2 ;
}
.geren{
  background-color: #82c0ff;
  height: 25px;
  width: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 50px;
}
.geren:hover{
  background-color: #409efc;
}
/deep/.el-dialog__header {
  /* background: #e7eef7; */
}
/deep/.el-header {
  padding: 0 0px;
  box-sizing: border-box;
  flex-shrink: 0;
}
.el-avatar{
  background-color: #e0e5eb00;
}
.tag{
  display:flex;
  width: 100%;
}
.router_view{
  width:100%;
}
/* 公告通知 */
.message {
  width:90%;
  line-height:35px;
  overflow: hidden;
  position: relative;
  /* text-overflow:ellipsis;
  white-space:nowrap; */
}
.sapn_message{
  width:100%;
  list-style: none;
  color:#242424;
  font-size:14px;
  position: absolute;
  white-space: nowrap;
  animation:5s animation1 infinite linear;
}
.sapn_message:hover{
  animation-play-state: paused;
}
@keyframes animation1{
  0% {
    top: 0px;
  }100%{
    top: -30px;
  }
}
/deep/.el-dialog__header {
    padding: 13px 20px 13px;
    background-color: skyblue;
}
</style>
