<template>
    <div class="body" v-loading="loading" element-loading-text="拼命加载中" element-loading-background="rgb(213 213 213 / 57%)" style="">
      <el-card style="height:100%;width:100%">
          <!-- 修改企业信息 -->
          <!-- <span style="margin-left:8px;height:40px">企业信息</span> -->
        <el-form
          ref="personalForm"
          :model="personalForm"
          label-width="90px"
          class="demo-ruleForm"
          style="width:30%"
        >
          <el-form-item label="企业名称：">
              <span>{{personalForm.name}}</span>
              <!-- <el-input v-model=""></el-input> -->
          </el-form-item>
          <el-form-item label="企业税号：">
              <span>{{personalForm.taxNumber}}</span>
              <!-- <el-input v-model="personalForm.taxNumber"></el-input> -->
          </el-form-item>
          <el-form-item label="联系电话：">
              <span>{{personalForm.telephone}}</span>
              <!-- <el-input v-model="personalForm.telephone"></el-input> -->
          </el-form-item>
          <el-form-item label="开户银行：">
              <span>{{personalForm.bankName}}</span>
              <!-- <el-input v-model="personalForm.bankName"></el-input> -->
          </el-form-item>
          <el-form-item label="银行账号：">
              <span>{{personalForm.bankNo}}</span>
              <!-- <el-input v-model="personalForm.bankNo"></el-input> -->
          </el-form-item>
          <el-form-item label="企业地址：">
              <span>{{personalForm.address}}</span>
              <!-- <el-input v-model="personalForm.address"></el-input> -->
          </el-form-item>
          <el-form-item label="注册地址：">
              <span>{{personalForm.domicile}}</span>
              <!-- <el-input v-model="personalForm.address"></el-input> -->
          </el-form-item>
          <el-form-item label="logo：">
            <el-upload
              ref="upload"
              class="avatar-uploader"
              :action="$UserManager_UploadLargeFile"
              multiple
              :headers="headers"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove">
              <img v-if="logo" :src="logo" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
</template>
<script>
export default {
  // 上传图片链接带请求token
  computed: {
    headers() {
      return{
        "Authorization": 'Bearer ' + window.sessionStorage.getItem('token') // 直接从本地获取token就行
      }
    }
  },
  data() {
    return {
      loading: false,
      personalForm: {

      },
      logo: ''
    };
  },
  methods: {
    queryUser(){
      this.loading = true
      if(!this.$hasPermission(['CorporateInformation_query']))
      {
        return;
      }
      this.$http.get(this.$UserManager_GetUserOwn).then(response => {
        this.personalForm = response.data.data
        this.logo = response.data.data.logo
        this.loading = false
      })
    },
    handleAvatarSuccess(res, file) {
      this.logo = res.data
      if(this.logo == ''){
        this.$message.info('上传失败')
      }
      this.$http.post(this.$UserManager_UploadLogo,{
        logo: this.logo
      }).then(response => {
        if(response.data.isSuccess == true){
          this.queryUser()
          this.$message.success('上传成功')
        }else{
          this.$message.warning(response.data.message)
        }
      })
    },
    // 图片上传
    handleRemove(file, logo ) {
      console.log(file, logo );
    },
    handlePictureCardPreview(file) {
      this.logo  = file.url;
      this.dialogVisible = true;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    }
  },
  mounted () {
    this.queryUser()
  }
};
</script>
<style scoped>
.body1{
  width:60%;padding-top:2%;border-radius:5px;
  box-shadow: 2px 2px 10px 2px #cececf;
}
.body{
  padding: 15px;
  height: 96%;
  width: 98%;
  background-color:  #fdfdfd;
  box-shadow: 2px 1px 1px  #d8d8d8;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
/deep/.el-upload {
    background-color: #f8f8f8;
}
</style>