<template>
    <div class="dialog_body">
        <el-form style="width:100%;" size="small" ref="addform" :rules="addformRules" :model="addform" label-width="110px" class="demo-form-inline">
            <!-- 基础信息 合同名称 编码 类型 -->
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item label="合同名称:" prop="name">
                        <span >{{addform.name}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="合同编码:" prop="code">
                        <span >{{addform.code}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="业务类型:" prop="businessType" >
                        <span v-for="item in businessArr" :key="item.value" :label="item.value">
                            <span v-if="addform.businessType == item.value">{{item.name}}</span>
                        </span>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- 企业信息 选择企业 -->
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item label="选择企业:" prop="">
                        <span v-show="addform.businessType == 0">{{enterpriseData.purchaseName}}</span>
                        <span v-show="addform.businessType == 1">{{enterpriseData.enterpriseName}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label-width="" label="联系人名:">
                        <span v-show="addform.businessType == 0">{{enterpriseData.purchaseContacts}}</span>
                        <span v-show="addform.businessType == 1">{{enterpriseData.contacts}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label-width="" label="联系电话:">
                        <span v-show="addform.businessType == 0">{{enterpriseData.purchaseContactTel}}</span>
                        <span v-show="addform.businessType == 1">{{enterpriseData.contactTel}}</span>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item label-width="" label="企业税号:">
                        <span v-show="addform.businessType == 0">{{enterpriseData.purchaseTaxNumber}}</span>
                        <span v-show="addform.businessType == 1">{{enterpriseData.taxNumber}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label-width="" label="开户银行:">
                        <span v-show="addform.businessType == 0">{{enterpriseData.purchaseBankName}}</span>
                        <span v-show="addform.businessType == 1">{{enterpriseData.bankName}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label-width="" label="银行账户:">
                        <span v-show="addform.businessType == 0">{{enterpriseData.purchaseBankNo}}</span>
                        <span v-show="addform.businessType == 1">{{enterpriseData.bankNo}}</span>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item label-width="" label="注册地址:">
                        <span v-show="addform.businessType == 0">{{enterpriseData.purchaseDomicile}}</span>
                        <span v-show="addform.businessType == 1">{{enterpriseData.domicile}}</span>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- 矿石商品表格 -->
            <el-row v-if="addform.contractSands.length !== 0" :gutter="24">
                <el-col :span="22">
                    <el-form-item label-width="" label="矿石商品:">
                        <el-table
                            :header-cell-style="headClass"
                            :cell-style="rowClass"
                            :data="addform.contractSands"
                            height="250px"
                            border
                            style="width: 100%">
                            <el-table-column
                            prop="name"
                            :show-overflow-tooltip="true"
                            label="商品名称"
                            width="150">
                            </el-table-column>
                            <el-table-column
                            prop="standards"
                            :show-overflow-tooltip="true"
                            label="规格"
                            width="">
                            </el-table-column>
                            <el-table-column
                            prop="unit"
                            :show-overflow-tooltip="true"
                            label="单位"
                            width="">
                            </el-table-column>
                            <el-table-column
                            prop="planNumber"
                            :key="1"
                            label="计划数量"
                            width="">
                                <template slot-scope="scope">
                                    <el-input maxlength="7" v-model="scope.row.planNumber" v-input-limit="2" @blur="function1(scope.row)" placeholder="请输入计划数量"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column
                            prop="discountPrice"
                            :key="2"
                            :show-overflow-tooltip="true"
                            label="结算单价"
                            width="">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.discountPrice" v-input-limit="2" @blur="function2(scope.row)" placeholder="请输入结算单价"></el-input>
                            </template>
                            </el-table-column>
                            <el-table-column
                            prop="freightPrice"
                            :key="3"
                            :show-overflow-tooltip="true"
                            label="运费单价"
                            width="">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.freightPrice" v-input-limit="2" @blur="function3(scope.row)" placeholder="请输入运费单价"></el-input>
                            </template>
                            </el-table-column>
                            <el-table-column
                            :key="4"
                            label="生产/发货地址"
                            width="">
                            <template slot-scope="scope">
                                <el-button @click="detailsAddress(scope.row)" type="text" size="small">详情</el-button>
                            </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- 混凝土商品表格 -->
            <el-row v-if="addform.contractConcretes.length !== 0" :gutter="24">
                <el-col :span="22">
                    <el-form-item label-width="" label="混凝土商品:">
                        <el-table
                            :header-cell-style="headClass"
                            :cell-style="rowClass"
                            :data="addform.contractConcretes"
                            height="250px"
                            border
                            style="width: 100%">
                            <el-table-column
                            prop="name"
                            :show-overflow-tooltip="true"
                            label="商品名称"
                            width="150">
                            </el-table-column>
                            <el-table-column
                            prop="standards"
                            :show-overflow-tooltip="true"
                            label="强度等级"
                            width="">
                            </el-table-column>
                            <el-table-column
                            prop="impermeability"
                            :show-overflow-tooltip="true"
                            label="抗渗等级"
                            width="">
                            </el-table-column>
                            <el-table-column
                            prop="slump"
                            label="塌落度"
                            width="">
                            </el-table-column>
                            <el-table-column
                            prop="aggregateSize"
                            :key="5"
                            :show-overflow-tooltip="true"
                            label="骨料粒径"
                            width="150">
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.aggregateSize" placeholder="请输入骨料粒径"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column
                            prop="pouringMethod"
                            :key="6"
                            :show-overflow-tooltip="true"
                            label="浇筑方式"
                            width="150">
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.pouringMethod" placeholder="请输入浇筑方式"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column
                            prop="constructionPosition"
                            :key="7"
                            :show-overflow-tooltip="true"
                            label="施工部位"
                            width="150">
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.constructionPosition" placeholder="请输入施工部位"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column
                            prop="planNumber"
                            :key="8"
                            :show-overflow-tooltip="true"
                            label="计划数量(m³)"
                            width="150">
                                <template slot-scope="scope">
                                    <el-input maxlength="7" v-model="scope.row.planNumber" v-input-limit="2" @blur="functiona1(scope.row)" placeholder="请输入计划数量"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column
                            prop="discountPrice"
                            :key="9"
                            :show-overflow-tooltip="true"
                            label="结算单价"
                            width="150">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.discountPrice" v-input-limit="2" @blur="functiona2(scope.row)" placeholder="请输入结算单价"></el-input>
                            </template>
                            </el-table-column>
                            <el-table-column
                            prop="freightPrice"
                            :key="11"
                            :show-overflow-tooltip="true"
                            label="运费单价"
                            width="150">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.freightPrice" v-input-limit="2" @blur="functiona3(scope.row)" placeholder="请输入运费单价"></el-input>
                            </template>
                            </el-table-column>
                            <el-table-column
                            prop="pumpingPrice"
                            :key="12"
                            :show-overflow-tooltip="true"
                            label="泵送单价"
                            width="150">
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.pumpingPrice" v-input-limit="2" @blur="functiona4(scope.row)" placeholder="请输入泵送单价"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column
                            fixed="right"
                            :key="13"
                            label="生产/发货地址"
                            width="150">
                                <template slot-scope="scope">
                                    <el-button @click="detailsAddress(scope.row)" type="text" size="small">详情</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- 充值支付 -->
            <div v-if="payType1 == false & payType2 == false & payType3 == false" style="display: flex">
                <el-form-item label="" label-width="30px">
                    <el-checkbox v-model="payType0" @change="payTypeChecked" :disabled="payType0checked">充值</el-checkbox>
                </el-form-item>
                <el-form-item v-if="payType0 == true" label="充值金额:">
                    <div style="display:flex">
                        <el-input v-model="addform.payAmount" @blur="functionp1()" v-input-limit="2" placeholder="请输入充值金额"></el-input><span>元</span>
                    </div>
                </el-form-item>
                <el-form-item v-if="payType0 == true" label="金额提示:" label-width="130px">
                    <div style="display:flex">
                        <el-input v-model="addform.creditTipsAmount" v-input-limit="2" placeholder="请输入金额提示"></el-input><span>元</span>
                        <!-- <span style="margin-left:3%">元</span> -->
                    </div>
                </el-form-item>
            </div>
            <!-- 授信支付 -->
            <div v-if="payType0 == false & payType3 == false" style="display: flex">
                <el-form-item label="" label-width="30px">
                    <el-checkbox v-model="payType1" :disabled="payType1checked" @change="payTypeChecked">授信</el-checkbox>
                </el-form-item>
                <el-form-item v-if="payType1 == true" label="授信金额:">
                    <div style="display:flex">
                        <el-input v-model="addform.payAmount" @blur="functionp1()" v-input-limit="2" placeholder="请输入授信金额"></el-input><span >元</span>
                    </div>
                </el-form-item>
                <el-form-item v-if="payType1 == true" label="" label-width="15px">
                    <div class="block" style="display:flex">
                        <el-checkbox v-model="addform.isDate">应收货款日期</el-checkbox>
                        <el-date-picker
                        v-if="addform.isDate !== false"
                        style="width:207px;margin-left:2%"
                        value-format="yyyy-MM-dd"
                        v-model="addform.repaymentDate"
                        type="date"
                        :picker-options="pickerOptions"
                        placeholder="选择日期">
                        </el-date-picker>
                        <span v-else><span v-if="addform.isDate != false">{{addform.repaymentDate}}</span></span>
                    </div>
                </el-form-item>
                <el-form-item v-if="payType1 == true" label="应收货款提示:" label-width="130px">
                    <div style="display:flex">
                        <el-input v-model="addform.creditTipsAmount" v-input-limit="2" placeholder="请输入金额提示"></el-input><span>元</span>
                    </div>
                </el-form-item>
                <el-form-item v-if="payType1 == true" label="" label-width="5px">
                    <span style="width:100%">上笔应收贷款: {{addform.receivableAmount}}元</span>
                </el-form-item>
            </div>
            <!-- 账期支付 -->
            <div v-if="payType0 == false & payType3 == false" style="display: flex">
                <el-form-item label="" label-width="30px">
                    <el-checkbox v-model="payType2" @change="payTypeChecked" :disabled="payType2checked">账期</el-checkbox>
                </el-form-item>
                <el-form-item v-if="payType2 == true" label="还款日期:">
                    <el-input v-model.number="addform.repaymentTime1" placeholder="请输入几个月"></el-input>
                </el-form-item>
                <el-form-item v-if="payType2 == true" style="margin-left:7px" label="-" label-width="14px">
                    <el-input v-model.number="addform.repaymentTime2" placeholder="请输入几个月后的多少天"></el-input>
                </el-form-item>
                <el-form-item v-if="payType2 == true" style="margin-left:2px" label="" label-width="0px">
                    <span style="color:red">注：为几个月之后的多少天</span>
                </el-form-item>
                <el-form-item v-if="payType2 == true" label="账期提示日期:" label-width="130px">
                    <el-input v-model.number="addform.tipsDay" placeholder="请输入天数"></el-input>
                </el-form-item>
                <el-form-item v-if="payType2 == true" label="" label-width="5px">
                    <span style="width:100%">上笔应收贷款: {{addform.receivableAmount}}元</span>
                </el-form-item>
            </div>
            <!-- 现结支付 -->
            <div v-if="payType0 == false & payType1 == false & payType2 == false" style="display: flex">
                <el-form-item label="" label-width="30px">
                    <el-checkbox v-model="payType3" @change="payTypeChecked" :disabled="payType3checked">现结</el-checkbox>
                </el-form-item>
            </div>
            <!-- 分配金额 -->
            <div v-if="payType0 == true || payType1 == true">
                <el-form-item v-if="addform.enterpriseId != 0" label="分配金额:" label-width="110px">
                    <el-form-item v-for="(item,index) in addressGroupsYuan" :key="item.addressId" :label="'厂区'+(index+1)+'： '+item.addressName" :value="item.addressId" label-width="250px">
                        <el-input style="width:220px" placeholder="请输入金额" @blur="functiong1(item)" v-model="item.price"></el-input>
                        <span v-if="item.isStop == true">当前可调整金额为{{item.receiptsPrice}}元</span>
                    </el-form-item>
                </el-form-item>
            </div>
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item label="承运方式:" prop="carryModel">
                        <span v-if="addform.carryModel == 0">买家自提</span>
                        <span v-if="addform.carryModel == 1">卖方配送</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="运费计算:" prop="freightCalculation">
                        <el-radio-group v-model="addform.freightCalculation">
                            <span v-if="addform.freightCalculation == 0">按吨数</span>
                            <span v-if="addform.freightCalculation == 1">按公里</span>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- 混凝土版本其他信息 -->
            <el-row :gutter="24">
                <el-col v-show="addform.industryType == 1 || addform.industryType == '0,1'" :span="8">
                    <el-form-item label="施工单位:" prop="unitName">
                        <span>{{addform.unitName}}</span>
                    </el-form-item>
                </el-col>
                <el-col v-show="addform.industryType == 1 || addform.industryType == '0,1'" :span="8">
                    <el-form-item label="销售人员:" prop="salesman">
                        <span>{{addform.salesman}}</span>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item label="销售人员:" prop="salesman">
                        <span>{{addform.salesman}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="销售电话:" prop="salesTel">
                        <span >{{addform.salesTel}}</span>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item label="备注:" prop="remarks">
                        <span>{{addform.remarks}}</span>
                    </el-form-item>
                </el-col>
            </el-row>
            <div style="width:100%;text-align:right;margin-left:-3%">
                <el-form-item label="" prop="">
                    <el-button @click="CloseAddDialog('addform')">取 消</el-button>
                    <el-button type="primary" @click="SureAddContract('addform')">确 定</el-button>
                </el-form-item>
            </div>
        </el-form>
        <el-dialog
            title="详情"
            :visible.sync="CarryDetailsDialog"
            :before-close="handleClose"
            :modal-append-to-body="false"
            :append-to-body="true"
            :show-close="false"
            style="margin-top:2%"
            width="60%">
                <el-table
                    :header-cell-style="headClass"
                    :cell-style="rowClass"
                    :data="AddressData"
                    height="400px"
                    border
                    style="width: 100%">
                    <el-table-column
                    prop="address"
                    :show-overflow-tooltip="true"
                    label="生产地址"
                    width="150">
                    </el-table-column>
                    <el-table-column
                    prop="receivingAddress"
                    :show-overflow-tooltip="true"
                    label="收货地址"
                    width="">
                    </el-table-column>
                    <el-table-column
                    prop="calculatedMileage"
                    label="计算里程"
                    width="">
                    </el-table-column>
                    <el-table-column
                    prop="confirmMileage"
                    :show-overflow-tooltip="true"
                    label="确认里程"
                    width="">
                    </el-table-column>
                    <el-table-column
                    prop="arrivalDate"
                    :show-overflow-tooltip="true"
                    label="到货日期"
                    width="">
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        width="">
                        <template slot-scope="scope">
                            <el-button @click="AddressDelete(scope.row,scope.$index)" type="text" size="small">删除</el-button>
                        </template>
                        </el-table-column>
                </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click="CarryDetailsDialog = false">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import axios from 'axios'
// 引入地图
import { TMap } from '@/utils/TMap'
// 地图参数
var searchService,geocoder,map,markersArray = [];
// 地图经纬度
var lat = '';
var lng = '';
// 初始生产地址经纬度
var jingdu = ''
var weidu = ''
// 总距离
var distance = ''
export default {
    data(){
        return {
            expireTimeOPtion: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;  //如果没有后面的-8.64e7就是不可以选择今天的 
                }
            },
            addform: {
                id: '',
                enterpriseId: 0,
                enterpriseName: "",
                name: "",
                code: "",
                purchaseId: 0,
                purchaseName: "",
                taxNumber: "",
                telephone: "",
                domicile: "",
                bankName: "",
                bankNo: "",
                contacts: "",
                contactTel: "",
                purchaseTaxNumber: '',
                purchaseTelephone: '',
                purchaseDomicile: '',
                purchaseBankName: '',
                purchaseBankNo: '',
                purchaseContacts: '',
                purchaseContactTel: '',
                purchaseBusinessName: '',
                purchaseBusinessUserId: '',
                purchaseBusinessTel: '',
                payType: '',
                payAmount: '',
                isDate: false,
                repaymentDate: "",
                creditTipsAmount: '',
                creditTipsDay: '',
                repaymentTime: "",
                repaymentTime1: '',
                repaymentTime2: '',
                tipsDay: '',
                carryModel: 0,
                freightCalculation: 0,
                unitName: "",
                projectName: "",
                salesman: "",
                salesTel: "",
                remarks: "",
                receivableAmount: 0,
                receiptsAmount: '',
                type: 0,
                businessType: '0',
                industryType: 0,
                contractSands: [],
                contractConcretes: [],
                contractAddresses: [],
                creatorId: 0,
                addressGroups: [],
                partnersId: ''
            },
            businessArr: [],
            addformRules: {},
            FormOptions: [],
            enterpriseData: [],
            AddressData: [],
            enterpriseName: '',
            ch0: false,
            ch1: false,
            enterpriseRadio: -1,
            payType0: false,
            payType1: false,
            payType2: false,
            payType3: false,
            addressGroupsYuan: [],
            disabled: false,
            CarryDetailsDialog: false,
            title: '',
            // 调整的时候判断当前的支付方式
            payType0checked:false,
            payType1checked: false,
            payType2checked: false,
            payType3checked: false,
        }
    },
    mounted(){
        this.businessArr = JSON.parse(window.sessionStorage.getItem('business'))
    },
    methods: {
        // 表头 列 居中
        headClass() {
            return "text-align:center;background-color: #c8e0f391;color:#E6A23C";
        },
        rowClass() {
            return "text-align:center";
        },
        // 查询合作伙伴
        querySysEnterprise(){
            this.title = '添加'
            // 查询是销售还是采购合作伙伴
            if(this.addform.businessType == 0){
                this.$http.get(this.$syspartners_getsalelist)
                .then(response => {
                    this.FormOptions = response.data.data.list
                })
            }else{
                this.$http.get(this.$syspartners_getpurchaselist)
                .then(response => {
                    this.FormOptions = response.data.data.list
                })
            }
        },
        handleClose(){

        },
        payTypeChecked(){
            if(this.payType0 == false & this.payType1 == false){
                this.addform.payAmount = ''
                this.addform.creditTipsAmount = ''
                this.addform.repaymentDate = ''
                this.addform.receivableAmount = ''
            }
            if(this.payType1 == false){
                this.addform.repaymentDate = ''
                this.addform.receivableAmount = ''
            }
            if(this.payType2 == false){
                this.addform.repaymentTime1 = ''
                this.addform.repaymentTime2 = ''
                this.addform.tipsDay = ''
                this.addform.receivableAmount = ''
            }
        },
        // 查看地址信息详情
        detailsAddress(row){
            this.AddressData = []
            this.addform.contractAddresses.forEach(item => {
                if(row.id == item.productId){
                    this.AddressData.push(item)
                }
            })
            this.CarryDetailsDialog = true
        },
        // 添加点击确定事件
        SureAddContract(formName){
            if(this.ch1 == false){
                this.addform.unitName = '1'
                this.addform.projectName = '1'
            }
            // 添加之前的判断
            if(this.addform.name == ''){
                return this.$message.warning('请输入合同名称')
            }
            if(this.addform.code == ''){
                return this.$message.warning('请输入合同编码')
            }
            if(this.enterpriseRadio == -1){
                return this.$message.warning('请选择企业')
            }
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if(this.ch1 == false){
                        this.addform.unitName = ''
                        this.addform.projectName = ''
                    }
                    var shop = []
                    var shop2 = []
                    var arrId = []
                    this.addform.contractAddresses.forEach(aa => {
                        arrId.push(aa.productId)
                    })
                    // 判断商品信息是否正确
                    if(this.ch0 == true){
                        this.addform.industryType = 0
                        var a = 0
                        this.addform.contractSands.forEach(item => {
                            a++
                            if(Number(item.planNumber) == 0 || item.planNumber == undefined){
                                this.$message.warning('请给第'+a+'条矿石商品添加计划数量')
                                throw Error();
                            }else{
                                shop.push({
                                    productId: Number(item.id),
                                    productName: item.name,
                                    standards: item.standards,
                                    unit: item.unit,
                                    price: Number(item.discountPrice),
                                    freightPrice: Number(item.freightPrice),
                                    planNumber: Number(item.planNumber),
                                    standardsCode: item.standardsCode
                                })
                                // 判断有没有给商品信息添加生产和发货地址
                                if(arrId.indexOf(Number(item.id)) == -1){
                                    this.$message.warning('请给第'+a+'条矿石商品添加生产和发货地址')
                                    throw Error();
                                }
                            }
                        })
                    }
                    if(this.ch1 == true){
                        this.addform.industryType = 1
                        var a = 0
                        this.addform.contractConcretes.forEach(item => {
                            a++
                            if(Number(item.planNumber) == 0 || item.planNumber == undefined){
                                this.$message.warning('请给第'+a+'条混凝土商品添加计划数量')
                                throw Error();
                            }else{
                                if(item.aggregateSize == undefined){
                                    this.$message.warning('请给第'+a+'条混凝土商品添加骨料粒径')
                                    throw Error();
                                }else if(item.pouringMethod == undefined){
                                    this.$message.warning('请给第'+a+'条混凝土商品添加浇筑方式')
                                    throw Error();
                                }else if(item.constructionPosition == undefined){
                                    this.$message.warning('请给第'+a+'条混凝土商品添加施工部位')
                                    throw Error();
                                }else if(item.pumpingPrice == undefined){
                                    this.$message.warning('请给第'+a+'条混凝土商品添加泵送单价')
                                    throw Error();
                                }else{
                                    shop2.push({
                                        productId: Number(item.id),
                                        productName: item.name,
                                        standards: item.standards,
                                        unit: item.unit,
                                        price: Number(item.discountPrice),
                                        freightPrice: Number(item.freightPrice),
                                        planNumber: Number(item.planNumber),
                                        pumpingPrice: Number(item.pumpingPrice),
                                        slump: item.slump,
                                        impermeability: item.impermeability,
                                        aggregateSize: item.aggregateSize,
                                        pouringMethod: item.pouringMethod,
                                        constructionPosition: item.constructionPosition,
                                        standardsCode: item.standardsCode,
                                        impermeabilityCode: item.impermeabilityCode
                                    })
                                    // 判断有没有给商品信息添加生产和发货地址
                                    if(arrId.indexOf(Number(item.id)) == -1){
                                        this.$message.warning('请给第'+a+'条混凝土商品添加生产和发货地址')
                                        throw Error();
                                    }
                                }
                            }
                        })
                    }
                    if(this.ch0 == true & this.ch1 == true){
                        this.addform.industryType = '0,1'
                    }
                    // 判断有没有商品信息
                    if(shop.length == 0){
                        if(shop2.length == 0){
                            return this.$message.warning('请至少购买一种商品')
                        }
                    }
                    // 判断有没有支付信息
                    if(this.payType0 == true){
                        this.addform.isDate = false
                        if(Number(this.addform.payAmount) == 0){
                            return this.$message.warning('请输入充值金额')
                        }
                        if(Number(this.addform.creditTipsAmount) == 0){
                            return this.$message.warning('请输入金额提示值')
                        }else{
                            if(Number(this.addform.creditTipsAmount) >= Number(this.addform.payAmount)){
                                return this.$message.warning('提示金额不得超过充值金额')
                            }
                        }
                        this.addform.repaymentDate = '1999-02-12 12:00:00'
                        this.addform.repaymentTime = '1999-02-12 12:00:00'
                        this.addform.payType = '0'
                    }else if(this.payType1 == true & this.payType2 == true){
                        if(Number(this.addform.payAmount) == 0){
                            return this.$message.warning('请输入充值金额')
                        }
                        if(this.addform.isDate == true){
                            if(this.addform.repaymentDate == ''){
                                return this.$message.warning('请选择应收货款日期')
                            }
                        }
                        if(Number(this.addform.creditTipsAmount) == 0){
                            return this.$message.warning('请输入金额提示值')
                        }
                        // 账期
                        if(this.addform.repaymentTime1 == ''){
                            return this.$message.warning('请输入账期还款日期月份')
                        }
                        if(this.addform.repaymentTime2 == ''){
                            return this.$message.warning('请输入账期还款日期日')
                        }
                        if(this.addform.tipsDay == ''){
                            return this.$message.warning('请选择账期还款提示日期')
                        }
                        this.addform.payType = '1,2'
                    }else if(this.payType1 == true & this.payType2 == false){
                        if(Number(this.addform.payAmount) == 0){
                            return this.$message.warning('请输入授信金额')
                        }
                        if(this.addform.repaymentDate == null){
                            this.addform.repaymentDate = ''
                        }
                        if(this.addform.isDate == true){
                            if(this.addform.repaymentDate == ''){
                                return this.$message.warning('请选择应收货款日期')
                            }
                        }else{
                            this.addform.repaymentDate = '1999-02-12 12:00:00'
                        }
                        if(Number(this.addform.creditTipsAmount) == 0){
                            return this.$message.warning('请输入金额提示值')
                        }else{
                            if(Number(this.addform.creditTipsAmount) >= Number(this.addform.payAmount)){
                                return this.$message.warning('提示金额不得超过充值金额')
                            }
                        }
                        this.addform.repaymentTime = '1999-02-12 12:00:00'
                        this.addform.payType = '1'
                    }else if(this.payType2 == true & this.payType1 == false){
                        if(this.addform.repaymentTime1 == ''){
                            return this.$message.warning('请输入账期还款日期月份')
                        }
                        if(this.addform.repaymentTime2 == ''){
                            return this.$message.warning('请输入账期还款日期日')
                        }
                        if(this.addform.tipsDay == ''){
                            return this.$message.warning('请选择账期还款提示日期')
                        }
                        this.addform.payType = '2'
                        this.addform.repaymentDate = '1999-02-12 12:00:00'
                    }else if(this.payType3 == true){
                        this.addform.repaymentDate = '1999-02-12 12:00:00'
                        this.addform.repaymentTime = '1999-02-12 12:00:00'
                        this.addform.payType = '3'
                    }else{
                        return this.$message.warning('请选择支付方式')
                    }
                    // 混凝土其他信息判断
                    if(this.ch1 == true){
                        if(this.addform.unitName == ''){
                            return this.$message.warning('请输入施工单位')
                        }
                        if(this.addform.projectName == ''){
                            return this.$message.warning('请输入工程名称')
                        }
                        if(this.addform.salesman == ''){
                            return this.$message.warning('请输入销售人员')
                        }
                        if(this.addform.salesTel == ''){
                            return this.$message.warning('请输入销售电话')
                        }
                    }
                    var aa = 0
                    this.addform.addressGroups = []
                    this.addform.contractAddresses.forEach(aaa => {
                        this.addform.addressGroups.push({
                            addressId: aaa.addressId,
                            addressName: aaa.address,
                            price: 0
                        })
                    })
                    var priceArr = []
                    // 分配厂区金额
                    this.addressGroupsYuan.forEach(item => {
                        this.addform.addressGroups.forEach(items => {
                            if(item.addressId == items.addressId){
                                items.price = Number(item.price)
                            }
                        })
                        this.addform.contractAddresses.forEach(aaa => {
                            if(aaa.addressId == item.addressId){
                                aaa.isStop = item.isStop,
                                aaa.receiptsPrice = item.receiptsPrice
                            }
                        })
                        priceArr.push(Number(item.price))
                    })
                    aa = eval(priceArr.join("+"))
                    if(this.addform.enterpriseId == 0){

                    }else{
                        if(aa != this.addform.payAmount){
                            return this.$message.warning('分配总金额 必须和充值金额相等')
                        }
                    }
                    if(this.title == '调整'){
                        this.$http.post(this.$SysContract_AddContract,{
                            id: this.addform.id,
                            enterpriseId: Number(this.addform.enterpriseId),
                            enterpriseName: this.addform.enterpriseName,
                            name: this.addform.name,
                            code: this.addform.code,
                            purchaseId: Number(this.addform.purchaseId),
                            purchaseName: this.addform.purchaseName,
                            taxNumber: this.addform.taxNumber,
                            telephone: this.addform.telephone,
                            domicile: this.addform.domicile,
                            bankName: this.addform.bankName,
                            bankNo: this.addform.bankNo,
                            contacts: this.addform.contacts,
                            contactTel: this.addform.contactTel,
                            purchaseTaxNumber: this.addform.purchaseTaxNumber,
                            purchaseTelephone: this.addform.purchaseTelephone,
                            purchaseDomicile: this.addform.purchaseDomicile,
                            purchaseBankName: this.addform.purchaseBankName,
                            purchaseBankNo: this.addform.purchaseBankNo,
                            purchaseContacts: this.addform.purchaseContacts,
                            purchaseContactTel: this.addform.purchaseContactTel,
                            purchaseBusinessName: this.addform.purchaseBusinessName,
                            purchaseBusinessUserId: this.addform.purchaseBusinessUserId,
                            purchaseBusinessTel: this.addform.purchaseBusinessTel,
                            payType: this.addform.payType,
                            payAmount: Number(this.addform.payAmount),
                            isDate: this.addform.isDate,
                            repaymentDate: this.addform.repaymentDate,
                            creditTipsAmount: Number(this.addform.creditTipsAmount),
                            creditTipsDay: Number(this.addform.creditTipsDay),
                            repaymentTime: this.addform.repaymentTime1+'-'+this.addform.repaymentTime2,
                            tipsDay: Number(this.addform.tipsDay),
                            carryModel: Number(this.addform.carryModel),
                            freightCalculation: Number(this.addform.freightCalculation),
                            unitName: this.addform.unitName,
                            projectName: this.addform.projectName,
                            salesman: this.addform.salesman,
                            salesTel: this.addform.salesTel,
                            remarks: this.addform.remarks,
                            receivableAmount: Number(this.addform.receivableAmount),
                            receiptsAmount: Number(this.addform.receiptsAmount),
                            type: Number(this.addform.type),
                            businessType: Number(this.addform.businessType),
                            industryType: this.addform.industryType,
                            contractSands: shop,
                            contractConcretes: shop2,
                            contractAddresses: this.addform.contractAddresses,
                            creatorId: this.addform.creatorId,
                            addressGroupDtos: this.addform.addressGroups,
                            partnersId: Number(this.addform.partnersId)
                        }).then( response => {
                            if(response.data.isSuccess == true){
                                this.$message.success('调整成功')
                                this.$emit('CLOSE')
                            }else{
                                this.$message.warning(response.data.message)
                            }
                        })
                    }else if(this.title == '编辑'){
                        this.$http.post(this.$SysContract_EditContract,{
                            id: this.addform.id,
                            enterpriseId: Number(this.addform.enterpriseId),
                            enterpriseName: this.addform.enterpriseName,
                            name: this.addform.name,
                            code: this.addform.code,
                            purchaseId: Number(this.addform.purchaseId),
                            purchaseName: this.addform.purchaseName,
                            taxNumber: this.addform.taxNumber,
                            telephone: this.addform.telephone,
                            domicile: this.addform.domicile,
                            bankName: this.addform.bankName,
                            bankNo: this.addform.bankNo,
                            contacts: this.addform.contacts,
                            contactTel: this.addform.contactTel,
                            purchaseTaxNumber: this.addform.purchaseTaxNumber,
                            purchaseTelephone: this.addform.purchaseTelephone,
                            purchaseDomicile: this.addform.purchaseDomicile,
                            purchaseBankName: this.addform.purchaseBankName,
                            purchaseBankNo: this.addform.purchaseBankNo,
                            purchaseContacts: this.addform.purchaseContacts,
                            purchaseContactTel: this.addform.purchaseContactTel,
                            purchaseBusinessName: this.addform.purchaseBusinessName,
                            purchaseBusinessUserId: this.addform.purchaseBusinessUserId,
                            purchaseBusinessTel: this.addform.purchaseBusinessTel,
                            payType: this.addform.payType,
                            payAmount: Number(this.addform.payAmount),
                            isDate: this.addform.isDate,
                            repaymentDate: this.addform.repaymentDate,
                            creditTipsAmount: Number(this.addform.creditTipsAmount),
                            creditTipsDay: Number(this.addform.creditTipsDay),
                            repaymentTime: this.addform.repaymentTime1+'-'+this.addform.repaymentTime2,
                            tipsDay: Number(this.addform.tipsDay),
                            carryModel: Number(this.addform.carryModel),
                            freightCalculation: Number(this.addform.freightCalculation),
                            unitName: this.addform.unitName,
                            projectName: this.addform.projectName,
                            salesman: this.addform.salesman,
                            salesTel: this.addform.salesTel,
                            remarks: this.addform.remarks,
                            receivableAmount: Number(this.addform.receivableAmount),
                            receiptsAmount: Number(this.addform.receiptsAmount),
                            type: Number(this.addform.type),
                            businessType: Number(this.addform.businessType),
                            industryType: this.addform.industryType,
                            contractSands: shop,
                            contractConcretes: shop2,
                            contractAddresses: this.addform.contractAddresses,
                            creatorId: this.addform.creatorId,
                            addressGroupDtos: this.addform.addressGroups,
                            partnersId: Number(this.addform.partnersId)
                        }).then( response => {
                            if(response.data.isSuccess == true){
                                this.$message.success('调整成功')
                                this.$emit('CLOSE')
                            }else{
                                this.$message.warning(response.data.message)
                            }
                        })
                    }
                } else {
                    return false;
                }
            });
        },
        CloseAddDialog(formName){
            this.enterpriseName = ''
            this.$refs[formName].resetFields();
            this.restaddfrom()
            this.$emit('CLOSE')
        },
        // 重置表单
        restaddfrom(){
            this.addform = {
                id: '',
                enterpriseId: 0,
                enterpriseName: "",
                name: "",
                code: "",
                purchaseId: 0,
                purchaseName: "",
                taxNumber: "",
                telephone: "",
                domicile: "",
                bankName: "",
                bankNo: "",
                contacts: "",
                contactTel: "",
                purchaseTaxNumber: '',
                purchaseTelephone: '',
                purchaseDomicile: '',
                purchaseBankName: '',
                purchaseBankNo: '',
                purchaseContacts: '',
                purchaseContactTel: '',
                purchaseBusinessName: '',
                purchaseBusinessUserId: '',
                purchaseBusinessTel: '',
                payType: '',
                payAmount: '',
                isDate: false,
                repaymentDate: "",
                creditTipsAmount: '',
                creditTipsDay: '',
                repaymentTime: "",
                repaymentTime1: '',
                repaymentTime2: '',
                tipsDay: '',
                carryModel: 0,
                freightCalculation: 0,
                unitName: "",
                projectName: "",
                salesman: "",
                salesTel: "",
                remarks: "",
                receivableAmount: 0,
                receiptsAmount: '',
                type: 0,
                businessType: '0',
                industryType: 0,
                contractSands: [],
                contractConcretes: [],
                contractAddresses: [],
                addressGroups: []
            }
            this.ch0 = false
            this.ch1 = false
            this.enterpriseRadio = -1
            this.payType0 = false,
            this.payType1 = false,
            this.payType2 = false,
            this.payType3 = false,
            this.addressGroupsYuan = []
        },
        // 查询生产地址信息
        queryAddress(aa){
            this.dialogloading = true
            var a = ''
            var b = ''
            if(this.addform.businessType == 0){
                a = aa.enterpriseId
                b = aa.purchaseId
                this.$http.post(this.$SysAddress_GetPageList,{
                    currentPage: this.page,
                    pageSize: this.size,
                    filter: {
                        queryString: '',
                        enterpriseId: a
                    }
                }).then(response => {
                    this.PaddressList = response.data.data.list
                })
                this.$http.post(this.$SysAddress_GetPageList2,{
                    currentPage: this.page,
                    pageSize: this.size,
                    filter: {
                        queryString: '',
                        enterpriseId: aa.id
                    }
                }).then(response => {
                    this.SaddressList = response.data.data.list
                })
                this.dialogloading = false
            }else{
                b = aa.enterpriseId
                a = aa.purchaseId
                this.$http.post(this.$SysAddress_GetPageList,{
                    currentPage: this.page,
                    pageSize: this.size,
                    filter: {
                        queryString: '',
                        enterpriseId: a
                    }
                }).then(response => {
                    this.SaddressList = response.data.data.list
                })
                this.$http.post(this.$SysAddress_GetPageList2,{
                    currentPage: this.page,
                    pageSize: this.size,
                    filter: {
                        queryString: '',
                        enterpriseId: aa.id
                    }
                }).then(response => {
                    this.PaddressList = response.data.data.list
                })
                this.dialogloading = false
            }
        },
        Adjustment(row){
            this.title = '调整'
            this.$http.get(this.$SysContract_Get+'?id='+row.id).then(response => {
                this.addform.businessType = JSON.stringify(response.data.data.businessType),
                this.tiaozhengduixiang = response.data.data
                if(response.data.data.businessType == 0){
                    this.enterpriseName = response.data.data.purchaseName
                    this.enterpriseRadio = Number(response.data.data.enterpriseId)
                    // this.querySysEnterprise()
                    this.enterpriseData = {
                        purchaseName: response.data.data.purchaseName,
                        purchaseContacts: response.data.data.purchaseContacts,
                        purchaseContactTel: response.data.data.purchaseContactTel,
                        purchaseTaxNumber: response.data.data.purchaseTaxNumber,
                        purchaseDomicile: response.data.data.purchaseDomicile,
                        purchaseBankName: response.data.data.purchaseBankName,
                        purchaseBankNo: response.data.data.purchaseBankNo,
                    }
                }else{ 
                    this.enterpriseName = response.data.data.enterpriseName
                    this.enterpriseRadio = Number(response.data.data.enterpriseId)
                    // this.querySysEnterprise()
                    this.enterpriseData = {
                        enterpriseName: response.data.data.enterpriseName,
                        contacts: response.data.data.contacts,
                        contactTel: response.data.data.contactTel,
                        taxNumber: response.data.data.taxNumber,
                        domicile: response.data.data.domicile,
                        bankName: response.data.data.bankName,
                        bankNo: response.data.data.bankNo,
                    }
                }
                var aa = {
                    enterpriseId: response.data.data.enterpriseId,
                    purchaseId: response.data.data.purchaseId,
                    id: response.data.data.partnersId
                }
                this.queryAddress(aa)
                this.addform.partnersId = response.data.data.partnersId
                this.addform.id =  response.data.data.id,
                this.addform.enterpriseId = Number(response.data.data.enterpriseId),
                this.addform.enterpriseName = response.data.data.enterpriseName,
                this.addform.name = response.data.data.name,
                this.addform.code = response.data.data.code,
                this.addform.purchaseId = Number(response.data.data.purchaseId),
                this.addform.purchaseName = response.data.data.purchaseName,
                this.addform.taxNumber = response.data.data.taxNumber,
                this.addform.telephone = response.data.data.telephone,
                this.addform.domicile = response.data.data.domicile,
                this.addform.bankName = response.data.data.bankName,
                this.addform.bankNo = response.data.data.bankNo,
                this.addform.contacts = response.data.data.contacts,
                this.addform.contactTel = response.data.data.contactTel,
                this.addform.purchaseTaxNumber = response.data.data.purchaseTaxNumber,
                this.addform.purchaseTelephone = response.data.data.purchaseTelephone,
                this.addform.purchaseDomicile = response.data.data.purchaseDomicile,
                this.addform.purchaseBankName = response.data.data.purchaseBankName,
                this.addform.purchaseBankNo = response.data.data.purchaseBankNo,
                this.addform.purchaseContacts = response.data.data.purchaseContacts,
                this.addform.purchaseContactTel = response.data.data.purchaseContactTel,
                this.addform.purchaseBusinessName = response.data.data.purchaseBusinessName,
                this.addform.purchaseBusinessUserId = response.data.data.purchaseBusinessUserId,
                this.addform.purchaseBusinessTel = response.data.data.purchaseBusinessTel,
                this.addform.payType = response.data.data.payType
                if(this.addform.payType == 0){
                    this.payType0 = true
                }else if(this.addform.payType == 1){
                    this.payType1 = true
                }else if(this.addform.payType == 2){
                    this.payType2 = true
                }else if(this.addform.payType == 3){
                    this.payType3 = true
                }else if(this.addform.payType == '1,2'){
                    this.payType1 = true
                    this.payType2 = true
                }
                this.addform.payAmount = response.data.data.payAmount,
                this.addform.isDate = response.data.data.isDate,
                this.addform.repaymentDate = response.data.data.repaymentDate,
                this.addform.creditTipsAmount = response.data.data.creditTipsAmount,
                this.addform.creditTipsDay = response.data.data.creditTipsDay,
                this.addform.repaymentTime = response.data.data.repaymentTime
                var i1 = response.data.data.repaymentTime.indexOf("-");
                this.addform.repaymentTime1 = response.data.data.repaymentTime.slice(0,i1)
                this.addform.repaymentTime2 = response.data.data.repaymentTime.slice(i1+1)
                this.addform.tipsDay = response.data.data.tipsDay,
                this.addform.carryModel = Number(response.data.data.carryModel),
                this.addform.freightCalculation = Number(response.data.data.freightCalculation),
                this.addform.unitName = response.data.data.unitName,
                this.addform.projectName = response.data.data.projectName,
                this.addform.salesman = response.data.data.salesman,
                this.addform.salesTel = response.data.data.salesTel,
                this.addform.remarks = response.data.data.remarks,
                this.addform.receivableAmount = response.data.data.receivableAmount,
                this.addform.receiptsAmount = response.data.data.receiptsAmount,
                this.addform.type = Number(response.data.data.type),
                this.addform.industryType = response.data.data.industryType
                this.addform.creatorId = response.data.data.creatorId
                if(this.addform.industryType == '0'){
                    this.ch0 = true
                    this.ch1 = false
                    response.data.data.contractSands.forEach(item => {
                        this.addform.contractSands.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            unit: item.unit,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            planNumber: item.planNumber,
                            standardsCode: item.standardsCode
                        })
                    })
                }else if(this.addform.industryType == '1'){
                    this.ch1 = true
                    this.ch0 = false
                    response.data.data.contractConcretes.forEach(item => {
                        this.addform.contractConcretes.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            impermeability: item.impermeability,
                            slump: item.slump,
                            aggregateSize: item.aggregateSize,
                            pouringMethod: item.pouringMethod,
                            constructionPosition: item.constructionPosition,
                            planNumber: item.planNumber,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            pumpingPrice: item.pumpingPrice,
                            unit: item.unit,
                            standardsCode: item.standardsCode,
                            impermeabilityCode: item.impermeabilityCode
                        })
                    })
                }else{
                    this.ch0 = true
                    response.data.data.contractSands.forEach(item => {
                        this.addform.contractSands.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            unit: item.unit,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            planNumber: item.planNumber,
                            standardsCode: item.standardsCode
                        })
                    })
                    this.ch1 = true
                    response.data.data.contractConcretes.forEach(item => {
                        this.addform.contractConcretes.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            impermeability: item.impermeability,
                            slump: item.slump,
                            aggregateSize: item.aggregateSize,
                            pouringMethod: item.pouringMethod,
                            constructionPosition: item.constructionPosition,
                            planNumber: item.planNumber,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            pumpingPrice: item.pumpingPrice,
                            unit: item.unit,
                            standardsCode: item.standardsCode,
                            impermeabilityCode: item.impermeabilityCode
                        })
                    })
                }
                this.addform.contractAddresses = response.data.data.contractAddresses
                this.addform.addressGroups = response.data.data.addressGroups
                // 分配厂区金额
                var idarr = []
                this.addform.addressGroups.forEach(aa => {
                    if(idarr.indexOf(aa.addressId) === -1){
                        idarr.push(aa.addressId)
                        this.addressGroupsYuan.push({
                            addressId: aa.addressId,
                            addressName: aa.addressName,
                            price: Number(aa.price),
                            isStop: aa.isStop,
                            receiptsPrice: aa.receiptsPrice,
                        })
                    }
                })
            })
        },
        // 添加调整矿石商品需要的判断
        function1(row) {
            this.tiaozhengduixiang.contractSands.forEach(item => {
                if(item.productId == row.id){
                    if(row.planNumber < item.planNumber){
                        row.planNumber = item.planNumber
                        this.$message.warning('调整计划数量 不得少于上次添加数量')
                    }
                }
            })
        },
        function2(row) {
            this.tiaozhengduixiang.contractSands.forEach(item => {
                if(item.productId == row.id){
                    if(row.discountPrice < item.price){
                        row.discountPrice = item.price
                        this.$message.warning('调整结算单价 不得少于上次添加结算单价')
                    }
                }
            })
        },
        function3(row) {
            this.tiaozhengduixiang.contractSands.forEach(item => {
                if(item.productId == row.id){
                    if(row.freightPrice < item.freightPrice){
                        row.freightPrice = item.freightPrice
                        this.$message.warning('调整运费单价 不得少于上次添加运费单价')
                    }
                }
            })
        },
        // 添加调整判断混凝土商品
        functiona1(row) {
            this.tiaozhengduixiang.contractConcretes.forEach(item => {
                if(item.productId == row.id){
                    if(row.planNumber < item.planNumber){
                        row.planNumber = item.planNumber
                        this.$message.warning('调整计划数量 不得少于上次添加数量')
                    }
                }
            })
        },
        functiona2(row) {
            this.tiaozhengduixiang.contractConcretes.forEach(item => {
                if(item.productId == row.id){
                    if(row.discountPrice < item.price){
                        row.discountPrice = item.price
                        this.$message.warning('调整结算单价 不得少于上次添加结算单价')
                    }
                }
            })
        },
        functiona3(row) {
            this.tiaozhengduixiang.contractConcretes.forEach(item => {
                if(item.productId == row.id){
                    if(row.freightPrice < item.freightPrice){
                        row.freightPrice = item.freightPrice
                        this.$message.warning('调整运费单价 不得少于上次添加运费单价')
                    }
                }
            })
        },
        functiona4(row) {
            this.tiaozhengduixiang.contractConcretes.forEach(item => {
                if(item.productId == row.id){
                    if(row.pumpingPrice < item.pumpingPrice){
                        this.$message.warning('调整泵送单价 不得少于上次添泵送单价')
                    }
                }
            })
        },
        //  添加调整判断支付金额
        functionp1() {
            if(this.addressGroupsYuan.length == 1){
                this.addressGroupsYuan[0].price = this.addform.payAmount
            }
            if(this.addform.payAmount < this.tiaozhengduixiang.payAmount){
                this.addform.payAmount = this.tiaozhengduixiang.payAmount
                this.$message.warning('调整金额 不得少于上次添加金额')
            }
        },
        // 分配金额
        functiong1(item){
            this.tiaozhengduixiang.addressGroups.forEach(aa => {
                if(this.tiaozhengduixiang.addressGroups.length == 1){
                    if(aa.addressId == item.addressId){
                        if(aa.price > item.price){
                            item.price = aa.price
                            return this.$message.warning('调整厂区金额 不得少于上次分配厂区金额')
                        }
                    }
                }else{
                    if(aa.addressId == item.addressId){
                        if(aa.isStop == false){
                            if(aa.price > item.price){
                                item.price = aa.price
                                return this.$message.warning('调整厂区金额 不得少于上次分配厂区金额')
                            }
                        }else{
                            if((aa.price - item.price) > aa.receiptsPrice){
                                item.price = aa.price
                                return this.$message.warning('当前剩余调整金额为'+aa.receiptsPrice+'元')
                            }
                            if(aa.price < item.price){
                                item.price = aa.price
                                return this.$message.warning('调整厂区金额 不得大于上次分配厂区金额')
                            }
                        }
                    }
                }
            })
        },
        AdjustmentEdit(row){
            this.title = '编辑'
            this.$http.get(this.$SysContract_GetInfo+'?code='+row.code).then(response => {
                this.tiaozhengduixiang = response.data.data
                this.addform.businessType = JSON.stringify(response.data.data.businessType)
                if(response.data.data.businessType == 0){
                    this.enterpriseName = response.data.data.purchaseName
                    this.enterpriseRadio = Number(response.data.data.enterpriseId)
                    // this.querySysEnterprise()
                    this.enterpriseData = {
                        purchaseName: response.data.data.purchaseName,
                        purchaseContacts: response.data.data.purchaseContacts,
                        purchaseContactTel: response.data.data.purchaseContactTel,
                        purchaseTaxNumber: response.data.data.purchaseTaxNumber,
                        purchaseDomicile: response.data.data.purchaseDomicile,
                        purchaseBankName: response.data.data.purchaseBankName,
                        purchaseBankNo: response.data.data.purchaseBankNo,
                    }
                }else{ 
                    this.enterpriseName = response.data.data.enterpriseName
                    this.enterpriseRadio = Number(response.data.data.enterpriseId)
                    // this.querySysEnterprise()
                    this.enterpriseData = {
                        enterpriseName: response.data.data.enterpriseName,
                        contacts: response.data.data.contacts,
                        contactTel: response.data.data.contactTel,
                        taxNumber: response.data.data.taxNumber,
                        domicile: response.data.data.domicile,
                        bankName: response.data.data.bankName,
                        bankNo: response.data.data.bankNo,
                    }
                }
                var aa = {
                    enterpriseId: response.data.data.enterpriseId,
                    purchaseId: response.data.data.purchaseId,
                    id: response.data.data.partnersId
                }
                this.queryAddress(aa)
                this.addform.partnersId = response.data.data.partnersId
                this.addform.id =  response.data.data.id,
                this.addform.enterpriseId = Number(response.data.data.enterpriseId),
                this.addform.enterpriseName = response.data.data.enterpriseName,
                this.addform.name = response.data.data.name,
                this.addform.code = response.data.data.code,
                this.addform.purchaseId = Number(response.data.data.purchaseId),
                this.addform.purchaseName = response.data.data.purchaseName,
                this.addform.taxNumber = response.data.data.taxNumber,
                this.addform.telephone = response.data.data.telephone,
                this.addform.domicile = response.data.data.domicile,
                this.addform.bankName = response.data.data.bankName,
                this.addform.bankNo = response.data.data.bankNo,
                this.addform.contacts = response.data.data.contacts,
                this.addform.contactTel = response.data.data.contactTel,
                this.addform.purchaseTaxNumber = response.data.data.purchaseTaxNumber,
                this.addform.purchaseTelephone = response.data.data.purchaseTelephone,
                this.addform.purchaseDomicile = response.data.data.purchaseDomicile,
                this.addform.purchaseBankName = response.data.data.purchaseBankName,
                this.addform.purchaseBankNo = response.data.data.purchaseBankNo,
                this.addform.purchaseContacts = response.data.data.purchaseContacts,
                this.addform.purchaseContactTel = response.data.data.purchaseContactTel,
                this.addform.purchaseBusinessName = response.data.data.purchaseBusinessName,
                this.addform.purchaseBusinessUserId = response.data.data.purchaseBusinessUserId,
                this.addform.purchaseBusinessTel = response.data.data.purchaseBusinessTel,
                this.addform.payType = response.data.data.payType
                if(this.addform.payType == 0){
                    this.payType0 = true
                }else if(this.addform.payType == 1){
                    this.payType1 = true
                }else if(this.addform.payType == 2){
                    this.payType2 = true
                }else if(this.addform.payType == 3){
                    this.payType3 = true
                }else if(this.addform.payType == '1,2'){
                    this.payType1 = true
                    this.payType2 = true
                }
                this.addform.payAmount = response.data.data.payAmount,
                this.addform.isDate = response.data.data.isDate,
                this.addform.repaymentDate = response.data.data.repaymentDate,
                this.addform.creditTipsAmount = response.data.data.creditTipsAmount,
                this.addform.creditTipsDay = response.data.data.creditTipsDay,
                this.addform.repaymentTime = response.data.data.repaymentTime
                var i1 = response.data.data.repaymentTime.indexOf("-");
                this.addform.repaymentTime1 = response.data.data.repaymentTime.slice(0,i1)
                this.addform.repaymentTime2 = response.data.data.repaymentTime.slice(i1+1)
                this.addform.tipsDay = response.data.data.tipsDay,
                this.addform.carryModel = Number(response.data.data.carryModel),
                this.addform.freightCalculation = Number(response.data.data.freightCalculation),
                this.addform.unitName = response.data.data.unitName,
                this.addform.projectName = response.data.data.projectName,
                this.addform.salesman = response.data.data.salesman,
                this.addform.salesTel = response.data.data.salesTel,
                this.addform.remarks = response.data.data.remarks,
                this.addform.receivableAmount = response.data.data.receivableAmount,
                this.addform.receiptsAmount = response.data.data.receiptsAmount,
                this.addform.type = Number(response.data.data.type),
                this.addform.industryType = response.data.data.industryType
                this.addform.creatorId = response.data.data.creatorId
                if(this.addform.industryType == '0'){
                    this.ch0 = true
                    this.ch1 = false
                    response.data.data.contractSands.forEach(item => {
                        this.addform.contractSands.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            unit: item.unit,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            planNumber: item.planNumber,
                            standardsCode: item.standardsCode
                        })
                    })
                }else if(this.addform.industryType == '1'){
                    this.ch1 = true
                    this.ch0 = false
                    response.data.data.contractConcretes.forEach(item => {
                        this.addform.contractConcretes.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            impermeability: item.impermeability,
                            slump: item.slump,
                            aggregateSize: item.aggregateSize,
                            pouringMethod: item.pouringMethod,
                            constructionPosition: item.constructionPosition,
                            planNumber: item.planNumber,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            pumpingPrice: item.pumpingPrice,
                            unit: item.unit,
                            standardsCode: item.standardsCode,
                            impermeabilityCode: item.impermeabilityCode
                        })
                    })
                }else{
                    this.ch0 = true
                    response.data.data.contractSands.forEach(item => {
                        this.addform.contractSands.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            unit: item.unit,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            planNumber: item.planNumber,
                            standardsCode: item.standardsCode
                        })
                    })
                    this.ch1 = true
                    response.data.data.contractConcretes.forEach(item => {
                        this.addform.contractConcretes.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            impermeability: item.impermeability,
                            slump: item.slump,
                            aggregateSize: item.aggregateSize,
                            pouringMethod: item.pouringMethod,
                            constructionPosition: item.constructionPosition,
                            planNumber: item.planNumber,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            pumpingPrice: item.pumpingPrice,
                            unit: item.unit,
                            standardsCode: item.standardsCode,
                            impermeabilityCode: item.impermeabilityCode
                        })
                    })
                }
                this.addform.contractAddresses = response.data.data.contractAddresses
                this.addform.addressGroups = response.data.data.addressGroups
                // 分配厂区金额
                var idarr = []
                this.addform.addressGroups.forEach(aa => {
                    if(idarr.indexOf(aa.addressId) === -1){
                        idarr.push(aa.addressId)
                        this.addressGroupsYuan.push({
                            addressId: aa.addressId,
                            addressName: aa.addressName,
                            price: Number(aa.price),
                            isStop: aa.isStop,
                            receiptsPrice: aa.receiptsPrice,
                        })
                    }
                })
                this.dialogVisible = true
            })
        }
    }
}
</script>
<style scoped>
.dialog_body{
    height: 600px;
    padding: 0px 10px;
    overflow-y: scroll;
    overflow-x: hidden;
}
/deep/.el-dialog__header {
    padding: 13px 20px 13px;
    background-color: skyblue;
}
/deep/.el-dialog__footer {
    /* min-width: 1300px; */
}
</style>