<template>
    <div class="body">
        <div class="radio">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="商品审核">
                    <el-radio v-model="form.isShop" :label="true">启用</el-radio>
                    <el-radio v-model="form.isShop" :label="false">未启用</el-radio>
                </el-form-item>
                <el-form-item label="订单审核">
                    <el-radio v-model="form.isOrder" :label="true">启用</el-radio>
                    <el-radio v-model="form.isOrder" :label="false">未启用</el-radio>
                </el-form-item>
                <el-form-item label="金额浮动">
                    <div style="display:flex">
                        <el-input style="width:220px" @input="maxiMumAmount()" v-model="form.maxiMumAmount" placeholder="请输入浮动上限">
                            <template slot="append">%</template>    
                        </el-input>
                    </div>
                </el-form-item>
                <el-form-item label="">
                    <el-button type="primary" v-if="$hasPermission(['AuditSettings_Save'])" @click="AduitSetUser()" >保存</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
  export default {
        data(){
            return{
                form:{
                    isShop: false,
                    isOrder: false,
                    isSettlement: false,
                    maxiMumAmount: null,
                    miniMumAmount: null
                },

            }
        },
        methods:{
            async query(){
                if(!this.$hasPermission(['AuditSettings_Save'])){
                    return
                }
                const data = await this.$http.get(this.$aduitManager_GetAduit)
                if(data.data.isSuccess == false){
                    this.$message.warning(data.data.message)
                    return
                }
                this.form.isShop = data.data.data.isShop
                this.form.isOrder = data.data.data.isOrder
                this.form.isSettlement = data.data.data.isSettlement
                this.form.maxiMumAmount = data.data.data.maxiMumAmount
                this.form.miniMumAmount = data.data.data.miniMumAmount
            },
            // 判断金额浮动上限只能输入小数点后两位 并且不得超过一百
            maxiMumAmount(){
                if(this.form.maxiMumAmount >= 100){
                    this.$message.warning('输入有误，金额浮动上下限不可超过一百，请重新输入')
                    this.form.maxiMumAmount = null
                }
                this.form.maxiMumAmount = this.form.maxiMumAmount.replace(/\.\d\d\d$/,'')
                this.form.miniMumAmount = this.form.maxiMumAmount

            },
            // 判断金额浮动下限只能输入小数点后两位 并且不得超过一百
            miniMumAmount(){
                if(this.form.miniMumAmount >= 100){
                    this.$message.warning('输入有误，金额浮动上下限不可超过一百，请重新输入')
                    this.form.miniMumAmount = null
                }
                this.form.miniMumAmount = this.form.miniMumAmount.replace(/\.\d\d\d$/,'')
            },
            AduitSetUser(){
                this.$http.post(this.$aduitManager_PostAduit , this.form).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message({
                            type: 'success',
                            message: '设置成功',
                        })
                        this.query()
                    }else{
                        this.$message({
                            type: 'warning',
                            message: response.data.message,
                        })
                    }
                })
            }
        },
        mounted(){
            this.query()
        }
    }
</script>
<style scoped>
.body{
    height: 100%;
    width: 100%;
}
.radio{
    height: 40%;
    width: 40%;
    margin: 0 auto;
}
</style>