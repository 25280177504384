<template>
    <div style="height:610px" v-loading="loading" element-loading-text="拼命加载中" element-loading-background="rgb(213 213 213 / 57%)">
        <!-- 查询 -->
        <div>
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item label="查询时间">
                    <el-date-picker
                        @change="query()"
                        v-model="form.sttlementDate"
                        type="date"
                        placeholder="选择日期"
                        format="yyyy 年 MM 月 dd 日"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="">
                    <el-button type="primary" @click="query()">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- 表格 -->
        <div>
            <el-table
                :header-cell-style="headClass"
                :cell-style="rowClass"
                :data="tableData"
                height="450"
                border
                style="width: 100%">
                <el-table-column
                prop="receivableAmount"
                :key="1"
                label="应收金额"
                :show-overflow-tooltip="true"
                width="200px">
                </el-table-column>
                <el-table-column
                prop="receiptsAmount"
                :key="2"
                :show-overflow-tooltip="true"
                label="实收金额"
                width="">
                </el-table-column>
                <el-table-column
                prop="settlementDate"
                :key="3"
                :show-overflow-tooltip="true"
                label="应收时间">
                </el-table-column>
                <el-table-column
                prop="creationTime"
                :key="4"
                :show-overflow-tooltip="true"
                label="创建时间">
                </el-table-column>
                <el-table-column
                prop=""
                :key="5"
                label="操作">
                    <template slot-scope="scope">
                        <el-popover
                        placement="right"
                        width="400"
                        :ref="'popover-'+scope.row.id">
                            <el-input v-model="receiptsAmount" @blur="Save(scope.row)" placeholder="请输入实收金额"></el-input>
                            <el-button type="text" slot="reference">设置实收金额</el-button>
                        </el-popover>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 表格分页 -->
            <div style="float:right" class="block">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page"
                    :page-sizes="[10, 15, 20]"
                    :page-size="size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
                <div style="float:right;margin-top:10px">
                    <el-button @click="Close">关 闭</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    computed: {
        // tableHeight: document.documentElement.clientHeight
    },
    data(){
        return{
            loading: false,
            tableData: [],
            // 表格分页
            page: 1,
            size: 10,
            total: 0,
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
            },
            form: {
                orderId: '',
                sttlementDate: ''
            },
            receiptsAmount: ''
        }
    },
    methods:{
        // 表头 列 居中
        headClass() {
            return "text-align:center;background-color: #c8e0f391;color:#E6A23C";
        },
        rowClass() {
            return "text-align:center";
        },
        // 分页功能
        handleSizeChange(val) {
            this.size = val
            this.query()
        },
        handleCurrentChange(val) {
            this.page = val
            this.query()
        },
        creationTime(row){
            if(row.creationTime == '0001-01-01 00:00:00'){
                return ''
            }else{
                return row.creationTime
            }
        },
        text(row){
            this.form.sttlementDate = ''
            this.form.orderId = row.id
            this.query()
        },
        // 查询事件
        query(){
            this.loading = true
            this.$http.post(this.$SysOrder_GetOrderSttlementRecordPageList,{
                currentPage: this.page,
                pageSize: this.size,
                filter: {
                    orderId: this.form.orderId,
                    sttlementDate: this.form.sttlementDate,
                }
            }).then(response => {
                this.tableData = response.data.data.list
                this.total = response.data.data.total
                this.loading = false
            })
            
        },
        payType(row){
            if(row.payType == '0'){
                return '充值支付';
            }else if(row.payType == '1'){
                return '授信支付';
            }else if(row.payType == '2'){
                return '账期支付';
            }else if(row.payType == '3'){
                return '现金支付';
            }else if(row.payType == '1,2'){
                return '授信,账期支付';
            }
        },
        Close(){
            this.$emit('CLOSE')
        },
        Save(row){
            // this.$refs["popover-" + row.id].showPopper = false;
            if(Number(this.receiptsAmount) == 0){
                this.receiptsAmount = ''
                return this.$message.info('实收金额不允许为0')
            }
            if(Number(this.receiptsAmount) < Number(row.receiptsAmount)){
                this.receiptsAmount = ''
                return this.$message.info('实收金额不允许小于上次实收金额')
            }
            if(Number(this.receiptsAmount) > Number(row.receivableAmount)){
                this.receiptsAmount = ''
                return this.$message.info('实收金额不允许超过应收金额')
            }
            this.$http.post(this.$SysOrder_SetReceiptsAmount,{
                id: row.id,
                receiptsAmount: this.receiptsAmount
            }).then(response => {
                if(response.data.isSuccess == true){
                    this.query()
                    this.receiptsAmount = ''
                }
                else{
                    this.$message.warning(response.data.message)
                }
                
            })
        }
    },
    mounted(){
        // this.querySysEnterprise()
    }
}
</script>
<style scoped>
.body{
    /* padding: 15px;
    height: 96%;
    width: 98%;
    background-color:  #fdfdfd;
    box-shadow: 2px 1px 1px  #d8d8d8;
    border: 1px solid #ddd;
    border-radius: 5px; */
}
/deep/.el-dialog__header {
    padding: 13px 20px 13px;
    background-color: skyblue;
}
.xiangqing{
    color:#67C23A
}
.bianji{
    color: #E6A23C;
}
.shanchu{
    color: #F56C6C ;
}
</style>