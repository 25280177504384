import Vue from 'vue'
import App from './App.vue'
import router from './router'
/* 导入全局样式 */
import './assets/css/global.css'
// 手动配置element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import './plugins/element.js'
/* 导入阿里字体图标 */
import './assets/fonts/iconfont.css'
/* tree-table */
import TreeTable from 'vue-table-with-tree-grid'

import _ from 'lodash'
import { Message } from 'element-ui'
import store from './utils/store.js'
import echarts from 'echarts'
Vue.prototype.$echarts = echarts
import 'element-ui/lib/theme-chalk/base.css';
import {
  listToTree,
  getTreeParents,
  getListParents,
  getTreeParentsWithSelf,
} from './utils/tree'
// 引用手写签名组件
import vueEsign from 'vue-esign'
Vue.use(vueEsign)

Vue.use(ElementUI,{size: 'medium',})
// Vue.use(Message)
// Vue.prototype.$message = Message

// 限制输入框只能输入后两位小数
import * as instructions from '@/utils/instructions' //指令
Vue.directive('inputLimit',instructions.inputLimt)
/* 配置全局http 远程连接，并完成默认的连接前缀 */
import axios from 'axios'
// if(process.env.NODE_ENV === "development"){
  // http://192.168.2.128:9999/
  // https://api.i-hg.cn/
  axios.defaults.baseURL = 'https://api.i-hg.cn/'
  // alert('开发环境')
// }else{
//   axios.defaults.baseURL = 'http://192.168.2.187:5501/'
//   // 生产环境
// }

axios.create({
  // https://api.i-hg.cn/
  baseURL: 'https://api.i-hg.cn/',
  timeout: 5000
})

/*添加axios 拦截器  */
axios.interceptors.request.use(config => {
  // console.log(config)
  const tokenStr = window.sessionStorage.getItem('token')
  if (tokenStr != null) {
    config.headers.Authorization = 'Bearer ' + tokenStr
    // config.responseType = 'blob' 
  }
  //最后必须要加上这个
  return config
})

// 响应拦截器
axios.interceptors.response.use(response=>{
  if(response.data.type == 'application/vnd.ms-excel'){
    return response
  }else{
    const res = response.data
    if(res.code !== 200){
      var str = res.message
      if(str.indexOf("Token") != -1 == true){
        Message({
          message: '登录过期请重新登录！',
          type: 'info',
          duration: 5 * 1000
        })
        window.sessionStorage.clear()
        router.replace({path: '/login'})
        return 
      }
      // Message({
      //   message: res.message || 'Error',
      //   type: 'error',
      //   duration: 5 * 1000
      // })
      return Promise.reject(new Error(res.message || 'Error'))
    }
    if(res.isSuccess !== true){
      // Message({
      //   message: res.message,
      //   type: 'error',
      //   duration: 5 * 1000
      // })
      return response
    }else{
      return response
    }
  }
  // Message('请求成功')
}, error=>{
  // console.log('err' + error) // for debug
  Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
});

/* 使用treetable 名字是自定义的 */
Vue.component('tree-table', TreeTable)


Vue.prototype.$http = axios
Vue.prototype._ = _
// 配置全局请求后端url路径
// "/api/Login/GetTokenNuxt"
Vue.prototype.$loginUrl = "/ysmanage/Identity/GetToken"
Vue.prototype.$refreshTokenUrl = "/ysmanage/Identity/RefreshToken"//刷新token

Vue.prototype.$postRequest = async function (url, params) {
  const {
    data: res
  } = await this.$http.post(url, params)
  if (res.code !== 200) {
    return this.$message.error('获取数据失败！' + res.message)
  }
  var data = res.data.list
  return data
}

Vue.prototype.$deleteRequest = async function (url, params) {
  const {
    data: res
  } = await this.$http.delete(url, params)
  if (res.code !== 200) {
    return this.$message.error('获取数据失败！' + res.message)
  }
  var data = res.data.list
  return data
}

Vue.prototype.$getRequest = async function (url, params = '') {
    const paramsStr = ''
    if (params.length > 0) {
      paramsStr = "?" + params
    }
    const {
      data: res
    } = await this.$http.get(url + paramsStr)
    if (res.code !== 200) {
      return this.$message.error('获取数据失败！' + res.message)
    }
    var data = res
    return data
  },

  Vue.prototype.$initPermissions = function (permissions) {
    sessionStorage.removeItem("permissions");
    window.sessionStorage.setItem('permissions', JSON.stringify(permissions))

  },
  Vue.prototype.$getMenu = function () { //获得菜单数据  
    const tempData = JSON.parse(window.sessionStorage.getItem('permissions'))
    const menuTree = listToTree(tempData.filter(x => x.type === 2 || x.type === 1)) //返回权限类别为分组和菜单)
    return menuTree
  },


  Vue.prototype.$hasPermission = function(value) { //操作权限判断

    const permissions = JSON.parse(window.sessionStorage.getItem('permissions'))
    if (!(permissions instanceof Array)) {
      return false
    }
    let hasPermission = false
    if (value instanceof Array && value.length > 0) {
      hasPermission = permissions.some(permission => {
        return value.includes(permission.code)
      })
    } else {
      hasPermission = permissions.includes(value)
    }
    return hasPermission
  },

// 获取guid
Vue.prototype.$getKey = "/ysmanage/Identity/GetGuid"
// 获取验证码
Vue.prototype.$getVerificationCodeUrl = "/ysmanage/Identity/GetVerificationCode"
// 注册用户 /ysmanage/SysUser/Register
Vue.prototype.$RegisterUserType = "/ysmanage/SysUser/Register"
// 注册用户发送短信验证码/ysmessage/Send/SendCode
Vue.prototype.$RegisterSendType = "/ysmessage/Send/SendCode"
// 判断用户是否存在
Vue.prototype.$RegisterIsUser = "/ysmanage/SysUser/IsUser"
//1、用户管理
// 1.1用户分页查询
Vue.prototype.$userquerylist = "/ysmanage/SysUser/GetPageUserList"
// 1.2
Vue.prototype.$userManagerUrl = "ysmanage/SysUser/GetPageUserList"
Vue.prototype.$userManager_GetUserUrl = "/ysmanage/SysUser/Get"
// 1.4 用户添加
Vue.prototype.$userManager_CreateUserUrl = "/ysmanage/SysUser/CreateUser"
// 1.5用户修改
Vue.prototype.$userManager_EditUserUrl = "/ysmanage/SysUser/UpdateUser"
// 1.6用户删除
Vue.prototype.$userManager_DeleteUserUrl = "/ysmanage/SysUser/DeleteUserById"
// 1.7审核用户
Vue.prototype.$userManager_AduitUrl = "/ysmanage/SysUser/AduitUser"
// 1.8 重置密码
Vue.prototype.$usermanager_RestUrl = "/ysmanage/SysUser/ResetUserPassWord"
// 1.9 修改密码 /ysmanage/SysUser/UpdateUserPassWord
Vue.prototype.$usermanager_putPassUrl = "/ysmanage/SysUser/UpdateUserPassWord"
//2、角色管理、
// 角色管理表格刷新
Vue.prototype.$sysRoleManagerUrl = '/ysmanage/SysEnterpriseRole/GetPageSysRoleList'
// 角色权限菜单里面 获取角色
Vue.prototype.$sysRoleManagerUrl_GetSysRoleListUrl = '/ysmanage/sysenterpriserole/getsysrolelist'
Vue.prototype.$sysRoleManager_GetSysRoleUrl = '/ysmanage/SysEnterpriseRole/Get'
Vue.prototype.$sysRoleManager_CreateSysRoleUrl = '/ysmanage/SysEnterpriseRole/CreateSysRole'
Vue.prototype.$sysRoleManager_EditSysRoleUrl = '/ysmanage/SysEnterpriseRole/UpdateSysRole'
Vue.prototype.$sysRoleManager_DeleteSysRoleUrl = '/ysmanage/SysEnterpriseRole/DeleteSysRoleById'
// 获取所有角色权限树形数据
Vue.prototype.$GetSysRoleUser = '/ysmanage/SysEnterpriseRole/GetSysRoleUser'
//3、角色权限
Vue.prototype.$sysRoleManager_UpdateSysRolePermissionsUrl = '/ysmanage/sysenterpriserole/updatesysrolepermissions'

//4、权限管理
// 角色权限 权限列表刷新
Vue.prototype.$sysPermissionManagerUrl = '/ysmanage/sysenterprisepermission/getsyspermissionlist'
Vue.prototype.$sysPermissionManager_GetSysPermissionFilterByPidUrl = 'SysPermission/GetSysPermissionFilterByPid'
Vue.prototype.$sysPermissionManager_GetSysPermissionUrl = 'SysPermission/Get'
Vue.prototype.$sysPermissionManager_CreateSysPermissionUrl = 'SysPermission/CreateSysPermission'
Vue.prototype.$sysPermissionManager_EditSysPermissionUrl = 'SysPermission/UpdateSysPermission'
Vue.prototype.$sysPermissionManager_DeleteSysPermissionUrl = 'SysPermission/DeleteSysPermissionById'

//5、字典管理
Vue.prototype.$sysDataDictionaryManagerUrl = 'SysDataDictionary/GetSysDataDictionaryList'
Vue.prototype.$sysDataDictionaryManager_GetSysDataDictionaryFilterByPidUrl = 'SysDataDictionary/GetSysDataDictionaryFilterByPid'
Vue.prototype.$sysDataDictionaryManager_GetSysDataDictionaryUrl = 'SysDataDictionary/Get'
Vue.prototype.$sysDataDictionaryManager_CreateSysDataDictionaryUrl = 'SysDataDictionary/CreateSysDataDictionary'
Vue.prototype.$sysDataDictionaryManager_EditSysDataDictionaryUrl = 'SysDataDictionary/UpdateSysDataDictionary'
Vue.prototype.$sysDataDictionaryManager_DeleteSysDataDictionaryUrl = 'SysDataDictionary/DeleteSysDataDictionaryById'

//6、审计日志 
Vue.prototype.$sysAuditLogManagerUrl='SysAuditLog/GetPageSysAuditLogList'
Vue.prototype.$sysAuditLogManager_GetSysAuditLogUrl='SysAuditLog/Get'


//6、组织机构管理
Vue.prototype.$sysOrganizationManagerUrl='SysOrganization/GetSysOrganizationList'
Vue.prototype.$sysOrganizationManager_GetSysOrganizationFilterByPidUrl='SysOrganization/GetSysOrganizationFilterByPid'
Vue.prototype.$sysOrganizationManager_GetSysOrganizationUrl='SysOrganization/Get'
Vue.prototype.$sysOrganizationManager_CreateSysOrganizationUrl='SysOrganization/CreateSysOrganization'
Vue.prototype.$sysOrganizationManager_EditSysOrganizationUrl ='SysOrganization/UpdateSysOrganization'
Vue.prototype.$sysOrganizationManager_DeleteSysOrganizationUrl='SysOrganization/DeleteSysOrganizationById'

// 7、审核设置/ysmanage/AduitSet/AduitSetUser
Vue.prototype.$aduitManager_PostAduit = "/ysmanage/AduitSet/AduitSetUser"
// 7.1获取审核设置/ysmanage/AduitSet/GetAduitSetUser
Vue.prototype.$aduitManager_GetAduit = "/ysmanage/AduitSet/GetAduitSetUser"
Vue.config.productionTip = false


// 查询当前用户基本信息/ysmanage/SysEnterprise/GetInfo
Vue.prototype.$UserManager_GetUserOwn = "/ysmanage/SysEnterprise/GetInfo"
// 上传图片地址 /ysfile/FileUpload/UploadLargeFile/UploadLargeFile
Vue.prototype.$UserManager_UploadLargeFile = axios.defaults.baseURL+"ysfile/FileUpload/UploadLargeFile/UploadLargeFile"
// 修改企业logo /ysmanage/SysEnterprise/UploadLogo
Vue.prototype.$UserManager_UploadLogo = "/ysmanage/SysEnterprise/UploadLogo"

// 地址管理
// 查询 /ysmanage/SysAddress/GetPageSysAddressList
Vue.prototype.$SysAddress_GetPageSysAddressList = "/ysmanage/SysAddress/GetPageSysAddressList"
// 新增 /ysmanage/SysAddress/CreateSysAddress
Vue.prototype.$SysAddress_CreateSysAddress = "/ysmanage/SysAddress/CreateSysAddress"
// 查看详情 /ysmanage/SysAddress/Get
Vue.prototype.$SysAddress_Get = "/ysmanage/SysAddress/Get"
// 删除 /ysmanage/SysAddress/DeleteSysAddressById
Vue.prototype.$SysAddress_DeleteSysAddressById = "/ysmanage/SysAddress/DeleteSysAddressById"
// 编辑 /ysmanage/SysAddress/UpdateSysAddress
Vue.prototype.$SysAddress_UpdateSysAddress = "/ysmanage/SysAddress/UpdateSysAddress"
// 审核 /ysmanage/SysAddress/AuditSysAddress
Vue.prototype.$SysAddress_AuditSysAddress = "/ysmanage/SysAddress/AuditSysAddress"


// 合作伙伴地址管理
// 查询 /ysmanage/SysAddress/GetPartnersPageList
Vue.prototype.$SysAddress_GetPartnersPageList = "/ysmanage/SysAddress/GetPartnersPageList"
// 新增 /ysmanage/SysAddress/CreateSysAddress
Vue.prototype.$SysAddress_CreateSysAddress = "/ysmanage/SysAddress/CreateSysAddress"
// 查看详情 /ysmanage/SysAddress/Get
Vue.prototype.$SysAddress_Get = "/ysmanage/SysAddress/Get"
// 删除 /ysmanage/SysAddress/DeleteSysAddressById
Vue.prototype.$SysAddress_DeleteSysAddressById = "/ysmanage/SysAddress/DeleteSysAddressById"
// 编辑 /ysmanage/SysAddress/UpdateSysAddress
Vue.prototype.$SysAddress_UpdateSysAddress = "/ysmanage/SysAddress/UpdateSysAddress"
// 审核 /ysmanage/SysAddress/AuditSysAddress
Vue.prototype.$SysAddress_AuditSysAddress = "/ysmanage/SysAddress/AuditSysAddress"

// 商品管理 查询树形结构 /ysmanage/SysProduct/GetTreeList
Vue.prototype.$SysProduct_GetTreeList = "/ysmanage/SysProduct/GetTreeList"
// 商品管理 通过id查询 /ysmanage/SysProduct/Get
Vue.prototype.$SysProduct_Get = "/ysmanage/SysProduct/Get"
// 商品管理 创建商品 /ysmanage/SysProduct/CreateSysProduct
Vue.prototype.$SysProduct_CreateSysProduct = "/ysmanage/SysProduct/CreateSysProduct"
// 商品管理 根据id集合删除 /ysmanage/SysProduct/DeleteSysProductById
Vue.prototype.$SysProduct_DeleteSysProductById = "/ysmanage/SysProduct/DeleteSysProductById"
// 商品管理 编辑商品 /ysmanage/SysProduct/UpdateSysProduct
Vue.prototype.$SysProduct_UpdateSysProduct = "/ysmanage/SysProduct/UpdateSysProduct"
// 商品管理 审核商品/ysmanage/SysProduct/AduitSysProduct
Vue.prototype.$SysProduct_AduitSysProduct = "/ysmanage/SysProduct/AduitSysProduct"

// 合作伙伴
// 获取所有用户 生成选择框/ysmanage/SysUser/GetUserList
Vue.prototype.$BuyersManager_GetBuyersUser = "/ysmanage/SysUser/GetUserList"
// 通过输入企业名称查询企业信息 /ysmanage/SysEnterprise/GetPageList
Vue.prototype.$SysEnterprise_GetPageList = "/ysmanage/SysEnterprise/GetPageList"
// 勾选来源之后 通过输入企业名称查询企业信息使用的接口
Vue.prototype.$SysOrder_GetEnterprise = "/ysorder/SysOrder/GetEnterprise"
// 分页查询 /ysmanage/SysPartners/GetPageSysPartnerList
Vue.prototype.$SysPartners_GetPageSysPartnerList = "/ysmanage/SysPartners/GetPageSysPartnerList"
// 单条数据查询 /ysmanage/SysPartners/Get
Vue.prototype.$SysPartners_Get = "/ysmanage/SysPartners/Get"
// 新增 /ysmanage/SysPartners/CreatePartner
Vue.prototype.$SysPartners_CreatePartner = "/ysmanage/SysPartners/CreatePartner"
// 删除 /ysmanage/SysPartners/DeleteSysPartnerById
Vue.prototype.$SysPartners_DeleteSysPartnerById = "/ysmanage/SysPartners/DeleteSysPartnerById"
// 编辑 /ysmanage/SysPartners/UpdatePartner
Vue.prototype.$SysPartners_UpdatePartner = "/ysmanage/SysPartners/UpdatePartner"
// 审核 /ysmanage/SysPartners/AduitPartner
Vue.prototype.$SysPartners_AduitPartner = "/ysmanage/SysPartners/AduitPartner"
// 确认 /ysmanage/SysPartners/ConfirmPartner
Vue.prototype.$SysPartners_ConfirmPartner = "/ysmanage/SysPartners/ConfirmPartner"

// 车辆管理
// 获取企业信息树形结构数据 /ysmanage/SysCarry/GetTreeList
Vue.prototype.$SysCarry_GetTreeList = "/ysmanage/SysCarry/GetTreeList"
// 新增 承运企业 /ysmanage/SysCarry/CreateSysCustomer
Vue.prototype.$SysCarry_CreateSysCustomer = "/ysmanage/SysCarry/CreateSysCustomer"
// 删除 承运企业 /ysmanage/SysCarry/DeleteSysCustomerById
Vue.prototype.$SysCarry_DeleteSysCustomerById = "/ysmanage/SysCarry/DeleteSysCustomerById"
// 编辑 承运企业 /ysmanage/SysCarry/UpdateSysCustomer
Vue.prototype.$SysCarry_UpdateSysCustomer = "/ysmanage/SysCarry/UpdateSysCustomer"
// 新增 承运车辆 /ysmanage/SysCarry/CreateSysCarry
Vue.prototype.$SysCarry_CreateSysCarry = "/ysmanage/SysCarry/CreateSysCarry"
// 删除 承运车辆 /ysmanage/SysCarry/DeleteSysCarryById
Vue.prototype.$SysCarry_DeleteSysCarryById = "/ysmanage/SysCarry/DeleteSysCarryById"
// 编辑 承运车辆 /ysmanage/SysCarry/UpdateSysCarry
Vue.prototype.$SysCarry_UpdateSysCarry = "/ysmanage/SysCarry/UpdateSysCarry"
// 审核承运车辆 /ysmanage/SysCarry/AuditSysCarry
Vue.prototype.$SysCarry_AuditSysCarry = "/ysmanage/SysCarry/AuditSysCarry"

// 合同管理
// 单条数据查询 /ysorder/SysContract/Get
Vue.prototype.$SysContract_Get = "/ysorder/SysContract/Get"
// 分页查询合同管理 /ysorder/SysContract/GetPageList
Vue.prototype.$SysContract_GetPageList = "/ysorder/SysContract/GetPageList"
// 添加查询企业信息 /ysmanage/SysPartners/GetPageList
Vue.prototype.$SysPartners_GetPageList = "/ysmanage/SysPartners/GetPageList"
// 添加生产地址查询 /ysmanage/SysAddress/GetPageList
Vue.prototype.$SysAddress_GetPageList = "/ysmanage/SysAddress/GetPageList"
// 添加生产地址查询 没有云商的 /ysmanage/SysAddress/GetPageList2
Vue.prototype.$SysAddress_GetPageList2 = "/ysmanage/SysAddress/GetPageList2"
// 添加查询商品信息 /ysmanage/SysProduct/GetActivePageList
Vue.prototype.$SysProduct_GetActivePageList = "/ysmanage/SysProduct/GetActivePageList"
// 添加创建合同 /ysorder/SysContract/CreateContract
Vue.prototype.$SysContract_CreateContract = "/ysorder/SysContract/CreateContract"
// 编辑合同 /ysorder/SysContract/UpdateContract
Vue.prototype.$SysContract_UpdateContract = "/ysorder/SysContract/UpdateContract"
// 删除合同 /ysorder/SysContract/DeleteSysContractById
Vue.prototype.$SysContract_DeleteSysContractById = "/ysorder/SysContract/DeleteSysContractById"
// 审核合同 /ysorder/SysContract/AuditContract
Vue.prototype.$SysContract_AuditContract = "/ysorder/SysContract/AuditContract"
// 确认 /ysorder/SysContract/ConfirmContract
Vue.prototype.$SysContract_ConfirmContract = "/ysorder/SysContract/ConfirmContract"
// 取消审核状态 /ysorder/SysContract/CancelAuditContract
Vue.prototype.$SysContract_CancelAuditContract = "/ysorder/SysContract/CancelAuditContract"
// 设置实收金额 /ysorder/SysContract/SetReceiptsAmountContract
Vue.prototype.$SysContract_SetReceiptsAmountContract = "/ysorder/SysContract/SetReceiptsAmountContract"
// 合同新增调整 /ysorder/SysContract/AddContract
Vue.prototype.$SysContract_AddContract = "/ysorder/SysContract/AddContract"
// 合同编辑调整 /ysorder/SysContract/EditContract
Vue.prototype.$SysContract_EditContract = "/ysorder/SysContract/EditContract"
// 合同编辑调整查询 /ysorder/SysContract/GetInfo
Vue.prototype.$SysContract_GetInfo = "/ysorder/SysContract/GetInfo"
// 合同编辑取消确认 /ysorder/SysContract/CancelContract
Vue.prototype.$SysContract_CancelContract = "/ysorder/SysContract/CancelContract"
// 合同调整确认 /ysorder/SysContract/AdjustConfirmContract
Vue.prototype.$SysContract_AdjustConfirmContract = "/ysorder/SysContract/AdjustConfirmContract"
// 合同调整取消确认 /ysorder/SysContract/AdjustCancelConfirmContract
Vue.prototype.$SysContract_AdjustCancelConfirmContract = "/ysorder/SysContract/AdjustCancelConfirmContract"


// 销售订单页面接口
// 单条数据查询 /ysorder/SysOrder/Get
Vue.prototype.$SysOrder_Get = "/ysorder/SysOrder/Get"
// 销售分页列表 /ysorder/SysOrder/GetSalePageList
Vue.prototype.$SysOrder_GetSalePageList = "/ysorder/SysOrder/GetSalePageList"
// 采购分页列表 /ysorder/SysOrder/GetPurchasePageList
Vue.prototype.$SysOrder_GetPurchasePageList = "/ysorder/SysOrder/GetPurchasePageList"
// 创建订单 /ysorder/SysOrder/CreateOrder
Vue.prototype.$SysOrder_CreateOrder = "/ysorder/SysOrder/CreateOrder"
// 编辑订单 /ysorder/SysOrder/UpdateOrder
Vue.prototype.$SysOrder_UpdateOrder = "/ysorder/SysOrder/UpdateOrder"
// 确认订单状态 /ysorder/SysOrder/ConfirmOrder
Vue.prototype.$SysOrder_ConfirmOrder = "/ysorder/SysOrder/ConfirmOrder"
// 取消确认订单状态 /ysorder/SysOrder/CancelConfirmOrder
Vue.prototype.$SysOrder_CancelConfirmOrder = "/ysorder/SysOrder/CancelConfirmOrder"
// 取消订单状态 /ysorder/SysOrder/CancelOrder
Vue.prototype.$SysOrder_CancelOrder = "/ysorder/SysOrder/CancelOrder"
// 废弃订单状态 /ysorder/SysOrder/AbandonOrder
Vue.prototype.$SysOrder_AbandonOrder = "/ysorder/SysOrder/AbandonOrder"
// 终止订单状态 /ysorder/SysOrder/StopOrder
Vue.prototype.$SysOrder_StopOrder = "/ysorder/SysOrder/StopOrder"
// 暂停订单状态 /ysorder/SysOrder/SuspendOrder
Vue.prototype.$SysOrder_SuspendOrder = "/ysorder/SysOrder/SuspendOrder"
// 恢复订单状态 /ysorder/SysOrder/RecoveryOrder
Vue.prototype.$SysOrder_RecoveryOrder = "/ysorder/SysOrder/RecoveryOrder"
// 审核订单状态 /ysorder/SysOrder/AuditOrder
Vue.prototype.$SysOrder_AuditOrder = "/ysorder/SysOrder/AuditOrder"
// 取消审核订单状态 /ysorder/SysOrder/CancelAuditOrder
Vue.prototype.$SysOrder_CancelAuditOrder = "/ysorder/SysOrder/CancelAuditOrder"
// 销售分页查询合同数据 /ysorder/SysContract/GetSalePageList
Vue.prototype.$SysContract_GetSalePageList = "/ysorder/SysContract/GetSalePageList"
// 采购分页查询数据 /ysorder/SysContract/GetPurchasePageList
Vue.prototype.$SysContract_GetPurchasePageList = "/ysorder/SysContract/GetPurchasePageList"
// 承运信息根据行业获取公司名称 /ysmanage/SysCarry/GetCustomerList
Vue.prototype.$SysCarry_GetCustomerList = "/ysmanage/SysCarry/GetCustomerList"
// 承运信息根据根据公司ID获取承运车辆信息 /ysmanage/SysCarry/GetCarryList
Vue.prototype.$SysCarry_GetCarryList = "/ysmanage/SysCarry/GetCarryList"
// 销售查询客户数据 syspartners/getsalelist
Vue.prototype.$syspartners_getsalelist = "/ysmanage/syspartners/getsalelist"
// 采购查询客户数据 syspartners/getpurchaselist
Vue.prototype.$syspartners_getpurchaselist = "/ysmanage/syspartners/getpurchaselist"
// 订单修改车辆信息 /ysorder/SysOrder/UpdateOrderCarry
Vue.prototype.$SysOrder_UpdateOrderCarry = "/ysorder/SysOrder/UpdateOrderCarry"
// 订单获取车辆长度和净重 /ysorder/sysorder/getordercar
Vue.prototype.$SysOrder_getordercar = "/ysorder/sysorder/getordercar"


// 订单签收查询 http://192.168.2.187:5503/ysorder/SysOrder/GetCarryPageList
Vue.prototype.$SysOrder_GetCarryPageList = "/ysorder/SysOrder/GetCarryPageList"
// 订单签收点击确认 http://192.168.2.187:5503/ysorder/SysOrder/SignCarryOrder
Vue.prototype.$SysOrder_SignCarryOrder = "/ysorder/SysOrder/SignCarryOrder"
// 确认签收 http://192.168.2.187:5503/ysorder/SysOrder/GetSignRecordPageList
Vue.prototype.$SysOrder_GetSignRecordPageList = "/ysorder/SysOrder/GetSignRecordPageList"
// 确认签收 http://192.168.2.187:5503/ysorder/SysOrder/ConfirmSignCarryOrder
Vue.prototype.$SysOrder_ConfirmSignCarryOrder = "/ysorder/SysOrder/ConfirmSignCarryOrder"
// 运输过程查询 http://192.168.2.187:5503/ysorder/SysOrder/GetCarryProcessPageList
Vue.prototype.$SysOrder_GetCarryProcessPageList = "/ysorder/SysOrder/GetCarryProcessPageList"


// 订单账款记录查询 /ysorder/SysOrder/GetOrderSttlementRecordPageList
Vue.prototype.$SysOrder_GetOrderSttlementRecordPageList = "/ysorder/SysOrder/GetOrderSttlementRecordPageList"
// 订单账款记录设置实收金额 /ysorder/SysOrder/SetReceiptsAmount
Vue.prototype.$SysOrder_SetReceiptsAmount = "/ysorder/SysOrder/SetReceiptsAmount"


// 12工单管理
// 12.1工单分页查询 /ysmanage/SysJob/GetPageSysJobList
Vue.prototype.$WorkOrderManager_PostWorkOrderList = "/ysmanage/SysJob/GetPageSysJobList"
// 12.2工单条件查询 /ysmanage/SysJob/Get
Vue.prototype.$WorkOrderManager_GetWorkOrder = "/ysmanage/SysJob/Get"
// 12.3工单新增 /ysmanage/SysJob/CreateSysJob
Vue.prototype.$WorkOrderManager_PostWorkOrder = "/ysmanage/SysJob/CreateSysJob"
// 12.4工单删除 /ysmanage/SysJob/DeleteSysJobById
Vue.prototype.$WorkOrderManager_DeleteWorkOrder = "/ysmanage/SysJob/DeleteSysJobById"
// 12.5工单编辑 /ysmanage/SysJob/UpdateSysJob
Vue.prototype.$WorkOrderManager_PutWorkOrder = "/ysmanage/SysJob/UpdateSysJob"

// 销售报表详情 /ysreprot/salereport​​/getpagesalelist
Vue.prototype.$SaleReport_getpagesalelist = "/ysreprot/salereport/getpagesalelist"
// 销售报表详情 /ysreprot/salereport​​/getpagesalelist
Vue.prototype.$SaleReport_getpagesalelist2 = "/ysreprot/salereport/getpagesalelist2"
// 销售报表导出 /ysreprot/File/ExportSaleOrders
Vue.prototype.$File_ExportSaleOrders = "/ysreprot/File/ExportSaleOrders"
// 销售报表统计导出 /ysreprot/File/ExportSaleProductOrders
Vue.prototype.$File_ExportSaleProductOrders= "/ysreprot/File/ExportSaleProductOrders"

// 采购报表详情 /ysreprot/PurchaserReport/GetPagePurchaseList
Vue.prototype.$PurchaserReport_GetPagePurchaseList = "/ysreprot/PurchaserReport/GetPagePurchaseList"
// 采购报表详情 /ysreprot/PurchaserReport/GetPagePurchaseList
Vue.prototype.$PurchaserReport_GetPagePurchaseList2 = "/ysreprot/PurchaserReport/GetPagePurchaseList2"
// 采购报表导出 /ysreprot/File/ExportPurchaserOrders
Vue.prototype.$File_ExportPurchaserOrders = "/ysreprot/File/ExportPurchaserOrders"
// 采购报表统计导出 /ysreprot/File/ExportPurchaserProductOrders
Vue.prototype.$File_ExportPurchaserProductOrders = "/ysreprot/File/ExportPurchaserProductOrders"

// 首页数据
Vue.prototype.$SaleReport_GetHomeReport = "/ysreprot/SaleReport/GetHomeReport"
// 公告数据
Vue.prototype.$SysOrder_GetTipsInfo = "/ysorder/SysOrder/GetTipsInfo"
// 验证签收人
Vue.prototype.$SysOrder_GetCarryTel = "/ysorder/SysOrder/GetCarryTel"



new Vue({
  router, 
  render: h => h(App),
  store,
}).$mount('#app')
