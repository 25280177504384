<template>
    <div class="dialog_body">
        <el-form style="width:100%;" size="small" ref="addform" :rules="addformRules" :model="addform" label-width="110px" class="demo-form-inline">
            <!-- 基础信息 合同名称 编码 类型 -->
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item label="合同名称:" prop="name">
                        <span >{{addform.name}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="合同编码:" prop="code">
                        <span >{{addform.code}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="业务类型:" prop="businessType" >
                        <span v-for="item in businessArr" :key="item.value" :label="item.value">
                            <span v-if="addform.businessType == item.value">{{item.name}}</span>
                        </span>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- 企业信息 选择企业 -->
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item label="选择企业:" prop="">
                        <span v-show="addform.businessType == 0">{{enterpriseData.purchaseName}}</span>
                        <span v-show="addform.businessType == 1">{{enterpriseData.enterpriseName}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label-width="" label="联系人名:">
                        <span v-show="addform.businessType == 0">{{enterpriseData.purchaseContacts}}</span>
                        <span v-show="addform.businessType == 1">{{enterpriseData.contacts}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label-width="" label="联系电话:">
                        <span v-show="addform.businessType == 0">{{enterpriseData.purchaseContactTel}}</span>
                        <span v-show="addform.businessType == 1">{{enterpriseData.contactTel}}</span>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item label-width="" label="企业税号:">
                        <span v-show="addform.businessType == 0">{{enterpriseData.purchaseTaxNumber}}</span>
                        <span v-show="addform.businessType == 1">{{enterpriseData.taxNumber}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label-width="" label="开户银行:">
                        <span v-show="addform.businessType == 0">{{enterpriseData.purchaseBankName}}</span>
                        <span v-show="addform.businessType == 1">{{enterpriseData.bankName}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label-width="" label="银行账户:">
                        <span v-show="addform.businessType == 0">{{enterpriseData.purchaseBankNo}}</span>
                        <span v-show="addform.businessType == 1">{{enterpriseData.bankNo}}</span>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item label-width="" label="注册地址:">
                        <span v-show="addform.businessType == 0">{{enterpriseData.purchaseDomicile}}</span>
                        <span v-show="addform.businessType == 1">{{enterpriseData.domicile}}</span>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- 矿石商品表格 -->
            <el-row v-if="addform.contractSands.length !== 0" :gutter="24">
                <el-col :span="22">
                    <el-form-item label-width="" label="矿石商品:">
                        <el-table
                            :header-cell-style="headClass"
                            :cell-style="rowClass"
                            :data="addform.contractSands"
                            height="250px"
                            border
                            style="width: 100%">
                            <el-table-column
                            prop="name"
                            :show-overflow-tooltip="true"
                            label="商品名称"
                            width="150">
                            </el-table-column>
                            <el-table-column
                            prop="standards"
                            :show-overflow-tooltip="true"
                            label="规格"
                            width="">
                            </el-table-column>
                            <el-table-column
                            prop="unit"
                            :show-overflow-tooltip="true"
                            label="单位"
                            width="">
                            </el-table-column>
                            <el-table-column
                            prop="planNumber"
                            :key="1"
                            label="计划数量"
                            width="">
                            </el-table-column>
                            <el-table-column
                            prop="discountPrice"
                            :key="2"
                            :show-overflow-tooltip="true"
                            label="结算单价"
                            width="">
                            </el-table-column>
                            <el-table-column
                            prop="freightPrice"
                            :key="3"
                            :show-overflow-tooltip="true"
                            label="运费单价"
                            width="">
                            </el-table-column>
                            <el-table-column
                            :key="4"
                            label="生产/发货地址"
                            width="">
                            <template slot-scope="scope">
                                <!-- <el-butto @click="addCarryDialog(scope.row)" type="text" size="small">编辑</el-button> -->
                                <el-button @click="detailsAddress(scope.row)" type="text" size="small">详情</el-button>
                            </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- 混凝土商品表格 -->
            <el-row v-if="addform.contractConcretes.length !== 0" :gutter="24">
                <el-col :span="22">
                    <el-form-item label-width="" label="混凝土商品:">
                        <el-table
                            :header-cell-style="headClass"
                            :cell-style="rowClass"
                            :data="addform.contractConcretes"
                            height="250px"
                            border
                            style="width: 100%">
                            <el-table-column
                            prop="name"
                            :show-overflow-tooltip="true"
                            label="商品名称"
                            width="150">
                            </el-table-column>
                            <el-table-column
                            prop="standards"
                            :show-overflow-tooltip="true"
                            label="强度等级"
                            width="">
                            </el-table-column>
                            <el-table-column
                            prop="impermeability"
                            :show-overflow-tooltip="true"
                            label="抗渗等级"
                            width="">
                            </el-table-column>
                            <el-table-column
                            prop="slump"
                            label="塌落度"
                            width="">
                            </el-table-column>
                            <el-table-column
                            prop="aggregateSize"
                            :key="5"
                            :show-overflow-tooltip="true"
                            label="骨料粒径"
                            width="150">
                            </el-table-column>
                            <el-table-column
                            prop="pouringMethod"
                            :key="6"
                            :show-overflow-tooltip="true"
                            label="浇筑方式"
                            width="150">
                            </el-table-column>
                            <el-table-column
                            prop="constructionPosition"
                            :key="7"
                            :show-overflow-tooltip="true"
                            label="施工部位"
                            width="150">
                            </el-table-column>
                            <el-table-column
                            prop="planNumber"
                            :key="8"
                            :show-overflow-tooltip="true"
                            label="计划数量(m³)"
                            width="150">
                            </el-table-column>
                            <el-table-column
                            prop="discountPrice"
                            :key="9"
                            :show-overflow-tooltip="true"
                            label="结算单价"
                            width="150">
                            </el-table-column>
                            <el-table-column
                            prop="freightPrice"
                            :key="11"
                            :show-overflow-tooltip="true"
                            label="运费单价"
                            width="150">
                            </el-table-column>
                            <el-table-column
                            prop="pumpingPrice"
                            :key="12"
                            :show-overflow-tooltip="true"
                            label="泵送单价"
                            width="150">
                            </el-table-column>
                            <el-table-column
                            fixed="right"
                            :key="13"
                            label="生产/发货地址"
                            width="150">
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- 充值支付 -->
            <div v-if="payType1 == false & payType2 == false & payType3 == false" style="display: flex">
                <el-form-item label="" label-width="30px">
                    <span>支付方式：充值支付</span>
                </el-form-item>
                <el-form-item v-if="payType0 == true" label="充值金额:">
                    <span style="width:100px">{{addform.payAmount}}元</span>
                </el-form-item>
                <el-form-item v-if="payType0 == true" label="金额提示:" label-width="130px">
                    <span style="width: 100px">{{addform.creditTipsAmount}}元</span>
                </el-form-item>
            </div>
            <!-- 授信支付 -->
            <div v-if="payType0 == false & payType3 == false" style="display: flex">
                <el-form-item label="" label-width="30px">
                    <span>支付方式：授信支付</span>
                </el-form-item>
                <el-form-item v-if="payType1 == true" label="授信金额:">
                    <span style="width: 100px">{{addform.payAmount}}元</span>
                </el-form-item>
                <el-form-item v-if="payType1 == true" label="" label-width="15px">
                    <div class="block" style="display:flex">
                        <span v-if="addform.isDate != false">应收货款日期:{{addform.repaymentDate}}</span>
                    </div>
                </el-form-item>
                <el-form-item v-if="payType1 == true" label="应收货款提示:" label-width="130px">
                    <span style="width: 100px">{{addform.creditTipsAmount}}元</span>
                </el-form-item>
                <el-form-item v-if="payType1 == true" label="" label-width="5px">
                    <span style="width:100%">上笔应收贷款: {{addform.receivableAmount}}元</span>
                </el-form-item>
            </div>
            <!-- 账期支付 -->
            <div v-if="payType0 == false & payType3 == false" style="display: flex">
                <el-form-item label="" label-width="30px">
                    <span>支付方式：账期支付</span>
                </el-form-item>
                <el-form-item v-if="payType2 == true" label="还款日期:">
                    <span>{{addform.repaymentTime1}}月</span>
                </el-form-item>
                <el-form-item v-if="payType2 == true" style="margin-left:7px" label="-" label-width="14px">
                    <span>{{addform.repaymentTime2}}天</span>
                </el-form-item>
                <el-form-item v-if="payType2 == true" style="margin-left:2px" label="" label-width="0px">
                    <span style="color:red">注：为几个月之后的多少天</span>
                </el-form-item>
                <el-form-item v-if="payType2 == true" label="账期提示日期:" label-width="130px">
                    <span>{{addform.tipsDay}}</span>
                </el-form-item>
                <el-form-item v-if="payType2 == true" label="" label-width="5px">
                    <span style="width:100%">上笔应收贷款: {{addform.receivableAmount}}元</span>
                </el-form-item>
            </div>
            <!-- 现结支付 -->
            <div v-if="payType0 == false & payType1 == false & payType2 == false" style="display: flex">
                <el-form-item label="" label-width="30px">
                    <span>支付方式：现金支付</span>
                </el-form-item>
            </div>
            <!-- 分配金额 -->
            <div v-if="payType0 == true || payType1 == true">
                <el-form-item v-if="addform.enterpriseId != 0" label="分配金额:" label-width="110px">
                    <el-form-item v-for="(item,index) in addressGroupsYuan" :key="item.addressId" :label="'厂区'+(index+1)+'： '+item.addressName" :value="item.addressId" label-width="250px">
                        <span>{{item.price}}元</span> <span v-if="item.isStop == true">当前可调整金额为{{item.receiptsPrice}}元</span>
                    </el-form-item>
                </el-form-item>
            </div>
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item label="承运方式:" prop="carryModel">
                        <span v-if="addform.carryModel == 0">买家自提</span>
                        <span v-if="addform.carryModel == 1">卖方配送</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="运费计算:" prop="freightCalculation">
                        <el-radio-group v-model="addform.freightCalculation">
                            <span v-if="addform.freightCalculation == 0">按吨数</span>
                            <span v-if="addform.freightCalculation == 1">按公里</span>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- 混凝土版本其他信息 -->
            <el-row :gutter="24">
                <el-col v-show="addform.industryType == 1 || addform.industryType == '0,1'" :span="8">
                    <el-form-item label="施工单位:" prop="unitName">
                        <span>{{addform.unitName}}</span>
                    </el-form-item>
                </el-col>
                <el-col v-show="addform.industryType == 1 || addform.industryType == '0,1'" :span="8">
                    <el-form-item label="工程名称:" prop="projectName">
                        <span>{{addform.projectName}}</span>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item label="销售人员:" prop="salesman">
                        <span>{{addform.salesman}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="销售电话:" prop="salesTel">
                        <span >{{addform.salesTel}}</span>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item label="备注:" prop="remarks">
                        <span>{{addform.remarks}}</span>
                    </el-form-item>
                </el-col>
            </el-row>
            <div style="width:100%;text-align:right;margin-left:-3%">
                <el-form-item label="" prop="">
                    <el-button @click="CloseAddDialog('addform')">取 消</el-button>
                    <el-button type="primary" @click="AdjustConfirm('addform')">确 定</el-button>
                </el-form-item>
            </div>
        </el-form>
        <el-dialog
            title="详情"
            :visible.sync="CarryDetailsDialog"
            :before-close="handleClose"
            :modal-append-to-body="false"
            :append-to-body="true"
            :show-close="false"
            style="margin-top:2%"
            width="60%">
                <el-table
                    :header-cell-style="headClass"
                    :cell-style="rowClass"
                    :data="AddressData"
                    height="400px"
                    border
                    style="width: 100%">
                    <el-table-column
                    prop="address"
                    :show-overflow-tooltip="true"
                    label="生产地址"
                    width="150">
                    </el-table-column>
                    <el-table-column
                    prop="receivingAddress"
                    :show-overflow-tooltip="true"
                    label="收货地址"
                    width="">
                    </el-table-column>
                    <el-table-column
                    prop="calculatedMileage"
                    label="计算里程"
                    width="">
                    </el-table-column>
                    <el-table-column
                    prop="confirmMileage"
                    :show-overflow-tooltip="true"
                    label="确认里程"
                    width="">
                    </el-table-column>
                    <el-table-column
                    prop="arrivalDate"
                    :show-overflow-tooltip="true"
                    label="到货日期"
                    width="">
                    </el-table-column>
                </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click="CarryDetailsDialog = false">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data(){
        return {
            expireTimeOPtion: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;  //如果没有后面的-8.64e7就是不可以选择今天的 
                }
            },
            addform: {
                id: '',
                enterpriseId: 0,
                enterpriseName: "",
                name: "",
                code: "",
                purchaseId: 0,
                purchaseName: "",
                taxNumber: "",
                telephone: "",
                domicile: "",
                bankName: "",
                bankNo: "",
                contacts: "",
                contactTel: "",
                purchaseTaxNumber: '',
                purchaseTelephone: '',
                purchaseDomicile: '',
                purchaseBankName: '',
                purchaseBankNo: '',
                purchaseContacts: '',
                purchaseContactTel: '',
                purchaseBusinessName: '',
                purchaseBusinessUserId: '',
                purchaseBusinessTel: '',
                payType: '',
                payAmount: '',
                isDate: false,
                repaymentDate: "",
                creditTipsAmount: '',
                creditTipsDay: '',
                repaymentTime: "",
                repaymentTime1: '',
                repaymentTime2: '',
                tipsDay: '',
                carryModel: 0,
                freightCalculation: 0,
                unitName: "",
                projectName: "",
                salesman: "",
                salesTel: "",
                remarks: "",
                receivableAmount: 0,
                receiptsAmount: '',
                type: 0,
                businessType: '0',
                industryType: 0,
                contractSands: [],
                contractConcretes: [],
                contractAddresses: [],
                creatorId: 0,
                addressGroups: [],
                partnersId: ''
            },
            businessArr: [],
            addformRules: {},
            enterpriseData: [],
            AddressData: [],
            enterpriseName: '',
            ch0: false,
            ch1: false,
            // 添加承运信息表单
            CarryForm: {
                productId: '',
                productName: '',
                addressId: '',
                address: '',
                longitude: '',
                latitude: '',
                addressName: '',
                warehouseNumber: '0',
                receivingAddress: '',
                receivingLongitude: '',
                receivingLatitude: '',
                receivingWarehouseNumber: '0',
                calculatedMileage: '',
                confirmMileage: '',
                arrivalDate: '',
                receivingAddressId: ''
            },
            enterpriseRadio: -1,
            payType0: false,
            payType1: false,
            payType2: false,
            payType3: false,
            addressGroupsYuan: [],
            CarryDetailsDialog: false,
        }
    },
    mounted(){
        this.businessArr = JSON.parse(window.sessionStorage.getItem('business'))
    },
    methods: {
        // 表头 列 居中
        headClass() {
            return "text-align:center;background-color: #c8e0f391;color:#E6A23C";
        },
        rowClass() {
            return "text-align:center";
        },
        handleClose(){

        },
        // 查看地址信息详情
        detailsAddress(row){
            this.AddressData = []
            this.addform.contractAddresses.forEach(item => {
                if(row.id == item.productId){
                    this.AddressData.push(item)
                }
            })
            this.CarryDetailsDialog = true
        }, 
        CloseAddDialog(formName){
            this.enterpriseName = ''
            this.$refs[formName].resetFields();
            this.restaddfrom()
            this.$emit('CLOSE')
        },
        // 重置表单
        restaddfrom(){
            this.addform = {
                id: '',
                enterpriseId: 0,
                enterpriseName: "",
                name: "",
                code: "",
                purchaseId: 0,
                purchaseName: "",
                taxNumber: "",
                telephone: "",
                domicile: "",
                bankName: "",
                bankNo: "",
                contacts: "",
                contactTel: "",
                purchaseTaxNumber: '',
                purchaseTelephone: '',
                purchaseDomicile: '',
                purchaseBankName: '',
                purchaseBankNo: '',
                purchaseContacts: '',
                purchaseContactTel: '',
                purchaseBusinessName: '',
                purchaseBusinessUserId: '',
                purchaseBusinessTel: '',
                payType: '',
                payAmount: '',
                isDate: false,
                repaymentDate: "",
                creditTipsAmount: '',
                creditTipsDay: '',
                repaymentTime: "",
                repaymentTime1: '',
                repaymentTime2: '',
                tipsDay: '',
                carryModel: 0,
                freightCalculation: 0,
                unitName: "",
                projectName: "",
                salesman: "",
                salesTel: "",
                remarks: "",
                receivableAmount: 0,
                receiptsAmount: '',
                type: 0,
                businessType: '0',
                industryType: 0,
                contractSands: [],
                contractConcretes: [],
                contractAddresses: [],
                addressGroups: []
            }
            this.CarryForm = {
                productId: '',
                productName: '',
                addressId: '',
                address: '',
                longitude: '',
                latitude: '',
                warehouseNumber: '0',
                receivingAddress: '',
                receivingLongitude: '',
                receivingLatitude: '',
                receivingWarehouseNumber: '0',
                calculatedMileage: '',
                confirmMileage: '',
                arrivalDate: '',
                receivingAddressId: '',
            }
            this.ch0 = false
            this.ch1 = false
            this.enterpriseRadio = -1
            this.payType0 = false,
            this.payType1 = false,
            this.payType2 = false,
            this.payType3 = false,
            this.addressGroupsYuan = []
        },
        AdjustmentSure(auditid) {
            this.auditid = auditid
            this.$http.get(this.$SysContract_GetInfo+'?code='+auditid).then(response => {
                this.loading = false
                this.tiaozhengduixiang = response.data.data
                if(response.data.data.businessType == 0){
                    if(response.data.data.modeType == 0){
                        this.enterpriseName = response.data.data.purchaseName
                        this.enterpriseRadio = Number(response.data.data.enterpriseId)
                        // this.querySysEnterprise()
                        this.enterpriseData = {
                            purchaseName: response.data.data.purchaseName,
                            purchaseContacts: response.data.data.purchaseContacts,
                            purchaseContactTel: response.data.data.purchaseContactTel,
                            purchaseTaxNumber: response.data.data.purchaseTaxNumber,
                            purchaseDomicile: response.data.data.purchaseDomicile,
                            purchaseBankName: response.data.data.purchaseBankName,
                            purchaseBankNo: response.data.data.purchaseBankNo,
                        }
                    }else{
                        this.enterpriseName = response.data.data.enterpriseName
                        this.enterpriseRadio = Number(response.data.data.enterpriseId)
                        this.enterpriseData = {
                            purchaseName: response.data.data.enterpriseName,
                            purchaseContacts: response.data.data.contacts,
                            purchaseContactTel: response.data.data.contactTel,
                            purchaseTaxNumber: response.data.data.taxNumber,
                            purchaseDomicile: response.data.data.domicile,
                            purchaseBankName: response.data.data.bankName,
                            purchaseBankNo: response.data.data.bankNo,
                        }
                    }
                }else{
                    if(response.data.data.modeType == 1){
                        this.enterpriseName = response.data.data.purchaseName
                        this.enterpriseRadio = Number(response.data.data.enterpriseId)
                        // this.querySysEnterprise()
                        this.enterpriseData = {
                            enterpriseName: response.data.data.purchaseName,
                            contacts: response.data.data.purchaseContacts,
                            contactTel: response.data.data.purchaseContactTel,
                            taxNumber: response.data.data.purchaseTaxNumber,
                            domicile: response.data.data.purchaseDomicile,
                            bankName: response.data.data.purchaseBankName,
                            bankNo: response.data.data.purchaseBankNo,
                        }
                    }else{
                        this.enterpriseName = response.data.data.enterpriseName
                        this.enterpriseRadio = Number(response.data.data.enterpriseId)
                        this.enterpriseData = {
                            enterpriseName: response.data.data.enterpriseName,
                            contacts: response.data.data.contacts,
                            contactTel: response.data.data.contactTel,
                            taxNumber: response.data.data.taxNumber,
                            domicile: response.data.data.domicile,
                            bankName: response.data.data.bankName,
                            bankNo: response.data.data.bankNo,
                        }
                    }
                }
                this.addform.id =  response.data.data.id,
                this.addform.enterpriseId = Number(response.data.data.enterpriseId),
                this.addform.enterpriseName = response.data.data.enterpriseName,
                this.addform.name = response.data.data.name,
                this.addform.code = response.data.data.code,
                this.addform.purchaseId = Number(response.data.data.purchaseId),
                this.addform.purchaseName = response.data.data.purchaseName,
                this.addform.taxNumber = response.data.data.taxNumber,
                this.addform.telephone = response.data.data.telephone,
                this.addform.domicile = response.data.data.domicile,
                this.addform.bankName = response.data.data.bankName,
                this.addform.bankNo = response.data.data.bankNo,
                this.addform.contacts = response.data.data.contacts,
                this.addform.contactTel = response.data.data.contactTel,
                this.addform.purchaseTaxNumber = response.data.data.purchaseTaxNumber,
                this.addform.purchaseTelephone = response.data.data.purchaseTelephone,
                this.addform.purchaseDomicile = response.data.data.purchaseDomicile,
                this.addform.purchaseBankName = response.data.data.purchaseBankName,
                this.addform.purchaseBankNo = response.data.data.purchaseBankNo,
                this.addform.purchaseContacts = response.data.data.purchaseContacts,
                this.addform.purchaseContactTel = response.data.data.purchaseContactTel,
                this.addform.purchaseBusinessName = response.data.data.purchaseBusinessName,
                this.addform.purchaseBusinessUserId = response.data.data.purchaseBusinessUserId,
                this.addform.purchaseBusinessTel = response.data.data.purchaseBusinessTel,
                this.addform.payType = response.data.data.payType
                if(this.addform.payType == 0){
                    this.payType0 = true
                }else if(this.addform.payType == 1){
                    this.payType1 = true
                }else if(this.addform.payType == 2){
                    this.payType2 = true
                }else if(this.addform.payType == 3){
                    this.payType3 = true
                }else if(this.addform.payType == '1,2'){
                    this.payType1 = true
                    this.payType2 = true
                }
                this.addform.payAmount = response.data.data.payAmount,
                this.addform.isDate = response.data.data.isDate,
                this.addform.repaymentDate = response.data.data.repaymentDate,
                this.addform.creditTipsAmount = response.data.data.creditTipsAmount,
                this.addform.creditTipsDay = response.data.data.creditTipsDay,
                this.addform.repaymentTime = response.data.data.repaymentTime
                var i1 = response.data.data.repaymentTime.indexOf("-");
                this.addform.repaymentTime1 = response.data.data.repaymentTime.slice(0,i1)
                this.addform.repaymentTime2 = response.data.data.repaymentTime.slice(i1+1)
                this.addform.tipsDay = response.data.data.tipsDay,
                this.addform.carryModel = Number(response.data.data.carryModel),
                this.addform.freightCalculation = Number(response.data.data.freightCalculation),
                this.addform.unitName = response.data.data.unitName,
                this.addform.projectName = response.data.data.projectName,
                this.addform.salesman = response.data.data.salesman,
                this.addform.salesTel = response.data.data.salesTel,
                this.addform.remarks = response.data.data.remarks,
                this.addform.receivableAmount = response.data.data.receivableAmount,
                this.addform.receiptsAmount = response.data.data.receiptsAmount,
                this.addform.type = Number(response.data.data.type),
                this.addform.businessType = JSON.stringify(response.data.data.businessType),
                this.addform.industryType = response.data.data.industryType
                this.addform.creatorId = response.data.data.creatorId
                if(this.addform.industryType == '0'){
                    this.ch0 = true
                    this.ch1 = false
                    response.data.data.contractSands.forEach(item => {
                        this.addform.contractSands.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            unit: item.unit,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            planNumber: item.planNumber,
                            standardsCode: item.standardsCode
                        })
                    })
                }else if(this.addform.industryType == '1'){
                    this.ch1 = true
                    this.ch0 = false
                    response.data.data.contractConcretes.forEach(item => {
                        this.addform.contractConcretes.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            impermeability: item.impermeability,
                            slump: item.slump,
                            aggregateSize: item.aggregateSize,
                            pouringMethod: item.pouringMethod,
                            constructionPosition: item.constructionPosition,
                            planNumber: item.planNumber,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            pumpingPrice: item.pumpingPrice,
                            unit: item.unit,
                            standardsCode: item.standardsCode,
                            impermeabilityCode: item.impermeabilityCode
                        })
                    })
                }else{
                    this.ch0 = true
                    response.data.data.contractSands.forEach(item => {
                        this.addform.contractSands.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            unit: item.unit,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            planNumber: item.planNumber,
                            standardsCode: item.standardsCode
                        })
                    })
                    this.ch1 = true
                    response.data.data.contractConcretes.forEach(item => {
                        this.addform.contractConcretes.push({
                            id: item.productId,
                            name: item.productName,
                            standards: item.standards,
                            impermeability: item.impermeability,
                            slump: item.slump,
                            aggregateSize: item.aggregateSize,
                            pouringMethod: item.pouringMethod,
                            constructionPosition: item.constructionPosition,
                            planNumber: item.planNumber,
                            discountPrice: item.price,
                            freightPrice: item.freightPrice,
                            pumpingPrice: item.pumpingPrice,
                            standardsCode: item.standardsCode,
                            impermeabilityCode: item.impermeabilityCode
                        })
                    })
                }
                this.addform.contractAddresses = response.data.data.contractAddresses
                this.addform.addressGroups = response.data.data.addressGroups
                // 分配厂区金额
                var idarr = []
                this.addform.addressGroups.forEach(aa => {
                    if(idarr.indexOf(aa.addressId) === -1){
                        idarr.push(aa.addressId)
                        this.addressGroupsYuan.push({
                            addressId: aa.addressId,
                            addressName: aa.addressName,
                            price: Number(aa.price),
                            isStop: aa.isStop,
                            receiptsPrice: aa.receiptsPrice,
                        })
                    }
                })
                this.dialogVisible = true
            })
        },
        // 调整确认
        AdjustConfirm(){
            this.$confirm('此操作将确认该合同, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysContract_AdjustConfirmContract,{
                    contractNo : this.auditid,
                    aduitRemarks : ''
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('确认成功')
                        this.$emit('CLOSE')
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
    }
}
</script>
<style scoped>
.dialog_body{
    height: 600px;
    padding: 0px 10px;
    overflow-y: scroll;
    overflow-x: hidden;
}
/deep/.el-dialog__header {
    padding: 13px 20px 13px;
    background-color: skyblue;
}
/deep/.el-dialog__footer {
    /* min-width: 1300px; */
}
</style>