<template>
    <div class="body" v-loading="loading" element-loading-text="拼命加载中" element-loading-background="rgb(213 213 213 / 57%)">
        <!-- 查询 -->
        <div>
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item label="合同编号">
                    <el-input v-model="form.contractNo" placeholder="请输入合同编号"></el-input>
                </el-form-item>
                <el-form-item label="企业名称：">
                    <el-input v-model="form.name" placeholder="请输入企业名称"></el-input>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['ContractManagement_query'])" label="">
                    <el-button type="primary" @click="query()">查询</el-button>
                </el-form-item>
                <!-- <el-form-item v-if="$hasPermission(['ContractManagement_add'])">
                    <el-button type="primary" @click="add()">新增</el-button>
                </el-form-item> -->
                <el-form-item v-if="$hasPermission(['ContractManagement_add'])">
                    <el-button type="primary" @click="OpenAddDialog()">新增</el-button>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['ContractManagement_audit']) & AuditiSactive == false & AuditmodeType == 0">
                    <el-button type="primary" @click="Audit()">审核</el-button>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['ContractManagement_audit']) & AuditiSactive == true & Auditstate == 0 & AuditmodeType == 0">
                    <el-button type="primary" @click="CancelAudit()">取消审核</el-button>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['ContractManagement_Sure']) & AuditiSactive == true & Auditstate == 0 & AuditmodeType == 1">
                    <el-button type="primary" @click="Sure()">确认</el-button>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['ContractManagement_Sure']) & AuditiSactive == true & Auditstate == 1 & AuditmodeType == 1">
                    <el-button type="primary" @click="CancelSure()">取消确认</el-button>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['ContractManagement_AdjustConfirm']) & AuditiSactive == true & Auditstate == 7 & AuditmodeType == 1">
                    <el-button type="primary" @click="AdjustmentSure()">调整确认</el-button>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['ContractManagement_AdjustConfirm']) & AuditiSactive == true & Auditstate == 7 & AuditmodeType == 1">
                    <el-button type="primary" @click="CancelAdjustmentSure()">取消确认</el-button>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['ContractManagement_AdjustConfirm']) & AuditiSactive == true & Auditstate == 7 & AuditmodeType == 0 & AuditUserid == 0">
                    <el-button type="primary" @click="AdjustmentSure()">调整确认</el-button>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['ContractManagement_AdjustConfirm']) & AuditiSactive == true & Auditstate == 7 & AuditmodeType == 0 & AuditUserid == 0">
                    <el-button type="primary" @click="CancelAdjustmentSure()">取消确认</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- 表格 -->
        <div>
            <el-table
                :header-cell-style="headClass"
                :cell-style="rowClass"
                :data="tableData"
                :height="tableHeight"
                highlight-current-row
                @cell-dblclick="dblclick"
                border
                style="width: 100%">
                <el-table-column
                fixed
                prop="date"
                label="选择"
                width="75">
                    <template slot-scope="scope">
                        <el-radio :label="scope.row.id" v-model="radio" @change.native="getCurrentRow(scope.row)">{{''}}</el-radio>
                    </template>
                </el-table-column>
                <el-table-column
                prop="code"
                :show-overflow-tooltip="true"
                label="合同编号"
                width="180">
                </el-table-column>
                <el-table-column
                prop="name"
                :show-overflow-tooltip="true"
                label="合同名称"
                width="">
                </el-table-column>
                <el-table-column
                prop="enterpriseName"
                :formatter="aa"
                :show-overflow-tooltip="true"
                label="企业名称"
                width="">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                :formatter="payType"
                prop="payType"
                label="支付方式"
                width="">
                </el-table-column>
                <el-table-column
                prop="carryModel"
                :formatter="carryModel"
                :show-overflow-tooltip="true"
                label="承运方式"
                width="">
                </el-table-column>
                <el-table-column
                prop="businessType"
                :formatter="industryType"
                :show-overflow-tooltip="true"
                label="业务类型"
                width="">
                </el-table-column>
                <el-table-column
                prop="receivableAmount"
                :show-overflow-tooltip="true"
                label="应收款项"
                width="">
                </el-table-column>
                <el-table-column
                prop="receiptsAmount"
                :show-overflow-tooltip="true"
                label="实时款项"
                width="">
                </el-table-column>
                <el-table-column
                prop="arrears"
                :formatter="arrears"
                :show-overflow-tooltip="true"
                label="欠款"
                width="">
                </el-table-column>
                <el-table-column
                prop="isActive"
                :formatter="isActive"
                :show-overflow-tooltip="true"
                label="审核状态"
                width="">
                </el-table-column>
                <el-table-column
                prop="state"
                :formatter="state"
                :show-overflow-tooltip="true"
                label="确认状态"
                width="">
                </el-table-column>
                <el-table-column
                width="150"
                prop="creationTime"
                :show-overflow-tooltip="true"
                label="创建时间">
                </el-table-column>
                <el-table-column
                fixed="right"
                label="操作"
                width="200">
                <template slot-scope="scope">
                    <el-button v-if="$hasPermission(['ContractManagement_details'])" @click="handleClick(scope.row)" type="text" size="small">详情</el-button>
                    <el-button v-if="$hasPermission(['ContractManagement_edit']) & scope.row.isActive == false" @click="modify(scope.row)" type="text" size="small">编辑</el-button>
                    <el-button v-if="$hasPermission(['ContractManagement_delete']) & scope.row.isActive == false" @click="Delete(scope.row)" type="text" size="small">删除</el-button>
                    <el-button v-if="$hasPermission(['ActuallyReceived']) & scope.row.modeType == 0 & scope.row.isActive == true & scope.row.state > 0" @click="receipts(scope.row)" type="text" size="small">实收款项</el-button>
                    <el-button v-if="$hasPermission(['ContractManagement_Adjustment']) & scope.row.isActive == true & scope.row.modeType == 0 &  3 > scope.row.state > 0" @click="Adjustment(scope.row)" type="text" size="small">调整</el-button>
                    <el-button v-if="$hasPermission(['ContractManagement_Adjustment']) & scope.row.modeType == 0 & scope.row.state > 6" @click="AdjustmentEdit(scope.row)" type="text" size="small">调整</el-button>
                </template>
                </el-table-column>
            </el-table>
            <!-- 表格分页 -->
            <div style="float:right" class="block">
                <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10, 15, 20]"
                :page-size="size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
                </el-pagination>
            </div>
        </div>
        <!-- 实收款项 -->
        <div>
            <el-dialog
                title="实收款项"
                :visible.sync="receiptsAmountDialog"
                :before-close="handleClose"
                :show-close="false"
                width="400px">
                <div style="display:flex">
                    <el-input style="width:320px" placeholder="请输入实收金额" v-model="receiptsAmount" clearable></el-input>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="receiptsAmountDialog = false,start()">取 消</el-button>
                    <el-button type="primary" @click="Surereceipts()">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div>
            <!-- 添加和修改表单 -->
            <el-dialog
                :title="title"
                :visible.sync="AddEditDialog"
                :before-close="handleClose"
                style="margin-top:-3%"
                width="70%">
                <AddEditDialog @SURE='SureAddContract' @CLOSE='CloseAddDialog' ref="RefAddEditDialog"></AddEditDialog>
            </el-dialog>
            <!-- 详情表单 -->
            <el-dialog
                :title="title"
                :visible.sync="DetailDialog"
                :before-close="handleClose"
                style="margin-top:-3%"
                width="70%">
                <DetailDialog @CLOSE='CloseDetailDialog' ref="RefDetailDialog"></DetailDialog>
            </el-dialog>
            <!-- 调整和调整编辑表单 -->
            <el-dialog
                :title="title"
                :visible.sync="AdjustDialog"
                :before-close="handleClose"
                style="margin-top:-3%"
                width="70%">
                <AdjustDialog @CLOSE='CloseAdjustDialog' ref="RefAdjustDialog"></AdjustDialog>
            </el-dialog>
            <!-- 调整确认表单 -->
            <el-dialog
                :title="title"
                :visible.sync="AdjustSureDialog"
                :before-close="handleClose"
                style="margin-top:-3%"
                width="70%">
                <AdjustSureDialog @CLOSE='CloseAdjustSureDialog' ref="RefAdjustSureDialog"></AdjustSureDialog>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import AddEditDialog from '@/view/ContractManagement/AddEditDialog.vue'
import DetailDialog from '@/view/ContractManagement/DetailDialog.vue'
import AdjustDialog from '@/view/ContractManagement/AdjustDialog.vue'
import AdjustSureDialog from '@/view/ContractManagement/AdjustSureDialog.vue'
export default {
    components: { AddEditDialog , DetailDialog , AdjustDialog , AdjustSureDialog},
    computed: {
        // tableHeight: document.documentElement.clientHeight
    },
    created() {
        var height = document.documentElement.clientHeight
        this.tableHeight = (height*0.67)+'px';
    },
    data(){
        return{
            loading: false,
            tableHeight: 0,
            form: {
                name: '',
                contractNo: ''
            },
            // 日期
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                },
            },
            tableData: [],
            // 表格分页
            page: 1,
            size: 10,
            total: 0,
            // 表格单选绑定
            radio: '',
            // 表单里面的行业类型
            business: [],
            // 添加对话框 企业搜索输入框
            enterpriseName: '',
            // 对话框标题
            title: '新增',
            // 审核id 
            auditid: '',
            AuditiSactive: false,
            AuditmodeType: 0,
            Auditstate: 0,
            AuditUserid: 0,
            // 实收款项对话框
            receiptsAmountDialog: false,
            receiptsAmount0: '',
            receiptsAmount: '',
            receiptsno: '',
            timer: null,// 初始定时器变量名为null
            AddEditDialog: false,
            DetailDialog: false,
            AdjustDialog: false,
            AdjustSureDialog: false
        }
    },
    beforeDestroy() {
        // js提供的clearInterval方法用来清除定时器
        clearInterval(this.timer);
        this.end()
    },
    methods:{
        start() {
            // 将定时器名字赋值到变量中
            this.timer = setInterval(() => {
                // console.log('开始-----')
                this.query()
            }, 20000);
        },
        end() {
            clearInterval(this.timer);
            this.timer = null // 这里最好清除一下，回归默认值
            // 众所周知，定时器返回一个随机整数，用于表示定时器的编号，后面通过名字可以取消这个定时器的执行。
            console.log(this.timer)
        },
        // 表头 列 居中
        headClass() {
            return "text-align:center;background-color: #c8e0f391;color:#E6A23C";
        },
        rowClass() {
            return "text-align:center";
        },
        // 分页功能
        handleSizeChange(val) {
            this.size = val
            this.query()
        },
        handleCurrentChange(val) {
            this.page = val
            this.query()
        },
        // 表格里面判断
        isActive(row){
            if(row.isActive == true){
                return '已审核';
            }else{
                return '未审核'
            }
        },
        // 添加对话框企业信息表格返回值
        name(row){
            if(row.modeType == 0){
                return row.purchaseName;
            }else{
                return row.enterpriseName;
            }
        },
        contacts(row){
            if(row.modeType == 0){
                return row.purchaseContacts;
            }else{
                return row.contacts;
            }
        },
        contactTel(row){
            if(row.modeType == 0){
                return row.purchaseContactTel;
            }else{
                return row.contactTel;
            }
        },
        taxNumber(row){
            if(row.modeType == 0){
                return row.purchaseTaxNumber;
            }else{
                return row.taxNumber;
            }
        },
        domicile(row){
            if(row.modeType == 0){
                return row.purchaseDomicile;
            }else{
                return row.domicile;
            }
        },
        bankName(row){
            if(row.modeType == 0){
                return row.purchaseBankName;
            }else{
                return row.bankName;
            }
        },
        bankNo(row){
            if(row.modeType == 0){
                return row.purchaseBankNo;
            }else{
                return row.bankNo;
            }
        },
        payType(row){
            if(row.payType == 0){
                return '充值支付';
            }else if(row.payType == 1){
                return '授信支付';
            }else if(row.payType == 2){
                return '账期支付';
            }else if(row.payType == 3){
                return '现金支付';
            }else if(row.payType == '1,2'){
                return '账期,授信支付';
            }
        },
        carryModel(row){
            if(row.carryModel == 0){
                return '买家自提';
            }else{
                return '卖方配送';
            }
        },
        industryType(row){
            if(row.businessType == '0'){
                return '销售';
            }else if(row.businessType == '1'){
                return '采购';
            }
        },
        state(row){
            if(row.state == 0){
                return '未确认';
            }else if(row.state == 1){
                return '已确认';
            }else if(row.state == 2){
                return '已下单';
            }else if(row.state == 3){
                return '已完成';
            }else if(row.state == 4){
                return '暂停';
            }else if(row.state == 5){
                return '取消';
            }else if(row.state == 6){
                return '终止';
            }else if(row.state == 7){
                return '调整待确认';
            }
        },
        // 表格里面欠款金额
        arrears(row){
            var arrears = row.receivableAmount-row.receiptsAmount
            return arrears
        },
        // 表格里面企业名称判断是己方还是对方
        aa(row){
            if(row.businessType == 0){
                if(row.modeType == 0){
                    return row.purchaseName;
                }else{
                    return row.enterpriseName;
                }
            }else{
                if(row.modeType == 1){
                    return row.purchaseName;
                }else{
                    return row.enterpriseName;
                }
            }
        },
        // 查询事件
        query(){
            if(!this.$hasPermission(['ContractManagement_query'])){
                return
            }
            this.radio = -1
            this.auditid = ''
            this.loading = true
            this.$http.post(this.$SysContract_GetPageList,{
                currentPage: this.page,
                pageSize: this.size,
                filter: {
                    queryString: this.form.name,
                    contractNo: this.form.contractNo,
                    startDate: this.form.startDate,
                    endDate: this.form.endDate,
                }
            }).then(response => {
                this.tableData = response.data.data.list
                this.total = response.data.data.total
                this.loading = false
            })
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
            .then(_ => {
                done();
            })
            .catch(_ => {});
        },
        // 点击详情按钮打开表单
        handleClick(row) {
            this.end()
            this.DetailDialog = true
            this.$nextTick(() => {
                this.$refs['RefDetailDialog'].handleClick(row);
            })
        },
        dblclick(row){
            this.radio = row.id
            this.getCurrentRow(row)
        },
        // 点击表格里面的单选框
        getCurrentRow(row){
            this.auditid = row.code
            this.AuditiSactive = row.isActive
            this.AuditmodeType = Number(row.modeType)
            this.Auditstate = Number(row.state)
            if(row.businessType == 0){
                this.AuditUserid = row.purchaseId
            }else if(row.businessType == 1){
                this.AuditUserid = row.enterpriseId
            }
            // alert(name)
        },
        // 审核
        Audit(){
            if(this.auditid == ''){
                return this.$message.info('请选择数据')
            }
            this.$confirm('此操作将审核该合同, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysContract_AuditContract,{
                    contractNo : this.auditid,
                    aduitRemarks : ''
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('审核成功')
                        this.query()
                        this.radio = -1
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        // 取消审核
        CancelAudit(){
            if(this.auditid == ''){
                return this.$message.info('请选择数据')
            }
            this.$confirm('此操作将取消审核该合同, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysContract_CancelAuditContract,{
                    contractNo : this.auditid,
                    aduitRemarks : ''
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('取消审核成功')
                        this.query()
                        this.radio = -1
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        // 确认
        Sure(){
            if(this.auditid == ''){
                return this.$message.info('请选择数据')
            }
            this.$confirm('此操作将确认该合同, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysContract_ConfirmContract,{
                    contractNo : this.auditid,
                    aduitRemarks : ''
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('确认成功')
                        this.query()
                        this.radio = -1
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        // 取消确认
        CancelSure(){
            if(this.auditid == ''){
                return this.$message.info('请选择数据')
            }
            this.$confirm('此操作将取消确认该合同, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysContract_CancelContract,{
                    contractNo : this.auditid,
                    aduitRemarks : ''
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('取消确认成功')
                        this.query()
                        this.radio = -1
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        // 删除
        Delete(row){
            this.$confirm('此操作将删除该合同, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var ids = []
                ids.push(row.id)
                this.$http.post(this.$SysContract_DeleteSysContractById,ids).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('删除成功')
                        this.query()
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        // 点击实收款项打开对话框
        receipts(row){
            this.end()
            this.receiptsAmount = ''
            this.receiptsAmountDialog = true
            this.receiptsAmount0 = row.receiptsAmount
            this.receiptsno = row.code
        },
        // 点击确认 实收款项 保存
        Surereceipts(){
            if(this.receiptsAmount == ''){
                return this.$message.warning('请输入实收金额')
            }
            if(Number(this.receiptsAmount) <= Number(this.receiptsAmount0)){
                return this.$message.warning('实收金额不得少于上次的实收金额')
            }
            this.$http.post(this.$SysContract_SetReceiptsAmountContract,{
                contractNo : this.receiptsno,
                receiptsAmount : Number(this.receiptsAmount)
            }).then(response => {
                if(response.data.isSuccess == true){
                    this.receiptsAmountDialog = false
                    this.$message.success('更改成功')
                    this.start()
                    this.query()
                }else{
                    this.$message.warning(response.data.message)
                }
            })
        },
        // 打开添加合同表单
        OpenAddDialog(){
            this.end()
            this.AddEditDialog = true
            this.$nextTick(() => {
                this.$refs['RefAddEditDialog'].querySysEnterprise();
            })
        },
        // 关闭添加合同表单
        CloseAddDialog(){
            this.start()
            this.AddEditDialog = false
        },
        // 添加合同确定
        SureAddContract(){
            this.start()
            this.query()
            this.AddEditDialog = false
        },
        // 合同详情取消
        CloseDetailDialog(){
            this.start()
            this.DetailDialog = false
        },
        // 修改打开表单
        modify(row){
            this.end()
            this.title = '修改'
            this.AddEditDialog = true
            this.$nextTick(() => {
                this.$refs['RefAddEditDialog'].querySysEnterprise();
                this.$refs['RefAddEditDialog'].modify(row);
            })
        },
        // 调整打开表单
        Adjustment(row){
            this.end()
            this.title = '调整'
            this.AdjustDialog = true
            this.$nextTick(() => {
                this.$refs['RefAdjustDialog'].querySysEnterprise();
                this.$refs['RefAdjustDialog'].Adjustment(row);
            })
        },
        // 关闭调整打开表单
        CloseAdjustDialog(){
            this.start()
            this.AdjustDialog = false
            this.query()
        },
        // 调整编辑打开表单
        AdjustmentEdit(row){
            this.end()
            this.title = '调整编辑'
            this.AdjustDialog = true
            this.$nextTick(() => {
                this.$refs['RefAdjustDialog'].querySysEnterprise();
                this.$refs['RefAdjustDialog'].AdjustmentEdit(row);
            })
        }, 
        // 调整确认打开表单
        AdjustmentSure(){
            this.end()
            if(this.auditid == ''){
                return this.$message.info('请选择数据')
            }
            this.title = '调整确认'
            this.AdjustSureDialog = true
            this.$nextTick(() => {
                this.$refs['RefAdjustSureDialog'].AdjustmentSure(this.auditid);
            })
        },
        // 取消调整确认
        CancelAdjustmentSure(){
            if(this.auditid == ''){
                return this.$message.info('请选择数据')
            }
            this.$confirm('此操作将取消确认该合同, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysContract_AdjustCancelConfirmContract,{
                    contractNo : this.auditid,
                    aduitRemarks : ''
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('取消确认成功')
                        this.query()
                        this.radio = -1
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        // 关闭调整确认框
        CloseAdjustSureDialog(){
            this.start()
            this.AdjustSureDialog = false
            this.query()
        }
    },
    mounted(){
        this.query()
        this.start()
        // 存储当前企业的业务类型
        this.business = JSON.parse(window.sessionStorage.getItem('business'))
    }
}
</script>
<style scoped>
.body{
    padding: 15px;
    height: 96%;
    width: 98%;
    background-color:  #fdfdfd;
    box-shadow: 2px 1px 1px  #d8d8d8;
    border: 1px solid #ddd;
    border-radius: 5px;
}
/deep/.el-dialog__header {
    padding: 13px 20px 13px;
    background-color: skyblue;
}
/deep/.el-dialog__footer {
    /* min-width: 1300px; */
}
.xiangqing{
    color:#67C23A
}
.bianji{
    color: #E6A23C;
}
.shanchu{
    color: #F56C6C ;
}
</style>