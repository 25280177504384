import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../view/Login.vue'
import Home from '../view/Home.vue'
import Welcome from '../view/Welcome'
// 用户管理
import Users from '../view/User/Users'
// 地址管理
import AddressManagement from '../view/AddressManagement/AddressManagement'
// 商品管理
import GoodsManagement from '../view/GoodsManagement/GoodsManagement'
// 运输管理
import TransportationManagement from '../view/TransportationManagement/TransportationManagement'
// 合作伙伴
import Partner from '../view/Partner/Partner'
// 销售统计
import SalesStatistics from '../view/SalesStatistics/SalesStatistics'
// 采购统计
import PurchasingStatistics from '../view/PurchasingStatistics/PurchasingStatistics'
// 销售订单
import SalesOrder from '../view/SalesOrder/SalesOrder'
// 采购订单
import PurchaseOrder from '../view/PurchaseOrder/PurchaseOrder'
// 合同管理
import ContractManagement from '../view/ContractManagement/ContractManagement'
import copy from '../view/ContractManagement/copy'
// 审核设置
import AuditSettings from '../view/AuditSettings/AuditSettings'
// AuditSettings
import OnlineService from '../view/OnlineService/OnlineService'
// 角色权限
import RolePermissions from '../view/RolePermissions/RolePermissions'
// 企业信息
import CorporateInformation from '../view/CorporateInformation/CorporateInformation'
// 账款记录
import ReceiptsAmount from '../view/ReceiptsAmount/ReceiptsAmount'
// 订单签收
// import SignCarryOrder from '../view/SignCarryOrder/SignCarryOrder'
// 订单确认
// import ConfirmSignCarryOrder from '../view/ConfirmSignCarryOrder/ConfirmSignCarryOrder'
import NoPermission from '../view/NoPermission.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', name: 'Login', component: Login },
  { path: '/noPermission', name: 'noPermission', component: NoPermission },
     
  {
    path: '/home',
    name: 'home',
    component: Home,
    redirect: '/welcome',
    children: [
      { path: '/welcome', component: Welcome },
      // 用户管理
      { path: '/users', component: Users },
      // 地址管理
      { path: '/AddressManagement', component: AddressManagement },
      // 商品管理
      { path: '/GoodsManagement', component: GoodsManagement },
      // 运输管理
      { path: '/TransportationManagement', component: TransportationManagement },
      // 合作伙伴
      { path: '/Partner', component: Partner },
      // 销售统计
      { path: '/SalesStatistics', component: SalesStatistics },
      // 采购统计
      { path: '/PurchasingStatistics', component: PurchasingStatistics },
      // 销售订单
      { path: '/SalesOrder', component: SalesOrder },
      // 采购订单
      { path: '/PurchaseOrder', component: PurchaseOrder },
      // 合同管理
      { path: '/ContractManagement', component: ContractManagement },
      { path: '/copy', component: copy },
      // 审核设置
      { path: '/AuditSettings', component: AuditSettings },
      // 在线服务
      { path: '/OnlineService', component: OnlineService },
      // 角色权限
      { path: '/RolePermissions', component: RolePermissions },
      // 企业信息
      { path: '/CorporateInformation', component: CorporateInformation },
      // 账款记录
      { path: '/ReceiptsAmount', component: ReceiptsAmount },
      // 订单签收
      // { path: '/SignCarryOrder', component: SignCarryOrder },
      // 订单确认
      // { path: '/ConfirmSignCarryOrder', component: ConfirmSignCarryOrder }
      
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes: routes
})

/* 添加导航守卫 */
router.beforeEach((to, from, next) => {
  if (to.path === '/login') return next() /*默认登录直接放行  */
  const tokenStr = window.sessionStorage.getItem('token')
  if (!tokenStr) return next('/login') /* token 为空等，需要跳转到登录 */
   //菜单权限过滤
  const tempData = JSON.parse(window.sessionStorage.getItem('permissions'))
  const hasPermission = tempData.some(x => x.path.indexOf(to.path)!==-1)
  if (!hasPermission&&to.path!=='/home') return next('/noPermission')//如果没有权限就直接让他跳转没有权限页面
  
  next() /* 原先正常跳转 */
  // next() /* 原先正常跳转 */
  if(to.path === '/RestPass'){
    // alert(from.path)
    next(from.path)
  }
})

export default router
