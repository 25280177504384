<template>
    <div class="body" v-loading="loading" element-loading-text="拼命加载中" element-loading-background="rgb(213 213 213 / 57%)" style="min-width:900px">
        <!-- 查询 -->
        <div>
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item label="企业名称">
                    <el-input v-model="form.name" placeholder="请输入企业名称"></el-input>
                </el-form-item>
                <el-form-item label="订单编号">
                    <el-input v-model="form.orderNo" placeholder="请输入订单编号"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="form.state" @change="query()" clearable placeholder="请选择">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="开始时间">
                    <el-date-picker
                        @change="query()"
                        v-model="form.startDate"
                        type="date"
                        placeholder="选择日期"
                        format="yyyy 年 MM 月 dd 日"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间">
                    <el-date-picker
                        @change="query()"
                        v-model="form.endDate"
                        type="date"
                        placeholder="选择日期"
                        format="yyyy 年 MM 月 dd 日"
                        :picker-options="expireTimeOPtion"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['SalesOrder_query'])" label="">
                    <el-button type="primary" @click="query()">查询</el-button>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['SalesOrder_add'])">
                    <el-button type="primary" @click="add()">新增</el-button>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['SalesOrder_audit']) & AuditiSactive == false & modeType == 0 & state == 0 & type == 0">
                    <el-button type="primary" @click="Audit()">审核</el-button>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['SalesOrder_audit']) & AuditiSactive == true & modeType == 0 & state == 0 & type == 0">
                    <el-button type="primary" @click="CancelAudit()">取消审核</el-button>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['SalesOrder_Sure']) & AuditiSactive == true & modeType == 1 & state == 0 & type == 0">
                    <el-button type="primary"  @click="Sure()">确认</el-button>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['SalesOrder_Sure']) & AuditiSactive == true & modeType == 1 & state == 0 & type == 0">
                    <el-button type="primary"  @click="CancelSure()">取消确认</el-button>
                </el-form-item>
                <el-form-item v-if="$hasPermission(['SalesOrder_UpdateOrderCarry']) & AuditiSactive == true & modeType == 0 & state == 2 & type == 0">
                    <el-button type="primary"  @click="CreatCar()">车辆信息</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- 表格 -->
        <div>
            <el-table
                :header-cell-style="headClass"
                :cell-style="rowClass"
                :data="tableData"
                :height="tableHeight"
                highlight-current-row
                @cell-dblclick="dblclick"
                border
                style="width: 100%">
                <el-table-column
                fixed
                prop="date"
                label="选择"
                width="75">
                    <template slot-scope="scope">
                        <el-radio :label="scope.row.id" v-model="radio" @change.native="getCurrentRow(scope.row)">{{''}}</el-radio>
                    </template>
                </el-table-column>
                <el-table-column
                prop="contractCode"
                :show-overflow-tooltip="true"
                label="合同编号"
                width="180">
                </el-table-column>
                <el-table-column
                prop="code"
                :show-overflow-tooltip="true"
                label="订单编号"
                width="180">
                </el-table-column>
                <el-table-column
                :formatter="aa"
                prop="purchaseBusinessName"
                :show-overflow-tooltip="true"
                label="企业名称"
                width="">
                </el-table-column>
                <el-table-column
                prop="totalPrice"
                label="预算金额(元)"
                width="">
                </el-table-column>
                <el-table-column
                prop="freightCalculation"
                label="运费(元)"
                width="">
                </el-table-column>
                <el-table-column
                prop="payAmount"
                label="订单总价(元)"
                width="100">
                </el-table-column>
                <el-table-column
                prop="receivableAmount"
                label="结算金额(元)"
                width="100">
                </el-table-column>
                <el-table-column
                prop="payType"
                :show-overflow-tooltip="true"
                :formatter="payType"
                label="支付方式"
                width="">
                </el-table-column>
                <el-table-column
                prop="state"
                :formatter="state1"
                label="状态"
                width="">
                </el-table-column>
                <el-table-column
                prop="type"
                :formatter="typetype"
                label="来源"
                width="">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                prop="creationTime"
                label="创建时间"
                width="150">
                </el-table-column>
                <el-table-column
                fixed="right"
                label="操作"
                width="250">
                <template slot-scope="scope">
                    <el-button v-if="$hasPermission(['SalesOrder_details'])" @click="handleClick(scope.row)" type="text" size="small">详情</el-button>
                    <el-button v-if="$hasPermission(['SalesOrder_edit']) & scope.row.modeType == 0 & scope.row.isActive == false & scope.row.state == 0 & scope.row.type == 0" @click="modify(scope.row)" type="text" size="small">编辑</el-button>
                    <el-button v-if="$hasPermission(['SalesOrder_abandoned']) & scope.row.modeType == 0 & scope.row.state == 1 & scope.row.type == 0" @click="abandoned(scope.row)" type="text" size="small">废弃</el-button>
                    <el-button v-if="$hasPermission(['SalesOrder_Cancel']) & scope.row.modeType == 1 & scope.row.state == 1 & scope.row.type == 0" @click="SalesOrder_Cancel(scope.row)" type="text" size="small">取消</el-button>
                    <el-button v-if="$hasPermission(['SalesOrder_stop']) & scope.row.modeType == 0 & scope.row.state == 2 & scope.row.type == 0" @click="stop(scope.row)" type="text" size="small">终止</el-button>
                    <el-button v-if="$hasPermission(['SalesOrder_suspend']) & scope.row.modeType == 0 & scope.row.state == 2 & scope.row.type == 0" @click="suspend(scope.row)" type="text" size="small">暂停</el-button>
                    <el-button v-if="$hasPermission(['SalesOrder_recovery']) & scope.row.modeType == 0 & scope.row.state == 8 & scope.row.type == 0" @click="recovery(scope.row)" type="text" size="small">恢复</el-button>
                    <el-button v-if="$hasPermission(['SalesOrder_recovery']) & scope.row.modeType == 0 & scope.row.state == 9 & scope.row.type == 0" @click="recovery(scope.row)" type="text" size="small">恢复</el-button>
                    <el-button v-if="$hasPermission(['SalesOrder_receipt']) & scope.row.carryModel == 1 & scope.row.state == 2 & scope.row.purchaseId == 0 & scope.row.type == 0" @click="sign(scope.row)" type="text" size="small">签收</el-button>
                    <el-button v-if="$hasPermission(['SalesOrder_ConfirmReceipt']) & scope.row.carryModel == 1 & scope.row.state == 2 & scope.row.type == 0" @click="SureSign(scope.row)" type="text" size="small">确认</el-button>
                    <el-button v-if="$hasPermission(['SalesOrder_recovery']) & scope.row.state == 2" @click="GetCarryProcessPageList(scope.row)" type="text" size="small">运输</el-button>
                    <el-button v-if="$hasPermission(['SalesOrder_ReceiptsAmount']) & scope.row.state == 2 & scope.row.type == 0 & scope.row.payType == 2" @click="openReceiptsAmountDialog(scope.row)" type="text" size="small">账款记录</el-button>
                    <el-button v-if="$hasPermission(['SalesOrder_ReceiptsAmount']) & scope.row.state == 2 & scope.row.type == 0 & scope.row.payType == '1,2'" @click="openReceiptsAmountDialog(scope.row)" type="text" size="small">账款记录</el-button>
                </template>
                </el-table-column>
            </el-table>
            <!-- 表格分页 -->
            <div style="float:right" class="block">
                <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10, 15, 20]"
                :page-size="size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
                </el-pagination>
            </div>
        </div>
        <!-- 表单 -->
        <div>
            <el-dialog
                :title="title"
                :visible.sync="dialogVisible"
                width="40%"
                :fullscreen="true"
                >
                <el-form style="width:99%;min-width:1300px" ref="addform" :rules="addformRules" :model="addform" label-width="110px" class="demo-form-inline">
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="订单编号:" prop="code">
                                <span>{{addform.code}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- 企业信息 -->
                    <el-form-item style="margin-top:-10px" label-width="0px">
                        <el-divider content-position="left">企业信息</el-divider>
                    </el-form-item>
                    <!-- v-if="formButton !== 'details'" -->
                    <el-row v-if="formButton !== 'details' & formButton !== 'modify'" style="margin-top:-10px" :gutter="24">
                        <el-col :span="6">
                            <el-form-item label="选择企业:" prop="">
                                <el-select @change="SelectFormOptions()" v-model="enterpriseName" clearable placeholder="请选择">
                                    <el-option
                                    v-for="item in FormOptions"
                                    :key="item.id"
                                    :label="item.purchaseName"
                                    :value="item.id">
                                    </el-option>
                                </el-select>
                                <!-- <el-input v-model="enterpriseName" placeholder="请输入企业名称或税号">
                                    <el-button slot="append" @click="querySysEnterprise()" icon="el-icon-search"></el-button>
                                </el-input> -->
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row v-else style="margin-top:-10px" :gutter="24">
                        <el-col :span="6">
                            <el-form-item label="企业名称:" prop="">
                                <span>{{enterpriseData.purchaseName}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="3">
                            <el-form-item label-width="" label="联系人:">
                                <span>{{enterpriseData.purchaseContacts}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item label-width="" label="联系电话:">
                                <span>{{enterpriseData.purchaseContactTel}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label-width="" label="税号:">
                                <span>{{enterpriseData.purchaseTaxNumber}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label-width="" label="注册地址:">
                                <span>{{enterpriseData.purchaseDomicile}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label-width="" label="开户银行:">
                                <span>{{enterpriseData.purchaseBankName}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label-width="" label="银行账户:">
                                <span>{{enterpriseData.purchaseBankNo}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item v-if="addform.type == 0" style="margin-top:-10px" label-width="0px">
                        <el-divider content-position="left">合同信息</el-divider>
                    </el-form-item>
                    <el-form-item v-if="formButton !== 'details' & formButton !== 'modify'" label="选择合同：" :rules="[{ required: true, message: '请选择合同', trigger: 'blur' }]">
                        <el-select @change="changeContract()" v-model="addform.contractId" placeholder="请选择">
                            <el-option
                            v-for="item in Contract"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-row v-else v-show="addform.type == 0" :gutter="24">
                        <el-col :span="4">
                            <el-form-item label="合同名称：">
                                <span>{{addform.contractName}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="合同编号：">
                                <span>{{addform.contractCode}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- 商品信息 -->
                    <el-form-item style="margin-top:-10px" label-width="0px">
                        <el-divider content-position="left">商品信息</el-divider>
                    </el-form-item>
                    <!-- 矿石版本商品 -->
                    <el-row :gutter="24">
                        <el-col :span="2">
                            <el-form-item style="margin-top:-10px" label="" label-width="40px">
                                <el-checkbox :disabled="true" v-model="ch0">矿石商品</el-checkbox>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item v-if="formButton !== 'details' & ch0 == true" style="margin-top:-10px" label="" label-width="0px">
                                <el-button @click="addCarrys(),GetCustomerList1()" type="primary" size="small">添加承运信息</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row v-if="ch0 == true" style="margin-top: -20px" :gutter="24">
                        <el-form-item label-width="38px">
                            <el-table
                                :header-cell-style="headClass"
                                :cell-style="rowClass"
                                :data="addform.orderSands"
                                height="400px"
                                border
                                style="width: 100%">
                                <el-table-column
                                prop="productName"
                                :show-overflow-tooltip="true"
                                label="商品名称"
                                width="150">
                                </el-table-column>
                                <el-table-column
                                prop="standards"
                                :show-overflow-tooltip="true"
                                label="规格"
                                width="">
                                </el-table-column>
                                <el-table-column
                                prop="unit"
                                :show-overflow-tooltip="true"
                                label="单位"
                                width="">
                                </el-table-column>
                                <el-table-column
                                prop="planNumber"
                                label="计划数量"
                                width="">
                                </el-table-column>
                                <el-table-column
                                prop="price"
                                :show-overflow-tooltip="true"
                                label="结算单价"
                                width="">
                                </el-table-column>
                                <el-table-column
                                prop="freightPrice"
                                :show-overflow-tooltip="true"
                                label="运费单价"
                                width="">
                                </el-table-column>
                                <!-- <el-table-column
                                label="承运单位"
                                :key="2"
                                width="">
                                <template slot-scope="scope">
                                    <el-button @click="OpenDetailsCarry(scope.row)" type="text" size="small">详情</el-button>
                                </template>
                                </el-table-column> -->
                                <el-table-column
                                :key="3"
                                v-if="addform.carryModel == 1"
                                label="签署人"
                                width="">
                                <template slot-scope="scope">
                                    <el-button v-if="formButton != 'details'" @click="AddSigns(scope.row)" type="text" size="small">添加</el-button>
                                    <el-button @click="detailsSigns(scope.row)" type="text" size="small">详情</el-button>
                                </template>
                                </el-table-column>
                                <el-table-column
                                label="生产/发货地址"
                                :key="4"
                                width="">
                                <template slot-scope="scope">
                                    <el-button @click="detailsAddress(scope.row)" type="text" size="small">详情</el-button>
                                </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </el-row>
                    <!-- 混凝土版本商品 -->
                    <el-row :gutter="24">
                        <el-col :span="2">
                            <el-form-item style="margin-top:-10px" label="" label-width="40px">
                                <el-checkbox :disabled="true" v-model="ch1">混凝土商品</el-checkbox>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item v-if="formButton !== 'details' & ch1 == true" style="margin-top:-10px" label="" label-width="0px">
                                <el-button @click="addCarrys2(),GetCustomerList2()" type="primary" size="small">添加承运信息</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row v-if="ch1 == true" style="margin-top: -20px" :gutter="24">
                        <el-form-item label-width="38px">
                            <el-table
                                :header-cell-style="headClass"
                                :cell-style="rowClass"
                                :data="addform.orderConcretes"
                                height="400px"
                                border
                                style="width: 100%">
                                <el-table-column
                                prop="productName"
                                :show-overflow-tooltip="true"
                                label="配方名称"
                                width="150">
                                </el-table-column>
                                <el-table-column
                                prop="standards"
                                :show-overflow-tooltip="true"
                                label="强度等级"
                                width="">
                                </el-table-column>
                                <el-table-column
                                prop="impermeability"
                                :show-overflow-tooltip="true"
                                label="抗渗等级"
                                width="">
                                </el-table-column>
                                <el-table-column
                                prop="slump"
                                label="塌落度"
                                width="">
                                </el-table-column>
                                <el-table-column
                                prop="aggregateSize"
                                :show-overflow-tooltip="true"
                                label="骨料粒径"
                                width="">
                                </el-table-column>
                                <el-table-column
                                prop="pouringMethod"
                                :show-overflow-tooltip="true"
                                label="浇筑方式"
                                width="">
                                </el-table-column>
                                <el-table-column
                                prop="constructionPosition"
                                :show-overflow-tooltip="true"
                                label="施工部位"
                                width="">
                                </el-table-column>
                                <el-table-column
                                prop="planNumber"
                                :show-overflow-tooltip="true"
                                label="计划数量(m³)"
                                width="">
                                </el-table-column>
                                <el-table-column
                                prop="price"
                                :show-overflow-tooltip="true"
                                label="结算单价"
                                width="">
                                </el-table-column>
                                <el-table-column
                                prop="freightPrice"
                                :show-overflow-tooltip="true"
                                label="运费单价"
                                width="">
                                </el-table-column>
                                <el-table-column
                                prop="pumpingPrice"
                                :show-overflow-tooltip="true"
                                label="泵送单价"
                                width="">
                                </el-table-column>
                                <!-- <el-table-column
                                label="承运单位"
                                :key="5"
                                width="">
                                <template slot-scope="scope">
                                    <el-button @click="OpenDetailsCarry(scope.row)" type="text" size="small">详情</el-button>
                                </template>
                                </el-table-column> -->
                                <el-table-column
                                :key="6"
                                v-if="addform.carryModel == 1"
                                label="签署人"
                                width="">
                                <template slot-scope="scope">
                                    <el-button v-if="formButton != 'details'" @click="AddSigns(scope.row)" type="text" size="small">添加</el-button>
                                    <el-button @click="detailsSigns(scope.row)" type="text" size="small">详情</el-button>
                                </template>
                                </el-table-column>
                                <el-table-column
                                :key="7"
                                label="生产/发货地址"
                                width="">
                                    <template slot-scope="scope">
                                        <el-button @click="detailsAddress(scope.row)" type="text" size="small">详情</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </el-row>
                    <!-- 车辆信息 -->
                    <el-form-item style="margin-top:-10px" label-width="0px">
                        <el-divider content-position="left">车辆信息</el-divider>
                    </el-form-item>
                    <el-row style="margin-top: -20px" :gutter="24">
                        <el-form-item label-width="38px">
                            <el-table
                                :header-cell-style="headClass"
                                :cell-style="rowClass"
                                :data="addform.orderCarrys"
                                height="400px"
                                border
                                style="width: 100%">
                                <el-table-column
                                prop="productName"
                                :show-overflow-tooltip="true"
                                label="商品名称/配方名称"
                                width="150">
                                </el-table-column>
                                <el-table-column
                                prop="carryName"
                                :show-overflow-tooltip="true"
                                label="承运单位"
                                width="">
                                </el-table-column>
                                <el-table-column
                                prop="driver"
                                :show-overflow-tooltip="true"
                                label="司机名称"
                                width="">
                                </el-table-column>
                                <el-table-column
                                prop="telephone"
                                :show-overflow-tooltip="true"
                                label="联系电话"
                                width="">
                                </el-table-column>
                                <el-table-column
                                prop="licensePlate"
                                label="车牌号"
                                width="">
                                </el-table-column>
                                <el-table-column
                                prop="maxLoad"
                                :show-overflow-tooltip="true"
                                label="最大载重(吨)"
                                width="">
                                </el-table-column>
                                <el-table-column
                                :formatter="CarryCartype"
                                prop="carType"
                                :show-overflow-tooltip="true"
                                label="车辆类型"
                                width="">
                                </el-table-column>
                                <el-table-column
                                prop="address"
                                :show-overflow-tooltip="true"
                                label="生产地址"
                                width="">
                                </el-table-column>
                                <el-table-column
                                prop="receivingAddress"
                                :show-overflow-tooltip="true"
                                label="收货地址"
                                width="">
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </el-row>
                    <!-- 支付信息 -->
                    <div v-if="addform.payType != ''">
                        <div v-if="payType1 == false & payType2 == false & payType3 == false" style="display: flex">
                            <el-form-item label="" label-width="30px">
                                支付方式：充值支付
                            </el-form-item>
                            <el-form-item v-if="payType0 == true" label="充值金额：">
                                <span>{{addform.payAmount}}元</span>
                            </el-form-item>
                        </div>
                        <div v-if="payType0 == false & payType3 == false" style="display: flex">
                            <el-form-item v-if="payType1 == true" label="" label-width="30px">
                                支付方式：授信支付
                            </el-form-item>
                            <el-form-item v-if="payType1 == true" label="授信金额：">
                                <span>{{addform.payAmount}}元</span>
                            </el-form-item>
                            <el-form-item v-if="payType1 == true" label="" label-width="15px">
                                <div class="block" style="display:flex">
                                    <span v-if="addform.isDate == true">应收货款日期：{{addform.repaymentDate}}</span>
                                </div>
                            </el-form-item>
                            <el-form-item v-if="payType1 == true" label="应收货款提示：" label-width="130px">
                                <span>{{addform.creditTipsAmount}}元</span>
                            </el-form-item>
                        </div>
                        <div v-if="payType0 == false & payType3 == false" style="display: flex">
                            <el-form-item v-if="payType2 == true" label="" label-width="30px">
                                支付方式：账期支付
                            </el-form-item>
                            <el-form-item v-if="payType2 == true" label="还款日期：">
                                <span>{{addform.repaymentTime1}}月</span>
                            </el-form-item>
                            <el-form-item v-if="payType2 == true" style="margin-left:7px" label="-" label-width="14px">
                                <span>{{addform.repaymentTime2}}日</span>
                            </el-form-item>
                            <el-form-item v-if="payType2 == true" style="margin-left:2px" label="" label-width="0px">
                                <span style="color:red">注：为几个月之后的多少天</span>
                            </el-form-item>
                            <el-form-item v-if="payType2 == true" label="账期提示天数：" label-width="130px">
                                <span>{{addform.tipsDay}}</span>
                            </el-form-item>
                        </div>
                        <div v-if="payType0 == false & payType1 == false & payType2 == false" style="display: flex">
                            <el-form-item label="" label-width="30px">
                                支付方式：现结
                            </el-form-item>
                        </div>
                    </div>
                    <!-- 分配金额 -->
                    <!-- <div>
                        <el-form-item label="分配金额：" label-width="110px">
                            <span>{{addform.unitName}}</span>
                            <el-form-item v-for="item in addressGroupsYuan" :key="item.addressId" :label="item.addressName" :value="item.addressId" label-width="250px">
                                <el-input v-if="formButton !== 'details'" style="width:220px" placeholder="请输入金额" v-model="item.price"></el-input>
                                <span v-else>{{item.price}}</span>
                            </el-form-item>
                        </el-form-item>
                    </div> -->
                    <!-- 承运信息 -->
                    <el-form-item style="margin-top:-10px" label-width="0px">
                        <el-divider content-position="left">承运信息</el-divider>
                    </el-form-item>
                    <el-row v-if="addform.carryModel != null & addform.freightCalculation != null" :gutter="24">
                        <el-col :span="6">
                            <el-form-item label="承运方式：" prop="carryModel">
                                <span v-if="addform.carryModel == 0">买家自提</span>
                                <span v-if="addform.carryModel == 1">卖方配送</span>
                                <span ></span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="运费计算：" prop="freightCalculation">
                                <span v-if="addform.freightCalculation == 0">按吨数</span>
                                <span v-if="addform.freightCalculation == 1">按公里</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- 其他信息 -->
                    <el-form-item style="margin-top:-10px" label-width="0px">
                        <el-divider content-position="left">其他信息</el-divider>
                    </el-form-item>
                    <!-- 混凝土版本其他信息 -->
                    <el-row :gutter="24">
                        <el-col v-show="ch1 == true" :span="6">
                            <el-form-item v-if="formButton !== 'details'" label="施工单位：" prop="unitName" :rules="[{ required: true, message: '请输入施工单位', trigger: 'blur' }]">
                                <el-input placeholder="请输入施工单位" v-model="addform.unitName"></el-input>
                            </el-form-item>
                            <el-form-item v-else label="施工单位：" prop="unitName">
                                <span>{{addform.unitName}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col v-show="ch1 == true" :span="6">
                            <el-form-item v-if="formButton !== 'details'" label="工程名称：" prop="projectName" :rules="[{ required: true, message: '请输入工程名称', trigger: 'blur' }]">
                                <el-input  placeholder="请输入工程名称" v-model="addform.projectName"></el-input>
                            </el-form-item>
                            <el-form-item v-else label="工程名称：" prop="projectName">
                                <span >{{addform.projectName}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item v-if="formButton !== 'details'" label="销售员：" prop="salesman" :rules="[{ required: true, message: '请输入销售员', trigger: 'blur' }]">
                                <el-input placeholder="请输入销售员" v-model="addform.salesman"></el-input>
                            </el-form-item>
                            <el-form-item v-else label="销售员：" prop="salesman">
                                <span >{{addform.salesman}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item v-if="formButton !== 'details'" label="销售电话：" prop="salesTel" :rules="[{ required: true, message: '请输入销售电话', trigger: 'blur' }]">
                                <el-input placeholder="请输入销售电话" v-model.number="addform.salesTel"></el-input>
                            </el-form-item>
                            <el-form-item v-else label="销售电话：" prop="salesTel">
                                <span >{{addform.salesTel}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="备注：" prop="remarks">
                                <span>{{addform.remarks}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="AddCancel('addform')">取 消</el-button>
                    <el-button v-if="formButton == 'add'" type="primary" @click="AddSure('addform')">确 定</el-button>
                    <el-button v-if="formButton == 'modify'" type="primary" @click="AddSure('addform')">确 定</el-button>
                </span>
            </el-dialog>
            <!-- 承运信息地址详情表单 -->
            <el-dialog
                title="详情"
                :visible.sync="CarryDetailsDialog"
                style="margin-top:-3%"
                :before-close="handleClose"
                :show-close="false"
                width="60%"
                >
                    <el-table
                        :header-cell-style="headClass"
                        :cell-style="rowClass"
                        :data="AddressData"
                        height="450px"
                        border
                        style="width: 100%">
                        <el-table-column
                        prop="address"
                        :show-overflow-tooltip="true"
                        label="生产地址"
                        width="150">
                        </el-table-column>
                        <!-- <el-table-column
                        prop="warehouseNumber"
                        :show-overflow-tooltip="true"
                        label="生产卸料点"
                        width="">
                        </el-table-column> -->
                        <el-table-column
                        prop="receivingAddress"
                        :show-overflow-tooltip="true"
                        label="收货地址"
                        width="">
                        </el-table-column>
                        <el-table-column
                        prop="calculatedMileage"
                        label="计算里程"
                        width="">
                        </el-table-column>
                        <el-table-column
                        prop="confirmMileage"
                        :show-overflow-tooltip="true"
                        label="确认里程"
                        width="">
                        </el-table-column>
                        <!-- <el-table-column
                        prop="receivingWarehouseNumber"
                        :show-overflow-tooltip="true"
                        label="收货卸料点"
                        width="">
                        </el-table-column> -->
                        <el-table-column
                        prop="arrivalDate"
                        :formatter="arrivalDate"
                        :show-overflow-tooltip="true"
                        label="到货日期"
                        width="">
                        </el-table-column>
                    </el-table>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="CarryDetailsDialog = false">关 闭</el-button>
                </span>
            </el-dialog>
            <!-- 添加承运信息车辆对话框 -->
            <el-dialog
                title="添加商品承运信息"
                :visible.sync="AddCarryDialog"
                style="margin-top:-3%"
                :before-close="handleClose"
                :show-close="false"
                width="90%"
                >
                    <el-row :gutter="24">
                        <el-form style="" ref="Carryform" label-width="100px" :rules="CarryformRules" :model="Carryform" class="demo-form-inline">
                            <el-col :span="5">
                                <el-card style="height: 596px;">
                                    <el-form-item label="生产厂区：" label-width="88px" prop="addressId">
                                        <el-select @change="SelectFactoryAddress" v-model="Carryform.addressId" clearable placeholder="请选择">
                                            <el-option
                                            v-for="item in FactoryAddressData"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="收货厂区：" label-width="88px" prop="receivingAddressId">
                                        <el-select @change="SelectDeliveryAddress" v-model="Carryform.receivingAddressId" clearable placeholder="请选择">
                                            <el-option
                                            v-for="item in DeliveryAddressData"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-table
                                        ref="multipleTable"
                                        :data="shopNameCarryData"
                                        border
                                        height="438px"
                                        tooltip-effect="dark"
                                        style="width: 100%"
                                        >
                                        <!-- @selection-change="handleSelectionChange" -->
                                        <el-table-column
                                        fixed
                                        prop="date"
                                        label="选择"
                                        width="50">
                                            <template slot-scope="scope">
                                                <el-radio :label="scope.row.productId" v-model="multipleSelection" @change.native="handleSelectionChange(scope.row)">{{''}}</el-radio>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                        label="商品名称/配方名称"
                                        prop="productName"
                                        width="">
                                        </el-table-column>
                                    </el-table>
                                </el-card>
                            </el-col>
                            <el-col :span="5">
                                <el-card style="height: 597px;">
                                        <el-form-item label="企业名称：" prop="customerId">
                                            <el-select @change="SelectCustomer" v-model="Carryform.customerId" clearable placeholder="请选择">
                                                <el-option
                                                v-for="item in CustomerList"
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item label="车牌号码：">
                                            <el-input placeholder="请输入" v-model="filterText"></el-input>
                                        </el-form-item>
                                        <div style="height:370px;overflow-y:scroll">
                                            <el-tree
                                                :data="CarryList"
                                                show-checkbox
                                                node-key="id"
                                                ref="Cartree"
                                                highlight-current
                                                :props="defaultProps"
                                                :filter-node-method="filterNode"
                                                :expand-on-click-node="false"
                                                @check-change="SelectCarry">
                                            </el-tree>
                                        </div>
                                        <el-button style="margin-top:10px" type="primary" @click="SureCarry('Carryform')">保 存</el-button>
                                </el-card>
                            </el-col>
                        </el-form>
                        <el-col :span="14">
                            <el-card>
                                <el-table
                                    :header-cell-style="headClass"
                                    :cell-style="rowClass"
                                    :data="CarrysData"
                                    height="543px"
                                    border
                                    style="width: 100%">
                                    <el-table-column
                                    prop="productName"
                                    :show-overflow-tooltip="true"
                                    label="商品名称/配方名称"
                                    width="200px">
                                    </el-table-column>
                                    <el-table-column
                                    prop="carryName"
                                    :show-overflow-tooltip="true"
                                    label="承运单位"
                                    width="">
                                    </el-table-column>
                                    <el-table-column
                                    prop="driver"
                                    :show-overflow-tooltip="true"
                                    label="司机名称"
                                    width="">
                                    </el-table-column>
                                    <el-table-column
                                    prop="telephone"
                                    :show-overflow-tooltip="true"
                                    label="联系电话"
                                    width="">
                                    </el-table-column>
                                    <el-table-column
                                    prop="licensePlate"
                                    :show-overflow-tooltip="true"
                                    label="车牌号"
                                    width="">
                                    </el-table-column>
                                    <el-table-column
                                    prop="maxLoad"
                                    :show-overflow-tooltip="true"
                                    label="最大载重(吨)"
                                    width="100px">
                                    </el-table-column>
                                    <el-table-column
                                    :formatter="CarryCartype"
                                    prop="carType"
                                    :show-overflow-tooltip="true"
                                    label="车辆类型"
                                    width="">
                                    </el-table-column>
                                    <!-- <el-table-column
                                    prop="carLength"
                                    :show-overflow-tooltip="true"
                                    label="车长(米)"
                                    </el-table-column>
                                    <el-table-column
                                    prop="actualWeight"
                                    :show-overflow-tooltip="true"
                                    label="净重(吨)"
                                    width="">
                                    </el-table-column> -->
                                    <el-table-column
                                    :formatter="CarryCartype"
                                    prop="carType"
                                    :show-overflow-tooltip="true"
                                    label="车辆类型"
                                    width="">
                                    </el-table-column>
                                    <el-table-column
                                    v-if="formButton !== 'details'"
                                    fixed="right"
                                    label="操作"
                                    width="50">
                                    <template slot-scope="scope">
                                        <el-button  @click="DeleteCarry(scope.row,scope.$index)" type="text" size="small">删除</el-button>
                                    </template>
                                    </el-table-column>
                                </el-table>
                            </el-card>
                        </el-col>
                    </el-row>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="AddCarryDialog = false">关 闭</el-button>
                </span>
            </el-dialog>
            <el-dialog
                title="详情"
                :visible.sync="CarrysDetailsDialog"
                style="margin-top:-3%"
                :before-close="handleClose"
                :show-close="false"
                width="60%"
                >
                    <el-table
                        :header-cell-style="headClass"
                        :cell-style="rowClass"
                        :data="CarrysData"
                        height="450px"
                        border
                        style="width: 100%">
                        <el-table-column
                        prop="productName"
                        :show-overflow-tooltip="true"
                        label="商品名称/配方名称"
                        width="150">
                        </el-table-column>
                        <el-table-column
                        prop="carryName"
                        :show-overflow-tooltip="true"
                        label="承运单位"
                        width="150">
                        </el-table-column>
                        <el-table-column
                        prop="driver"
                        :show-overflow-tooltip="true"
                        label="司机名称"
                        width="">
                        </el-table-column>
                        <el-table-column
                        prop="telephone"
                        :show-overflow-tooltip="true"
                        label="联系电话"
                        width="">
                        </el-table-column>
                        <el-table-column
                        prop="licensePlate"
                        label="车牌号"
                        width="">
                        </el-table-column>
                        <el-table-column
                        prop="maxLoad"
                        :show-overflow-tooltip="true"
                        label="最大载重(吨)"
                        width="">
                        </el-table-column>
                        <el-table-column
                        :formatter="CarryCartype"
                        prop="carType"
                        :show-overflow-tooltip="true"
                        label="车辆类型"
                        width="">
                        </el-table-column>
                    </el-table>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="CarrysDetailsDialog = false">关 闭</el-button>
                </span>
            </el-dialog>
            <!-- 添加签署人对话框 -->
            <el-dialog
                title="添加"
                :visible.sync="AddSignsDialog"
                :before-close="handleClose"
                :show-close="false"
                style="margin-top:-3%"
                width="60%"
                >
                    <el-row :gutter="24">
                        <el-col :span="10">
                            <el-card style="height:506px">
                                <el-form ref="Signsform" label-width="100px" :rules="SignsformRules" :model="Signsform" class="demo-form-inline">
                                    <el-form-item label="签署人：" prop="signatory">
                                        <el-input maxlength="10" v-model="Signsform.signatory" placeholder="请输入签署人"></el-input>
                                    </el-form-item>
                                    <el-form-item label="联系电话：" prop="telephone">
                                        <el-input maxlength="11" v-model="Signsform.telephone" placeholder="请输入联系电话"></el-input>
                                    </el-form-item>
                                    <el-form-item label="" prop="">
                                        <el-button type="primary" @click="SureSigns('Signsform')">保 存</el-button>
                                    </el-form-item>
                                </el-form>
                            </el-card>
                        </el-col>
                        <el-col :span="14">
                            <el-card style="width:100%">
                                <el-table
                                    :header-cell-style="headClass"
                                    :cell-style="rowClass"
                                    :data="SignsData"
                                    height="450px"
                                    border>
                                    <el-table-column
                                    prop="productName"
                                    :show-overflow-tooltip="true"
                                    label="商品名称/配方名称"
                                    width="150">
                                    </el-table-column>
                                    <el-table-column
                                    prop="signatory"
                                    :show-overflow-tooltip="true"
                                    label="签署人"
                                    width="">
                                    </el-table-column>
                                    <el-table-column
                                    prop="telephone"
                                    :show-overflow-tooltip="true"
                                    label="联系电话"
                                    width="">
                                    </el-table-column>
                                    <el-table-column
                                    v-if="formButton !== 'details'"
                                    fixed="right"
                                    label="操作"
                                    width="150">
                                    <template slot-scope="scope">
                                        <el-button @click="DeleteSigns(scope.row,scope.$index)" type="text" size="small">删除</el-button>
                                    </template>
                                    </el-table-column>
                                </el-table>
                            </el-card>
                        </el-col>
                    </el-row>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="AddSignsDialog = false">关 闭</el-button>
                </span>
            </el-dialog>
            <el-dialog
                title="详情"
                :visible.sync="SignsDetailsDialog"
                style="margin-top:-3%"
                :before-close="handleClose"
                :show-close="false"
                width="60%"
                >
                    <el-table
                        :header-cell-style="headClass"
                        :cell-style="rowClass"
                        :data="SignsData"
                        height="450px"
                        border
                        style="width: 100%">
                        <el-table-column
                        prop="productName"
                        :show-overflow-tooltip="true"
                        label="商品名称/配方名称"
                        width="150">
                        </el-table-column>
                        <el-table-column
                        prop="signatory"
                        :show-overflow-tooltip="true"
                        label="签署人"
                        width="">
                        </el-table-column>
                        <el-table-column
                        prop="telephone"
                        :show-overflow-tooltip="true"
                        label="联系电话"
                        width="">
                        </el-table-column>
                        <el-table-column
                        v-if="formButton !== 'details'"
                        fixed="right"
                        label="操作"
                        width="150">
                        <template slot-scope="scope">
                            <el-button @click="DeleteSigns(scope.row,scope.$index)" type="text" size="small">删除</el-button>
                        </template>
                        </el-table-column>
                    </el-table>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="SignsDetailsDialog = false">关 闭</el-button>
                </span>
            </el-dialog>
            <!-- 签收对话框 -->
            <el-dialog
                title="签收详情"
                :visible.sync="SignCarryDialog"
                style="margin-top:-2%"
                :before-close="handleClose"
                :show-close="false"
                width="70%"
                >
                <div style="height: 525px">
                    <el-table
                        :header-cell-style="headClass"
                        :cell-style="rowClass"
                        :data="SignCarryData"
                        height="500px"
                        border
                        style="width: 100%">
                        <el-table-column
                        prop="orderCode"
                        :show-overflow-tooltip="true"
                        label="订单编号"
                        width="150">
                        </el-table-column>
                        <el-table-column
                        prop="carryName"
                        :show-overflow-tooltip="true"
                        label="企业名称"
                        width="">
                        </el-table-column>
                        <el-table-column
                        prop="productName"
                        label="商品名称/配方名称"
                        width="">
                        </el-table-column>
                        <el-table-column
                        prop="standards"
                        label="规格"
                        width="">
                        </el-table-column>
                        <el-table-column
                        prop="unit"
                        label="单位"
                        width="">
                        </el-table-column>
                        <el-table-column
                        prop="driver"
                        label="司机"
                        width="">
                        </el-table-column>
                        <el-table-column
                        prop="telephone"
                        label="联系电话"
                        width="">
                        </el-table-column>
                        <el-table-column
                        prop="licensePlate"
                        label="车牌"
                        width="">
                        </el-table-column>
                        <el-table-column
                        prop="aggregateSize"
                        label="运输数量"
                        width="">
                        </el-table-column>
                        <el-table-column
                        prop="state"
                        :formatter="SignCarrystate"
                        label="状态"
                        width="">
                        </el-table-column>
                        <el-table-column
                        fixed="right"
                        label="操作"
                        width="150">
                        <template slot-scope="scope">
                            <el-button v-if="$hasPermission(['AddressManagement_details'])" @click="SignCarryhandleClick(scope.row)" type="text" size="small">详情</el-button>
                            <el-button v-if="$hasPermission(['AddressManagement_edit']) & scope.row.state == 2 " @click="SignCarryOrder(scope.row)" type="text" size="small">签收</el-button>
                        </template>
                        </el-table-column>
                    </el-table>
                    <div style="float:right" class="block">
                        <el-pagination
                        @size-change="signhandleSizeChange"
                        @current-change="signhandleCurrentChange"
                        :current-page="signPage"
                        :page-sizes="[10, 15, 20]"
                        :page-size="signSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="signTotal">
                        </el-pagination>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="SignCarryDialog = false">关 闭</el-button>
                </span>
            </el-dialog>
            <!-- 签收数量对话框 -->
            <el-dialog
                :title="title"
                :visible.sync="SureRepectCarryDialog"
                width="43%"
                :before-close="handleClose"
                :show-close="false"
                style="margin-top:-1%"
                >
                <el-form style="width:95%" ref="SureSignCarryform" :rules="SureSignCarryformRules" :model="SureSignCarryform" label-width="110px" class="demo-form-inline">
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="订单编号：" prop="orderCode">
                                <span>{{SureSignCarryform.orderCode}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="企业名称：" prop="carryName">
                                <span>{{SureSignCarryform.carryName}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="商品名称：" prop="productName">
                                <span>{{SureSignCarryform.productName}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="规格：" prop="standards">
                                <span>{{SureSignCarryform.standards}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="单位：" prop="unit">
                                <span>{{SureSignCarryform.unit}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="司机：" prop="driver">
                                <span>{{SureSignCarryform.driver}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="车牌：" prop="licensePlate">
                                <span>{{SureSignCarryform.licensePlate}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="运输数量：" prop="aggregateSize">
                                <span>{{SureSignCarryform.aggregateSize}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="签收数量：" prop="number">
                                <span v-if="formButton == 'details'">{{SureSignCarryform.number}}</span>
                                <el-input v-else v-model="SureSignCarryform.number" placeholder="请输入签收数量"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="手机号码：" prop="telephone">
                                <span v-if="formButton == 'details'">{{SureSignCarryform.telephone}}</span>
                                <el-input v-else v-model="SureSignCarryform.telephone" @blur="qianshouyanzheng()" placeholder="请输入手机号码"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row v-if="formButton !== 'details'" :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="签收人：" prop="">
                                <span>{{signee}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="验证码：" prop="code">
                                <el-input v-model="SureSignCarryform.code" placeholder="请输入验证码">
                                    <el-button v-show="ObtainStatu == false" @click="signCode" slot="append">发送验证码</el-button>
                                    <el-button v-show="ObtainStatu == true" slot="append">{{timing}}</el-button>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row v-if="formButton !== 'details'" :gutter="24">
                        <el-col :span="24">
                            <el-form-item label="签字：" prop="signImage">
                                <div>
                                    <vue-esign style="box-shadow: rgb(223 237 237) 0px 0px 6px 3px;" ref="esign" :width="400" :height="130" :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor" :bgColor.sync="bgColor"/>
                                    <button @click="handleReset">清空画板</button>
                                    <!-- <el-button @click="handleGenerate">生成图片</el-button> -->
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button v-if="formButton == 'details'" @click="SureRepectCarryDialog = false">取 消</el-button>
                    <el-button v-if="formButton == 'modify'" @click="SureSignCarryCancel('SureSignCarryform')">取 消</el-button>
                    <el-button v-if="formButton == 'modify'" type="primary" @click="SureSignCarry('SureSignCarryform')">确 定</el-button>
                </span>
            </el-dialog>
            <!-- 签收确认对话框 -->
            <el-dialog
                :title="title"
                :visible.sync="SureSignCarryDialog"
                style="margin-top:-2%"
                :before-close="handleClose"
                :show-close="false"
                width="70%"
                >
                <div style="height: 525px">
                    <el-table
                        :header-cell-style="headClass"
                        :cell-style="rowClass"
                        :data="SureSignCarrytableData"
                        height="500px"
                        border
                        style="width: 100%">
                        <el-table-column
                        prop="orderCode"
                        :show-overflow-tooltip="true"
                        label="订单编号"
                        width="150">
                        </el-table-column>
                        <el-table-column
                        prop="purchaseName"
                        :show-overflow-tooltip="true"
                        label="企业名称"
                        width="">
                        </el-table-column>
                        <el-table-column
                        prop="productName"
                        label="商品名称/配方名称"
                        width="">
                        </el-table-column>
                        <el-table-column
                        prop="standards"
                        label="规格"
                        width="">
                        </el-table-column>
                        <el-table-column
                        prop="unit"
                        label="单位"
                        width="">
                        </el-table-column>
                        <el-table-column
                        prop="driver"
                        label="司机"
                        width="">
                        </el-table-column>
                        <el-table-column
                        prop="telephone"
                        label="联系电话"
                        width="">
                        </el-table-column>
                        <el-table-column
                        prop="licensePlate"
                        label="车牌"
                        width="">
                        </el-table-column>
                        <el-table-column
                        prop="aggregateSize"
                        label="运输数量"
                        width="">
                        </el-table-column>
                        <el-table-column
                        prop="signNumber"
                        label="签收数量"
                        width="">
                        </el-table-column>
                        <el-table-column
                        prop="isConfirm"
                        :formatter="SureSignCarryisConfirm"
                        label="状态"
                        width="">
                        </el-table-column>
                        <el-table-column
                        fixed="right"
                        label="操作"
                        width="150">
                        <template slot-scope="scope">
                            <el-button v-if="$hasPermission(['AddressManagement_details'])" @click="ConfirmhandleClick(scope.row)" type="text" size="small">详情</el-button>
                            <el-button v-if="$hasPermission(['AddressManagement_edit']) & scope.row.isConfirm == false " @click="ConfirmSignCarryOrder(scope.row)" type="text" size="small">确认</el-button>
                        </template>
                        </el-table-column>
                    </el-table>
                    <div style="float:right" class="block">
                        <el-pagination
                        @size-change="SureSignhandleSizeChange"
                        @current-change="SureSignhandleCurrentChange"
                        :current-page="SureSignPage"
                        :page-sizes="[10, 15, 20]"
                        :page-size="SureSignSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="SureSignTotal">
                        </el-pagination>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="SureSignCarryDialog = false">关 闭</el-button>
                </span>
            </el-dialog>
            <!-- 签收确认表单 -->
            <el-dialog
                :title="title"
                :visible.sync="SureSignCarrydialogVisible"
                width="43%"
                :before-close="handleClose"
                :show-close="false"
                style="margin-top:-1%"
                >
                <el-form style="width:95%" ref="ConfirmSignCarryform" :model="ConfirmSignCarryform" label-width="110px" class="demo-form-inline">
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="订单编号：" prop="orderCode">
                                <span>{{ConfirmSignCarryform.orderCode}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="企业名称：" prop="carryName">
                                <span>{{ConfirmSignCarryform.carryName}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="商品名称：" prop="productName">
                                <span>{{ConfirmSignCarryform.productName}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="规格：" prop="standards">
                                <span>{{ConfirmSignCarryform.standards}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="单位：" prop="unit">
                                <span>{{ConfirmSignCarryform.unit}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="司机：" prop="driver">
                                <span>{{ConfirmSignCarryform.driver}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="车牌：" prop="licensePlate">
                                <span>{{ConfirmSignCarryform.licensePlate}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="运输数量：" prop="aggregateSize">
                                <span>{{ConfirmSignCarryform.aggregateSize}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="签收数量：" prop="signNumber">
                                <span>{{ConfirmSignCarryform.signNumber}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="手机号码：" prop="telephone">
                                <span>{{ConfirmSignCarryform.telephone}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button v-if="formButton == 'details'" @click="SureSignCarrydialogVisible = false">取 消</el-button>
                </span>
            </el-dialog>
            <!-- 运输状态对话框 -->
            <el-dialog
                title="运输状态"
                :visible.sync="GetCarryProcessPageListDialog"
                style="margin-top:-2%"
                :before-close="handleClose"
                :show-close="false"
                width="70%"
                >
                <div style="height:525px">
                    <el-form :inline="true" :model="yunshuform" class="demo-form-inline">
                        <el-form-item label="司机名称">
                            <el-input v-model="yunshuform.driver" placeholder="请输入司机名称"></el-input>
                        </el-form-item>
                        <el-form-item label="司机电话">
                            <el-input v-model="yunshuform.telephone" placeholder="请输入司机电话"></el-input>
                        </el-form-item>
                        <el-form-item label="车牌号码">
                            <el-input v-model="yunshuform.licensePlate" placeholder="请输入车牌号码"></el-input>
                        </el-form-item>
                        <el-form-item v-if="$hasPermission(['SalesOrder_CarryProcess'])" label="">
                            <el-button type="primary" @click="yunshuquery()">查询</el-button>
                        </el-form-item>
                    </el-form>
                    <el-table
                        :header-cell-style="headClass"
                        :cell-style="rowClass"
                        :data="CarryProcessPageList"
                        height="400px"
                        border
                        style="width: 100%">
                        <el-table-column
                        prop="driver"
                        :show-overflow-tooltip="true"
                        :key="1"
                        label="司机名称"
                        width="150">
                        </el-table-column>
                        <el-table-column
                        prop="telephone"
                        :show-overflow-tooltip="true"
                        :key="2"
                        label="司机电话"
                        width="150">
                        </el-table-column>
                        <el-table-column
                        prop="licensePlate"
                        :show-overflow-tooltip="true"
                        :key="3"
                        label="车牌号码"
                        width="150">
                        </el-table-column>
                        <el-table-column
                        prop="productName"
                        :key="4"
                        label="商品名称/配方名称"
                        width="150">
                        </el-table-column>
                        <el-table-column
                        v-if="this.GetCarryProcessPageListindustryType == 0 || this.GetCarryProcessPageListindustryType == '0,1'"
                        prop="standards"
                        :key="5"
                        label="规格"
                        width="">
                        </el-table-column>
                        <!-- 混凝土 -->
                        <el-table-column
                        v-if="this.GetCarryProcessPageListindustryType == 1 || this.GetCarryProcessPageListindustryType == '0,1'"
                        prop="standards"
                        :show-overflow-tooltip="true"
                        :key="6"
                        label="强度等级"
                        width="">
                        </el-table-column>
                        <el-table-column
                        v-if="this.GetCarryProcessPageListindustryType == 1 || this.GetCarryProcessPageListindustryType == '0,1'"
                        prop="impermeability"
                        :show-overflow-tooltip="true"
                        :key="7"
                        label="抗渗等级"
                        width="">
                        </el-table-column>
                        <el-table-column
                        v-if="this.GetCarryProcessPageListindustryType == 1 || this.GetCarryProcessPageListindustryType == '0,1'"
                        prop="slump"
                        :key="8"
                        label="塌落度"
                        width="">
                        </el-table-column>
                        <el-table-column
                        v-if="this.GetCarryProcessPageListindustryType == 1 || this.GetCarryProcessPageListindustryType == '0,1'"
                        prop="aggregateSize"
                        :show-overflow-tooltip="true"
                        :key="9"
                        label="骨料粒径"
                        width="">
                        </el-table-column>
                        <el-table-column
                        v-if="this.GetCarryProcessPageListindustryType == 1 || this.GetCarryProcessPageListindustryType == '0,1'"
                        prop="pouringMethod"
                        :show-overflow-tooltip="true"
                        :key="10"
                        label="浇筑方式"
                        width="">
                        </el-table-column>
                        <el-table-column
                        v-if="this.GetCarryProcessPageListindustryType == 1 || this.GetCarryProcessPageListindustryType == '0,1'"
                        prop="constructionPosition"
                        :show-overflow-tooltip="true"
                        :key="11"
                        label="施工部位"
                        width="">
                        </el-table-column>
                        <el-table-column
                        prop="state"
                        :key="12"
                        label="发货入厂"
                        width="80">
                        <template slot-scope="scope">
                            <el-checkbox disabled v-model="scope.row.a"></el-checkbox>
                            <!-- <span v-if="scope.row.state == 0"><i class="el-icon-circle-check"></i></span>
                            <span v-else><i class="el-icon-circle-close"></i></span> -->
                        </template>
                        </el-table-column>
                        <el-table-column
                        prop="state"
                        :key="13"
                        label="发货出厂"
                        width="80">
                        <template slot-scope="scope">
                            <el-checkbox disabled v-model="scope.row.a1"></el-checkbox>
                            <!-- <span v-if="scope.row.state == 2"><i class="el-icon-circle-check"></i></span>
                            <span v-else><i class="el-icon-circle-close"></i></span> -->
                        </template>
                        </el-table-column>
                        <el-table-column
                        prop="actualWeight"
                        :key="14"
                        label="实际重量"
                        width="100">
                        </el-table-column>
                        <el-table-column
                        v-if="TheTransportProcess == 1"
                        prop="receivingState"
                        :key="15"
                        label="收货入厂"
                        width="80">
                        <template slot-scope="scope">
                            <el-checkbox disabled v-model="scope.row.b"></el-checkbox>
                            <!-- <span v-if="scope.row.state == 0"><i class="el-icon-circle-check"></i></span>
                            <span v-else><i class="el-icon-circle-close"></i></span> -->
                        </template>
                        </el-table-column>
                        <el-table-column
                        v-if="TheTransportProcess == 1"
                        prop="receivingState"
                        :key="16"
                        label="收货出厂"
                        width="80">
                        <template slot-scope="scope">
                            <el-checkbox disabled v-model="scope.row.b1"></el-checkbox>
                            <!-- <span v-if="scope.row.state == 2"><i class="el-icon-circle-check"></i></span>
                            <span v-else><i class="el-icon-circle-close"></i></span> -->
                        </template>
                        </el-table-column>
                        <el-table-column
                            prop="stroke"
                            v-if="TheTransportProcess == 1"
                            :show-overflow-tooltip="true"
                            :key="17"
                            label="运输过程"
                            width="">
                        </el-table-column>
                    </el-table>
                    <div style="float:right;" class="block">
                        <el-pagination
                        @size-change="yunshuhandleSizeChange"
                        @current-change="yunshuhandleCurrentChange"
                        :current-page="yunshuPage"
                        :page-sizes="[10, 15, 20]"
                        :page-size="yunshuSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="yunshuTotal">
                        </el-pagination>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="GetCarryProcessPageListDialog = false , start()">关 闭</el-button>
                </span>
            </el-dialog>
        </div>
        <div>
            <el-dialog
                title="车辆信息"
                :visible.sync="addCarDialog"
                :before-close="handleClose"
                style="margin-top: -3%"
                width="60%"
                >
                <AddCarDialog ref="addCarDialogRef"></AddCarDialog>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="CancelModifyCars()">关 闭</el-button>
                    <el-button type="primary" @click="SureModifyCars()">确 定</el-button>
                </span>
            </el-dialog>
            <el-dialog
                title="账款记录"
                :visible.sync="ReceiptsAmountDialog"
                style="margin-top: -3%"
                width="60%"
                >
                <ReceiptsAmount @CLOSE="CancelReceiptsAmount" ref="RefReceiptsAmount"></ReceiptsAmount>
            </el-dialog>
        </div>
    </div>
</template>
<script>
var zhuanhuandate
import AddCarDialog from '@/view/SalesOrder/addCarDialog.vue'
import ReceiptsAmount from '@/view/ReceiptsAmount/ReceiptsAmount.vue'
export default {
    components: { AddCarDialog , ReceiptsAmount },
    computed: {
        // tableHeight: document.documentElement.clientHeight
    },
    watch: {
        filterText(val) {
            this.$refs.Cartree.filter(val);
        }
    },
    created() {
        var height = document.documentElement.clientHeight
        var width = document.documentElement.clientWidth
        if(width >= 1980){
            this.tableHeight = (height*0.67)+'px';
        }else{
            this.tableHeight = (height*0.58)+'px';
        }
    },
    data(){
        return{
            // 树形结构过滤
            filterText: '',
            expireTimeOPtion: {
                disabledDate(time) {
                    return time.getTime() < Date.parse(zhuanhuandate) - 8.64e7;  //如果没有后面的-8.64e7就是不可以选择今天的 
                }
            },
            loading: false,
            tableHeight: 0,
            options: [{
                value: 0,
                label: '未接单'
                }, {
                value: 1,
                label: '已接单'
                }, {
                value: 2,
                label: '装货中'
                }, {
                value: 3,
                label: '已完成'
            }, {
                value: 4,
                label: '已取消'
            }, {
                value: 5,
                label: '改价'
            }, {
                value: 6,
                label: '废弃'
            }, {
                value: 7,
                label: '终止'
            }, {
                value: 8,
                label: '暂停'
            }],
             pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
            },
            FormOptions: [],
            form: {
                name: '',
                orderNo: '',
                contractCode: '',
                state: null,
                payType: '',
                carryModel: null,
                startDate: '',
                endDate: '',
                datetime: this.getDateTime()
            },
            tableData: [],
            // 表格分页
            page: 1,
            size: 10,
            total: 0,
            // 表格单选绑定
            radio: '',
            // 表单
            dialogVisible: false,
            // 添加对话框输入企业
            enterpriseName: '',
            // 企业表格
            enterpriseData: {},
            // 企业加载
            EnterpriseLoading: false,
            // 企业选中
            enterpriseRadio: -1,
            // 企业合同
            Contract: [],
            // 添加商品信息
            // 判断是保存矿石商品 还是混凝土商品
            ch0: false,
            ch1: false,
            // 支付信息 支付方式
            payType0: false,
            payType1: false,
            payType2: false,
            payType3: false,
            // 承运信息详情表单
            CarryDetailsDialog: false,
            AddressData: [],
            // 添加承运信息对话框
            AddCarryDialog: false,
            // 承运信息企业名称
            CustomerList: [],
            // 判断车辆类型
            CarryindustryType: 0,
            // 承运信息选择列表
            CarryList: [],
            // 添加承运信息表单
            Carryform: {
                // 选中的企业名称id
                customerId: '',
                // 车辆id
                id: '',
                productId: 0,
                productName: '',
                driver: '',
                carryName: '',
                telephone: '',
                licensePlate: '',
                driveCard: '',
                roadCard: '',
                maxLoad: 0,
                remarks: '',
                state: 0,
                carType: 0,
                baseId: 0,
                receivingAddressId: null,
                addressId: null
            },
            // 添加承运信息规则验证
            CarryformRules: {
                customerId: [
                    { required: true , message: '请选择企业名称' , trigger: 'blur' },
                ],
                id: [
                    { required: true , message: '请选择车牌' , trigger: 'blur' },
                ],
            },
            // 承运详情对话框
            CarrysDetailsDialog: false,
            // 承运详情信息
            CarrysData: [],
            // 签署人对话框以及签署人表单
            AddSignsDialog: false,
            Signsform: {
                productId: '',
                productName: '',
                signatory: '',
                telephone: ''
            },
            // 签署人规则验证
            SignsformRules: {
                signatory: [
                    { required: true , message: '请输入签署人' , trigger: 'blur' },
                ],
                telephone: [
                    { required: true , message: '请输入联系电话' , trigger: 'blur' },
                ],
            },
            // 签署人详情
            SignsDetailsDialog: false,
            SignsData: [],
            addform: {
                id: '',
                enterpriseId: 0,
                enterpriseName: "",
                contractId: '',
                contractName: "",
                contractCode: "",
                code: '',
                purchaseId: 0,
                purchaseName: "",
                taxNumber: "",
                telephone: "",
                domicile: "",
                bankName: "",
                bankNo: "",
                contacts: "",
                contactTel: "",
                purchaseTaxNumber: '',
                purchaseTelephone: '',
                purchaseDomicile: '',
                purchaseBankName: '',
                purchaseBankNo: '',
                purchaseContacts: '',
                purchaseContactTel: '',
                purchaseBusinessName: '',
                purchaseBusinessUserId: '',
                purchaseBusinessTel: '',
                carryPrice: 0,
                totalPrice: 0,
                payType: '',
                payAmount: '',
                isDate: false,
                repaymentDate: "",
                creditTipsAmount: '',
                creditTipsDay: '',
                repaymentTime: "",
                repaymentTime1: '',
                repaymentTime2: '',
                tipsDay: '',
                carryModel: null,
                freightCalculation: null,
                unitName: "",
                projectName: "",
                salesman: "",
                salesTel: "",
                remarks: "",
                receivableAmount: 0,
                receiptsAmount: '',
                type: 0,
                businessType: '0',
                industryType: '0',
                state: 0,
                orderSands: [],
                orderConcretes: [],
                orderAddresses: [],
                orderSigns: [],
                orderCarrys: [],
                addressGroups: [],
                creatorId: 0,
                modeType: 0,
            },
            // 添加表单验证
            addformRules: {
                name: [
                    { required: true , message: '请输入厂区名称' , trigger: 'blur' },
                    { min: 1 , max: 20 , message: '长度在 1 到 20个字符' , trigger: 'blur' }
                ],
                area: [
                    { required: true , message: '请输入厂区面积' , trigger: 'blur' },
                    // { type: 'number', message: '厂区面积必须为数字值'}
                ],
                capacity: [
                    { required: true , message: '请输入产能' , trigger: 'blur' },
                    // { type: 'number', message: '产能必须为数字值'}
                ],
                contacts: [
                    { required: true , message: '请输入负责人' , trigger: 'blur' },
                ],
                telephone: [
                    { required: true , message: '请输入联系电话' , trigger: 'blur' },
                    { type: 'number', message: '联系电话必须为数字值'}
                ],
                address: [
                    { required: true , message: '请输入生产地址' , trigger: 'blur' },
                    { min: 1 , max: 50 , message: '长度在 1 到 50个字符' , trigger: 'blur' }
                ]
            },
            // 修改
            formButton: 'add',
            // 对话框标题
            title: '新增',
            // 审核id 
            auditid: '',
            AuditiSactive: false,
            modeType: 0,
            type: 0,
            state: 0,
            // 分配厂区金额
            addressGroupsYuan: [],
            // 签收对话框和签收表格数据
            SignCarryDialog: false,
            SignCarryData: [],
            SureRepectCarryDialog: false,
            SureSignCarryform: {
                id: '',
                orderCode: '',
                carryName: '',
                productName: '',
                standards: '',
                unit: '',
                driver: '',
                licensePlate: '',
                aggregateSize: '',
                number: '',
                telephone: '',
                code: '',
                signImage: '',
            },
            signee: '',
            // 添加表单验证
            SureSignCarryformRules: {
                number: [
                    { required: true , message: '请输入签收数量' , trigger: 'blur' },
                ],
                telephone: [
                    { required: true , message: '请输入电话号码' , trigger: 'blur' },
                    // { type: 'number', message: '厂区面积必须为数字值'}
                ],
                code: [
                    { required: true , message: '请输入验证码' , trigger: 'blur' },
                    // { type: 'number', message: '产能必须为数字值'}
                ],
            },
            // 添加承运信息商品表格
            shopNameCarryData: [],
            // 添加承运信息商品表格选中
            multipleSelection: '',
            // 签收画板
            lineWidth: 6,
            lineColor: '#000000',
            bgColor: '',
            resultImg: '',
            isCrop: false,
            // 签收确认对话框以及表格数据
            SureSignCarryDialog: false,
            SureSignCarrytableData: [],
            // 签收确认详情
            SureSignCarrydialogVisible: false,
            ConfirmSignCarryform: {},
            // 签收查询
            rowcode: '',
            // 签收确认查询
            Surerowcode: '',
            // 运输过程数据
            CarryProcessPageList: [],
            // 运输过程对话框
            GetCarryProcessPageListDialog: false,
            // 判断是混凝土 还是矿石商品
            GetCarryProcessPageListindustryType: 0,
            ObtainStatu: false,
            timing: 0,
            timer: null,// 初始定时器变量名为null
            // 判断运输过程表格运输过程那一列是否显示，
            TheTransportProcess: 0,
            // 运输分页
            yunshuPage: 1,
            yunshuSize: 10,
            yunshuTotal: 0,
            yunshuform: {
                driver: '',
                telephone: '',
                licensePlate: ''
            },
            yunshucode: '',
            // 签收分页
            signPage: 1,
            signSize: 10,
            signTotal: 0,
            // 确认签收分页
            SureSignPage: 1,
            SureSignSize: 10,
            SureSignTotal: 0,
            // 订单过程中添加或修改车辆表单
            addCarDialog: false,
            defaultProps: {
                children: 'children',
                label: 'name'
            },
            getCheckedNodesCardata: [],
            FactoryAddressData: [],
            DeliveryAddressData: [],
            ReceiptsAmountDialog: false
        }
    },
    beforeDestroy() {
        // js提供的clearInterval方法用来清除定时器
        clearInterval(this.timer);
        this.end()
    },
    methods:{
        filterNode(value, data) {
            if (!value) return true;
            return data.licensePlate.indexOf(value) !== -1;
        },
        start(){
            // 将定时器名字赋值到变量中
            this.timer = setInterval(() => {
                this.query()
                // console.log('开始-----')
            }, 20000);
        },
        end(){
            clearInterval(this.timer);
            this.timer = null // 这里最好清除一下，回归默认值
            // 众所周知，定时器返回一个随机整数，用于表示定时器的编号，后面通过名字可以取消这个定时器的执行。
            // console.log('结束-----')
        },
        // 
        getDateTime() {
            const start = new Date(new Date().getTime() - 3600 * 1000 * 24 * 1)
                .toISOString()
                .replace('T', ' ')
                .split('.')[0] //默认开始时间7天前
            const end = new Date(new Date().getTime() - 3600 * 1000 * 0)
                .toISOString()
                .replace('T', ' ')
                .split('.')[0]//默认结束时间1天前
            return [start, end]
        },
        // 表头 列 居中
        headClass() {
            return "text-align:center;background-color: #c8e0f391;color:#E6A23C;height:30px";
        },
        rowClass() {
            return "text-align:center";
        },
        // 分页功能
        handleSizeChange(val) {
            this.size = val
            this.query()
        },
        handleCurrentChange(val) {
            this.page = val
            this.query()
        },
        // 运输分页功能
        yunshuhandleSizeChange(val) {
            this.yunshuSize = val
            this.yunshuquery()
        },
        yunshuhandleCurrentChange(val) {
            this.yunshuPage = val
            this.yunshuquery()
        },
        // 签收分页功能
        signhandleSizeChange(val) {
            this.signSize = val
            this.signquery()
        },
        signhandleCurrentChange(val) {
            this.signPage = val
            this.signquery()
        },
        // 确认签收分页功能
        SureSignhandleSizeChange(val) {
            this.SureSignSize = val
            this.SureSignquery()
        },
        SureSignhandleCurrentChange(val) {
            this.SureSignPage = val
            this.SureSignquery()
        },
        // 表格里面判断
        isActive(row){
            if(row.isActive == true){
                return '已审核';
            }else{
                
                return '未审核'
            }
        },
        SureSignCarryisConfirm(row){
            if(row.isConfirm == true){
                return '已确认';
            }else{
                return '未确认'
            }
        },
        // 表格里面企业名称判断是己方还是对方
        aa(row){
            if(row.modeType == 0){
                return row.purchaseName;
            }else{
                return row.enterpriseName;
            }
        },
        payType(row){
            if(row.payType == '0'){
                return '充值支付';
            }else if(row.payType == '1'){
                return '授信支付';
            }else if(row.payType == '2'){
                return '账期支付';
            }else if(row.payType == '3'){
                return '现金支付';
            }else if(row.payType == '1,2'){
                return '授信,账期支付';
            }
        },
        SignCarrystate(row){
            if(row.state == 0){
                return '待入厂';
            }else if(row.state == 1){
                return '入厂';
            }else if(row.state == 2){
                return '出厂';
            }else if(row.state == 3){
                return '已完成';
            }
        },
        typetype(row){
            if(row.type == 0){
                return '云商系统';
            }else if(row.type == 1){
                return '收发货系统';
            }
        },
        state1(row){
            if(row.state == 0){
                return '未接单';
            }else if(row.state == 1){
                return '已接单';
            }else if(row.state == 2){
                return '装货中';
            }else if(row.state == 3){
                return '已完成';
            }else if(row.state == 4){
                return '已取消';
            }else if(row.state == 5){
                return '改价';
            }else if(row.state == 6){
                return '废弃';
            }else if(row.state == 7){
                return '终止';
            }else if(row.state == 8){
                return '暂停';
            }else if(row.state == 9){
                return '暂停';
            }
        },
        // 查询事件
        query(){
            this.radio = ''
            this.auditid = ''
            this.loading = true
            if(this.form.startDate == ''){
                zhuanhuandate = 0
            }else{
                zhuanhuandate = this.form.startDate
            }
            if(!this.$hasPermission(['SalesOrder_query']))
            {
                return;
            }
            this.$http.post(this.$SysOrder_GetSalePageList,{
                currentPage: this.page,
                pageSize: this.size,
                filter: {
                    name: this.form.name,
                    orderNo: this.form.orderNo,
                    contractCode: this.form.contractCode,
                    state: this.form.state,
                    payType: this.form.payType,
                    carryModel: this.form.carryModel,
                    startDate: this.form.startDate,
                    endDate: this.form.endDate
                }
            }).then(response => {
                this.tableData = response.data.data.list
                this.total = response.data.data.total
                this.loading = false
            })
        },
        // 查询企业信息
        querySysEnterprise(){
            this.EnterpriseLoading = true
            this.$http.get(this.$syspartners_getsalelist)
            .then(response => {
                this.FormOptions = response.data.data.list
                this.EnterpriseLoading = false
            })
        },
        // 选择客户框数据
        SelectFormOptions(){
            this.enterpriseData = {}
            this.Contract = []
            this.restAddfrom()
            this.FormOptions.forEach(item => {
                if(item.id == this.enterpriseName){
                    this.enterpriseData = item
                    this.enterpriseRadio = item.id
                    this.addform.enterpriseId = item.purchaseId
                    this.addform.enterpriseName = item.purchaseName
                    this.queryAdd()
                }
            })
        },
        // 添加对话框企业信息表格返回值
        name(row){
            if(row.modeType == 0){
                return row.purchaseName;
            }else{
                return row.enterpriseName;
            }
        },
        contacts(row){
            if(row.modeType == 0){
                return row.purchaseContacts;
            }else{
                return row.contacts;
            }
        },
        contactTel(row){
            if(row.modeType == 0){
                return row.purchaseContactTel;
            }else{
                return row.contactTel;
            }
        },
        taxNumber(row){
            if(row.modeType == 0){
                return row.purchaseTaxNumber;
            }else{
                return row.taxNumber;
            }
        },
        domicile(row){
            if(row.modeType == 0){
                return row.purchaseDomicile;
            }else{
                return row.domicile;
            }
        },
        bankName(row){
            if(row.modeType == 0){
                return row.purchaseBankName;
            }else{
                return row.bankName;
            }
        },
        bankNo(row){
            if(row.modeType == 0){
                return row.purchaseBankNo;
            }else{
                return row.bankNo;
            }
        },
        // 获取企业信息
        getenterprise(row){
            this.addform.enterpriseId = row.purchaseId
            this.addform.enterpriseName = row.purchaseName
            this.queryAdd()
        },
        // 查询合同信息
        queryAdd(){
            this.ShopLoading = true
            this.addform.industryType = 0
            this.$http.post(this.$SysContract_GetSalePageList,{
                currentPage: this.page,
                pageSize: this.size,
                filter: {
                    id: this.addform.enterpriseId,
                    name: this.addform.enterpriseName
                }
            }).then(response => {
                this.Contract = response.data.data.list
                this.ShopLoading = false
            })
        },
        // 选择合同
        changeContract(){
            this.Contract.forEach(item => {
                if(this.addform.contractId == item.id){
                    this.addform.enterpriseId = item.enterpriseId
                    this.addform.enterpriseName = item.enterpriseName
                    this.addform.contractId = item.id
                    this.addform.contractCode = item.code
                    this.addform.contractName = item.name
                    this.addform.code = ''
                    this.addform.purchaseId = item.purchaseId
                    this.addform.purchaseName = item.purchaseName
                    this.addform.taxNumber = item.taxNumber
                    this.addform.telephone = item.telephone
                    this.addform.domicile = item.domicile
                    this.addform.bankName = item.bankName
                    this.addform.bankNo = item.bankNo
                    this.addform.contacts = item.contacts
                    this.addform.contactTel = item.contactTel
                    this.addform.purchaseTaxNumber = item.purchaseTaxNumber
                    this.addform.purchaseTelephone = item.purchaseTelephone
                    this.addform.purchaseDomicile = item.purchaseDomicile
                    this.addform.purchaseBankName = item.purchaseBankName
                    this.addform.purchaseBankNo = item.purchaseBankNo
                    this.addform.purchaseContacts = item.purchaseContacts
                    this.addform.purchaseContactTel = item.purchaseContactTel
                    this.addform.purchaseBusinessName = item.purchaseBusinessName
                    this.addform.purchaseBusinessUserId = item.purchaseBusinessUserId
                    this.addform.purchaseBusinessTel = item.purchaseBusinessTel
                    this.addform.payType = item.payType
                    if(this.addform.payType == '0'){
                        this.payType0 = true
                    }else if(this.addform.payType == '1'){
                        this.payType1 = true
                    }else if(this.addform.payType == '2'){
                        this.payType2 = true
                    }else if(this.addform.payType == '3'){
                        this.payType3 = true
                    }else if(this.addform.payType == '1,2'){
                        this.payType1 = true
                        this.payType2 = true
                    }
                    this.addform.payAmount = item.payAmount
                    this.addform.isDate = item.isDate
                    this.addform.repaymentDate = item.repaymentDate
                    this.addform.creditTipsAmount = item.creditTipsAmount
                    this.addform.creditTipsDay = item.creditTipsDay
                    this.addform.repaymentTime = item.repaymentTime
                    var i1 = item.repaymentTime.indexOf("-")
                    this.addform.repaymentTime1 = item.repaymentTime.slice(0,i1)
                    this.addform.repaymentTime2 = item.repaymentTime.slice(i1+1)
                    this.addform.tipsDay = item.tipsDay
                    this.addform.carryModel = item.carryModel
                    this.addform.freightCalculation = item.freightCalculation
                    this.addform.unitName = item.unitName
                    this.addform.projectName = item.projectName
                    this.addform.salesman = item.salesman
                    this.addform.salesTel = item.salesTel
                    this.addform.remarks = item.remarks
                    this.addform.receivableAmount = item.receivableAmount
                    this.addform.receiptsAmount = item.receiptsAmount
                    this.addform.type = item.type,
                    this.addform.businessType = item.businessType
                    this.addform.industryType = item.industryType
                    if(this.addform.industryType == '0'){
                        this.ch0 = true
                        this.ch1 = false
                    }else if(this.addform.industryType == '1'){
                        this.ch1 = true
                        this.ch0 = false
                    }else{
                        this.ch0 = true
                        this.ch1 = true
                    }
                    this.addform.isActive = item.isActive
                    this.addform.state = item.state
                    this.addform.orderSands = item.contractSands
                    this.addform.orderConcretes = item.contractConcretes
                    this.addform.orderAddresses = item.contractAddresses
                    this.addform.modeType = item.modeType
                    this.addform.orderSigns = []
                    this.addform.orderCarrys = []
                    this.addform.addressGroups = item.addressGroups
                }
            });
        },
        // 查询承运信息企业名称
        GetCustomerList1(){
            this.CarryindustryType = 0
            this.$http.get(this.$SysCarry_GetCustomerList+'?industryType=0')
            .then(response => {
                this.CustomerList = response.data.data
            })
        },
        // 查询承运信息企业名称
        GetCustomerList2(){
            this.CarryindustryType = 1
            this.$http.get(this.$SysCarry_GetCustomerList+'?industryType=1')
            .then(response => {
                this.CustomerList = response.data.data
            })
        },
        // 选择的公司
        SelectCustomer(){
            this.Carryform.driver = '',
            this.Carryform.id = '',
            this.Carryform.telephone = '',
            this.Carryform.licensePlate = '',
            this.Carryform.maxLoad = '',
            this.Carryform.remarks = '',
            this.Carryform.carType = '',
            this.Carryform.driveCard = '',
            this.Carryform.roadCard = '',
            this.GetCarryList()
            this.CustomerList.forEach(item => {
                if(this.Carryform.customerId == item.id){
                    this.Carryform.carryName = item.name
                }
            })
        },
        SelectFactoryAddress(){
            this.Carryform.customerId = null
            this.multipleSelection = ''
            this.CarryList = []
            this.getCheckedNodesCardata = []
            var index = 1
            var shopNameIDArr = []
            this.shopNameCarryData = []
            this.CarrysData = []
            this.addform.orderAddresses.forEach(AddressItem => {
                let bb = shopNameIDArr.find(b => b === AddressItem.productId)
                if(bb == undefined & AddressItem.addressId == this.Carryform.addressId){
                    if(this.CarryindustryType == '0'){
                        let KSahop = this.addform.orderSands.find(shop => Number(shop.productId) === Number(AddressItem.productId))
                        if(KSahop == undefined) {
                            
                        }else{
                            shopNameIDArr.push(AddressItem.productId)
                            this.shopNameCarryData.push({
                                productName: AddressItem.productName,
                                productId: AddressItem.productId,
                                index: index
                            })
                        }
                    }else{
                        let HNTahop = this.addform.orderConcretes.find(shop => shop.productId === AddressItem.productId)
                        if(HNTahop == undefined) {
                            
                        }else{
                            shopNameIDArr.push(AddressItem.productId)
                            this.shopNameCarryData.push({
                                productName: AddressItem.productName,
                                productId: AddressItem.productId,
                                index: index
                            })
                        }
                    }
                }
            })
            this.addform.orderCarrys.forEach(carrys => {
                let isshop = this.shopNameCarryData.find(i => i.productId == carrys.productId)
                if(this.Carryform.addressId == carrys.addressId & isshop !== undefined) {
                    this.CarrysData.push(carrys)
                }
            })
        },
        // 收货选择地址
        SelectDeliveryAddress(){

        },
        // 查询承运信息选择列表
        GetCarryList(){
            if(this.Carryform.addressId == null) {
                this.Carryform.customerId = null
                this.$message.info('请选择厂区地址')
                return
            }
            this.CarryList = []
            this.getCheckedNodesCardata = []
            this.$http.get(this.$SysCarry_GetCarryList+'?customerId='+Number(this.Carryform.customerId))
            .then(response => {
                var i = 1
                response.data.data.forEach(element => {
                    let aa = this.addform.orderCarrys.find(a => a.licensePlate === element.licensePlate & a.addressId === this.Carryform.addressId)
                    if(aa == undefined){
                        this.CarryList.push({
                            carType:  Number(element.carType),
                            creationTime: element.creationTime,
                            customerId: Number(element.customerId),
                            driver:  element.driver,
                            id: element.id,
                            isActive: element.isActive,
                            licensePlate: element.licensePlate,
                            driveCard: element.driveCard,
                            roadCard: element.roadCard,
                            maxLoad: element.maxLoad,
                            remarks: element.remarks,
                            telephone: element.telephone,
                            type: Number(element.type),
                            disabled: false,
                            addressId: this.Carryform.addressId,
                            name: i+'--'+element.licensePlate
                        })
                    }else{
                        this.CarryList.push({
                            carType:  Number(element.carType),
                            creationTime: element.creationTime,
                            customerId: Number(element.customerId),
                            driver:  element.driver,
                            id: element.id,
                            isActive: element.isActive,
                            licensePlate: element.licensePlate,
                            driveCard: element.driveCard,
                            roadCard: element.roadCard,
                            maxLoad: element.maxLoad,
                            remarks: element.remarks,
                            telephone: element.telephone,
                            type: Number(element.type),
                            disabled: true,
                            addressId: this.Carryform.addressId,
                            name: i+'--'+element.licensePlate
                        })
                    }
                    i++
                });
                this.$nextTick(() => {
                    var idarr = []
                    this.getCheckedNodesCardata.forEach(item => {
                        idarr.push(Number(item.id))
                    })
                    this.addform.orderCarrys.forEach(id => {
                        if(id.CARid == undefined & id.addressId == this.Carryform.addressId){
                            this.CarryList.forEach(aaitem => {
                                if(id.licensePlate == aaitem.licensePlate){
                                    idarr.push(Number(aaitem.id))
                                }
                            })
                        }else{
                            if(id.addressId == this.Carryform.addressId) {
                                idarr.push(Number(id.CARid))
                            }
                        }
                    })
                    this.$refs.Cartree.setCheckedKeys(idarr)
                })
            })
        },
        // 选择车辆
        SelectCarry(data,node,obj){
            var index = -1
            if(this.getCheckedNodesCardata.length == 0) {
                if(node == true){
                    this.getCheckedNodesCardata.push(data)
                }else{

                }
            }else{
                this.getCheckedNodesCardata.forEach( i => {
                    index++
                    if(node == true) {
                        let a = this.getCheckedNodesCardata.find(a => Number(a.id) === Number(data.id))
                        if (a == undefined) {
                            this.getCheckedNodesCardata.push(data)
                        }
                    }else{
                        if(i.id == data.id){
                            this.getCheckedNodesCardata.splice(index , 1)
                        }
                    }
                })
            }
        },
        // 查看地址信息详情
        detailsAddress(row){
            this.AddressData = []
            this.addform.orderAddresses.forEach(item => {
                if(row.productId == item.productId){
                    this.AddressData.push(item)
                }
            })
            this.CarryDetailsDialog = true
        },
        //  添加承运信息车辆表单 
        addCarrys(){
            this.shopNameCarryData = []
            this.FactoryAddressData = []
            this.DeliveryAddressData = []
            this.CarrysData = []
            this.Carryform = {
                // 选中的企业名称id
                customerId: '',
                // 车辆id
                id: '',
                productId: 0,
                productName: '',
                driver: '',
                carryName: '',
                telephone: '',
                licensePlate: '',
                driveCard: '',
                roadCard: '',
                maxLoad: 0,
                remarks: '',
                state: 0,
                carType: 0,
                baseId: 0,
                receivingAddressId: null,
                addressId: null
            }
            var index = 1
            this.AddCarryDialog = true
            var AddressIDArr = []
            var shouhuoAddressIDArr = []
            this.addform.orderAddresses.forEach(AddressItem => {
                let aa = AddressIDArr.find(a => a === AddressItem.addressId)
                if(aa == undefined){
                    AddressIDArr.push(AddressItem.addressId)
                    this.FactoryAddressData.push({
                        id: AddressItem.addressId,
                        name: AddressItem.address,
                        addressId: AddressItem.addressId,
                        address: AddressItem.address,
                        receivingAddressId: AddressItem.receivingAddressId,
                        receivingAddress: AddressItem.receivingAddress
                    })
                }
                let bb = shouhuoAddressIDArr.find(b => b === AddressItem.receivingAddressId)
                if(bb == undefined){
                    shouhuoAddressIDArr.push(AddressItem.receivingAddressId)
                    this.DeliveryAddressData.push({
                        id: AddressItem.receivingAddressId,
                        name: AddressItem.receivingAddress,
                        addressId: AddressItem.addressId,
                        address: AddressItem.address,
                        receivingAddressId: AddressItem.receivingAddressId,
                        receivingAddress: AddressItem.receivingAddress
                    })
                }
            })
        },
        addCarrys2(){
            this.shopNameCarryData = []
            this.FactoryAddressData = []
            this.DeliveryAddressData = []
            this.CarrysData = []
            this.Carryform = {
                // 选中的企业名称id
                customerId: '',
                // 车辆id
                id: '',
                productId: 0,
                productName: '',
                driver: '',
                carryName: '',
                telephone: '',
                licensePlate: '',
                driveCard: '',
                roadCard: '',
                maxLoad: 0,
                remarks: '',
                state: 0,
                carType: 0,
                baseId: 0,
                receivingAddressId: null,
                addressId: null
            }
            var index = 1
            this.AddCarryDialog = true
            var AddressIDArr = []
            var shouhuoAddressIDArr = []
            this.addform.orderAddresses.forEach(AddressItem => {
                let aa = AddressIDArr.find(a => a === AddressItem.addressId)
                if(aa == undefined){
                    AddressIDArr.push(AddressItem.addressId)
                    this.FactoryAddressData.push({
                        id: AddressItem.addressId,
                        name: AddressItem.address,
                        addressId: AddressItem.addressId,
                        address: AddressItem.address,
                        receivingAddressId: AddressItem.receivingAddressId,
                        receivingAddress: AddressItem.receivingAddress
                    })
                }
                let bb = shouhuoAddressIDArr.find(b => b === AddressItem.receivingAddressId)
                if(bb == undefined){
                    shouhuoAddressIDArr.push(AddressItem.receivingAddressId)
                    this.DeliveryAddressData.push({
                        id: AddressItem.receivingAddressId,
                        name: AddressItem.receivingAddress,
                        addressId: AddressItem.addressId,
                        address: AddressItem.address,
                        receivingAddressId: AddressItem.receivingAddressId,
                        receivingAddress: AddressItem.receivingAddress
                    })
                }
            })
        },
        inputIndex(row){
            if(Object.is(Number(row.index),NaN)){
                row.index = 0
                return this.$message.warning('请输入数字类型')
            }else{
                if(Number(row.index) >= 100){
                    row.index = 0
                    return this.$message.warning('序号不得大于100')
                }
                var aa = true
                this.shopNameCarryData.forEach(item => {
                    if(item.productId != row.productId){
                        if(Number(item.index) == Number(row.index)){
                            aa = false
                        }
                    }
                })
                if(aa == false){
                    row.index = ''
                    this.$message.warning('商品顺序不可一样')
                    return
                }
                row.index = Number(row.index)
            }
        },
        // 选中承运信息商品表格事件 
        handleSelectionChange(row){
            this.multipleSelection = row.productId
            // this.multipleSelection = val
        },
        // 确认添加承运信息
        SureCarry(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if(this.multipleSelection == ''){
                        return this.$message.warning('请选择一个商品!')
                    }
                    if(this.Carryform.addressId == null){
                        return this.$message.warning('请选择生产地址!')
                    }
                    if(this.Carryform.receivingAddressId == null){
                        return this.$message.warning('请选择收货地址!')
                    }
                    var addressIdArr = []
                    this.getCheckedNodesCardata.forEach(CarItem => {
                        if(CarItem.disabled == false){
                            this.shopNameCarryData.forEach(item => {
                                if(this.multipleSelection == item.productId){
                                    let aa = this.addform.orderAddresses.find(a => a.addressId == this.Carryform.addressId)
                                    let bb = this.addform.orderAddresses.find(b => b.receivingAddressId == this.Carryform.receivingAddressId)
                                    CarItem.disabled = true
                                    this.addform.orderCarrys.push({
                                        productId: Number(item.productId),
                                        productName: item.productName,
                                        index: Number(item.index),
                                        driver: CarItem.driver,
                                        carryName: this.Carryform.carryName,
                                        telephone: CarItem.telephone,
                                        licensePlate: CarItem.licensePlate,
                                        maxLoad: CarItem.maxLoad,
                                        remarks: CarItem.remarks,
                                        driveCard: CarItem.driveCard,
                                        roadCard: CarItem.roadCard,
                                        carType: CarItem.carType,
                                        CARid: CarItem.id,
                                        id: CarItem.id,
                                        address: aa.address,
                                        addressId: aa.addressId,
                                        receivingAddressId: bb.receivingAddressId,
                                        receivingAddress: bb.receivingAddress,
                                        carLength: CarItem.carLength,
                                        actualWeight: CarItem.actualWeight,
                                    })
                                }
                            })
                        }else{

                        }
                    })
                    // console.log(this.addform.orderCarrys)
                    this.getCheckedNodesCardata = []
                    this.CarrysData = []
                    // this.multipleSelection = ''
                    this.addform.orderCarrys.forEach(string => {
                        if(this.CarryindustryType == '0'){
                            let KSahop = this.addform.orderSands.find(shop => Number(shop.productId) === Number(string.productId))
                            if(string.addressId == this.Carryform.addressId & KSahop !== undefined) {
                                this.CarrysData.push(string)
                            }
                        }else{
                            let HNTshop = this.addform.orderConcretes.find(shop => Number(shop.productId) === Number(string.productId))
                            if(string.addressId == this.Carryform.addressId & HNTshop !== undefined) {
                                this.CarrysData.push(string)
                            }
                        }
                        
                    })
                    // this.AddCarryDialog = false
                } else {
                    return false;
                }
            });
        },
        DeleteCarry(row,index){
            this.CarrysData.splice(index,1)
            this.CarryList.forEach( Carry => {
                if(row.licensePlate == Carry.licensePlate & row.addressId == Carry.addressId){
                    Carry.disabled = false
                    this.$refs.Cartree.setChecked(Carry, false)
                }
            })
            for(var a=0; a<this.getCheckedNodesCardata.length; a++){
                if(Number(row.id) == Number(this.getCheckedNodesCardata[a].id)){
                    this.getCheckedNodesCardata.splice(a,1)
                }
            }
            for(var i=0; i<this.addform.orderCarrys.length; i++){
                if(
                    row.productId == this.addform.orderCarrys[i].productId &
                    row.driver == this.addform.orderCarrys[i].driver &
                    row.telephone == this.addform.orderCarrys[i].telephone &
                    row.carryName == this.addform.orderCarrys[i].carryName &
                    row.licensePlate == this.addform.orderCarrys[i].licensePlate &
                    row.addressId == this.addform.orderCarrys[i].addressId
                ){
                    this.addform.orderCarrys.splice(i,1)
                }
            }
        },
        // 打开承运信息详情
        OpenDetailsCarry(row){
            this.CarrysData = []
            var arrid = []
            this.addform.orderCarrys.forEach(item => {
                if(row.productId == item.productId){
                    if(arrid.length == 0){
                        this.CarrysData.push(item)
                        arrid.push(item.licensePlate)
                    }else{
                        if(arrid.indexOf(item.licensePlate) == -1){
                            this.CarrysData.push(item)
                            arrid.push(item.licensePlate)
                        }
                    }
                }
            })
            this.CarrysDetailsDialog = true
        },
        // 承运信息表格判断
        CarryCartype(row){
            if(row.carType == 0){
                return '低围板'
            }else if(row.carType == 1){
                return '集装箱'
            }else if(row.carType == 2){
                return '小三轮'
            }else if(row.carType == 3){
                return '其他'
            }
        },
        Carrystate(row){
            if(row.state == 0){
                return '未入厂'
            }else if(row.state == 1){
                return '入厂'
            }else if(row.state == 2){
                return '出厂'
            }else if(row.state == 3){
                return '已完成'
            }
        },
        arrivalDate(row){
            var i = row.arrivalDate.slice(0,11)
            return i
        },
        // 添加签署人打开对话框
        AddSigns(row){
            this.Signsform.signatory = ''
            this.Signsform.telephone = ''
            this.Signsform.productId = row.productId
            this.Signsform.productName = row.productName
            this.SignsData = []
            this.addform.orderSigns.forEach(item => {
                if(row.productId == item.productId){
                    this.SignsData.push(item)
                }
            })
            this.AddSignsDialog = true
        },
        // 保存签署人
        SureSigns(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.addform.orderSigns.push({
                        productId: this.Signsform.productId,
                        productName: this.Signsform.productName,
                        signatory: this.Signsform.signatory,
                        telephone: this.Signsform.telephone,
                    })
                    this.SignsData.push({
                        productId: this.Signsform.productId,
                        productName: this.Signsform.productName,
                        signatory: this.Signsform.signatory,
                        telephone: this.Signsform.telephone,
                    })
                    this.Signsform.signatory = ''
                    this.Signsform.telephone = ''
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 查看详情签署人
        detailsSigns(row){
            this.SignsData = []
            this.SignsDetailsDialog = true
            this.addform.orderSigns.forEach(item => {
                if(row.productId == item.productId){
                    this.SignsData.push(item)
                }
            })
        },
        // 新增按钮事件
        add(){
            this.end()
            this.restAddfrom()
            this.formButton = 'add'
            this.title = '新增'
            this.dialogVisible = true
            this.querySysEnterprise()
        },
        // 添加点击确定事件
        AddSure(formName){
            if(this.addform.enterpriseName == ''){
                return this.$message.warning('请选择企业')
            }
            if(this.addform.contractCode == ''){
                return this.$message.warning('请选择合同')
            }
            var orderSandsCarrylength = 0
            var orderConcretesCarrylength = 0
            var orderSignslength = 0
            if(this.addform.industryType == '0' || this.addform.industryType == 0){
                for(var i = 0; i<this.addform.orderSands.length ; i++){
                    this.addform.totalPrice += this.addform.orderSands[i].planNumber * this.addform.orderSands[i].price
                    // 初始化每一次为0 判断当前商品有没有添加承运信息
                    // orderSandsCarrylength = 0
                    // this.addform.orderCarrys.forEach(itme => {
                    //     if(itme.productId == this.addform.orderSands[i].productId){
                    //         orderSandsCarrylength ++
                    //     }
                    // })
                    // 判断当前矿石商品需不需要添加签署人
                    if(Number(this.addform.carryModel) == 1){
                        orderSignslength = 0
                        this.addform.orderSigns.forEach(itme => {
                            if(itme.productId == this.addform.orderSands[i].productId){
                                orderSignslength ++
                            }
                        })
                    }
                }
                if(this.addform.orderCarrys.length == 0){
                    return this.$message.warning('请至少添加一个车辆')
                }
                if(Number(this.addform.carryModel) == 1 & orderSignslength == 0){
                    return this.$message.warning('还有矿石商品为添加签署人')
                }
            }else if(this.addform.industryType == '1' || this.addform.industryType == 1){
                for(var i = 0; i<this.addform.orderConcretes.length ; i++){
                    // 初始化每一次为0 判断当前商品有没有添加承运信息
                    // orderConcretesCarrylength = 0
                    this.addform.totalPrice += this.addform.orderConcretes[i].planNumber * this.addform.orderConcretes[i].price
                    // this.addform.orderCarrys.forEach(itme => {
                    //     if(itme.productId == this.addform.orderConcretes[i].productId){
                    //         orderConcretesCarrylength ++
                    //     }
                    // })
                    // 判断当前混凝土商品需不需要添加签署人
                    if(Number(this.addform.carryModel) == 1){
                        orderSignslength = 0
                        this.addform.orderSigns.forEach(itme => {
                            if(itme.productId == this.addform.orderSands[i].productId){
                                orderSignslength ++
                            }
                        })
                    }
                }
                if(this.addform.orderCarrys.length == 0){
                    return this.$message.warning('请至少添加一个车辆')
                }
                if(Number(this.addform.carryModel) == 1 & orderSignslength == 0){
                    return this.$message.warning('还有混凝土商品为添加签署人')
                }
            }else{
                var total = 0
                var total1 = 0
                for(var i = 0; i<this.addform.orderSands.length ; i++){
                    // 初始化每一次为0 判断当前商品有没有添加承运信息
                    total += this.addform.orderSands[i].planNumber * this.addform.orderSands[i].price
                    this.addform.orderCarrys.forEach(itme => {
                        if(itme.productId == this.addform.orderSands[i].productId){
                            orderSandsCarrylength ++
                        }
                    })
                    // 判断当前矿石商品需不需要添加签署人
                    if(Number(this.addform.carryModel) == 1){
                        orderSignslength = 0
                        this.addform.orderSigns.forEach(itme => {
                            if(itme.productId == this.addform.orderSands[i].productId){
                                orderSignslength ++
                            }
                        })
                    }
                }
                if(orderSandsCarrylength == 0){
                    return this.$message.warning('还有矿石商品未添加承运信息')
                }
                if(Number(this.addform.carryModel) == 1 & orderSignslength == 0){
                    return this.$message.warning('还有矿石商品为添加签署人')
                }
                orderConcretesCarrylength = 0
                for(var i = 0; i<this.addform.orderConcretes.length ; i++){
                    // 初始化每一次为0 判断当前商品有没有添加承运信息
                    total1 += this.addform.orderConcretes[i].planNumber * this.addform.orderConcretes[i].price
                    this.addform.orderCarrys.forEach(itme => {
                        if(itme.productId == this.addform.orderConcretes[i].productId){
                            orderConcretesCarrylength ++
                        }
                    })
                    // 判断当前混凝土商品需不需要添加签署人
                    if(this.addform.carryModel == 1){
                        orderSignslength = 0
                        this.addform.orderSigns.forEach(itme => {
                            if(itme.productId == this.addform.orderSands[i].productId){
                                orderSignslength ++
                            }
                        })
                    }
                }
                if(orderConcretesCarrylength == 0){
                    return this.$message.warning('还有混凝土商品未添加承运信息')
                }
                if(Number(this.addform.carryModel) == 1 & orderSignslength == 0){
                    return this.$message.warning('还有混凝土商品为添加签署人')
                }
                this.addform.totalPrice = total+total1
            }
            // if(this.addform.payType == '0' || this.addform.payType == '1'){
            //     if(this.addform.totalPrice >= this.addform.payAmount){
            //         return this.$message.info('添加商品总金额不得高于充值或授信金额')
            //     }
            // }
            this.addressGroupsYuan.forEach(item => {
                this.addform.addressGroups.forEach(items => {
                    if(item.addressId == items.addressId){
                        items.price = item.price
                    }
                })
            })
            delete this.addform.repaymentTime1;
            delete this.addform.repaymentTime2;
            if(this.formButton == 'add'){
                this.$http.post(this.$SysOrder_CreateOrder,this.addform).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('添加成功')
                        this.CarrysData = []
                        this.query()
                        this.dialogVisible = false
                        this.start()
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }else if(this.formButton == 'modify'){
                this.$http.put(this.$SysOrder_UpdateOrder,this.addform).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('修改成功')
                        this.CarrysData = []
                        this.query()
                        this.dialogVisible = false
                        this.start()
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }
        },
        // 添加点击取消事件
        AddCancel(formName){
            this.enterpriseName = ''
            this.restAddfrom()
            // this.$refs[formName].resetFields();
            this.dialogVisible = false
            this.start()
            this.CarrysData = []
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
            .then(_ => {
                done();
            })
            .catch(_ => {});
        },
        // 点击查看详情
        handleClick(row) {
            this.end()
            this.restAddfrom()
            this.title = '详情'
            this.dialogVisible = true
            this.formButton = 'details'
            this.loading = true
            this.$http.get(this.$SysOrder_Get+'?id='+row.id).then(response => {
                if(response.data.data.businessType == 0){
                    this.enterpriseName = response.data.data.purchaseName
                    this.enterpriseRadio = Number(response.data.data.enterpriseId)
                    // this.querySysEnterprise()
                    this.enterpriseData = {
                        purchaseName: response.data.data.purchaseName,
                        purchaseContacts: response.data.data.purchaseContacts,
                        purchaseContactTel: response.data.data.purchaseContactTel,
                        purchaseTaxNumber: response.data.data.purchaseTaxNumber,
                        purchaseDomicile: response.data.data.purchaseDomicile,
                        purchaseBankName: response.data.data.purchaseBankName,
                        purchaseBankNo: response.data.data.purchaseBankNo,
                    }
                }else{ 
                    this.enterpriseName = response.data.data.enterpriseName
                    this.enterpriseRadio = Number(response.data.data.enterpriseId)
                    this.enterpriseData = {
                        purchaseName: response.data.data.enterpriseName,
                        purchaseContacts: response.data.data.contacts,
                        purchaseContactTel: response.data.data.contactTel,
                        purchaseTaxNumber: response.data.data.taxNumber,
                        purchaseDomicile: response.data.data.domicile,
                        purchaseBankName: response.data.data.bankName,
                        purchaseBankNo: response.data.data.bankNo,
                    }
                    // this.querySysEnterprise()
                }
                this.addform.id = response.data.data.id
                this.addform.enterpriseName = response.data.data.enterpriseName
                this.addform.enterpriseId = response.data.data.enterpriseId
                this.addform.contractId = JSON.stringify(response.data.data.contractId)
                this.addform.contractName = response.data.data.contractName
                this.addform.contractCode = response.data.data.contractCode
                this.addform.code = response.data.data.code
                this.addform.purchaseId = response.data.data.purchaseId
                this.addform.purchaseName = response.data.data.purchaseName
                this.addform.taxNumber = response.data.data.taxNumber
                this.addform.telephone = response.data.data.telephone
                this.addform.domicile = response.data.data.domicile
                this.addform.bankName = response.data.data.bankName
                this.addform.bankNo = response.data.data.bankNo
                this.addform.contacts = response.data.data.contacts
                this.addform.contactTel = response.data.data.contactTel
                this.addform.purchaseTaxNumber = response.data.data.purchaseTaxNumber
                this.addform.purchaseTelephone = response.data.data.purchaseTelephone
                this.addform.purchaseDomicile = response.data.data.purchaseDomicile
                this.addform.purchaseBankName = response.data.data.purchaseBankName
                this.addform.purchaseBankNo = response.data.data.purchaseBankNo
                this.addform.purchaseContacts = response.data.data.purchaseContacts
                this.addform.purchaseContactTel = response.data.data.purchaseContactTel
                this.addform.purchaseBusinessName = response.data.data.purchaseBusinessName
                this.addform.purchaseBusinessUserId = response.data.data.purchaseBusinessUserId
                this.addform.purchaseBusinessTel = response.data.data.purchaseBusinessTel
                this.queryAdd()
                this.addform.payType = response.data.data.payType
                if(this.addform.payType == '0'){
                    this.payType0 = true
                }else if(this.addform.payType == '1'){
                    this.payType1 = true
                }else if(this.addform.payType == '2'){
                    this.payType2 = true
                }else if(this.addform.payType == '3'){
                    this.payType3 = true
                }else if(this.addform.payType == '1,2'){
                    this.payType1 = true
                    this.payType2 = true
                }
                this.addform.payAmount = response.data.data.payAmount
                this.addform.isDate = response.data.data.isDate
                this.addform.repaymentDate = response.data.data.repaymentDate
                this.addform.creditTipsAmount = response.data.data.creditTipsAmount
                this.addform.creditTipsDay = response.data.data.creditTipsDay
                this.addform.repaymentTime = response.data.data.repaymentTime
                if(response.data.data.repaymentTime == null){

                }else{
                    var i1 = response.data.data.repaymentTime.indexOf("-")
                    this.addform.repaymentTime1 = response.data.data.repaymentTime.slice(0,i1)
                    this.addform.repaymentTime2 = response.data.data.repaymentTime.slice(i1+1)
                }
                this.addform.tipsDay = response.data.data.tipsDay
                this.addform.carryModel = response.data.data.carryModel
                this.addform.freightCalculation = response.data.data.freightCalculation
                this.addform.unitName = response.data.data.unitName
                this.addform.projectName = response.data.data.projectName
                this.addform.salesman = response.data.data.salesman
                this.addform.salesTel = response.data.data.salesTel
                this.addform.remarks = response.data.data.remarks
                this.addform.receivableAmount = response.data.data.receivableAmount
                this.addform.receiptsAmount = response.data.data.receiptsAmount
                this.addform.type = response.data.data.type
                this.addform.businessType = response.data.data.businessType
                this.addform.industryType = response.data.data.industryType
                if(this.addform.industryType == '0'){
                        this.ch0 = true
                        this.ch1 = false
                }else if(this.addform.industryType == '1'){
                    this.ch1 = true
                    this.ch0 = false
                }else{
                    this.ch0 = true
                    this.ch1 = true
                }
                this.addform.state = response.data.data.state
                this.addform.orderSands = response.data.data.orderSands
                this.addform.orderConcretes = response.data.data.orderConcretes
                this.addform.orderAddresses = response.data.data.orderAddresses
                this.addform.orderSigns = response.data.data.orderSigns
                this.addform.orderCarrys = response.data.data.orderCarrys
                this.addform.orderCarrys.forEach(item => {
                    let aa = this.addform.orderAddresses.find(a => a.addressId === item.addressId)
                    let bb = this.addform.orderAddresses.find(b => b.receivingAddressId == item.receivingAddressId)
                    if( aa !== undefined) {
                        let a = {
                            address: aa.address,
                            receivingAddress: bb.receivingAddress
                        }
                        Object.assign(item,a)
                    }
                })
                this.CarrysData = this.addform.orderCarrys
                this.addform.addressGroups = []
                this.addform.addressGroups = response.data.data.addressGroups
                var idarr = []
                this.addform.addressGroups.forEach(aa => {
                    if(idarr.indexOf(aa.addressId) === -1){
                        idarr.push(aa.addressId)
                        this.addressGroupsYuan.push({
                            addressId: aa.addressId,
                            addressName: aa.addressName,
                            price: Number(aa.price)
                        })
                    }
                })
                this.dialogVisible = true
            })
            this.loading = false
        },
        dblclick(row){
            this.radio = row.id
            this.getCurrentRow(row)
        },
        getCurrentRow(row){
            this.auditid = row.code
            this.AuditiSactive = row.isActive
            this.modeType = row.modeType
            this.state = row.state
            this.type = row.type
            // alert(name)
        },
        // 修改打开表单
        modify(row){
            this.end()
            this.restAddfrom()
            this.formButton = 'modify'
            this.title = '修改'
            this.loading = true
            this.$http.get(this.$SysOrder_Get+'?id='+row.id).then(response => {
                if(response.data.data.businessType == 0){
                    this.enterpriseName = response.data.data.purchaseName
                    this.enterpriseRadio = Number(response.data.data.enterpriseId)
                    // this.querySysEnterprise()
                    this.enterpriseData = {
                        purchaseName: response.data.data.purchaseName,
                        purchaseContacts: response.data.data.purchaseContacts,
                        purchaseContactTel: response.data.data.purchaseContactTel,
                        purchaseTaxNumber: response.data.data.purchaseTaxNumber,
                        purchaseDomicile: response.data.data.purchaseDomicile,
                        purchaseBankName: response.data.data.purchaseBankName,
                        purchaseBankNo: response.data.data.purchaseBankNo,
                    }
                }else{ 
                    this.enterpriseName = response.data.data.enterpriseName
                    this.enterpriseRadio = Number(response.data.data.enterpriseId)
                    // this.querySysEnterprise()
                    this.enterpriseData = {
                        purchaseName: response.data.data.enterpriseName,
                        purchaseContacts: response.data.data.contacts,
                        purchaseContactTel: response.data.data.contactTel,
                        purchaseTaxNumber: response.data.data.taxNumber,
                        purchaseDomicile: response.data.data.domicile,
                        purchaseBankName: response.data.data.bankName,
                        purchaseBankNo: response.data.data.bankNo,
                    }
                }
                this.addform.id = response.data.data.id
                this.addform.enterpriseName = response.data.data.enterpriseName
                this.addform.enterpriseId = response.data.data.enterpriseId
                this.addform.contractId = JSON.stringify(response.data.data.contractId)
                this.addform.contractName = response.data.data.contractName
                this.addform.contractCode = response.data.data.contractCode
                this.addform.code = response.data.data.code
                this.addform.purchaseId = response.data.data.purchaseId
                this.addform.purchaseName = response.data.data.purchaseName
                this.addform.taxNumber = response.data.data.taxNumber
                this.addform.telephone = response.data.data.telephone
                this.addform.domicile = response.data.data.domicile
                this.addform.bankName = response.data.data.bankName
                this.addform.bankNo = response.data.data.bankNo
                this.addform.contacts = response.data.data.contacts
                this.addform.contactTel = response.data.data.contactTel
                this.addform.purchaseTaxNumber = response.data.data.purchaseTaxNumber
                this.addform.purchaseTelephone = response.data.data.purchaseTelephone
                this.addform.purchaseDomicile = response.data.data.purchaseDomicile
                this.addform.purchaseBankName = response.data.data.purchaseBankName
                this.addform.purchaseBankNo = response.data.data.purchaseBankNo
                this.addform.purchaseContacts = response.data.data.purchaseContacts
                this.addform.purchaseContactTel = response.data.data.purchaseContactTel
                this.addform.purchaseBusinessName = response.data.data.purchaseBusinessName
                this.addform.purchaseBusinessUserId = response.data.data.purchaseBusinessUserId
                this.addform.purchaseBusinessTel = response.data.data.purchaseBusinessTel
                this.addform.creatorId = response.data.data.creatorId
                this.queryAdd()
                this.addform.payType = response.data.data.payType
                if(this.addform.payType == '0'){
                    this.payType0 = true
                }else if(this.addform.payType == '1'){
                    this.payType1 = true
                }else if(this.addform.payType == '2'){
                    this.payType2 = true
                }else if(this.addform.payType == '3'){
                    this.payType3 = true
                }else if(this.addform.payType == '1,2'){
                    this.payType1 = true
                    this.payType2 = true
                }
                this.addform.payAmount = response.data.data.payAmount
                this.addform.isDate = response.data.data.isDate
                this.addform.repaymentDate = response.data.data.repaymentDate
                this.addform.creditTipsAmount = response.data.data.creditTipsAmount
                this.addform.creditTipsDay = response.data.data.creditTipsDay
                this.addform.repaymentTime = response.data.data.repaymentTime
                var i1 = response.data.data.repaymentTime.indexOf("-")
                this.addform.repaymentTime1 = response.data.data.repaymentTime.slice(0,i1)
                this.addform.repaymentTime2 = response.data.data.repaymentTime.slice(i1+1)
                this.addform.tipsDay = response.data.data.tipsDay
                this.addform.carryModel = response.data.data.carryModel
                this.addform.freightCalculation = response.data.data.freightCalculation
                this.addform.unitName = response.data.data.unitName
                this.addform.projectName = response.data.data.projectName
                this.addform.salesman = response.data.data.salesman
                this.addform.salesTel = response.data.data.salesTel
                this.addform.remarks = response.data.data.remarks
                this.addform.receivableAmount = response.data.data.receivableAmount
                this.addform.receiptsAmount = response.data.data.receiptsAmount
                this.addform.type = response.data.data.type
                this.addform.businessType = response.data.data.businessType
                this.addform.industryType = response.data.data.industryType
                if(this.addform.industryType == '0'){
                        this.ch0 = true
                        this.ch1 = false
                }else if(this.addform.industryType == '1'){
                    this.ch1 = true
                    this.ch0 = false
                }else{
                    this.ch0 = true
                    this.ch1 = true
                }
                this.addform.state = response.data.data.state
                this.addform.orderSands = response.data.data.orderSands
                this.addform.orderConcretes = response.data.data.orderConcretes
                this.addform.orderAddresses = response.data.data.orderAddresses
                this.addform.orderSigns = response.data.data.orderSigns
                this.addform.orderCarrys = response.data.data.orderCarrys
                this.addform.orderCarrys.forEach(item => {
                    let aa = this.addform.orderAddresses.find(a => a.addressId === item.addressId)
                    let bb = this.addform.orderAddresses.find(b => b.receivingAddressId == item.receivingAddressId)
                    if( aa !== undefined) {
                        let a = {
                            address: aa.address,
                            receivingAddress: bb.receivingAddress
                        }
                        Object.assign(item,a)
                    }
                })
                this.CarrysData = this.addform.orderCarrys
                this.addform.addressGroups = []
                this.addform.addressGroups = response.data.data.addressGroups
                this.addform.modeType = response.data.data.modeType
                var idarr = []
                this.addform.addressGroups.forEach(aa => {
                    if(idarr.indexOf(aa.addressId) === -1){
                        idarr.push(aa.addressId)
                        this.addressGroupsYuan.push({
                            addressId: aa.addressId,
                            addressName: aa.addressName,
                            price: Number(aa.price)
                        })
                    }
                })
                this.dialogVisible = true
            })
            this.loading = false
        },
        // 审核
        Audit(){
            if(this.auditid == ''){
                return this.$message.info('请选择数据')
            }
            this.$confirm('此操作将审核该销售订单, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysOrder_AuditOrder,{
                    orderNo : this.auditid,
                    aduitRemarks : ''
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('审核成功')
                        this.query()
                        this.radio = ''
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        // 取消审核
        CancelAudit(){
            if(this.auditid == ''){
                return this.$message.info('请选择数据')
            }
            this.$confirm('此操作将取消审核该销售订单, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysOrder_CancelAuditOrder,{
                    orderNo : this.auditid,
                    aduitRemarks : ''
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('取消审核成功')
                        this.query()
                        this.radio = ''
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        // 确认
        Sure(){
            if(this.auditid == ''){
                return this.$message.info('请选择数据')
            }
            this.$confirm('此操作将确认该订单, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysOrder_ConfirmOrder,{
                    orderNo : this.auditid,
                    aduitRemarks : ''
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('确认成功')
                        this.query()
                        this.radio = ''
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        // 取消确认
        CancelSure(){
            if(this.auditid == ''){
                return this.$message.info('请选择数据')
            }
            this.$confirm('此操作将取消确认该订单, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysOrder_CancelConfirmOrder,{
                    orderNo : this.auditid,
                    aduitRemarks : ''
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('取消确认成功')
                        this.query()
                        this.radio = ''
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        // 重置表单
        restAddfrom(){
            this.addform = {
                id: '',
                enterpriseId: 0,
                enterpriseName: "",
                contractId: '',
                contractName: "",
                contractCode: "",
                code: '',
                purchaseId: 0,
                purchaseName: "",
                taxNumber: "",
                telephone: "",
                domicile: "",
                bankName: "",
                bankNo: "",
                contacts: "",
                contactTel: "",
                purchaseTaxNumber: '',
                purchaseTelephone: '',
                purchaseDomicile: '',
                purchaseBankName: '',
                purchaseBankNo: '',
                purchaseContacts: '',
                purchaseContactTel: '',
                purchaseBusinessName: '',
                purchaseBusinessUserId: '',
                purchaseBusinessTel: '',
                carryPrice: 0,
                totalPrice: 0,
                payType: '',
                payAmount: '',
                isDate: false,
                repaymentDate: "",
                creditTipsAmount: '',
                creditTipsDay: '',
                repaymentTime: "",
                repaymentTime1: '',
                repaymentTime2: '',
                tipsDay: '',
                carryModel: null,
                freightCalculation: null,
                unitName: "",
                projectName: "",
                salesman: "",
                salesTel: "",
                remarks: "",
                receivableAmount: 0,
                receiptsAmount: '',
                type: 0,
                businessType: '0',
                industryType: '0',
                state: 0,
                orderSands: [],
                orderConcretes: [],
                orderAddresses: [],
                orderSigns: [],
                orderCarrys: [],
                addressGroups: []
            }
            this.addressGroupsYuan = []
            this.enterpriseData = {}
            this.enterpriseRadio = -1
            this.payType0 = false
            this.payType1 = false
            this.payType2 = false
            this.payType3 = false
            this.ch0 = false
            this.ch1 = false
        },
        // 废弃订单
        abandoned(row){
            this.$confirm('此操作将废弃该订单, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysOrder_AbandonOrder,{
                    orderNo : row.code,
                    aduitRemarks : ''
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('废弃成功')
                        this.query()
                        this.radio = ''
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        // 终止订单
        stop(row){
            this.$confirm('此操作将终止该订单, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysOrder_StopOrder,{
                    orderNo : row.code,
                    aduitRemarks : ''
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('终止成功')
                        this.query()
                        this.radio = ''
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        // 暂停订单
        suspend(row){
            this.$confirm('此操作将暂停该订单, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysOrder_SuspendOrder,{
                    orderNo : row.code,
                    aduitRemarks : ''
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('暂停成功')
                        this.query()
                        this.radio = ''
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        // 恢复订单
        recovery(row){
            this.$confirm('此操作将恢复该订单, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysOrder_RecoveryOrder,{
                    orderNo : row.code,
                    aduitRemarks : ''
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('恢复成功')
                        this.query()
                        this.radio = ''
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        // 取消订单订单
        SalesOrder_Cancel(row){
            this.$confirm('此操作将取消该订单, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysOrder_CancelOrder,{
                    orderNo : row.code,
                    aduitRemarks : ''
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('取消成功')
                        this.query()
                        this.radio = ''
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        signquery(){
            this.$http.post(this.$SysOrder_GetCarryPageList,{
                currentPage: this.signPage,
                pageSize: this.signSize,
                filter: {
                    name: '',
                    orderNo: this.rowcode,
                    contractCode: '',
                    state: '',
                    payType: '',
                    carryModel: '',
                    startDate: '',
                    endDate: '',
                }
            }).then(response => {
                this.signTotal = response.data.data.total
                this.SignCarryData = response.data.data.list
                this.SignCarryDialog = true
            })
        },
        // 签收
        sign(row){
            this.end()
            this.rowcode = row.code
            this.signquery()
        },
        // 签收打开表单
        SignCarryOrder(row){
            this.formButton = 'modify'
            this.title = '签收'
            this.SureRepectCarryDialog = true
            var aa = row
            this.SureSignCarryform = aa
            this.SureSignCarryform.telephone = ''
        },
        // 添加点击确定事件
        SureSignCarry(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if(this.SureSignCarryform.telephone.toString().length !== 11){
                        this.$message.info('手机号码不正确')
                        return
                    }
                    this.$refs['esign'].generate().then(res => {
                        this.resultImg = res // 得到了签字生成的base64图片
                        this.SureSignCarryform.signImage = res
                        this.$http.post(this.$SysOrder_SignCarryOrder,{
                            id: Number(this.SureSignCarryform.id),
                            number: Number(this.SureSignCarryform.number),
                            telephone: this.SureSignCarryform.telephone,
                            code: this.SureSignCarryform.code,
                            signImage: this.SureSignCarryform.signImage
                        }).then( response => {
                            if(response.data.isSuccess == true){
                                this.$message.success('签收成功')
                                this.SureRepectCarryDialog = false
                                this.signquery()
                                this.SignCarryDialog = true
                                this.SureSignCarryform = {
                                    id: '',
                                    orderCode: '',
                                    carryName: '',
                                    productName: '',
                                    standards: '',
                                    unit: '',
                                    driver: '',
                                    licensePlate: '',
                                    aggregateSize: '',
                                    number: '',
                                    telephone: '',
                                    code: '',
                                    signImage: ''
                                }
                                this.timing = 0
                                this.handleReset()
                                this.start()
                            }else{
                                this.$message.warning(response.data.message)
                            }
                        })
                    }).catch(err => { //  没有签名，点击生成图片时调用
                        this.$message({message: ' 未签名！',type: 'warning'})
                    })
                } else {
                    return false;
                }
            });
        },
        handleReset () {
            this.$refs['esign'].reset() //清空画布
            this.addform.signImage = ''
        },
        // 添加点击取消事件
        SureSignCarryCancel(formName){
            this.$refs[formName].resetFields();
            this.SureRepectCarryDialog = false
            this.handleReset()
        },
        // 详情点击事件
        SignCarryhandleClick(row) {
            this.title = '详情'
            this.SureRepectCarryDialog = true
            this.formButton = 'details'
            this.SureSignCarryform = row
            this.loading = false
        },
        SureSignquery(){
            this.$http.post(this.$SysOrder_GetSignRecordPageList,{
                currentPage: this.SureSignPage,
                pageSize: this.SureSignSize,
                filter: {
                    name: '',
                    orderNo: this.Surerowcode,
                    contractCode: '',
                    state: '',
                    payType: '',
                    carryModel: '',
                    startDate: '',
                    endDate: '',
                }
            }).then(response => {
                this.SureSignTotal = response.data.data.total
                this.SureSignCarrytableData = response.data.data.list
                this.title = '签收确认'
                this.SureSignCarryDialog = true
            })
        },
        // 确认签收
        SureSign(row){
            this.end()
            this.Surerowcode = row.code
            this.SureSignquery()
        },
        // 确认签收
        ConfirmSignCarryOrder(row){
            this.$confirm('此操作将确认该签收单, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysOrder_ConfirmSignCarryOrder,{
                    id: row.id,
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('确认成功')
                        this.SureSignquery()
                        this.start()
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        // 详情点击事件
        ConfirmhandleClick(row) {
            this.title = '签收确认详情'
            this.SureSignCarrydialogVisible = true
            this.formButton = 'details'
            this.ConfirmSignCarryform = row
        },
        // 
        yunshuquery(){
            this.CarryProcessPageList = []
            this.$http.post(this.$SysOrder_GetCarryProcessPageList,{
                currentPage: this.yunshuPage,
                pageSize: this.yunshuSize,
                filter: {
                    name: '',
                    driver: this.yunshuform.driver,
                    telephone: this.yunshuform.telephone,
                    licensePlate: this.yunshuform.licensePlate,
                    orderNo: this.yunshucode,
                    contractCode: '',
                    state: '',
                    payType: '',
                    carryModel: '',
                    startDate: '',
                    endDate: '',
                }
            }).then(response => {
                this.yunshuTotal = response.data.data.total
                response.data.data.list.forEach(item => {
                    if(item.state == 1){
                        this.CarryProcessPageList.push({
                            actualWeight: item.actualWeight,
                            aggregateSize: item.aggregateSize,
                            baseId: item.baseId,
                            carType: item.carType,
                            carryName: item.carryName,
                            constructionPosition: item.constructionPosition,
                            driver: item.driver,
                            id: item.id,
                            impermeability: item.impermeability,
                            industryType: item.industryType,
                            licensePlate: item.licensePlate,
                            maxLoad: item.maxLoad,
                            orderCode: item.orderCode,
                            pouringMethod: item.pouringMethod,
                            productId: item.productId,
                            productName: item.productName,
                            remarks: item.remarks,
                            slump: item.slump,
                            standards: item.standards,
                            state: item.state,
                            stroke: item.stroke,
                            telephone: item.telephone,
                            unit: item.unit,
                            receivingState: item.receivingState,
                            a: true,
                            a1: false
                        })
                    }else if(item.state == 2){
                        if(item.receivingState == 1){
                            this.CarryProcessPageList.push({
                                actualWeight: item.actualWeight,
                                aggregateSize: item.aggregateSize,
                                baseId: item.baseId,
                                carType: item.carType,
                                carryName: item.carryName,
                                constructionPosition: item.constructionPosition,
                                driver: item.driver,
                                id: item.id,
                                impermeability: item.impermeability,
                                industryType: item.industryType,
                                licensePlate: item.licensePlate,
                                maxLoad: item.maxLoad,
                                orderCode: item.orderCode,
                                pouringMethod: item.pouringMethod,
                                productId: item.productId,
                                productName: item.productName,
                                remarks: item.remarks,
                                slump: item.slump,
                                standards: item.standards,
                                state: item.state,
                                stroke: item.stroke,
                                telephone: item.telephone,
                                unit: item.unit,
                                receivingState: item.receivingState,
                                a: true,
                                a1: true,
                                b: true,
                                b1: false
                            })
                        }else if(item.receivingState == 2){
                            this.CarryProcessPageList.push({
                                actualWeight: item.actualWeight,
                                aggregateSize: item.aggregateSize,
                                baseId: item.baseId,
                                carType: item.carType,
                                carryName: item.carryName,
                                constructionPosition: item.constructionPosition,
                                driver: item.driver,
                                id: item.id,
                                impermeability: item.impermeability,
                                industryType: item.industryType,
                                licensePlate: item.licensePlate,
                                maxLoad: item.maxLoad,
                                orderCode: item.orderCode,
                                pouringMethod: item.pouringMethod,
                                productId: item.productId,
                                productName: item.productName,
                                remarks: item.remarks,
                                slump: item.slump,
                                standards: item.standards,
                                state: item.state,
                                stroke: item.stroke,
                                telephone: item.telephone,
                                unit: item.unit,
                                receivingState: item.receivingState,
                                a: true,
                                a1: true,
                                b: true,
                                b1: true
                            })
                        }else{
                            this.CarryProcessPageList.push({
                                actualWeight: item.actualWeight,
                                aggregateSize: item.aggregateSize,
                                baseId: item.baseId,
                                carType: item.carType,
                                carryName: item.carryName,
                                constructionPosition: item.constructionPosition,
                                driver: item.driver,
                                id: item.id,
                                impermeability: item.impermeability,
                                industryType: item.industryType,
                                licensePlate: item.licensePlate,
                                maxLoad: item.maxLoad,
                                orderCode: item.orderCode,
                                pouringMethod: item.pouringMethod,
                                productId: item.productId,
                                productName: item.productName,
                                remarks: item.remarks,
                                slump: item.slump,
                                standards: item.standards,
                                state: item.state,
                                stroke: item.stroke,
                                telephone: item.telephone,
                                unit: item.unit,
                                receivingState: item.receivingState,
                                a: true,
                                a1: true,
                                b: false,
                                b1: false
                            })
                        }
                    }else{
                        this.CarryProcessPageList.push(item)
                    }
                })
                this.GetCarryProcessPageListDialog = true
            })
        },
        // 运输过程详情点击事件
        GetCarryProcessPageList(row){
            this.end()
            this.GetCarryProcessPageListindustryType = row.industryType
            this.TheTransportProcess = row.carryModel
            this.yunshucode = row.code
            this.yunshuquery()
        },
        DeleteSigns(row,index){
            this.SignsData.splice(index,1)
            for(var i=0; i<this.addform.orderSigns.length; i++){
                if(
                    row.productId == this.addform.orderSigns[i].productId &
                    row.signatory == this.addform.orderSigns[i].signatory &
                    row.telephone == this.addform.orderSigns[i].telephone
                ){
                    this.addform.orderSigns.splice(i,1)
                }
            }
        },
        signCode(){
            if(this.SureSignCarryform.telephone == ''){
                this.$message.info('请输入手机号码')
            }else{
                if(this.SureSignCarryform.telephone.toString().length !== 11){
                    this.$message.info('手机号码不正确')
                    return
                }
                if(this.signee == ''){
                    this.$message.info('签收手机不正确')
                    return
                }
                this.$http.post(this.$RegisterSendType,{
                    phone: this.SureSignCarryform.telephone
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.timing = 60
                        if(this.ObtainStatu == false){
                            // 点击发送验证码之后倒数计时60秒
                            this.ObtainStatu = true
                            // 添加定时器
                            var timer = null
                            timer = setInterval(()=>{
                            if(this.timing > 0){
                                // 每秒减一
                                this.timing--
                            }else{
                                // 小于0的时候清楚定时器
                                this.ObtainStatu = false
                                clearInterval(timer);
                                timer = null
                            }
                            },1000)
                        }else{
                            
                        }
                    }else {
                        this.$message.warning(response.data.message)
                    }
                })
            }
        },
        qianshouyanzheng(){
            if(this.SureSignCarryform.telephone == ''){
                this.$message.info('请输入手机号码')
                return
            }
            var a = this.SureSignCarryform.telephone.toString()
            if(a.length != 11){
                this.$message.info('请输入正确的手机号码')
                return
            }
            this.$http.post(this.$SysOrder_GetCarryTel,{
                UniqueCode: this.SureSignCarryform.uniqueCode,
                Tel: this.SureSignCarryform.telephone
            }).then(response => {
                if(response.data.isSuccess == true){
                    this.signee = response.data.data
                }else {
                    this.signee = ''
                    this.$message.warning(response.data.message)
                }
            })
        },
        CreatCar(){
            if(this.radio == ''){
                this.$message.info('请先选择订单！')
                return
            }
            clearInterval(this.timer);
            this.timer = null
            this.end()
            this.addCarDialog = true
            this.$nextTick(() => {
                // console.log(this.$refs['AddressRef'])
                this.$refs['addCarDialogRef'].getquery(this.radio);
            })
        },
        SureModifyCars(){
            this.$http.put(this.$SysOrder_UpdateOrderCarry,{
                id: Number(this.radio),
                orderCarrys: this.$refs['addCarDialogRef'].CarrysData.concat(this.$refs['addCarDialogRef'].deleteCarArr)
            }).then(response => {
                if(response.data.isSuccess == true){
                    this.$message.success('更改车辆信息成功')
                    this.addCarDialog = false
                    this.start()
                }else {
                    this.$message.warning(response.data.message)
                }
            })
        },
        CancelModifyCars(){
            this.addCarDialog = false
            this.start()
        },
        openReceiptsAmountDialog(row){
            this.end()
            this.ReceiptsAmountDialog = true
            this.$nextTick(() => {
                this.$refs['RefReceiptsAmount'].text(row)
            })
        },
        CancelReceiptsAmount(){
            this.start()
            this.ReceiptsAmountDialog = false
        },
    },
    mounted(){
        this.query()
        this.start()
        this.addCarDialog = false
    }
}
</script>
<style scoped>
.body{
    padding: 15px;
    height: 96%;
    width: 98%;
    background-color:  #fdfdfd;
    box-shadow: 2px 1px 1px  #d8d8d8;
    border: 1px solid #ddd;
    border-radius: 5px;
}
/deep/.el-dialog__header {
    padding: 13px 20px 13px;
    background-color: skyblue;
}
.xiangqing{
    color:#67C23A
}
.bianji{
    color: #E6A23C;
}
.shanchu{
    color: #F56C6C ;
}
</style>
// <style lang="less" scoped>
// #app {
//   width: 100%;
//   height: 100%;
//   .el-table {
//     // 看这里！！！！！！！！！！！！！！！！！！！！！！！！！！！！
//     // 深度选择器，去除默认的padding
//     /deep/ th {
//       padding: 0 ;
//     }
//     /deep/ td {
//       padding: 10px ;
//     }
//   }
// }
// </style>