<template>
  <div class="app" id="app">
    <!--路由占位符-->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app"
};
</script>

<style scoped>
.app{
  height: 100%;
  width: 100%;
  background-color: aliceblue;
}
</style>
