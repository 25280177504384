<template>
  <div
    class="login_container"
    v-loading="loading"
    element-loading-text="登录中......"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)"
  >
    <div class="login_box">
      <div class="login_span">
         <span style="color:#000000">智慧云商</span>
      </div>
      <!-- 表单区域 -->
      <el-form
        label-width="0px"
        ref="loginFormRef"
        :model="loginForm"
        :rules="loginFormRules"
        class="login_form"
        style="margin-top: 0px"
      >
        <el-form-item prop="userName">
          <el-input
            size="medium"
            v-model="loginForm.userName"
            prefix-icon="iconfont icon-users"
            placeholder="请输入用户名或手机号"
          ></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input
            size="medium"
            v-model="loginForm.password"
            prefix-icon="iconfont icon-3702mima"
            type="password"
            show-password
            placeholder="请输入用户密码"
          ></el-input>
        </el-form-item>
        <!--验证码-->
        <el-form-item prop="validateCode">
          <div style="display:flex">
            <el-input
              size="medium"
              v-model="loginForm.validateCode"
              placeholder="请输入验证码"
              type="text"
            ></el-input>
            <div class="code" style="width:90px;margin-left:2%;margin-top:0.5%" @click="refreshCode">
              <s-identify :fresh="flag" @makedCode="getMakedCode"></s-identify>
            </div>
          </div>
        </el-form-item>
        <!-- 登录按钮 -->
        <el-form-item class="btns">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-button style="width:100%" size="medium" type="primary" @click="login">登录</el-button>
            </el-col>
            <el-col :span="12">
              <el-button style="width:100%" size="medium" type="primary" @click="dialogVisible = true">注册</el-button>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
    </div>
    <!-- 注册表单 -->
    <el-dialog
      title="注册"
      :visible.sync="dialogVisible"
      style="margin-top:-2%"
      width="35%">
      <el-steps :active="active">
        <el-step title="用户信息" icon="el-icon-edit"></el-step>
        <el-step title="企业信息" icon="el-icon-edit"></el-step>
      </el-steps>
        <el-form v-if="active == 0" style="width:95%" ref="userFromRules" :rules="userFromRules" :model="userFrom" class="demo-form-inline" label-width="90px">
          <el-form-item label="用户名" prop="account">
            <el-input v-model="userFrom.account" placeholder="请输入用户名"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input type="password" show-password v-model="userFrom.password" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="mobile">
            <el-input v-model="userFrom.mobile" maxlength="11" placeholder="请输入手机号码"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="code">
            <el-input v-model="userFrom.code" placeholder="请输入验证码">
              <el-button v-show="ObtainStatu == false" @click="Obtain()" slot="append">获取验证码</el-button>
              <el-button v-show="ObtainStatu == true" slot="append">{{timing}}</el-button>
            </el-input>
          </el-form-item>
        </el-form>
        <el-form v-if="active == 1" style="width:95%" ref="enterpriseFromRules" :rules="enterpriseFromRules" :model="enterpriseFrom" class="demo-form-inline" label-width="90px">
          <el-form-item label="企业名称" prop="name">
            <el-input v-model="enterpriseFrom.name" placeholder="请输入企业名称"></el-input>
          </el-form-item>
          <el-form-item label="企业税号" prop="taxNumber">
            <el-input v-model="enterpriseFrom.taxNumber" placeholder="请输入企业税号"></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="telephone">
            <el-input v-model="enterpriseFrom.telephone" placeholder="请输入电话"></el-input>
          </el-form-item>
          <el-form-item label="注册地址" prop="domicile">
            <el-input v-model="enterpriseFrom.domicile" placeholder="请输入注册地址"></el-input>
          </el-form-item>
          <el-form-item label="开户银行" prop="bankName">
            <el-input v-model="enterpriseFrom.bankName" placeholder="请输入开户银行"></el-input>
          </el-form-item>
          <el-form-item label="开户账号" prop="bankNo">
            <el-input v-model="enterpriseFrom.bankNo" placeholder="请输入开户账号"></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="contacts">
            <el-input v-model="enterpriseFrom.contacts" placeholder="请输入联系人"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="contactTel">
            <el-input v-model="enterpriseFrom.contactTel" placeholder="请输入联系电话"></el-input>
          </el-form-item>
          <el-form-item label="联系地址" prop="address">
            <el-input v-model="enterpriseFrom.address" placeholder="请输入联系地址"></el-input>
          </el-form-item>
        </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="active == 0" @click="cancel('userFromRules')">关 闭</el-button>
        <el-button v-if="active == 1" @click="cancel2('enterpriseFromRules')">关 闭</el-button>
        <el-button v-if="active == 0" type="primary" @click="next('userFromRules')">下 一 步</el-button>
        <el-button :disabled='successStatu == true' v-if="active == 1" type="primary" @click="success('enterpriseFromRules')">完 成</el-button>
      </span>
    </el-dialog>
    <div class="footer">
      <el-row align="middle" type="flex" style="height:100%;">
          <el-col :span="24">
              <div style="width:100%;text-align:center;color:#000000">
                  COPYRIGHT 长沙惠工智能科技股份有限公司All Rightreserved. 湘ICP备18002477号
              </div>
          </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { TMap } from '../utils/TMap'
import SIdentify from '../utils/identify.vue'
import md5 from 'js-md5';
import { getKeyRequest } from '../api/demo';
var searchService,geocoder,map,markersArray = [];
var lat = '';
var lng = '';
export default {
  created() {
    this.init()
  },
  components : {
    SIdentify,
  },
  data() {
    return {
      flag:true,  //该值变化，就会触发刷新
      code:'',  //刷新后的验证码
      /* 这是登录表单的数据对象绑定 */
      loginForm: {
        userName: 'hgzkcgs',
        password: '123456',
        validateCode: '',
        tenantId: 1
      },
      loading: false,
      isMultiTnancy: true, //是否开启多租户
      imgCode: '', //验证码
      /* 登录限制规则验证 */
      loginFormRules: {
        userName: [
          { required: true , message: '请输入用户名' , trigger: 'blur' },
          { min: 2 , max: 20 , message: '长度在 2 到 20个字符' , trigger: 'blur' }
        ],
        password: [
          { required: true , message: '请输入密码' , trigger: 'blur' },
          { min: 1 , max: 20 , message: '长度在 1 到 20个字符' , trigger: 'blur' }
        ],
        validateCode: [
          { required: true , message: '请输入验证码' , trigger: 'blur' },
          { min: 4 , max: 4 , message: '长度在 4 个字符' , trigger: 'blur' }
        ]
      },
      // 注册表单打开或者关闭
      dialogVisible: false,
      // 注册表单
      userFrom: {
        account: '',
        password: '',
        mobile: '',
        code: ''
      },
      enterpriseFrom: {
        name: '',
        taxNumber: '',
        telephone: '',
        domicile: '',
        bankName: '',
        bankNo: '',
        contacts: '',
        contactTel: '',
        address: ''
      },
      // 注册步骤显示
      active: 0,
      // 注册用户字段规则验证
      userFromRules: {
        account: [
          { required: true , message: '请输入用户名' , trigger: 'blur' },
          { min: 2 , max: 20 , message: '长度在 2 到 20个字符' , trigger: 'blur' }
        ],
        password: [
          { required: true , message: '请输入密码' , trigger: 'blur' },
          { min: 1 , max: 20 , message: '长度在 1 到 20个字符' , trigger: 'blur' }
        ],
        mobile: [
          { required: true , message: '请输入电话号码' , trigger: 'blur' },
          { min: 11 , max: 11 , message: '长度在 11 个字符' , trigger: 'blur' }
        ],
        code: [
          { required: true , message: '请输入验证码' , trigger: 'blur' }
        ]
      },
      enterpriseFromRules: {
        name: [
          { required: true , message: '请输入企业名称' , trigger: 'blur' },
          { min: 2 , max: 20 , message: '长度在 2 到 20个字符' , trigger: 'blur' }
        ],
        taxNumber: [
          { required: true , message: '请输入企业税号' , trigger: 'blur' },
          { min: 1 , max: 20 , message: '长度在 1 到 20个字符' , trigger: 'blur' }
        ],
        telephone: [
          { required: true , message: '请输入电话' , trigger: 'blur' },
          { min: 11 , max: 13 , message: '长度在 11 到 13 个字符' , trigger: 'blur' }
        ],
        domicile: [
          { required: true , message: '请输入注册地址' , trigger: 'blur' },
          { min: 1 , max: 50 , message: '长度在 1 到 50 个字符' , trigger: 'blur' }
        ],
        bankName: [
          { required: true , message: '请输入开户银行' , trigger: 'blur' },
          { min: 1 , max: 20 , message: '长度在 1 到 20 个字符' , trigger: 'blur' }
        ],
        bankNo: [
          { required: true , message: '请输入开户账号' , trigger: 'blur' },
          { min: 12 , max: 20 , message: '长度在 12 到 20 个字符' , trigger: 'blur' }
        ],
        contacts: [
          { required: true , message: '请输入联系人' , trigger: 'blur' },
          { min: 2 , max: 20 , message: '长度在 2 到 20 个字符' , trigger: 'blur' }
        ],
        contactTel: [
          { required: true , message: '请输入联系人电话' , trigger: 'blur' },
          { min: 1 , max: 13 , message: '长度在 11 到 13 个字符' , trigger: 'blur' }
        ],
        address: [
          { required: true , message: '请输入联系地址' , trigger: 'blur' },
          { min: 1 , max: 50 , message: '长度在 12 到 50 个字符' , trigger: 'blur' }
        ]
      },
      // 注册时验证码按钮状态
      ObtainStatu: false,
      // 注册时发送验证码倒数60秒
      timing: 60,
      // 注册点击完成时候 按钮防抖
      successStatu: false,
      enterpriseName: ''
    }
  },
  methods: {
    async init() {
      const key = await this.getGuidKey() //获取全局Key
        window.sessionStorage.setItem('guidKey', key)
        this.loginGuid = key
      if (!this.isMultiTnancy) {
        this.loginForm.tenantId = 0
      }
    },
    /* 重置事件 */
    resetLoginForm() {
      this.$refs.loginFormRef.resetFields()
    },
    /* 获取全局Key */
    async getGuidKey() {
      // getKeyRequest().then(response => {
        
      // })
      const { data: responseData } = await this.$http.post(this.$getKey)
      return responseData.data
    },
    changeCodeImg() {
      const guidKey = this.loginGuid
      this.imgCode =
        this.$getVerificationCodeUrl +
        '?guidKey=' +
        guidKey +
        '&time=' +
        new Date().getTime()
    },
    /* 登录事件 */
    login() {
      this.$refs.loginFormRef.validate(async (valid, validateObj) => {
        if (!valid) return
        if(this.loginForm.validateCode == this.code ){
          this.loading = true
          const { data: response } = await this.$http.get(this.$getVerificationCodeUrl + 
          '?guidKey=' + this.loginGuid + '&code=' + this.code
          )
          if(response.code != 200){
            return this.$msg.error(responseData.message)
          }else{
            const { data: responseData } = await this.$http.post(this.$loginUrl, {
              userName: this.loginForm.userName,
              pwd: md5(this.loginForm.password),
              tenantId: 1,
              validateCode: this.loginForm.validateCode,
              source: 0,
              guidKey: this.loginGuid,
              enterpriseType: 1
            })
            if (responseData.isSuccess != true){
              this.loading = false
              return this.$message.error(responseData.message)
            }else{
              // 清楚全部缓存
              window.sessionStorage.clear()
              // 存储当前时间
              window.sessionStorage.setItem('tokenTime', new Date())
              // 存储当前token
              window.sessionStorage.setItem('token', responseData.data.token)
              // 获取企业名称并且存储
              this.enterpriseName = responseData.data.enterpriseName
              window.sessionStorage.setItem('enterpriseName', this.enterpriseName)
              // 设置网页网址为企业名称
              // document.title = window.sessionStorage.getItem('enterpriseName')
              // 存储当前企业的logo
              window.sessionStorage.setItem('logo', responseData.data.logo)
              //相关的全局权限处理
              this.$initPermissions(responseData.data.permissionList)
              var data = this.$getMenu()
              var aa = []
              data.forEach( item => {
                  if(item.code !== 'mywork'){
                      aa.push(item.path)
                  }
              })
              // 取出商品相关的树形结构的值
              // 存储当前企业的塌落度
              window.sessionStorage.setItem('slump', JSON.stringify(responseData.data.slump))
              // 存储当前企业的规格
              window.sessionStorage.setItem('standards', JSON.stringify(responseData.data.standards))
              // 存储当前企业的强度等级
              window.sessionStorage.setItem('strengthGrade', JSON.stringify(responseData.data.strengthGrade))
              // 存储当前企业的单位
              window.sessionStorage.setItem('unit', JSON.stringify(responseData.data.unit))
              // 存储当前企业的抗渗等级
              window.sessionStorage.setItem('impermeabilityGrade', JSON.stringify(responseData.data.impermeabilityGrade))
              // 存储当前企业的业务类型
              window.sessionStorage.setItem('business', JSON.stringify(responseData.data.business))
              // 存储当前企业的行业类型
              window.sessionStorage.setItem('industry', JSON.stringify(responseData.data.industry))
              // if(!this.$hasPermission(['Welcome'])){
              //   return;
              // }
              var path = aa[0]
              this.$router.push(path)
              this.loading = false
              this.$message.success('登录成功！')
              await this.$http.get(this.$SysOrder_GetTipsInfo).then(response => {
                // 存储当前公告的值
                window.sessionStorage.setItem('aa', JSON.stringify(response.data.data))
              })
            }
          }
        }else{
            this.$message('验证码不正确，请重新输入')
            this.refreshCode()
        }
      })
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          this.dialogVisible = false
          done();
        })
        .catch(_ => {});
    },
    // 切换验证码
    refreshCode() {
      this.flag = !this.flag;
    },
    getMakedCode(code){
      this.code = code
    },
    // 注册通过手机号码获取验证码
    async Obtain(){
      if(this.ObtainStatu == false){
        const {data} = await this.$http.post(this.$RegisterSendType , {
          phone: this.userFrom.mobile
        })
        if(data.isSuccess !== true){
          this.$message({
            type: 'info',
            message: data.message
          })
        }else{
          // 点击发送验证码之后倒数计时60秒
          this.ObtainStatu = true
          this
          // 添加定时器
          var timer = null
          timer = setInterval(()=>{
            if(this.timing > 0){
              // 每秒减一
              this.timing--
            }else{
              // 小于0的时候清楚定时器
              this.ObtainStatu = false
              clearInterval(timer);
              timer = null
            }
          },1000)
        }
      }else{
        
      }
    },
    // 注册下一步
    next(formName){
      this.$refs[formName].validate( async (valid) => {
        if (valid) {
          const {data} = await this.$http.post(this.$RegisterIsUser , {
            mobile: this.userFrom.mobile,
            account: this.userFrom.account,
            code: this.userFrom.code,
          })
          if(data.isSuccess == true){
            this.active++
            if (this.active > 1) this.active = 0;
            this.enterpriseFrom.mobile = this.userFrom.mobile
          }else{
            this.$message.info(data.message)
          }
        } else {
          return false;
        }
      });
    },
    // 信息填写完成
    success(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.successStatu = true
          var data = {
            account: this.userFrom.account,
            password: md5(this.userFrom.password),
            mobile: this.userFrom.mobile,
            code: this.userFrom.code,
            name: this.enterpriseFrom.name,
            taxNumber: this.enterpriseFrom.taxNumber,
            telephone: this.enterpriseFrom.telephone,
            domicile: this.enterpriseFrom.domicile,
            bankName: this.enterpriseFrom.bankName,
            bankNo: this.enterpriseFrom.bankNo,
            contacts: this.enterpriseFrom.contacts,
            contactTel: this.enterpriseFrom.contactTel,
            address: this.enterpriseFrom.address
          }
          this.$http.post(this.$RegisterUserType,data)
          .then(response => {
            if(response.data.isSuccess == true){
              this.$message.success('注册成功')
              this.dialogVisible = false
              if (this.active++ > 0) this.active = 0;
              this.userFrom = {
                account: '',
                password: '',
                mobile: '',
                code: ''
              },
              this.enterpriseFrom = {
                name: '',
                taxNumber: '',
                mobile: '',
                domicile: '',
                bankName: '',
                bankNo: '',
                contacts: '',
                address: ''
              }
            }else{
              this.$message.warning(response.data.message)
            }
            this.successStatu = false
          })
        } else {
          return false;
        }
      });
    },
    // 用户信息取消
    cancel(formName){
      this.$refs[formName].resetFields();
      this.active = 0
      this.dialogVisible = false
      this.userFrom = {
        account: '',
        password: '',
        mobile: '',
        code: ''
      },
      this.enterpriseFrom = {
        name: '',
        taxNumber: '',
        mobile: '',
        domicile: '',
        bankName: '',
        bankNo: '',
        contacts: '',
        address: ''
      }
    },
    // 企业信息取消
    cancel2(formName){
      this.$refs[formName].resetFields();
      this.active = 0
      this.dialogVisible = false
      this.userFrom = {
        account: '',
        password: '',
        mobile: '',
        code: ''
      },
      this.enterpriseFrom = {
        name: '',
        taxNumber: '',
        mobile: '',
        domicile: '',
        bankName: '',
        bankNo: '',
        contacts: '',
        address: ''
      }
    }
  },
  mounted() {
    this.flag = !this.flag;
    // this.getGuidKey()
  }
}
</script>

<!-- 一定要加入scoped 标识当前组件自己使用，去掉的话，会是全局-->
<style lang="less" scoped>
.login_container {
  // background-image: url('../assets/images/login/商城4.jpg');
  background-color: #dff6ff80;
  background:-webkit-linear-gradient(bottom,white,lightblue,skyblue);
  height: 100%;
  //background-image: url('../assets/images/bg/bg-5.jpg');
  // background-size: 100% 100%;
  // background-size: cover;
}

.login_box {
  width: 413px;
  height: 392px;
  // background-color: #ffff;
  /*   background-image: url("../assets/images/bg/bg-5.jpg");
  background-size: cover; */
  border-radius: 3px;
  position: absolute;
  /**绝对定位**/
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px #ddd;
  /** left 和top 需要和它配置才可以在中间位置**/
  h2 {
    color: rgb(31, 28, 28);
  }
}

.avatar_box {
  height: 100px;
  width: 100px;
  border: 1px solid #eee;
  border-radius: 50%;
  /**圆角**/
  padding: 10px;
  box-shadow: 0 0 10px #ddd;
  /**加入阴影**/
  position: absolute; //绝对定位
  left: 50%;
  transform: translate(-50%, -50%);
  /* 位移 */
  background-color: #fff;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    /**圆角**/
    background-color: #eee;
  }
}

.loginTitle {
  /* font-display: ; */
}

.btns {
  // display: flex;
  /* 弹性盒子模型 */
  // justify-content: flex-end;
  /* 尾部对齐 */
}

.login_form {
  // position: absolute;
  bottom: 0;
  width: 80%;
  padding: 0 20px;
  margin: 0 auto;
  box-sizing: border-box;
  // top: 33%;
}
.login_span{
  text-align: center;
  height: 100px;
  line-height: 100px;
  // background-color: red;
  font-size: 40px;
  color: rgb(250, 250, 250);
  font-family: 黑体;
}
.footer{
    font-size: 13px;
    height: 4%;
    position: fixed;
    bottom: 0px;
    width: 100%;
    color: #d9d9d9;
}
</style>
<style scoped>
/deep/.el-dialog__header {
    padding: 13px 20px 13px;
    background-color: skyblue;
}
</style>
