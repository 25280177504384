<template>
    <div class="body" v-loading="loading" element-loading-text="拼命加载中" element-loading-background="rgb(213 213 213 / 57%)">
        <div style="width:25%;height:100%;min-width:430px">
            <el-card style="width:100%;height:100%">
                <el-input placeholder="输入关键字进行过滤" v-model="filterText"></el-input>
                <el-tree
                    style="margin-top:2%"
                    class="filter-tree"
                    :data="TreeData"
                    :props="defaultProps"
                    default-expand-all
                    :filter-node-method="filterNode"
                    :expand-on-click-node="false"
                    @node-click="handleNodeClick"
                    @node-contextmenu="youjishijian"
                    ref="tree">
                    <span class="custom-tree-node" slot-scope="{ node, data }">
                        <span>{{ node.label }}</span>
                        <span>
                        <el-button
                            style="margin-left: 10px"
                            type="text"
                            v-if="data.pid !== -1 & $hasPermission(['RolePermissions_add'])"
                            icon="el-icon-circle-plus-outline"
                            @click="() => append(data)">
                        </el-button>
                        <el-button
                            style="margin-left: 10px"
                            type="text"
                            icon="el-icon-circle-close"
                            v-if="node.label !== '角色' & data.ji == '三级' & $hasPermission(['RolePermissions_delete']) & data.isActive == false"
                            @click="() => remove(node, data)">
                        </el-button>
                        <el-button
                            style="margin-left: 10px"
                            type="text"
                            icon="el-icon-circle-close"
                            v-if="node.label !== '角色' & data.ji == '二级' & $hasPermission(['RolePermissions_delete']) & data.childrenlength == 0"
                            @click="() => remove(node, data)">
                        </el-button>
                        </span>
                    </span>
                </el-tree>
            </el-card>
        </div>
        <div>
            <el-dialog
                :title="RoleTitle"
                :visible.sync="Roledialog"
                width="30%"
                :show-close="false">
                <el-form style="width:95%" label-width="90px" :model="form" class="demo-form-inline">
                    <el-form-item label="角色名称">
                        <el-input v-model="form.name" placeholder="请输入角色名称"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelRole()">关 闭</el-button>
                    <el-button :disabled="SureRoleButtonStatu == true" type="primary" @click="SureRole()">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div style="width:75%;height:100%;">
            <el-card v-loading="Tree1loading" element-loading-text="拼命加载中" element-loading-background="rgb(213 213 213 / 57%)"  v-if="roleNameFromStatu == true" style="width:100%;height:100%">
                <div style="height:40px">
                    <span>角色权限</span>
                    <el-button type="text" style="float: right; padding: 0px 0; margin-left: 20px" v-if="$hasPermission(['RolePermissions_edit'])" @click="saveRolePermissions()">保存</el-button>
                </div>
                <div style="display:flex;width:100%">
                    <el-card style="width:50%">
                        <span>
                            PC端权限
                        </span>
                        <el-tree
                            style="margin-top: 5px"
                            :data="RoleTreeData"
                            show-checkbox
                            node-key="id"
                            :accordion="true"
                            :props="RoledefaultProps"
                            ref="roleTree"
                            >
                        </el-tree>
                    </el-card>
                    <el-card style="width:50%">
                        <span>
                            小程序权限
                        </span>
                        <el-tree
                            style="width:50%;margin-top: 5px"
                            :data="WXRoleTreeData"
                            show-checkbox
                            node-key="id"
                            :accordion="true"
                            :props="WXRoledefaultProps"
                            ref="WXroleTree"
                            >
                        </el-tree>
                    </el-card>
                </div>
            </el-card>
            <el-card v-loading="Tree2loading" element-loading-text="拼命加载中" element-loading-background="rgb(213 213 213 / 57%)" v-if="UserFromStatu == true" style="width:100%;height:100%">
                <div style="height:40px;width:100%;display:flex">
                    <div style="width:20%;margin-left:50px">
                        <span v-if="UserButtonStatu == 'modify'" style="width">修改人员表单</span>
                        <span v-if="UserButtonStatu == 'add'" style="width">添加人员表单</span>
                    </div>
                </div>
                <div>
                    <el-form style="width:30%;margin-top:-2.5%" ref="UserFrom" :rules="UserFromRule" label-width="100px" :model="UserFrom" class="demo-form-inline">
                        <el-divider></el-divider>
                        <el-form-item label="账号：" prop="account">
                            <el-input v-if="UserFrom.isActive == false" v-model="UserFrom.account" placeholder="请输入账号"></el-input>
                            <span v-else>{{UserFrom.account}}</span>
                        </el-form-item>
                        <el-form-item label="密码：" prop="password">
                            <el-input v-if="UserButtonStatu == 'add'" show-password :disabled="UserButtonStatu == 'modify'" type="password" v-model="UserFrom.password" placeholder="请输入密码"></el-input>
                            <!-- <span v-else>{{UserFrom.password}}</span> -->
                            <span v-else style="font-size:30px;line-height:10px">..........</span>
                        </el-form-item>
                        <el-form-item label="昵称：" prop="userName">
                            <el-input v-if="UserFrom.isActive == false" v-model="UserFrom.userName" placeholder="请输入昵称"></el-input>
                            <span v-else>{{UserFrom.userName}}</span>
                        </el-form-item>
                        <el-form-item label="性别：">
                            <el-radio-group :disabled="UserFrom.isActive == true" v-model="UserFrom.sex">
                                <el-radio :label="0">男</el-radio>
                                <el-radio :label="1">女</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="邮箱：" prop="email">
                            <el-input v-if="UserFrom.isActive == false" v-model="UserFrom.email" placeholder="请输入邮箱"></el-input>
                            <span v-else>{{UserFrom.email}}</span>
                        </el-form-item>
                        <el-form-item label="手机号码：" prop="mobile">
                            <el-input v-if="UserFrom.isActive == false" v-model.number="UserFrom.mobile" maxlength="11" placeholder="请输入手机号码"></el-input>
                            <span v-else>{{UserFrom.mobile}}</span>
                        </el-form-item>
                        <el-form-item label="备注：">
                            <el-input v-if="UserFrom.isActive == false" type="textarea" :autosize="{ minRows: 2, maxRows: 4}" v-model="UserFrom.remark" placeholder="请输入备注"></el-input>
                            <span v-else>{{UserFrom.remark}}</span>
                        </el-form-item>
                        <el-form-item label="">
                            <el-button v-if="UserButtonStatu == 'modify' & UserFrom.isActive == false & $hasPermission(['RolePermissions_audit'])" size="small" type="primary" @click="Audit()">审 核</el-button>
                            <el-button v-if="UserButtonStatu == 'modify' & UserFrom.isActive == true & $hasPermission(['RolePermissions_RestPwd'])" size="small" type="primary" @click="RestPwd()">重置密码</el-button>
                            <el-button v-if="UserButtonStatu == 'modify' & UserFrom.isActive == true & $hasPermission(['RolePermissions_audit'])" size="small" type="primary" @click="CancelAudit()">取消审核</el-button>
                            <el-button v-if="UserButtonStatu == 'modify' & UserFrom.isActive == false & $hasPermission(['RolePermissions_edit'])" size="small" type="primary" @click="SaveUser('UserFrom')">保 存</el-button>
                            <el-button v-if="UserButtonStatu == 'add' & $hasPermission(['RolePermissions_add'])" size="small" type="primary" @click="SaveUser('UserFrom')">保 存</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-card>
            <el-card v-if="UserFromStatu == false & roleNameFromStatu == false" style="width:100%;height:100%">
                
            </el-card>
        </div>
    </div>
</template>
<script>
import md5 from 'js-md5';
export default {
    computed: {
        
    },
    watch: {
        filterText(val) {
            this.$refs.tree.filter(val);
        }
    },
    data(){
        return{
            loading: false,
            Tree1loading: false,
            Tree2loading: false,
            RoleTitle: '',
            filterText: '',
            // 添加角色表单
            Roledialog: false,
            // 角色表单数据
            form: {
                name: '',
                id: ''
            },
            // 角色权限数据
            TreeData: [{
                id: 0,
                name: '角色',
                children: []
            }],
            defaultProps: {
                children: 'children',
                label: 'name'
            },
            // 添加角色名称按钮状态
            SureRoleButtonStatu: false,
            // 判断点击是否是给角色添加名称
            roleNameFromStatu: false,
            // 用于处理节点上面的点击事件
            tree1: 0,
            // 判断点击是否是给角色添加人员
            UserFromStatu: false,
            // 表单数据
            UserFrom: {
                account: '',
                password: '',
                userName: '',
                sex: 0,
                email: '',
                mobile: '',
                remark: '',
                isActive: false,
                id: '',
                userRoleIds: [],
                enterpriseId: null,
            },
            // 表单验证
            UserFromRule: {
                account: [
                    { required: true , message: '请输入账号' , trigger: 'blur' },
                    { min: 1 , max: 20 , message: '长度在 1 到 20个字符' , trigger: 'blur' }
                ],
                password: [
                    { required: true , message: '请输入密码' , trigger: 'blur' }
                ],
                userName: [
                    { required: true , message: '请输入昵称' , trigger: 'blur' },
                    { min: 1 , max: 20 , message: '长度在 1 到 20个字符' , trigger: 'blur' }
                ],
                email: [
                    { required: true , message: '请输入邮箱' , trigger: 'blur' },
                ],
                mobile: [
                    { required: true , message: '请输入手机号码' , trigger: 'blur' }
                ],
            },
            // 人员管理控制显示按钮
            UserButtonStatu: '',
            // 权限数据
            RoleTreeData: [],
            RoledefaultProps: {
                children: 'children',
                label: 'label'
            },
            // 小程序权限数据
            WXRoleTreeData: [],
            WXRoledefaultProps: {
                children: 'children',
                label: 'label'
            },
            // 通过角色权限返回已经选中的节点id
            SelectedData: [],
            // 当前选中的角色id
            roleId: '',
        }
    },
    methods:{
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        // 获取角色树形结构数据
        queryTreeData(){
            this.loading = true
            if(!this.$hasPermission(['RolePermissions_query']))
            {
                return;
            }
            this.TreeData = []
            this.$http.get(this.$GetSysRoleUser).then(response => {
                if(response.data.isSuccess == true){
                    this.TreeData = [{
                        id: 0,
                        name: '角色',
                        children: [],
                        ji: '一级'
                    }],
                    response.data.data.forEach(item => {
                        this.TreeData[0].children.push({
                            id: item.id,
                            name: item.name,
                            children: [],
                            childrenlength: 0,
                            ji: '二级'
                        })
                        this.TreeData[0].children.forEach(element => {
                            if(item.id == element.id){
                                element.childrenlength = item.users.length
                                item.users.forEach( arr => {
                                    element.children.push({
                                        id: arr.id,
                                        name: arr.account,
                                        isActive: arr.isActive,
                                        pid: -1,
                                        ji: '三级'
                                    })
                                })
                            }
                        });
                    })
                    this.loading = false
                }else{

                }
            })
        },
        append(data){
            // 添加角色名称的时候 打开表单
            if(data.id == 0){
                this.Roledialog = true
                this.RoleTitle = '添加角色'
            }else{
                this.UserFrom = {
                    account: '',
                    password: '',
                    userName: '',
                    sex: 0,
                    email: '',
                    mobile: '',
                    remark: '',
                    isActive: false,
                    id: ''
                }
                // 如果点击添加事件 this.tree2 = 1 下面节点事件就不会进行处理
                this.tree1 = 1
                // 打开添加人员页面
                this.UserFromStatu = true
                // 关闭添加权限页面
                this.roleNameFromStatu = false
                // 添加人员按钮状态
                this.UserButtonStatu = 'add'
            }
        },
        youjishijian(data,node){
            if(node.ji == '二级'){
                this.RoleTitle = '修改角色'
                this.form.name = node.name
                this.form.id = node.id
                this.Roledialog = true
                return
            }
        },
        // 添加角色点击确定
        SureRole(){
            // 添加角色名称
            if(this.form.name == ''){
                return this.$message.info('请输入角色名称之后再点击确定哦')
            }else{
                this.SureRoleButtonStatu = true
                if(this.RoleTitle == '添加角色'){
                    this.$http.post(this.$sysRoleManager_CreateSysRoleUrl,{
                        name: this.form.name
                    }).then(response => {
                        this.SureRoleButtonStatu = false
                        if(response.data.isSuccess == true){
                            this.queryTreeData()
                            this.Roledialog = false
                            this.$message.success('添加成功')
                        }else{
                            this.$message.warning(response.data.message)
                        }
                    })
                }else{
                    this.$http.put(this.$sysRoleManager_EditSysRoleUrl,{
                        id: this.form.id,
                        name: this.form.name
                    }).then(response => {
                        this.SureRoleButtonStatu = false
                        if(response.data.isSuccess == true){
                            this.queryTreeData()
                            this.Roledialog = false
                            this.$message.success('修改成功')
                        }else{
                            this.$message.warning(response.data.message)
                        }
                    })
                }
            }
        },
        // 添加角色点击取消
        cancelRole(){
            this.form.name = ''
            this.Roledialog = false
        },
        // 点击对话框之外的区域
        handleClose(done) {
            this.$confirm('确认关闭？')
            .then(_ => {
                done();
                this.form.name = ''
            })
            .catch(_ => {});
        },
        // 删除事件
        remove(node , data){
            // 删除创建的角色名称
            if(node.id !== 0){
                    if(data.pid == -1){
                        this.$confirm('此操作将永久删除该人员, 是否继续?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            this.$http.delete(this.$userManager_DeleteUserUrl+
                            '?id='+ node.data.id
                            ).then(response => {
                                if(response.data.isSuccess == true){
                                    this.$message.success('删除成功')
                                    this.queryTreeData()
                                    // 关闭添加人员页面
                                    this.UserFromStatu = false
                                }else{
                                    this.$message.warning(response.data.message)
                                }
                            })
                        }).catch(() => {
                            this.$message({
                                type: 'info',
                                message: '已取消删除'
                            });          
                        });
                    }else{
                        this.$confirm('此操作将永久删除该角色名称, 是否继续?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            this.$http.delete(this.$sysRoleManager_DeleteSysRoleUrl+
                            '?id='+ node.data.id
                            ).then(response => {
                                if(response.data.isSuccess == true){
                                    this.$message.success('删除成功')
                                    this.queryTreeData()
                                    // 关闭添加权限页面
                                    this.roleNameFromStatu = false
                                }else{
                                    this.$message.warning(response.data.message)
                                }
                            })
                        }).catch(() => {
                            this.$message({
                                type: 'info',
                                message: '已取消删除'
                            });          
                        });
                    }
            }
        },
        // 当节点被点击时候执行的事件
        handleNodeClick(data,node,obj){
            // 当点击添加操作的时候这个节点事件不会执行
            if(this.tree1 == 1){
                this.tree1 = 0
                this.roleId = data.id
                return
            }else{
                if(data.id !== 0 & data.pid !== -1){
                    // 打开加载动画
                    this.Tree1loading = true
                    // 重新赋值菜单数据，角色权限数据
                    this.RoleTreeData = []
                    this.WXRoleTreeData = []
                    this.SelectedData = []
                    // 当前选中的角色id
                    this.roleId = data.id
                    // 再执行查询小程序数据的方法
                    this.queryMenuData(data)
                    // 打开添加权限页面
                    this.roleNameFromStatu = true
                    // 关闭添加人员页面
                    this.UserFromStatu = false
                    // 获取所有菜单
                    this.$http.post(this.$sysPermissionManagerUrl,{
                        queryString: '',
                        source: 0
                    }).then(response => {
                        response.data.data.list.forEach(element => {
                            // 获取一级菜单数据
                            if(element.parentId == 0){
                                this.RoleTreeData.push({
                                    api: element.api,
                                    closable: element.closable,
                                    code: element.code,
                                    description: element.description,
                                    external: element.external,
                                    hidden: element.hidden,
                                    icon: element.icon,
                                    id: element.id,
                                    label: element.label,
                                    newWindow: element.newWindow,
                                    opened: element.opened,
                                    parentId: element.parentId,
                                    path: element.path,
                                    sort: element.sort,
                                    type: element.type,
                                    typeName: element.typeName,
                                    view: element.view,
                                    children: []
                                })
                            }
                            // 获取二级菜单数据
                            this.RoleTreeData.forEach(item => {
                                if(item.id == element.parentId){
                                    item.children.push({
                                        api: element.api,
                                        closable: element.closable,
                                        code: element.code,
                                        description: element.description,
                                        external: element.external,
                                        hidden: element.hidden,
                                        icon: element.icon,
                                        id: element.id,
                                        label: element.label,
                                        newWindow: element.newWindow,
                                        opened: element.opened,
                                        parentId: element.parentId,
                                        path: element.path,
                                        sort: element.sort,
                                        type: element.type,
                                        typeName: element.typeName,
                                        view: element.view,
                                        children: []
                                    })
                                }
                                // 获取三级菜单数据
                                item.children.forEach(arr => {
                                    if(arr.id == element.parentId){
                                        arr.children.push({
                                            api: element.api,
                                            closable: element.closable,
                                            code: element.code,
                                            description: element.description,
                                            external: element.external,
                                            hidden: element.hidden,
                                            icon: element.icon,
                                            id: element.id,
                                            label: element.label,
                                            newWindow: element.newWindow,
                                            opened: element.opened,
                                            parentId: element.parentId,
                                            path: element.path,
                                            sort: element.sort,
                                            type: element.type,
                                            typeName: element.typeName,
                                            view: element.view
                                        })
                                    }
                                });
                            })
                        });
                    })
                    // 获取所有角色权限数据
                    this.$http.get(this.$sysRoleManagerUrl_GetSysRoleListUrl).then(response => {
                        response.data.data.list.forEach(element => {
                            if(data.id == element.id){
                                // 返回角色选中的菜单数据
                                // this.SelectedData = element.permissionIds
                                element.permissionIds.forEach(vlaue => {
                                    // 设置选中节点
                                    this.$refs.roleTree.setChecked(vlaue, true);
                                })
                            }
                        });
                        this.Tree1loading = false
                    })
                }else if(data.pid == -1){
                    // 打开加载动画
                    this.Tree2loading = true
                    // 关闭添加权限页面
                    this.roleNameFromStatu = false
                    // 打开修改人员信息页面
                    this.UserFromStatu = true
                    this.UserButtonStatu = 'modify'
                    this.$http.get(this.$userManager_GetUserUrl
                    +'?id='+data.id).then(response => {
                        this.UserFrom.account = response.data.data.account
                        this.UserFrom.password = response.data.data.password
                        this.UserFrom.userName = response.data.data.userName
                        this.UserFrom.sex = response.data.data.sex
                        this.UserFrom.email = response.data.data.email
                        this.UserFrom.mobile = response.data.data.mobile
                        this.UserFrom.remark = response.data.data.remark
                        this.UserFrom.isActive = response.data.data.isActive
                        this.UserFrom.id = response.data.data.id
                        this.UserFrom.userRoleIds = response.data.data.userRoleIds
                        this.UserFrom.enterpriseId = response.data.data.enterpriseId
                        this.Tree2loading = false
                    })
                }
            }
        },
        // 查询当前菜单的数据
        queryMenuData(data){
            if(this.roleId == ''){
                return
            }
            // 打开加载动画
            this.Tree1loading = true
            // 重新赋值菜单数据，角色权限数据
            this.WXRoleTreeData = []
            this.RoleTreeData = []
            this.SelectedData = []
            // 打开添加权限页面
            this.roleNameFromStatu = true
            // 关闭添加人员页面
            this.UserFromStatu = false
            // 获取所有菜单
            this.$http.post(this.$sysPermissionManagerUrl,{
                queryString: '',
                source: 1
            }).then(response => {
                response.data.data.list.forEach(element => {
                    // 获取一级菜单数据
                    if(element.parentId == 0){
                        this.WXRoleTreeData.push({
                            api: element.api,
                            closable: element.closable,
                            code: element.code,
                            description: element.description,
                            external: element.external,
                            hidden: element.hidden,
                            icon: element.icon,
                            id: element.id,
                            label: element.label,
                            newWindow: element.newWindow,
                            opened: element.opened,
                            parentId: element.parentId,
                            path: element.path,
                            sort: element.sort,
                            type: element.type,
                            typeName: element.typeName,
                            view: element.view,
                            children: []
                        })
                    }
                    // 获取二级菜单数据
                    this.WXRoleTreeData.forEach(item => {
                        if(item.id == element.parentId){
                            item.children.push({
                                api: element.api,
                                closable: element.closable,
                                code: element.code,
                                description: element.description,
                                external: element.external,
                                hidden: element.hidden,
                                icon: element.icon,
                                id: element.id,
                                label: element.label,
                                newWindow: element.newWindow,
                                opened: element.opened,
                                parentId: element.parentId,
                                path: element.path,
                                sort: element.sort,
                                type: element.type,
                                typeName: element.typeName,
                                view: element.view,
                                children: []
                            })
                        }
                        // 获取三级菜单数据
                        item.children.forEach(arr => {
                            if(arr.id == element.parentId){
                                arr.children.push({
                                    api: element.api,
                                    closable: element.closable,
                                    code: element.code,
                                    description: element.description,
                                    external: element.external,
                                    hidden: element.hidden,
                                    icon: element.icon,
                                    id: element.id,
                                    label: element.label,
                                    newWindow: element.newWindow,
                                    opened: element.opened,
                                    parentId: element.parentId,
                                    path: element.path,
                                    sort: element.sort,
                                    type: element.type,
                                    typeName: element.typeName,
                                    view: element.view
                                })
                            }
                        });
                    })
                });
            })
            // 获取所有角色权限数据
            this.$http.get(this.$sysRoleManagerUrl_GetSysRoleListUrl).then(response => {
                response.data.data.list.forEach(element => {
                    if(this.roleId == element.id){
                        // 返回角色选中的菜单数据
                        // this.SelectedData = element.permissionIds
                        element.permissionIds.forEach(vlaue => {
                            // 设置选中节点
                            this.$refs.WXroleTree.setChecked(vlaue, true);
                            this.Tree1loading = false
                        })
                    }
                });
            })
        },
        // 修改当前角色的菜单数据
        saveRolePermissions(){
            this.loading = true
            var idArr = []
            const checkedPermissions = this.$refs.roleTree.getCheckedNodes()
            const checkedPermissions2 = this.$refs.WXroleTree.getCheckedNodes()
            checkedPermissions.forEach(element => {
                idArr.push(element.id)
            });
            checkedPermissions2.forEach(element => {
                idArr.push(element.id)
            });
            this.$http.put(
            this.$sysRoleManager_UpdateSysRolePermissionsUrl,
                {
                id: this.roleId,
                permissionIds: idArr
                }
            ).then(response => {
                this.loading = false
                if (response.data.isSuccess == true) {
                    this.$message.success('更新角色权限成功！')
                }else{
                    this.$message.warning(response.data.message)
                }
            })
        },
        // 添加和修改点击保存
        SaveUser(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if(this.UserFrom.mobile.toString().length !== 11){
                        this.$message.info('手机号码不正确')
                        return
                    }
                    const reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
                    if(!reg.test(this.UserFrom.email)){
                        this.$message.info('请输入正确的邮箱')
                        return
                    }
                    this.loading = true
                    var userRoleIds = []
                    userRoleIds.push(this.roleId)
                    if(this.UserButtonStatu == 'add'){
                        this.$http.post(this.$userManager_CreateUserUrl,{
                            account: this.UserFrom.account,
                            password: md5(this.UserFrom.password),
                            userName: this.UserFrom.userName,
                            sex: this.UserFrom.sex,
                            email: this.UserFrom.email,
                            mobile: this.UserFrom.mobile,
                            remark: this.UserFrom.remark,
                            userRoleIds: userRoleIds
                        }).then(response => {
                            this.loading = false
                            if(response.data.isSuccess == true){
                                this.queryTreeData()
                                this.$message.success('添加成功')
                                this.UserFrom = {
                                    account: '',
                                    password: '',
                                    userName: '',
                                    sex: 0,
                                    email: '',
                                    mobile: '',
                                    remark: '',
                                    isActive: false,
                                    id: ''
                                }
                            }else{
                                this.$message.warning(response.data.message)
                            }
                        })
                    }else if(this.UserButtonStatu == 'modify'){
                        this.$http.put(this.$userManager_EditUserUrl,{
                            account: this.UserFrom.account,
                            password: md5(this.UserFrom.password),
                            userName: this.UserFrom.userName,
                            sex: this.UserFrom.sex,
                            email: this.UserFrom.email,
                            mobile: this.UserFrom.mobile,
                            remark: this.UserFrom.remark,
                            userRoleIds: this.UserFrom.userRoleIds,
                            id: this.UserFrom.id,
                            enterpriseId:this.UserFrom.enterpriseId,
                            isActive: this.UserFrom.isActive,
                        }).then(response => {
                            this.loading = false
                            if(response.data.isSuccess == true){
                                this.queryTreeData()
                                this.$message.success('修改成功')
                            }else{
                                this.$message.warning(response.data.message)
                            }
                        })
                    }
                } else {
                    return false;
                }
            });
        },
        // 审核
        Audit(){
            this.$confirm('此操作将审核该人员, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$userManager_AduitUrl,{
                    id : this.UserFrom.id,
                    isActive : true
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('审核成功')
                        this.queryTreeData()
                        // 关闭添加人员页面
                        this.UserFromStatu = false
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        // 取消审核
        CancelAudit(){
            this.$confirm('此操作将取消审核该人员, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$userManager_AduitUrl,{
                    id : this.UserFrom.id,
                    isActive : false
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('取消审核成功')
                        this.queryTreeData()
                        // 关闭添加人员页面
                        this.UserFromStatu = false
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        // 重置密码
        RestPwd(){
            this.$confirm('此操作将重置该人员密码, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$usermanager_RestUrl,{
                    id : this.UserFrom.id,
                    password: md5('123456'),
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('重置成功')
                        this.queryTreeData()
                        // 关闭添加人员页面
                        this.UserFromStatu = false
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        }
    },
    mounted(){
        this.queryTreeData()
    }
}
</script>
<style scoped>
.body{
    padding: 15px;
    height: 96%;
    width: 98%;
    background-color:  #fdfdfd;
    box-shadow: 2px 1px 1px  #d8d8d8;
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex;
}
/deep/.el-dialog__header {
    padding: 13px 20px 13px;
    background-color: skyblue;
}
.xiangqing{
    color:#67C23A
}
.bianji{
    color: #E6A23C;
}
.shanchu{
    color: #F56C6C ;
}
/deep/.el-dialog__header {
    padding: 13px 20px 13px;
    background-color: skyblue;
}
</style>