<template>
    <div class="body" v-loading="loading" element-loading-text="拼命加载中" element-loading-background="rgb(213 213 213 / 57%)">
        <!-- 查询 -->
        <div>
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item label="地址">
                    <el-input v-model="form.address" placeholder="请输入地址"></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-button type="primary" v-if="$hasPermission(['Partner_AddressManagement'])" @click="query()">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" v-if="$hasPermission(['Partner_AddressManagement'])" @click="add()">新增</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" v-if="$hasPermission(['Partner_AddressManagement']) & AuditiSactive == false" @click="Audit()">审核</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" v-if="$hasPermission(['Partner_AddressManagement']) & AuditiSactive == true" @click="CancelAudit()">取消审核</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- 表格 -->
        <div>
            <el-table
                :header-cell-style="headClass"
                :cell-style="rowClass"
                :data="tableData"
                :height="tableHeight"
                highlight-current-row
                @cell-dblclick="dblclick"
                border
                style="width: 100%">
                <el-table-column
                fixed
                prop="date"
                label="选择"
                width="75">
                    <template slot-scope="scope">
                        <el-radio :label="scope.row.id" v-model="radio" @change.native="getCurrentRow(scope.row)">{{''}}</el-radio>
                    </template>
                </el-table-column>
                <el-table-column
                prop="name"
                :show-overflow-tooltip="true"
                label="厂区名称"
                width="150">
                </el-table-column>
                <el-table-column
                prop="address"
                :show-overflow-tooltip="true"
                label="地址"
                width="">
                </el-table-column>
                <el-table-column
                prop="area"
                label="厂区面积"
                width="">
                </el-table-column>
                <el-table-column
                prop="capacity"
                label="产能"
                width="">
                </el-table-column>
                <el-table-column
                prop="contacts"
                label="负责人"
                width="">
                </el-table-column>
                <el-table-column
                prop="telephone"
                :show-overflow-tooltip="true"
                label="联系电话"
                width="">
                </el-table-column>
                <el-table-column
                prop="isActive"
                :formatter="isActive"
                label="状态"
                width="">
                </el-table-column>
                <el-table-column
                prop="creationTime"
                :show-overflow-tooltip="true"
                label="创建时间"
                width="">
                </el-table-column>
                <el-table-column
                fixed="right"
                label="操作"
                width="150">
                <template slot-scope="scope">
                    <el-button v-if="$hasPermission(['Partner_AddressManagement'])" @click="handleClick(scope.row)" type="text" size="small">详情</el-button>
                    <el-button v-if="$hasPermission(['Partner_AddressManagement']) & scope.row.isActive == false" @click="modify(scope.row)" type="text" size="small">编辑</el-button>
                    <el-button v-if="$hasPermission(['Partner_AddressManagement']) & scope.row.isActive == false" @click="Delete(scope.row)" type="text" size="small">删除</el-button>
                </template>
                </el-table-column>
            </el-table>
            <!-- 表格分页 -->
            <!-- float: right -->
            <div style="margin-left:70%" class="block">
                <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10, 15, 20]"
                :page-size="size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
                </el-pagination>
            </div>
        </div>
        <!-- 表单 -->
        <div>
            <el-dialog
                :title="title"
                :visible.sync="AddressCreatDialog"
                :before-close="handleClose"
                :append-to-body="true"
                :show-close="false"
                style="margin-top:-2%;min-width:500px;"
                width="50%">
                <el-form style="width:95%" ref="addform" :rules="addformRules" :model="addform" label-width="120px" class="demo-form-inline">
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item v-if="formButton == 'details'" label="厂区名称：" prop="">
                                <span>{{addform.name}}</span>
                            </el-form-item>
                            <el-form-item v-else label="厂区名称：" prop="name">
                                <el-input  v-model="addform.name" maxlength="20" placeholder="请输入厂区名称"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item v-if="formButton == 'details'" label="厂区面积(㎡)：" prop="">
                                <span >{{addform.area}}</span>
                            </el-form-item>
                            <el-form-item v-else label="厂区面积(㎡)：" prop="area">
                                <el-input  v-model.number="addform.area" placeholder="请输入厂区面积"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item v-if="formButton == 'details'" label="产能：" prop="">
                                <span >{{addform.capacity}}</span>
                            </el-form-item>
                            <el-form-item v-else label="产能：" prop="capacity">
                                <el-input v-model="addform.capacity" v-input-limit="2" placeholder="请输入产能"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item v-if="formButton == 'details'" label="负责人：" prop="">
                                <span >{{addform.contacts}}</span>
                            </el-form-item>
                            <el-form-item v-else label="负责人：" prop="contacts">
                                <el-input v-model="addform.contacts" maxlength="20" placeholder="请输入负责人"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item v-if="formButton == 'details'" label="联系电话：" prop="">
                                <span >{{addform.telephone}}</span>
                            </el-form-item>
                            <el-form-item v-else label="联系电话：" prop="telephone">
                                <el-input maxlength="11" v-model="addform.telephone" placeholder="请输入联系电话"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="24">
                            <el-form-item v-if="formButton == 'details'" label="生产地址：" prop="">
                                <span >{{addform.address}}</span>
                            </el-form-item>
                            <el-form-item v-else label="生产地址：" prop="address">
                                <el-input v-model="addform.address" maxlength="50" placeholder="请输入生产地址">
                                    <el-button v-if="formButton !== 'details'" @click="searchKeyword()" slot="append">定位</el-button>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="24">
                            <el-form-item label="" label-width="40px" prop="">
                                <div id="qiyeMap" style="height:250px;width:100%;background-color:#f3ecec">
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="AddCancel('addform')">取 消</el-button>
                    <el-button v-if="formButton == 'add'" type="primary" @click="AddSure('addform')">确 定</el-button>
                    <el-button v-if="formButton == 'modify'" type="primary" @click="AddSure('addform')">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
// 引入地图
import { TMap } from '@/utils/TMap'
// 地图参数
var searchService,geocoder,map,markersArray = [];
// 地图经纬度
var lat = '';
var lng = '';
// 地址半径
var radius = 3000;
// var center = '';
export default {
    computed: {
        // tableHeight: document.documentElement.clientHeight
    },
    created() {
        var height = document.documentElement.clientHeight
        this.tableHeight = (height*0.50)+'px';
    },
    data(){
        return{
            loading: false,
            tableHeight: 0,
            form: {
                address: ''
            },
            tableData: [],
            // 表格分页
            page: 1,
            size: 10,
            total: 0,
            // 表格单选绑定
            radio: '',
            // 表单
            AddressCreatDialog: false,
            // 添加表单
            addform: {
                name: '',
                area: '',
                capacity: '',
                contacts: '',
                telephone: '',
                address: '',
                longitude: 0,
                latitude: 0,
                enterpriseName: '',
                enterpriseId: 0,
                warehouseNumber: '0',
                id: 0
            },
            // 添加表单验证
            addformRules: {
                name: [
                    { required: true , message: '请输入厂区名称' , trigger: 'blur' },
                    { min: 1 , max: 20 , message: '长度在 1 到 20个字符' , trigger: 'blur' }
                ],
                area: [
                    { required: true , message: '请输入厂区面积' , trigger: 'blur' },
                    // { type: 'number', message: '厂区面积必须为数字值'}
                ],
                capacity: [
                    { required: true , message: '请输入产能' , trigger: 'blur' },
                    // { type: 'number', message: '产能必须为数字值'}
                ],
                contacts: [
                    { required: true , message: '请输入负责人' , trigger: 'blur' },
                ],
                telephone: [
                    { required: true , message: '请输入联系电话' , trigger: 'blur' }
                ],
                address: [
                    { required: true , message: '请输入生产地址' , trigger: 'blur' },
                    { min: 1 , max: 50 , message: '长度在 1 到 50个字符' , trigger: 'blur' }
                ]
            },
            // dynamicTags: [],
            // 修改
            formButton: 'add',
            // 对话框标题
            title: '新增',
            // 审核id 
            auditid: '',
            AuditiSactive: false,
            PartnersId: ''
        }
    },
    methods:{
        getMsg(id){
            this.PartnersId = id
            this.query()
        },
        // 表头 列 居中
        headClass() {
            return "text-align:center;background-color: #c8e0f391;color:#E6A23C";
        },
        rowClass() {
            return "text-align:center";
        },
        // 分页功能
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.size = val
            this.query()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.page = val
            this.query()
        },
        // 添加仓号
         handleInputConfirm() {
            
        },
        // 删除仓号
        DeleteTag(){
            
        },
        // 表格里面判断
        isActive(row){
            if(row.isActive == true){
                return '已审核';
            }else{
                return '未审核'
            }
        },
        // 查询事件
        query(id){
            if(this.PartnersId == ''){
                return
            }
            this.loading = true
            this.radio = ''
            this.auditid = ''
            if(!this.$hasPermission(['Partner_AddressManagement']))
            {
                return;
            }
            this.$http.post(this.$SysAddress_GetPartnersPageList,{
                currentPage: this.page,
                pageSize: this.size,
                filter: {
                    queryString: this.form.address,
                    enterpriseId: this.PartnersId
                }
            }).then(response => {
                this.tableData = response.data.data.list
                this.total = response.data.data.total
                this.loading = false
            })
        },
        // 新增按钮事件
        add(){
            this.formButton = 'add'
            this.title = '新增'
            this.AddressCreatDialog = true
            this.initMap()
        },
        // 地图初始化函数，本例取名为init，开发者可根据实际情况定义
        initMap(row) {
            TMap('TSCBZ-QE7KW-IFNRH-OYI2A-5L6Z3-ZLBH3').then(qq => {
                var center = new qq.maps.LatLng(28.201195, 113.13224);
                var map = new qq.maps.Map(document.getElementById("qiyeMap"), {
                    center: center,
                    zoom: 20
                });
                var latlngBounds = new qq.maps.LatLngBounds();
                qq.maps.event.addListener(map, "click", function(event) {
                    if (markersArray) {
                        return
                        for (let i in markersArray) {
                            markersArray[i].setMap(null);
                        }
                    }
                    var marker = new qq.maps.Marker({
                    map: map,
                    position: event.latLng
                    });
                    markersArray.push(marker);
                });
                var circle = ''
                geocoder = new qq.maps.Geocoder({
                    complete: function(result) {
                    map.setCenter(result.detail.location);
                    var marker = new qq.maps.Marker({
                        map: map,
                        position: result.detail.location
                    });
                    if (markersArray) {
                        for (var i = 0; i < markersArray.length; i++) {
                            markersArray[i].setMap(null);
                        }
                        markersArray.length = 0;
                    }
                    markersArray.push(marker);
                    lat = result.detail.location.lat
                    lng = result.detail.location.lng
                    center = new qq.maps.LatLng(lat, lng)
                    if(circle !== ''){
                        circle.setMap(null)
                    }
                    circle = new qq.maps.Circle({
                        map:map,
                        center: center,
                        radius: radius,
                        color: 'rgba(41,91,255,0.16)',
                        strokeWeight:2
                    });
                    },
                });
            });
        },
        // 搜索地址
        async searchKeyword() {
            var keyword = this.addform.address;
            var a = this.addform.area/Math.PI
            radius = Math.sqrt(a)
            //this.clearOverlays(markersArray);
            //根据输入的城市设置搜索范围
            // searchService.setLocation("北京");
            //根据输入的关键字在搜索范围内检索
            if (keyword) {
                await geocoder.getLocation(keyword);
            } else {
                this.$message.info('请输入正确的生产地址')
            }
        },
        // 添加点击确定事件
        AddSure(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if(lat == '' || lng == ''){
                        this.$message.warning('请定位生产地址')
                    }else{
                        // var a = ''
                        // this.dynamicTags.forEach(item => {
                        //     if(a == ''){
                        //         a = item
                        //     }else{
                        //         a = a + ',' + item
                        //     }
                        // })
                        // if(this.dynamicTags.length == 0){
                        //     this.$message.warning('请添加仓号')
                        //     return
                        // }
                        if(this.formButton == 'add'){
                            this.$http.post(this.$SysAddress_CreateSysAddress,{
                                name: this.addform.name,
                                area: this.addform.area,
                                capacity: this.addform.capacity,
                                contacts: this.addform.contacts,
                                telephone: this.addform.telephone,
                                address: this.addform.address,
                                longitude: lng,
                                latitude: lat,
                                warehouseNumber: '0',
                                PartnersId: Number(this.PartnersId)
                            }).then( response => {
                                if(response.data.isSuccess == true){
                                    this.query()
                                    this.$message.success('添加成功')
                                    this.addform = {
                                        name: '',
                                        area: '',
                                        capacity: '',
                                        contacts: '',
                                        telephone: '',
                                        address: '',
                                        longitude: 0,
                                        latitude: 0,
                                        enterpriseName: '',
                                        enterpriseId: 0,
                                        id: 0
                                    }
                                    this.AddressCreatDialog = false
                                }else{
                                    this.$message.warning(response.data.message)
                                }
                            })
                        }else if(this.formButton == 'modify'){
                            this.$http.put(this.$SysAddress_UpdateSysAddress,{
                                name: this.addform.name,
                                area: this.addform.area,
                                capacity: this.addform.capacity,
                                contacts: this.addform.contacts,
                                telephone: this.addform.telephone,
                                address: this.addform.address,
                                longitude: lng,
                                latitude: lat,
                                id: this.addform.id,
                                enterpriseId: this.addform.enterpriseId,
                                enterpriseName: this.addform.enterpriseName,
                                warehouseNumber: '0',
                                PartnersId: Number(this.PartnersId)
                            }).then( response => {
                                if(response.data.isSuccess == true){
                                    this.query()
                                    this.$message.success('修改成功')
                                    this.addform = {
                                        name: '',
                                        area: '',
                                        capacity: '',
                                        contacts: '',
                                        telephone: '',
                                        address: '',
                                        longitude: 0,
                                        latitude: 0,
                                        enterpriseName: '',
                                        enterpriseId: 0,
                                        id: 0
                                    }
                                    this.AddressCreatDialog = false
                                }else{
                                    this.$message.warning(response.data.message)
                                }
                            })
                        }
                    }
                } else {
                    return false;
                }
            });
        },
        // 添加点击取消事件
        AddCancel(formName){
            // this.dynamicTags = []
            this.addform = {
                name: '',
                area: '',
                capacity: '',
                contacts: '',
                telephone: '',
                address: '',
                longitude: 0,
                latitude: 0,
                enterpriseName: '',
                enterpriseId: 0,
                id: 0
            }
            this.$refs[formName].resetFields();
            this.AddressCreatDialog = false
        },
        handleClose(done) {
            // this.$confirm('确认关闭？')
            // .then(_ => {
            //     done();
            // })
            // .catch(_ => {});
        },
        handleClick(row) {
            this.title = '详情'
            this.AddressCreatDialog = true
            this.formButton = 'details'
            this.initMap()
            this.loading = true
            this.$http.get(this.$SysAddress_Get+'?id='+row.id).then(response => {
                this.addform.name = response.data.data.name
                this.addform.area = response.data.data.area
                var a = this.addform.area/Math.PI
                radius = Math.sqrt(a)
                this.addform.capacity = response.data.data.capacity
                this.addform.contacts = response.data.data.contacts
                this.addform.telephone = response.data.data.telephone
                this.addform.address = response.data.data.address
                this.addform.id = response.data.data.id
                this.addform.enterpriseId = response.data.data.enterpriseId
                this.addform.enterpriseName = response.data.data.enterpriseName
                lat = response.data.data.latitude
                lng = response.data.data.longitude
            }).then( aa => {
                this.searchKeyword()
            })
            this.loading = false
        },
        dblclick(row){
            this.radio = row.id
            this.getCurrentRow(row)
        },
        getCurrentRow(row){
            this.auditid = row.id
            this.AuditiSactive = row.isActive
            // alert(name)
        },
        // 修改打开表单
        modify(row){
            this.formButton = 'modify'
            this.title = '修改'
            this.AddressCreatDialog = true
            this.initMap()
            this.loading = true
            this.$http.get(this.$SysAddress_Get+'?id='+row.id).then(response => {
                this.addform.name = response.data.data.name
                this.addform.area = response.data.data.area
                var a = this.addform.area/Math.PI
                radius = Math.sqrt(a)
                this.addform.capacity = response.data.data.capacity
                this.addform.contacts = response.data.data.contacts
                this.addform.telephone = response.data.data.telephone
                this.addform.address = response.data.data.address
                this.addform.id = response.data.data.id
                this.addform.enterpriseId = response.data.data.enterpriseId
                this.addform.enterpriseName = response.data.data.enterpriseName
                lat = response.data.data.latitude
                lng = response.data.data.longitude
            }).then(aa => {
                this.searchKeyword()
            })
            this.loading = false
        },
        // 审核
        Audit(){
            if(this.auditid == ''){
                return this.$message.info('请选择数据')
            }
            this.$confirm('此操作将审核该厂区地址, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysAddress_AuditSysAddress,{
                    id : this.auditid,
                    isActive : true
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('审核成功')
                        this.query()
                        this.radio = ''
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        // 取消审核
        CancelAudit(){
            if(this.auditid == ''){
                return this.$message.info('请选择数据')
            }
            this.$confirm('此操作将取消审核该厂区地址, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$SysAddress_AuditSysAddress,{
                    id : this.auditid,
                    isActive : false
                }).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('取消审核成功')
                        this.query()
                        this.radio = ''
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        // 删除
        Delete(row){
            this.$confirm('此操作将删除该厂区, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.delete(this.$SysAddress_DeleteSysAddressById+'?id='+row.id).then(response => {
                    if(response.data.isSuccess == true){
                        this.$message.success('删除成功')
                        this.query()
                    }else{
                        this.$message.warning(response.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
    },
    mounted(){
        // this.query()
    }
}
</script>
<style scoped>
.el-tag + .el-tag {
    margin-left: 10px;
}
.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}
.body{
    padding: 15px;
    height: 96%;
    width: 98%;
    background-color:  #fdfdfd;
    box-shadow: 2px 1px 1px  #d8d8d8;
    border: 1px solid #ddd;
    border-radius: 5px;
}
/deep/.el-dialog__header {
    padding: 13px 20px 13px;
    background-color: skyblue;
}
.xiangqing{
    color:#67C23A
}
.bianji{
    color: #E6A23C;
}
.shanchu{
    color: #F56C6C ;
}
</style>